import React, { useState } from 'react';
import cx from 'classnames';
import { withContentRect } from 'react-measure';
import s from './style.module.css';

const LessonDescription = ({
  lesson: { title, description },
  className,
  index,
  contentRect: { bounds },
  measureRef,
}) => {
  const [expanded, setExpanded] = useState(false);
  const toggleLines = (event) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  const clamped = bounds && !expanded;
  return (
    <section className={cx(s.root, { [s.clamped]: clamped })}>
      <h4 key="title" className={cx(s.title, className)} onClick={toggleLines}>
        {index && <>{index}. </>}
        {title}
      </h4>
      {description && (
        <>
          <p
            ref={measureRef}
            className={s.desc}
            dangerouslySetInnerHTML={{ __html: description }}
          />
          <div className={cx(s.plus, { [s.less]: expanded })} onClick={toggleLines} />
        </>
      )}
    </section>
  );
};

export default withContentRect('bounds')(LessonDescription);
