export default [
  {
    slug: 'gardening',
    name: 'Gardening',
    published: true,
  },
  {
    slug: 'foodanddrink',
    name: 'Food & Drink',
    published: true,
  },
  {
    slug: 'jewellery',
    name: 'Jewellery',
    published: true,
  },
  {
    slug: 'photography',
    name: 'Photography',
    published: true,
  },
  {
    slug: 'floristry',
    name: 'Floristry',
    published: true,
  },
  {
    slug: 'antiques',
    name: 'Antiques',
    published: true,
  },
  {
    slug: 'wellbeing',
    name: 'Wellbeing',
    published: true,
  },
  {
    slug: 'artanddesign',
    name: 'Art & Design',
    published: true,
  },
  {
    slug: 'goldsmiths',
    name: `The Goldsmiths' Centre`,
  },
  {
    slug: 'the-university-of-buckingham',
    name: `The University of Buckingham`,
  },
  {
    slug: 'optimal-ageing',
    name: `Optimal Ageing`,
  },
];
