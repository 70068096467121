import React from 'react';
import { useQuery, gql } from '@apollo/client';
import SchoolNavigation from './components/SchoolNavigation';
import PageNotFound from 'components/PageNotFound';
import { SchoolThemeProvider } from 'components/ThemeProvider';
import { useBlackFriday } from 'hooks/useBlackFriday';

const SCHOOL_QUERY = gql`
  query SchoolQuery($schoolSlug: String!, $productSlug: String = "") {
    school(slug: $schoolSlug) {
      id
      slug
      name

      product(slug: $productSlug) {
        id
        title
      }
    }
  }
`;

const School = ({ children, params: { schoolSlug, productSlug } }) => {
  const variables = { schoolSlug, productSlug };
  const { data, loading } = useQuery(SCHOOL_QUERY, { variables, ssr: true });

  const { showBlackFriday } = useBlackFriday();
  if (loading) return null;

  const school = data?.school;

  if (!school) return <PageNotFound />;
  const showSiteWideBar = showBlackFriday;

  return (
    <SchoolThemeProvider school={school}>
      <SchoolNavigation
        school={school}
        productTitle={school?.product?.title}
        offsetHeader={showSiteWideBar}
      />
      {children}
    </SchoolThemeProvider>
  );
};

export default School;
