import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import ErrorList from 'components/ErrorList';
import arrayMutators from 'final-form-arrays';
import usePrevious from 'hooks/usePrevious';

const Form = (props) => {
  return <FinalForm mutators={arrayMutators} {...props} />;
};

export const toFormErrors = (e) => {
  return {
    [FORM_ERROR]: toErrorList(e),
  };
};

export const toErrorList = (e) => {
  if (e.graphQLErrors) {
    return e.graphQLErrors.flatMap((er) => (er.extensions ? er.extensions.details : er));
  }
  return e;
};

export const GlobalError = () => {
  return (
    <FormSpy subscription={{ submitError: true }}>
      {({ submitError }) => <ErrorList errors={submitError} />}
    </FormSpy>
  );
};

export const InputField = ({ component: Component, ...props }) => {
  return (
    <Field
      {...props}
      render={({ input, ...rest }) => {
        return <Component {...input} {...rest} />;
      }}
    />
  );
};

const SaveOnChange = ({ values, dirty, active, textFields = [], form }) => {
  const previous = usePrevious(active);
  useEffect(() => {
    if (
      dirty &&
      ((!active && !previous) || (!active && previous && ~textFields.indexOf(previous)))
    ) {
      form.submit();
    }
  }, [values, dirty, active]);
  return null;
};

export const AutoSave = (props) => {
  return (
    <FormSpy
      {...props}
      subscription={{ values: true, dirty: true, active: true }}
      component={SaveOnChange}
    />
  );
};

export { Field };
export { InputContainer, InputGroup, RadioContainer, CheckboxContainer } from './Layout';
export { BaseInput, BaseTextArea, BaseCheckbox, BaseRadio, BaseLabel } from './inputs/styles';
export { default as TextInput } from './inputs/TextInput';
export { TextInputWithLeftIcon, TextInputWithRightIcon } from './inputs/TextInput';
export { default as FileInput } from './inputs/FileInput';
export { default as TextArea } from './inputs/TextArea';
export { default as RadioButton } from './inputs/RadioButton';
export { default as Checkbox } from './inputs/Checkbox';
export { default as CardNumberInput } from './inputs/CardNumberInput';
export { default as CardExpiryInput } from './inputs/CardExpiryInput';
export { default as CardCVCInput } from './inputs/CardCVCInput';
export { default as TextField } from './fields/TextField';
export { default as EmailField } from './fields/EmailField';
export { default as PasswordField } from './fields/PasswordField';
export { default as AssetFileField } from './fields/AssetFileField';
export { default as AvatarField } from './fields/AvatarField';
// expo{ default as rt } EditorField from './fields/EditorField' // leave this out of here as it brings medium editor into the main bundle
export { default as FileField } from './fields/FileField';
// export StripePaymentIntentProvider from './providers/StripePaymentIntentProvider';

export default Form;
