import React from 'react';
import SignUpForm from 'components/forms/SignUpForm';
import SignInAndForgottenForm from 'components/forms/SignInAndForgottenForm';
import PasswordResetSent from './components/PasswordResetSent';
import useDialog from 'hooks/useDialog';
import { PrimaryButton } from 'components/Button';
import { DIALOGS } from 'components/Dialogs';
import cx from 'classnames';
import s from './style.module.css';
import { useConfiguration } from 'hooks/configuration';

const Auth = ({
  switchable = true,
  className,
  authType = 'login',
  isFree = false,
  handleClose,
  schoolSlug,
}) => {
  const { features } = useConfiguration();
  const { openDialog } = useDialog();
  const showLogin = () => openDialog(DIALOGS.AUTH, 'login');
  const showJoin = () => openDialog(DIALOGS.AUTH, 'registration');
  const showForgotten = () => openDialog(DIALOGS.AUTH, 'password_reset');
  const showSent = () => openDialog(DIALOGS.AUTH, 'password_reset_sent');
  const canSwitch = features.signup && switchable;
  const showAlreadyMemberLink = authType === 'registration' || authType === 'password_reset_sent';
  return (
    <div className={cx(s.root, className)}>
      {authType === 'registration' && (
        <SignUpForm onSuccess={handleClose} school={schoolSlug} isFree={isFree} />
      )}
      {authType === 'login' && <SignInAndForgottenForm onSuccess={handleClose} />}
      {authType === 'password_reset' && (
        <SignInAndForgottenForm onSuccess={showSent} forgotten={true} />
      )}
      {authType === 'password_reset_sent' && <PasswordResetSent showForgotten={showForgotten} />}
      {canSwitch && (
        <section className={s.switcher}>
          <p>{showAlreadyMemberLink ? 'Already have an account?' : "Don't have an account?"}</p>
          {showAlreadyMemberLink ? (
            <PrimaryButton small onClick={showLogin}>
              Sign in
            </PrimaryButton>
          ) : (
            <PrimaryButton small onClick={showJoin}>
              Join
            </PrimaryButton>
          )}
        </section>
      )}
    </div>
  );
};

export default Auth;
