import React from 'react';
import { withRouter } from 'react-router-v3';
import Helmet from 'react-helmet';
import Page from 'components/Page';
import Section from 'components/Page';
import { SectionHeading } from 'components/Elements';
import s from './style.module.css';

const PageNotFound = ({ router }) => {
  router.location.SSR_HTTP_RESPONSE_CODE = 404;
  return (
    <Page className={s.root}>
      <Helmet title="Learning With Experts - Page not found" />
      <Section>
        <SectionHeading centered>Page Not Found</SectionHeading>
        <p>Sorry, we were unable to find the page you're looking for.</p>
        <p>Please return to our homepage and try again.</p>
      </Section>
    </Page>
  );
};

export default withRouter(PageNotFound);
