import React from 'react';
import { Section } from 'components/Layout';
import LinkList, { InternalLink, ExternalLink, SearchLink } from './components/LinkList';
import s from './style.module.css';

const NoData = () => {
  return (
    <Section wide>
      <div className={s.root}>
        <LinkList heading="Popular searches">
          <SearchLink>RHS Level 2</SearchLink>
          <SearchLink>Sourdough</SearchLink>
          <SearchLink>Photography Foundation</SearchLink>
          <SearchLink>Foraging</SearchLink>
          <SearchLink>Wine appreciation</SearchLink>
        </LinkList>
        <LinkList heading="Quick links">
          <ExternalLink href="http://help.learningwithexperts.com/">
            Frequently asked questions
          </ExternalLink>
          <InternalLink to="/contact">Contact us</InternalLink>
          <InternalLink to="/about">About us</InternalLink>
          <InternalLink to="/gifts">Redeem a gift</InternalLink>
        </LinkList>
      </div>
    </Section>
  );
};

export default NoData;
