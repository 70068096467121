import React from 'react';
import styled from 'styled-components';
import Typography, { font } from 'components/Typography';
import { useConfiguration } from 'hooks/configuration';

const LinksList = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-contents: center;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  list-style-type: none;
`;

const ListItem = styled(Typography).attrs({
  mobile: font.medium.group(4),
  as: 'li',
})`
  padding: 0 0 0 0;
  color: ${({ theme }) => theme.header.color};

  &:first-child:not(:only-child)::after {
    padding: 0 6px 0 6px;
    content: ' / ';
  }
`;

const Link = styled(Typography).attrs({
  mobile: font.medium.group(2),
})`
  color: ${({ theme }) => theme.header.color};

  &:hover {
    cursor: pointer;
  }
`;

const AuthLinks = ({ showLogin, showRegister }) => {
  const { features } = useConfiguration();
  return (
    <LinksList>
      <ListItem>
        <Link onClick={showLogin} data-cy="sign-in">
          Sign in
        </Link>
      </ListItem>
      {features.signup && (
        <ListItem>
          <Link onClick={showRegister} data-cy="join">
            Join
          </Link>
        </ListItem>
      )}
    </LinksList>
  );
};

export default AuthLinks;
