import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

const Title = styled.span`
  text-transform: uppercase;
  color: white;
`;

const BaseLetter = styled.span`
  transition: all 0.3s ease-in-out;
`;

const Letter = ({ letter, color }) => {
  return <BaseLetter style={{ color: color }}>{letter}</BaseLetter>;
};

const colorSet = {
  primary: [
    '#D15A80',
    '#878D06',
    '#7D4CAB',
    '#0080B8',
    '#D89206',
    '#00A585',
    '#0080B8',
    '#D89206',
    '#D15A80',
    '#878D06',
    '#7D4CAB',
  ],
  secondary: [
    '#D89206',
    '#0080B8',
    '#00A585',
    '#D15A7F',
    '#7D4BAB',
    '#878D06',
    '#D15A7F',
    '#00A585',
    '#7D4CAB',
    '#0080B8',
    '#D89206',
  ],
};

const BlackFridayTitle = ({ title = '', suffix = '', animated = false }) => {
  const themeContext = useContext(ThemeContext);
  const [primary, setPrimary] = useState(true);

  useEffect(() => {
    if (animated) {
      const timer = setTimeout(() => setPrimary(!primary), 750);
      return () => clearTimeout(timer);
    }
  }, [animated, primary]);

  const red = themeContext.schools['antiques'].primary;
  const characters = title.split('');

  const colors = colorSet[primary ? 'primary' : 'secondary'];

  let index = 0;

  return (
    <Title>
      {characters.map((letter, idx) => {
        const color = colors[index % colors.length];

        if (letter !== ' ') index += 1;

        return <Letter key={`chars-${idx}`} letter={letter} color={color} />;
      })}
      {suffix && ` `}
      {suffix &&
        suffix.split('').map((letter, index) => {
          return <Letter key={`suffix-${index}`} letter={letter} color={red} />;
        })}
    </Title>
  );
};

export default BlackFridayTitle;
