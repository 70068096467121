import React from 'react';
import Auth from 'components/Auth';
import Dialog from 'components/Dialog';

const authTitle = {
  login: 'Sign in',
  password_reset: 'Forgotten password',
  registration: 'Join us',
};

const AuthDialog = ({
  handleClose,
  dialog: { authType, isFree },
  login,
  isOpen,
  params,
  location,
  schoolSlug,
  ...props
}) => {
  return (
    <Dialog
      title={authTitle[authType]}
      handleClose={handleClose}
      isOpen={isOpen}
      location={location}
      params={params}
      {...props}
    >
      <Auth
        authType={authType}
        params={params}
        location={location}
        handleClose={handleClose}
        schoolSlug={schoolSlug}
        isFree={isFree}
      />
    </Dialog>
  );
};

export default AuthDialog;
