import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useConfiguration } from 'hooks/configuration';

const SPRING_SALE_PROMOTIONS_QUERY = gql`
  query SpringSalePromotionsQuery {
    promotions {
      springSale: group(name: spring) {
        endsAt
      }
    }
  }
`;

export const useSpringSale = () => {
  const { data } = useQuery(SPRING_SALE_PROMOTIONS_QUERY, { ssr: true });
  const configuration = useConfiguration();
  const isUS = configuration?.country?.isoCode === 'US';

  const {
    promotions: { springSale },
  } = data ?? { promotions: {} };

  return useMemo(() => {
    return {
      showSpringSale: !!springSale,
      saleUpToPercentage: isUS ? 30 : 20
    };
  }, [springSale]);
};
