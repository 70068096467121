import React from 'react';
import styled from 'styled-components';
import cx from 'classnames';

const Container = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: 951px) {
    display: none;
  }

  & > .icon {
    display: block;
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: transform 300ms ease;

    & > span {
      user-select: none;
      display: block;
      position: absolute;
      color: transparent;
      top: 11px;
      left: 0px;

      &,
      &:before,
      &:after {
        background-color: ${({ theme }) => theme.header.color};
        position: absolute;
        border-radius: 1px;
        width: 24px;
        height: 2px;
        transition: all 300ms ease;
      }

      &:before,
      &:after {
        content: '';
      }

      &:before {
        top: -11px;
      }

      &:after {
        top: 11px;
        left: 0px;
      }
    }
  }

  &.notify {
    & > .icon {
      &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.alerts.error.color};
        border: 2.5px solid ${({ theme }) => theme.colors.global.white};
        position: absolute;
        right: -7px;
        top: -7px;
        opacity: 1;
        transition: opacity 200ms ease-in-out;
        pointer-events: none;
      }
    }
  }

  &.open {
    & > .icon {
      &:after {
        opacity: 0;
      }

      & > span {
        background: transparent;
        left: -4px;

        &:before,
        &:after {
          width: 32px;
        }

        &:before {
          transform: rotate(-45deg);
          top: 0;
        }
        &:after {
          transform: rotate(45deg);
          top: 0;
        }
      }
    }
  }
`;

const MenuButton = ({ open, notify, ...props }) => {
  return (
    <Container
      className={cx({ open: open, notify: notify })}
      {...props}
      data-cy="nav-toggle-mobile"
    >
      <div className="icon">
        <span>Toggle menu</span>
      </div>
    </Container>
  );
};

export default MenuButton;
