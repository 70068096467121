import React from 'react';
import Form, {
  toFormErrors,
  TextField,
  EmailField,
  PasswordField,
  InputGroup,
  InputField,
  Checkbox,
} from 'components/Form';
import styled from 'styled-components';
import { useRegistration } from '@lwe/toolkit/authentication';
import { Content as BaseContent } from 'components/Layout';
import FacebookAuthButton from 'components/FacebookAuthButton';
import { PrimaryButton } from 'components/Button';
import ErrorList from 'components/ErrorList';
import { NOOP } from 'utils';
import { Title, font, typeset } from 'components/Typography';
import { useConfiguration } from 'hooks/configuration';
import OptInsFields from '../OptInsFields';

const Content = styled(BaseContent)`
  margin: 20px auto 20px auto;
`;

const StyledTerms = typeset({
  mobile: font.book.group(1),
  as: 'p',
});

const SignUpForm = ({ onSuccess = NOOP, inCheckout = false, school, isFree = false }) => {
  const { features } = useConfiguration();
  const { register, newUser } = useRegistration();
  const onSubmit = async ({ firstName, lastName, email, password, optInResponses }) => {
    if (!firstName) return toFormErrors('Please enter your first name');
    if (!lastName) return toFormErrors('Please enter your surname');
    if (!email) return toFormErrors('Please enter your email address');
    if (!password) return toFormErrors('Please enter your password');
    try {
      await register({
        firstName,
        lastName,
        email,
        password,
        signupType: inCheckout ? 'CHECKOUT' : 'SHOP',
        school,
        optInResponses,
      });
      onSuccess();
    } catch (e) {
      return toFormErrors(e);
    }
  };

  return (
    <Content>
      {(!inCheckout && !isFree) && <Title as="h1">Create account</Title>}
      {inCheckout && <Title as="h1">Create account to continue</Title>}
      {!inCheckout && isFree && <Title as="h1">Join now to start your free course</Title>}
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitError }) => {
          return (
            <form onSubmit={handleSubmit}>
              {submitError && <ErrorList errors={submitError} />}
              <InputGroup row>
                <TextField id="firstName" name="firstName" label="First name" />
                <TextField id="lastName" name="lastName" label="Last name" />
              </InputGroup>
              <EmailField
                id="email"
                name="email"
                label="Email address"
                help="We'll use this to keep you informed about your account"
              />
              <PasswordField
                id="password"
                name="password"
                label="Password"
                help="Please pick something unique that you will remember, that is at least 8 characters long."
              />
              {newUser && (
                <OptInsFields
                  optIns={newUser.optInResponses.map(({ optIn }) => optIn)}
                  name="optInResponses"
                />
              )}
              <StyledTerms>
                By creating an account you agree to our{' '}
                <a target="_blank" rel="noreferrer noopener" href="/terms-and-conditions">
                  Terms and conditions
                </a>{' '}
                and{' '}
                <a target="_blank" rel="noreferrer noopener" href="/privacy-policy">
                  Privacy policy
                </a>
                .
              </StyledTerms>
              <InputGroup tight>
                {inCheckout && <PrimaryButton fullWidth>Sign up and continue</PrimaryButton>}
                {!inCheckout && !isFree && <PrimaryButton fullWidth>Sign up</PrimaryButton>}
                {!inCheckout && isFree && (
                  <PrimaryButton fullWidth>Sign up and get started for free</PrimaryButton>
                )}
                {features.facebook && (
                  <FacebookAuthButton
                    onSuccess={onSuccess}
                    label="Sign up with Facebook"
                    inCheckout={inCheckout}
                    school={school}
                  />
                )}
              </InputGroup>
            </form>
          );
        }}
      />
    </Content>
  );
};

export default SignUpForm;
