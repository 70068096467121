import React from 'react';
import Form, { EmailField, PasswordField, InputGroup, toFormErrors } from 'components/Form';
import { PrimaryButton } from 'components/Button';
import FacebookAuthButton from 'components/FacebookAuthButton';
import { NOOP } from 'utils';
import styled from 'styled-components';
import { Content as BaseContent } from 'components/Layout';
import ErrorList from 'components/ErrorList';
import { Title, font } from 'components/Typography';
import { useAuthenticateWithEmail } from '@lwe/toolkit/authentication';
import { useConfiguration } from 'hooks/configuration';

const ForgottenPasswordLink = styled.a`
  ${font.book.group(2)}
  cursor: pointer;
  text-decoration: underline;
`;

const Content = styled(BaseContent)`
  margin: 20px auto 20px auto;
`;

const SignInForm = ({
  onSuccess = NOOP,
  onRequestForgottenPassword,
  cta = 'Sign in and continue',
}) => {
  const { features } = useConfiguration();
  const authenticateWithEmail = useAuthenticateWithEmail();
  const onSubmit = async ({ email, password }) => {
    if (!email) return toFormErrors('Please enter an email address');
    if (!password) return toFormErrors('Please enter your password');
    try {
      await authenticateWithEmail(email, password);
      onSuccess();
    } catch (e) {
      return toFormErrors(e);
    }
  };

  return (
    <Content>
      <Title as="h1">Please sign in to your account</Title>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitError }) => {
          return (
            <form onSubmit={handleSubmit}>
              <InputGroup>
                {submitError && <ErrorList errors={submitError} />}
                <EmailField id="email" name="email" label="Email address" />
                <PasswordField id="password" name="password" label="Password" />
                <InputGroup tight>
                  <PrimaryButton fullWidth>Sign in and continue</PrimaryButton>
                  {features.facebook && (
                    <FacebookAuthButton onSuccess={onSuccess} label="Sign in with Facebook" />
                  )}
                </InputGroup>
                <InputGroup collapsed>
                  {onRequestForgottenPassword && (
                    <ForgottenPasswordLink onClick={onRequestForgottenPassword}>
                      Forgot your password?
                    </ForgottenPasswordLink>
                  )}
                </InputGroup>
              </InputGroup>
            </form>
          );
        }}
      />
    </Content>
  );
};

export default SignInForm;
