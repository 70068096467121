import React from 'react';
import cx from 'classnames';
import ReactPaginate from 'react-paginate';
import { withRouter } from 'react-router-v3';
import s from './style.module.css';

const Pagination = ({
  router,
  hrefBuilder,
  currentPage,
  totalPages,
  handleClick,
  white,
  outline,
  ...props
}) => {
  if (totalPages === 1) return null;
  return (
    <div className={s.root}>
      <ReactPaginate
        containerClassName={cx({ [s.outline]: outline, [s.white]: white })}
        forcePage={currentPage - 1}
        pageCount={totalPages}
        previousLabel={'Previous'}
        nextLabel={'Next'}
        activeClassName={s.active}
        previousClassName={s.previous}
        nextClassName={s.next}
        disabledClassName={s.disabled}
        breakLabel={'...'}
        marginPagesDisplayed={2}
        pageRangeDisplayed={4}
        onPageChange={({ selected }) => router.push(hrefBuilder(selected))}
        hrefBuilder={hrefBuilder}
        {...props}
      />
    </div>
  );
};

export default withRouter(Pagination);
