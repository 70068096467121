import React, { Children } from 'react';
import Nuka from 'nuka-carousel';
import cx from 'classnames';
import PaginationLinks from './components/PaginationLinks';
import s from './style.module.css';

const Carousel = ({
  className,
  wrapAround = true,
  children,
  dots = 'grey',
  inside = false,
  ...props
}) => {
  const slides = Children.count(children);
  const paginated = slides <= 6 && slides > 1;
  if (slides < 2) {
    return <div className={cx(s.nopagination, className)}>{children}</div>;
  }
  return (
    <Nuka
      wrapAround={wrapAround}
      renderCenterLeftControls={null}
      renderCenterRightControls={null}
      renderBottomCenterControls={(props) => (
        <PaginationLinks inside={inside} color={dots} {...props} />
      )}
      getControlsContainerStyles={(key) => {
        if (key === 'BottomCenter') return { bottom: inside ? 30 : -35 };
        return {};
      }}
      className={cx({ [s.pagination]: paginated && !inside }, className)}
      {...props}
    >
      {children}
    </Nuka>
  );
};

export default Carousel;
// export MobileOnlyCarousel from './components/MobileOnlyCarousel';
