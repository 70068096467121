import React, { useState } from 'react';
import SignIn from 'components/forms/SignInForm';
import ForgottenPassword from 'components/forms/ForgottenPasswordForm';

const SignInAndForgottenForm = ({
  onSuccess,
  forgotten: defaultForgotten = false,
  inCheckout = false,
}) => {
  const [forgotten, setForgotten] = useState(defaultForgotten);
  const toggleForgotten = () => setForgotten(!forgotten);

  return (
    <>
      {!forgotten && <SignIn onSuccess={onSuccess} onRequestForgottenPassword={toggleForgotten} />}
      {forgotten && (
        <ForgottenPassword onRequestLogin={toggleForgotten} onSuccess={toggleForgotten} />
      )}
    </>
  );
};

export default SignInAndForgottenForm;
