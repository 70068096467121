import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import Typography, { font } from 'components/Typography';
import HomeLink from 'components/HomeLink';
import TabLink from '../TabLink';
import NavItemBase from '../NavItem';
import { useConfiguration } from 'hooks/configuration';

const newWindow = require(`./assets/icon-newwindow.svg`);

const BaseList = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 3;

  & > li {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
  }
`;

const TABS = {
  COURSES: 'courses',
  EXPERTS: 'experts',
};

const NavItem = ({ toggleTab, active, tab, ...props }) => {
  const handleClick = () => toggleTab(tab);
  return (
    <NavItemBase tab active={active}>
      <TabLink
        aria-haspopup
        aria-expanded={active}
        aria-controls={`${tab}-nav`}
        onClick={handleClick}
        active={active}
        {...props}
      />
    </NavItemBase>
  );
};

const BusinessLink = styled(Typography).attrs({
  mobile: font.book.custom(22, 22),
  as: 'a',
})`
  border: 0;
  padding: 0 0 0 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
`;

const Icon = styled.img`
  height: 16px;
  vertical-align: middle;
  margin-left: 4px;
`;

const PrimaryNav = ({ tab, toggleTab, secure }) => {
  const configuration = useConfiguration();
  const {
    features: { experts, schools },
  } = configuration;
  const handleClick = useCallback(() => toggleTab(null), [toggleTab]);
  return (
    <BaseList data-cy="nav-primary-desktop">
      <li>
        <HomeLink onClick={handleClick} />
      </li>
      {!secure && (
        <>
          {schools && (
            <NavItem toggleTab={toggleTab} active={tab === TABS.COURSES} tab={TABS.COURSES}>
              Courses
            </NavItem>
          )}
          {experts && (
            <NavItem toggleTab={toggleTab} active={tab === TABS.EXPERTS} tab={TABS.EXPERTS}>
              Experts
            </NavItem>
          )}
          <li>
            <BusinessLink
              target="_blank"
              title="Online learning for business"
              href="https://business.learningwithexperts.com/?utm_source=learningwithexperts&utm_medium=lwe_header&utm_campaign=organic_b2b"
            >
              Business
              <Icon src={newWindow} alt="Opens in a new window" />
            </BusinessLink>
          </li>
        </>
      )}
    </BaseList>
  );
};

export default PrimaryNav;
