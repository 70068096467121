import React, { useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import TopHat from './components/TopHat';
import ThemeProvider from 'components/ThemeProvider';
import { useEventBus, USER_AUTHENTICATED } from '@lwe/toolkit/utils';
import { PartnerThemeProvider } from '@lwe/toolkit/theming';

const SHELL_CONFIGURATION_QUERY = gql`
  query ShellConfigurationQuery {
    configuration {
      id
      ...Configuration__partner
    }
  }
  ${PartnerThemeProvider.fragments.partner}
`;

const Shell = ({ location, children }) => {
  const { data } = useQuery(SHELL_CONFIGURATION_QUERY, { ssr: true });

  if (!data) return null;

  return (
    <PartnerThemeProvider theme={data?.configuration?.theme}>
      <ThemeProvider>
        <TopHat location={location} />
        {children}
      </ThemeProvider>
    </PartnerThemeProvider>
  );
};

export default Shell;
