import React from 'react';
import SearchResult, { Heading, Tags, Tag } from 'components/SearchResult';
import { gql } from '@apollo/client';

const PageSearchResult = ({ result: { id, slug, title, school } }) => {
  return (
    <SearchResult to={`/${school.slug}/${slug}`} type="Page">
      <Heading>{title}</Heading>
      <Tags>
        <Tag>Page</Tag>
        <Tag className={school.slug}>{school.name}</Tag>
      </Tags>
    </SearchResult>
  );
};

PageSearchResult.fragments = {
  result: gql`
    fragment PageSearchResult_result on Page {
      id
      slug
      title
      school {
        id
        slug
        name
      }
    }
  `,
};

export default PageSearchResult;
