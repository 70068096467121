import React from 'react';
import ProductCard from 'components/ProductCard';
import s from './style.module.css';

const ProductThumbnail = (props) => {
  return (
    <div className={s.root}>
      <ProductCard coloured thumbnail {...props} />
    </div>
  );
};

export default ProductThumbnail;
