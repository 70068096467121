import styled from 'styled-components';
import { Link } from 'react-router-v3';

export const ExpertLink = styled(Link)`
  font-family: 'Gotham', Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-align: center;
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: static;

  width: 100%;
  height: 100%;

  & > figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & > img {
      height: 100%;
      object-fit: cover;
      object-position: top;
      max-width: 100%;
    }
  }

  & > h3,
  & > p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & > h3 {
    padding: 20px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(255, 255, 255, 0) 40%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }

  & > p {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: ${({ theme, slug }) => theme?.schools?.[slug]?.primary};
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    padding: 20px;
    opacity: 0;
    transition: opacity 0.1s ease-in;
    user-select: none;
    cursor: pointer;
  }

  &:hover {
    & > p {
      opacity: 1;
    }
  }
`;
