import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-v3';
import BaseSection from 'components/Section';
import ErrorList from 'components/ErrorList';
import { useCreateOrder } from 'hooks/basket';
import { useStripe } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import Header from 'components/Basket/components/Header';
import Progress from 'components/Progress';

const Section = styled(BaseSection)`
  min-height: 40vh;
`;

const OrderProcessing = ({ router, params: { basketId }, location }) => {
  const [error, setError] = useState(null);
  const createOrder = useCreateOrder();
  const stripe = useStripe();

  useEffect(() => {
    (async () => {
      if (!basketId) {
        router.push(`/`);
      } else {
        let params = null;
        const clientSecret = location.query.payment_intent_client_secret;
        if (clientSecret) {
          // stripe order
          if (!stripe) {
            return;
          }

          const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
          switch (paymentIntent.status) {
            case 'succeeded':
              params = { stripePaymentIntentId: paymentIntent.id };
              break;
            case 'processing':
              setError('Your payment is processing.');
              break;
            case 'requires_payment_method':
              router.push(`/basket/checkout`);
              break;
            default:
              setError('Something went wrong.');
              break;
          }
        }

        if (params) {
          try {
            const order = await createOrder(params);
            router.push(`/orders/${order.id}`);
          } catch (e) {
            setError(e.messages);
          }
        } else {
          setError({ code: 'payment_error' });
        }
      }
    })();
  }, [stripe, setError, createOrder, router, basketId]);

  return (
    <>
      <Header>
        <h2>Processing your order</h2>
      </Header>
      <Section slim>
        <div style={{ padding: '0 10px', height: error ? 40 : 300, position: 'relative' }}>
          {error && <ErrorList errors={error} />}
          {!error && <Progress loading />}
        </div>
      </Section>
    </>
  );
};

export default withRouter(OrderProcessing);
