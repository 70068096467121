import React from 'react';
import TrustPilot from 'components/TrustPilot';
import { Section } from 'components/Layout';
import cx from 'classnames';
import s from './style.module.css';

const TrustPilotStaticQuote = ({ large = true, color, quote, author = null}) => {
  return (
    <Section className={cx(s.root, { [s.large]: large })} color={color}>
      <TrustPilot templateId="5419b732fbfb950b10de65e5" className={s.trustPilot} height="25px" />
      {quote && (
        <blockquote>
          <p>{quote}</p>
          {author && <cite>{author}</cite>}
        </blockquote>
      )}
    </Section>
  );
};

export default TrustPilotStaticQuote;
