import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

const CHRISTMAS_PROMOTIONS_QUERY = gql`
  query ChristmasPromotionsQuery {
    promotions {
      christmas: group(name: christmas) {
        endsAt
      }
    }
  }
`;

export const useChristmas = () => {
  const { data } = useQuery(CHRISTMAS_PROMOTIONS_QUERY, { ssr: true });

  const {
    promotions: { christmas },
  } = data ?? { promotions: {} };

  return useMemo(() => {
    return {
      showChristmas: !!christmas,
    };
  }, [christmas]);
};
