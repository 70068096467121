import { USER_REGISTERED, USER_AUTHENTICATED, USER_UNAUTHENTICATED } from '@lwe/toolkit/utils';

import {
  PROMO_CODE_APPLIED,
  PRODUCT_IMPRESSIONS,
  PRODUCT_VIEWED,
  TRAILER_PLAY_VIDEO_CLICKED,
  PRODUCT_CLICKED,
  PROMO_CLICKED,
  PROMO_VIEWED,
  PRIVATE_MESSAGE_CREATED,
  ASSIGNMENT_SUBMITTED,
  ASSIGNMENT_MESSAGE_CREATED,
  CLASSROOM_MESSAGE_CREATED,
  MESSAGE_REPLY_CREATED,
  COMMUNITY_QUESTION_CREATED,
  COMMUNITY_ANSWER_CREATED,
  SEAT_STARTED,
  FREE_SEAT_CLAIMED,
  ADDED_TO_BASKET,
  ADD_TO_BASKET_FAILED,
  REMOVED_FROM_BASKET,
  CHECKOUT_STEP,
  SOCIAL_BUTTON_CLICKED,
  BLOG_POST_SIGNUP,
  PRODUCT_BANNER_CLICKED,
  ORDER_CREATED,
  ENTERPRISE_SALE_ENQUIRY,
} from '@root/events';

import { useEventBusSubscription } from '@lwe/toolkit/utils';
import { useCurrentUser } from '@lwe/toolkit/authentication';
import { toLocalId } from '@lwe/toolkit/utils';
import { useMemo } from 'react';

const dataLayer = typeof window !== 'undefined' && (window.dataLayer || (window.dataLayer = []));
const ecommerceEventCategory = 'EnhancedEcommerce';
const ecommerceEventName = 'ecommerceEvent';
const nonInteractionEcommerceEventName = 'nonInteractionEcommerceEvent';

const categoryFromSchool = (school) => {
  return school ? school.slug : 'learningwithexperts';
};

const productData = ({ title: name, id, school, pricing }, currencyCode) => ({
  id: String(id),
  name,
  price: pricing?.from?.current ?? 0,
  brand: categoryFromSchool(school),
  category: categoryFromSchool(school),
});

const productVariantData = (product, variant) => {
  const { title: name, school, id } = product;
  const { name: variantName } = variant;

  return {
    id: String(id),
    name,
    price: variant?.price?.current ?? 0,
    brand: categoryFromSchool(school),
    category: categoryFromSchool(school),
    variant: variantName,
  };
};

// data pushers

const pushToDataLayer = (payload) => {
  if (dataLayer) {
    if (payload.ecommerce) {
      dataLayer.push({
        ecommerce: undefined,
      });
    }
    dataLayer.push(payload);
  } else {
    console.error('No data layer present');
  }
};

const stepToEventLabel = {
  0: 'Begin Checkout',
  1: 'Add Payment Info',
};

const promoEvent = (promo, target = '', eventType, event_action, user) => {
  const { id, name, heading, product } = promo;
  const creative = `${heading.replace('\n', ' ')} - ${product && product.title}`;
  const position = [promo.position, target].filter(Boolean).join(' - ');
  return {
    event: nonInteractionEcommerceEventName,
    event_category: ecommerceEventCategory,
    event_action,
    event_label: undefined,
    event_value: 0,
    event_non_interactive: true,
    ecommerce: {
      [eventType]: {
        promotions: [{ id, name, creative, position }],
      },
    },
    user,
  };
};

const toDataLayerUser = (u) =>
  u
    ? {
        user_id: u.id ? String(u.id) : undefined,
        email: u.email,
        googleAdsFormat: {
          email: u.email,
        },
      }
    : undefined;

const GA3 = () => {
  const { currentUser } = useCurrentUser();
  const user = useMemo(() => toDataLayerUser(currentUser), [currentUser]);

  useEventBusSubscription(USER_REGISTERED, ({ detail: user }) => {
    pushToDataLayer({
      event: 'sign_up',
      event_category: 'Authentication',
      event_action: 'Completed Registration',
      event_label: undefined,
      event_value: 0,
      event_non_interactive: false,
      method: 'Email',
      user: toDataLayerUser(user),
    });
  });

  useEventBusSubscription(USER_AUTHENTICATED, ({ detail: { user } }) => {
    pushToDataLayer({
      event: 'authentication',
      event_category: 'Authentication',
      event_action: String(toLocalId(user.id)),
      event_label: undefined,
      event_value: 0,
      event_non_interactive: true,
      user: toDataLayerUser(user),
    });
  });

  useEventBusSubscription(
    USER_UNAUTHENTICATED,
    () => {
      pushToDataLayer({
        event: 'log_out',
        event_category: 'Authentication',
        event_action: 'Log Out',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: true,
        user: undefined,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PROMO_CODE_APPLIED,
    ({ detail: code }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_category: 'Checkout',
        event_action: code ? 'Added a promo code' : 'Remove a coupon code',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PRODUCT_IMPRESSIONS,
    ({ detail }) => {
      const { products = [], list, currencyCode } = detail;
      if (products.length === 0) return;
      pushToDataLayer({
        event: nonInteractionEcommerceEventName,
        event_category: ecommerceEventCategory,
        event_action: 'Product Impression',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: true,
        ecommerce: {
          currencyCode,
          impressions: products.map((product, position) => {
            return {
              ...productData(product, currencyCode),
              position,
              list,
            };
          }),
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PRODUCT_VIEWED,
    ({ detail: { product, currencyCode } }) => {
      pushToDataLayer({
        event: nonInteractionEcommerceEventName,
        event_category: ecommerceEventCategory,
        event_action: 'Viewed Product',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        ecommerce: {
          currencyCode: currencyCode.toUpperCase(),
          detail: {
            products: [productData(product, currencyCode)],
          },
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    TRAILER_PLAY_VIDEO_CLICKED,
    ({ detail: { product } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_category: 'Product page trailer',
        event_action: 'Clicked play',
        event_label: product.title,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PRODUCT_CLICKED,
    ({ detail: { product, currencyCode, list, position } }) => {
      if (!product) return;
      pushToDataLayer({
        event: ecommerceEventName,
        event_category: ecommerceEventCategory,
        event_action: 'Product Click',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        ecommerce: {
          currencyCode,
          click: {
            actionField: {
              list,
            },
            products: [
              {
                ...productData(product, currencyCode),
                position,
              },
            ],
          },
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PROMO_CLICKED,
    ({ detail: promo }) => {
      const event = promoEvent(promo, null, 'promoClick', 'Promo Clicked', user);
      pushToDataLayer(event);
    },
    [user],
  );

  useEventBusSubscription(
    PROMO_VIEWED,
    ({ detail: promo }) => {
      const event = promoEvent(promo, null, 'promoView', 'Viewed Promo', user);
      pushToDataLayer(event);
    },
    [user],
  );

  useEventBusSubscription(
    ENTERPRISE_SALE_ENQUIRY,
    ({ detail: product }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Enterprise sale enquiry',
        category: 'Shop',
        event_category: product.school.slug,
        event_label: product.title,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PRIVATE_MESSAGE_CREATED,
    ({ detail: { message } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Created private message',
        category: 'Messages',
        event_category: message.user,
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    ASSIGNMENT_SUBMITTED,
    ({ detail: { assignment, lesson } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Submitted an assignment',
        event_category: 'Classroom',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        lesson: lesson.title,
        lessonId: lesson.id,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    ASSIGNMENT_MESSAGE_CREATED,
    ({ detail: { message } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Created an assignment message',
        event_category: 'Assignment',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        fromUserId: message.user,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    CLASSROOM_MESSAGE_CREATED,
    ({ detail: { feedItem } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Created a classroom message',
        event_category: 'Classroom',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        fromUserId: feedItem.entity.userId,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    MESSAGE_REPLY_CREATED,
    ({ detail: { message } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Created a message reply',
        event_category: 'Messages',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        fromUserId: message.user,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    COMMUNITY_QUESTION_CREATED,
    ({ detail: { question, category } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Created question',
        event_category: category.slug,
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    COMMUNITY_ANSWER_CREATED,
    ({ detail: { answer, category } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Created answer',
        event_category: category.slug,
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    SEAT_STARTED,
    ({ detail: { seat } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Started course',
        event_category: 'My Classes',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        courseId: seat.course.id,
        courseTitle: seat.course.title,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    FREE_SEAT_CLAIMED,
    ({ detail: { variant, product } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Claimed Seats',
        event_label: `${product.title} - ${variant.name}`,
        event_category: 'Free Course',
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    ADDED_TO_BASKET,
    ({
      detail: {
        product,
        variant,
        basket: { currencyCode },
      },
    }) => {
      pushToDataLayer({
        event: ecommerceEventName,
        event_category: ecommerceEventCategory,
        event_action: 'Add To Basket',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        ecommerce: {
          currencyCode: currencyCode.toUpperCase(),
          add: {
            products: [
              {
                ...productVariantData(product, variant, currencyCode),
                quantity: 1,
              },
            ],
          },
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    ADD_TO_BASKET_FAILED,
    () => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Add to basket failure',
        event_label: 'Product page',
        event_category: 'Checkout',
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    REMOVED_FROM_BASKET,
    ({
      detail: {
        product,
        variant,
        basket: { currencyCode },
      },
    }) => {
      pushToDataLayer({
        event: ecommerceEventName,
        event_category: ecommerceEventCategory,
        event_action: 'Remove From Basket',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        ecommerce: {
          currencyCode: currencyCode.toUpperCase(),
          remove: {
            products: [],
          },
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    CHECKOUT_STEP,
    ({
      detail: {
        step,
        option,
        basket: { currencyCode, items },
      },
    }) => {
      pushToDataLayer({
        event: ecommerceEventName,
        event_category: ecommerceEventCategory,
        event_action: 'Checkout Step',
        event_label: stepToEventLabel[step],
        event_value: 0,
        event_non_interactive: true,
        ecommerce: {
          currencyCode: currencyCode && currencyCode.toUpperCase(),
          checkout: {
            actionField: {
              step,
              option,
            },
            products: items.map(
              ({
                variant: {
                  price,
                  id,
                  name,
                  product: { title, school },
                },
              }) => ({
                name: title,
                id: String(id),
                price: String(price?.current ?? 0),
                brand: categoryFromSchool(school),
                category: categoryFromSchool(school),
                variant: name,
                quantity: 1,
              }),
            ),
          },
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    SOCIAL_BUTTON_CLICKED,
    ({ detail: { category, label } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Clicked share button',
        event_label: label,
        event_category: category,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    BLOG_POST_SIGNUP,
    ({ detail: { school } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        event_category: 'Blog post',
        event_action: 'Blog post signup',
        event_label: categoryFromSchool(school),
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PRODUCT_BANNER_CLICKED,
    () => {
      pushToDataLayer({
        event: 'GAEvent',
        event_action: 'Clicked Product Banner',
        event_category: 'Promo Banner',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    ORDER_CREATED,
    ({ detail: { order } }) => {
      const { id, items, total, promoCode, currencyCode } = order;
      pushToDataLayer({
        event: ecommerceEventName,
        event_category: ecommerceEventCategory,
        event_action: 'Order Complete',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: true,
        ecommerce: {
          currencyCode,
          purchase: {
            actionField: {
              id: String(id),
              revenue: String(total),
              coupon: promoCode ? promoCode.code : '',
            },
            products: items.map(({ variant, title, price, currencyCode: cc }) => ({
              id: variant.product.id,
              name: title,
              quantity: 1,
              price,
            })),
          },
        },
        user,
      });
    },
    [user],
  );

  return null;
};

export default GA3;
