import React from 'react';
import Section from 'components/Section';
import VignettedSectionBackgroundImage from 'components/VignettedSectionBackgroundImage';
import styled from 'styled-components';
import Typography, { typeset, font } from 'components/Typography';
import { Button as BaseButton, Link as BaseLink } from 'components/Elements';
import BaseProduct from './components/Product';
import s from './style.module.css';

export const Heading = typeset({
  mobile: font.bold.group(6),
  desktop: font.medium.group(10),
  hd: font.medium.group(11),
  white: true,
  as: 'h1',
});

export const Subheading = styled(Typography).attrs({
  mobile: font.book.group(4),
  desktop: font.medium.group(5),
  white: true,
  as: 'h2',
})`
  width: 100%;
  margin-top: 15px;
  max-width: 300px;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 360px;
  }
`;

export const Button = (props) => <BaseButton className={s.button} white large {...props} />;
export const Link = (props) => <BaseLink className={s.link} white button large {...props} />;
export const Product = (props) => <BaseProduct className={s.product} {...props} />;

const HeaderPromoSection = ({ children, image }) => {
  return (
    <Section className={s.root}>
      <VignettedSectionBackgroundImage image={image}>{children}</VignettedSectionBackgroundImage>
    </Section>
  );
};

export default HeaderPromoSection;
