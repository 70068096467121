import React from 'react';
import PropTypes from 'prop-types';
import { RadioContainer } from 'components/Form/Layout';
import { BaseLabel, BaseRadio } from '../styles';

const RadioButton = ({ id, name, label, error, disabled, input, ...props }) => {
  return (
    <RadioContainer error={error}>
      <BaseRadio id={id} name={name} disabled={disabled} {...input} {...props} />
      <BaseLabel htmlFor={id}>{label}</BaseLabel>
    </RadioContainer>
  );
};

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default RadioButton;
