import React from 'react';
import { Link } from 'react-router-v3';
import styled, { css } from 'styled-components';
import { PrimaryButton } from 'components/Button';
import ValentinesSection from '../ValentinesSection';
import Typography, { font } from 'components/Typography';
import { gql } from '@apollo/client';

const heartBackground = require('./assets/2024-background.png');

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 30px 25px 30px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    justify-contents: flex-start;
    align-items: flex-start;
    width: 670px;
    min-height: 375px;
    padding: 43px 0 43px 0;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    padding: 60px 0 60px 0;
    width: 920px;
    min-height: 525px;
  }
`;

const Intro = styled(Typography).attrs({
  mobile: font.medium.group(1),
  hd: font.bold.group(4),
  as: 'p',
  white: true,
})`
  margin: 0 0 15px 0;
  text-transform: uppercase;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 20px 0;
    text-align: left;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    margin: 0 0 25px 0;
  }
`;

const Title = styled(Typography).attrs({
  mobile: font.medium.custom(36, 36),
  desktop: font.medium.custom(46, 46),
  hd: font.medium.custom(64, 64),
  as: 'h1',
  white: true,
})`
  margin: 0 0 10px 0;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 15px 0;
    text-align: left;
    max-width: 350px;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    margin: 0 0 20px 0;
    max-width: 450px;
  }
`;

const ImageContainer = styled.div`
  margin: 0 0 10px 0;
  width: 200px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 318px;
    height: 100%;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    width: 446px;
  }

  & img {
    max-width: 100%;
    clip-path: url(#heartPath);
  }
`;

const SubTitle = styled(Typography).attrs({
  mobile: font.medium.group(4),
  desktop: font.medium.group(3),
  hd: font.medium.group(7),
  as: 'h2',
  white: true,
})`
  margin: 0 0 23px 0;
  text-align: center;

  font-weight: 300;
  & > strong {
    font-weight: 500;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 320px;
    margin: 0 0 18px 0;
    text-align: left;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    margin: 0 0 26px 0;
    max-width: 450px;
  }
`;

const ValentinesDay = ({ school }) => {
  const schoolSlug = school ? school.slug : 'homepage';
  return (
    <ValentinesSection wide>
      <Container>
        <Title>Give the love of learning</Title>
        <ImageContainer>
          <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
            <clipPath id="heartPath" clipPathUnits="objectBoundingBox">
              <path
                d="M0.518976698,0.130287088 C0.535742889,0.10483927 0.555386063,0.0825516383 0.5778229,0.0633317902 C0.648009552,0.00323280356 0.729704971,-0.0140927807 0.82297859,0.0113550375 L0.855607104,0.0263345066 C0.880838026,0.0394190188 0.903274863,0.056744372 0.922917616,0.0783112593 C0.986693442,0.145266557 1.0109397,0.235775409 0.995488076,0.349745412 C0.990556769,0.38674362 0.978310977,0.429784992 0.958668435,0.479146736 C0.91938293,0.576874582 0.862920537,0.657364626 0.789281254,0.720526776 L0.499984075,1 L0.210686895,0.720526776 C0.137047613,0.657360006 0.0805852191,0.576867651 0.0412997141,0.479146736 C0.0216571719,0.429787302 0.00941180092,0.38674362 0.004480073,0.349745412 C-0.010888866,0.235775409 0.0132740761,0.145266557 0.0771325888,0.0783112593 C0.0967751309,0.0567446031 0.119212179,0.0394187878 0.144443101,0.0263345066 L0.177071615,0.0113550375 C0.270353649,-0.0140927807 0.352046965,0.00323372759 0.422143145,0.0633317902 C0.443923541,0.0825523313 0.463482134,0.10484158 0.481071403,0.130287088 L0.499974396,0.161510101 L0.518976698,0.130287088 Z"
                fill="#D8D8D8"
              />
            </clipPath>
          </svg>
          <img src={heartBackground} alt="" />
        </ImageContainer>
        <SubTitle>
          Make Valentine's Day extra special by giving your loved one the gift of knowledge and{' '}
          <strong>get 15% OFF</strong>
        </SubTitle>
        <PrimaryButton inverse as={Link} to="/valentines">
          View Valentine gifts
        </PrimaryButton>
      </Container>
    </ValentinesSection>
  );
};

ValentinesDay.fragments = {
  school: gql`
    fragment ValentinesDay__school on School {
      id
      slug
    }
  `,
  promotions: gql`
    fragment ValentinesDay__promotions on Promotion {
      valentines: group(name: valentines) {
        endsAt
      }
    }
  `,
};

export default ValentinesDay;
