import React from 'react';
import Image from 'components/Image';
import s from './style.module.css';

const ImageBlock = ({ node }) => {
  return (
    <figure className={s.root}>
      <Image src={node.url} alt={node.alt} title={node.title} width={765} />
      {node.caption && <figcaption>{node.caption}</figcaption>}
    </figure>
  );
};

export default ImageBlock;
