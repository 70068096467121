import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useConfiguration } from 'hooks/configuration';

const SUMMER_SALE_PROMOTIONS_QUERY = gql`
  query SummerSalePromotionsQuery {
    promotions {
      summerSale: group(name: summerSale) {
        endsAt
      }
    }
  }
`;

export const useSummerSale = () => {
  const { data } = useQuery(SUMMER_SALE_PROMOTIONS_QUERY, { ssr: true });
  const configuration = useConfiguration();
  const isUS = configuration?.country?.isoCode === 'US';

  const {
    promotions: { summerSale },
  } = data ?? { promotions: {} };

  return useMemo(() => {
    return {
      showSummerSale: !!summerSale,
      saleUpToPercentage: isUS ? 20 : 15
    };
  }, [summerSale]);
};
