import React from 'react';
import { gql } from '@apollo/client';
import { scroller } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';
import HeaderPromoSection, {
  Heading,
  Subheading,
  Button,
  Product,
} from 'components/sections/HeaderPromoSection';

const handleViewCourses = () =>
  scroller.scrollTo('all-courses', {
    duration: 600,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset: -175,
    containerId: 'scroll-body',
  });

const PageHeaderPromo = ({
  name,
  promo,
  promo: { heading, subheading, image, product },
  handleClick,
}) => {
  const isNotMobile = useMediaQuery({ query: '(min-width: 1024px)' });

  const handleHeroClick = () => {
    handleViewCourses();
    handleClick();
  };

  const handleProductClick = () => handleClick();

  return (
    <HeaderPromoSection image={image}>
      <Heading>{heading}</Heading>
      <Subheading>{subheading}</Subheading>
      <Button onClick={handleHeroClick}>View Courses</Button>
      {product && <Product onClick={handleProductClick} product={product} />}
    </HeaderPromoSection>
  );
};

PageHeaderPromo.fragments = {
  promo: gql`
    fragment PageHeaderPromo__promo on PagePromo {
      id
      heading
      subheading
      image {
        id
        uuid
      }
      product {
        id
        title
        slug
        school {
          id
          slug
        }
        tutor {
          id
          profile {
            id
            name
            avatar {
              id
              uuid
            }
          }
        }
      }
    }
  `,
};

export default PageHeaderPromo;
