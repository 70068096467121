import React from 'react';

const ChatIcon = () => {
  return (
    <svg viewBox="0 0 33 31" width="33" height="31" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.5 4.004c-1.105 0-2-.896-2-2.002a2.001 2.001 0 112 2.002zm9-2.002a2.001 2.001 0 10-2 2.002c1.105 0 2-.896 2-2.002zm7 0a2.001 2.001 0 10-2 2.002c1.105 0 2-.896 2-2.002zM30.5 31c1.105 0 2-.896 2-2.002v-4.973c0-3.5-2.269-6.595-5.605-7.643a5.006 5.006 0 002.605-4.415 5.053 5.053 0 00-4.835-4.96 5.007 5.007 0 00-2.555 9.375 8.002 8.002 0 00-4.805 4.14.905.905 0 01-1.61 0 8.002 8.002 0 00-4.805-4.15 5.006 5.006 0 002.61-4.405 5.053 5.053 0 00-4.835-4.96 5.007 5.007 0 00-2.555 9.37A8.007 8.007 0 00.5 24.025v4.973c0 1.106.895 2.002 2 2.002h28z"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default ChatIcon;
