import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { gql, useApolloClient, useQuery } from '@apollo/client';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

const IS_MOBILE_QUERY = gql`
  query IsGenericMobileQuery {
    isMobile @client
  }
`;

const useIsMobile = () => {
  const { data } = useQuery(IS_MOBILE_QUERY, { ssr: true });
  return !!(data && data.isMobile);
};

const Header = (props) => {
  const isMobile = useIsMobile();

  const client = useApolloClient();
  const mediaChange = useCallback(
    (isMobile) =>
      client.writeQuery({
        query: IS_MOBILE_QUERY,
        data: { isMobile },
      }),
    [],
  );

  useMediaQuery({ maxWidth: 950 }, undefined, mediaChange);

  const PlatformSpecificHeader = isMobile ? MobileHeader : DesktopHeader;

  return <PlatformSpecificHeader {...props} />;
};

export default Header;
