import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useApolloClient, gql } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import DesktopNavigation from './components/DesktopNavigation';
import MobileNavigation from './components/MobileNavigation';

const NAVIGATION_QUERY = gql`
  query NavigationQuery($isMobile: Boolean = true) {
    isMobile @client @export(as: "isMobile")
    navigation {
      id
      ...MobileNavigation_navigation @include(if: $isMobile)
      ...DesktopNavigation_navigation @skip(if: $isMobile)
    }
  }
  ${MobileNavigation.fragments.navigation}
  ${DesktopNavigation.fragments.navigation}
`;

const IS_MOBILE_QUERY = gql`
  query IsMobileQuery {
    isMobile @client
  }
`;

const useIsMobile = () => {
  const { data } = useQuery(IS_MOBILE_QUERY, { ssr: true });
  return !!(data && data.isMobile);
};

const Navigation = ({ school, ...props }) => {
  const isMobile = useIsMobile();

  const client = useApolloClient();
  const mediaChange = useCallback(
    (isMobile) =>
      client.writeQuery({
        query: IS_MOBILE_QUERY,
        data: { isMobile },
      }),
    [],
  );
  useMediaQuery({ maxWidth: 949 }, undefined, mediaChange);

  const { data } = useQuery(NAVIGATION_QUERY, { ssr: true });

  if (!data) return null;

  const { navigation } = data;
  const PlatformSpecificNavigation = isMobile ? MobileNavigation : DesktopNavigation;

  return <PlatformSpecificNavigation navigation={navigation} {...props} />;
};

Navigation.propTypes = {
  user: PropTypes.object,
  notifications: PropTypes.array,
  logout: PropTypes.func,
  basket: PropTypes.object,
};

export default Navigation;
