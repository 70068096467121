import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Clock from 'react-clock';
import { scroller } from 'react-scroll';
import { gql } from '@apollo/client';

import Typography, { font } from 'components/Typography';
import { PrimaryButton } from 'components/Button';
import ResponsiveImage from 'components/ResponsiveImage';

const promoImageSmall = require('./assets/9to5_small.png');
const promoImageLarge = require('./assets/9to5_large.png');

import 'react-clock/dist/Clock.css';

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: #d15a80;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
    padding: 50px;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    padding: 60px;
  }
`;

const Content = styled.div`
  position: relative;
  padding: 0 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
    justify-content: space-between;
    width: 800px;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    width: 1100px;
  }
`;

const CallToAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 335px;
    align-items: flex-start;
    text-align: left;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    width: 440px;
  }
`;

const Spacer = styled.div`
  height: 30px;
  width: 30px;
`;

const BusinessButton = styled(PrimaryButton)`
  border: none;
  font-size: 12px;
  padding: 10px 15px;
  border-radius: 3px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 16px;
    font-weight: 500;
    padding: 15px 20px;
    border-radius: 4px;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    font-size: 24px;
    padding: 18px 20px;
  }
`;

const Title = styled(Typography).attrs({
  mobile: font.medium.group(6),
  desktop: font.medium.group(9),
  as: 'h1',
  white: true,
})`
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 10px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 46px;
    line-height: 56px;
    margin-bottom: 20px;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    font-size: 64px;
    line-height: 70px;
  }
`;

const Subtitle = styled(Typography).attrs({
  mobile: font.medium.group(2),
  as: 'span',
  white: true,
})`
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 18px;
  max-width: 320px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    text-align: start;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
    max-width: none;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    font-size: 24px;
    line-height: 32px;
  }
`;

const Visual = styled.div`
  width: 440px;
  height: 440px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transform: scale(0.65);
  margin: -77px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    transform: scale(0.8);
    margin: -56px;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    transform: scale(1);
    margin: 0px;
  }
`;

const Image = styled(ResponsiveImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const MockClock = styled(Clock)`
  .react-clock__face {
    border: none;
  }

  .react-clock__mark__body,
  .react-clock__hand__body {
    border-radius: 6px;
    background: white;
  }

  .react-clock__face::after {
    position: absolute;
    content: '';
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 13px solid white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .react-clock__hand__body {
    bottom: 55% !important;
  }
`;

const handleChooseCourse = () => {
  scroller.scrollTo('all-courses', {
    duration: 800,
    offset: -120,
    containerId: 'scroll-body',
  });
};

const NineToFivePromo = () => {
  const seconds = useRef(0);
  const [clockValue, setclockValue] = useState(new Date(2021, 9, 1, 0, 0, 0));

  useEffect(() => {
    const interval = setInterval(() => {
      seconds.current = seconds.current + 10;

      setclockValue(new Date(2021, 8, 1, 0, 0, seconds.current));
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Section>
      <Content>
        <CallToAction>
          <Title>Shake up your 9 to 5</Title>
          <Subtitle>
            Whether it’s starting a side hustle, improving job skills or a complete career change
            we’ve got the courses and experts to get you there.
          </Subtitle>
          <BusinessButton inverse onMouseDown={handleChooseCourse}>
            Find your course
          </BusinessButton>
        </CallToAction>

        <Spacer />

        <Visual>
          <Image
            urls={{ url: promoImageSmall, retinaUrl: promoImageLarge }}
            width={440}
            height={440}
          />
          <MockClock
            value={clockValue}
            size={375}
            hourHandWidth={13}
            hourHandLength={60}
            hourMarksWidth={13}
            hourMarksLength={22}
            minuteHandWidth={13}
            minuteHandLength={83}
            renderMinuteMarks={false}
            renderSecondHand={false}
          />
        </Visual>
      </Content>
    </Section>
  );
};

NineToFivePromo.fragments = {
  promotions: gql`
    fragment NineToFive__promotions on Promotion {
      nineToFive: group(name: nineToFive) {
        endsAt
      }
    }
  `,
};

export default NineToFivePromo;
