import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import env from '@lwe/toolkit/env';
import { useConfiguration } from 'hooks/configuration';
import Tapfiliate from './components/Tapfiliate';
import Klaviyo from './components/Klaviyo';
import GA3 from './components/GA3';
import GA4 from './components/GA4';
import Helpscout from './components/Helpscout';
import { isSSR } from 'utils';

const loaded = {};

const TopHat = ({ location }) => {
  const configuration = useConfiguration();

  if (!configuration) return null;

  const { settings, features } = configuration;
  const hasHelpscout = features.helpscout;
  const hasTapfiliate = settings?.tapfiliateId && process.env.NODE_ENV === 'production';
  const isProduction = env('ENVIRONMENT') === 'production';

  return (
    <>
      {features.shop && settings.klaviyoApiKey && <Klaviyo apiKey={settings.klaviyoApiKey} />}
      {settings?.gtmContainerId && (
        <>
          <GA3 />
          <GA4 />
        </>
      )}
      {hasHelpscout && <Helpscout />}
      {hasTapfiliate && <Tapfiliate id={settings?.tapfiliateId} />}
      <Helmet defaultTitle={settings?.title} titleTemplate={`%s | ${settings?.title}`}>
        <link rel="canonical" href={`${settings.frontendUrl}${location.pathname}`} />
        {!isSSR && hasHelpscout && (
          <script type="text/javascript">
            {`!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});`}
          </script>
        )}
        {!isSSR && hasHelpscout && settings?.helpscoutAppId && (
          <script type="text/javascript">{`window.Beacon('init', '${settings?.helpscoutAppId}');`}</script>
        )}
        {settings?.gtmContainerId && (
          <script>
            {`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag("consent", "default", {
            "ad_storage": "denied", // OFF by default
            "analytics_storage": "denied",
            "personalization_storage": "denied",
            "functionality_storage": "denied",
            "security_storage": "granted" // Necessary
        });
        gtag("set", "ads_data_redaction", true);
        dataLayer.push({"landingURL":document.location.protocol+"//"+document.location.hostname+document.location.pathname+document.location.search+document.location.hash});

        function initGTM() {
          if (window.gtmInit===true) return false;
          var sgtm_subdomain = "${isProduction ? '' : 'dev.'}mtgs.learningwithexperts.com";
          var gtm_environment_string = "${settings?.gtmContainerParams}";
          (function(w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                "gtm.start": new Date().getTime(),
                event: "gtm.js"
            });
            var dl = l != "dataLayer" ? "&l=" + l : "";
            var j = d.createElement(s);
                j.async = true;
                j.src = "https://" + sgtm_subdomain + "/gtm.js?id=" + i + dl + gtm_environment_string;
                j.setAttribute('data-cookieconsent', 'ignore');
                /* END Whitelist gtm.js so library not blocked */
            var f = d.getElementsByTagName(s)[0];
                f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${settings?.gtmContainerId}');
          window.gtmInit = true;
        }
        window.addEventListener('scroll', initGTM, { once: true })
        window.addEventListener('mousemove', initGTM, { once: true })
        window.addEventListener('touchstart', initGTM, { once: true })
        `}
          </script>
        )}
        {settings?.gtmContainerId && (
          <noscript>
            {`<iframe
            src="https://www.googletagmanager.com/ns.html?id=${settings?.gtmContainerId}${
              settings.gtmContainerParams ?? ''
            }"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
          </noscript>
        )}
      </Helmet>
    </>
  );
};

export default TopHat;
