import React from 'react';
import { useConfiguration } from 'hooks/configuration';

import LweHeader from 'components/Navigation';
import GenericHeader from 'components/tenants/generic/Header';

const Header = (props) => {
  const configuration = useConfiguration();
  if (!configuration) return null;
  const { slug } = configuration;

  const Component = slug === 'lwe' ? LweHeader : GenericHeader;
  return <Component {...props} />;
};

export default Header;
