import React from 'react';
import HowItWorksDialog from './components/HowItWorksDialog';
import HowGiftingWorksDialog from './components/HowGiftingWorksDialog';
import AuthDialog from './components/AuthDialog';
import NavDialog from './components/NavDialog';
import useDialog from 'hooks/useDialog';

export const DIALOGS = {
  HOWITWORKS: 'HOWITWORKS',
  HOWGIFTINGWORKS: 'HOWGIFTINGWORKS',
  PROFILE: 'PROFILE',
  MYCOURSES: 'MYCOURSES',
  AUTH: 'AUTH',
  NAV: 'NAV',
};

const Dialogs = ({ location, ...props }) => {
  const { closeDialog, dialog } = useDialog();
  if (!dialog) return null;

  const dialogProps = (d) => ({
    dialog,
    handleClose: closeDialog,
    location: location,
    isOpen: dialog.current === d,
    ...props,
  });

  return (
    <>
      <HowItWorksDialog {...dialogProps(DIALOGS.HOWITWORKS)} />
      <HowGiftingWorksDialog {...dialogProps(DIALOGS.HOWGIFTINGWORKS)} />
      <AuthDialog {...dialogProps(DIALOGS.AUTH)} />
      <NavDialog {...dialogProps(DIALOGS.NAV)} />
    </>
  );
};

export default Dialogs;
