import styled from 'styled-components';
import { Link } from 'react-router-v3';

export const FixedHeader = styled.header`
  position: fixed;
  z-index: 1;
  height: 60px;
  width: 100%;

  background-color: ${({ theme }) => theme.header.backgroundColor};

  display: flex;
  justify-content: space-between;

  .mobile-navigation {
    height: 100;
    display: flex;
    align-items: center;
    padding: 0 18px;
  }

  .dt-mobile-menu-icon {
    display: inline-flex;
    align-items: center;
    padding: 5px 2px 5px 2px;

    position: relative;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      border-radius: inherit;
      box-sizing: border-box;
    }
  }

  .lines-button {
    height: 16px;
    width: 22px;
    position: relative;
  }

  .menu-line {
    width: 22px;
    height: 2px;
    max-width: 100%;
    position: absolute;
    z-index: 2;
    left: 0;

    &:first-of-type {
      top: 0;
    }

    &:nth-of-type(2) {
      top: 7px;
    }

    &:nth-of-type(3) {
      top: 14px;
    }

    &::before {
      background: ${({ theme }) => theme?.header?.color};
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
    }
  }
`;

export const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 15px;
  max-width: 210px;
`;

export const Logo = styled.img`
  margin: 10px;
  object-fit: contain;
  box-sizing: border-box;
  align-self: center;
`;
