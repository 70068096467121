import React from 'react';
import Avatar from 'components/Avatar';
import { isTutor } from 'utils/roles';
import { Link } from 'components/Elements';
import { gql } from '@apollo/client';
import Clamp from 'shiitake';
import s from './style.module.css';

const Footer = ({ author }) => {
  const path = isTutor(author) ? `/experts/${author.slug}` : '';
  return (
    <footer className={s.root}>
      {author && author.profile && (
        <h3>
          <Link to={path}>{author.profile.name}</Link>
        </h3>
      )}
      <Link to={path} className={s.avatar}>
        <Avatar user={author} size={70} />
      </Link>
      {author && author.profile && (
        <>
          {author.isTutor && (
            <Clamp
              lines={2}
              tagName="p"
              overflowNode={
                <span>
                  ... <Link to={`/experts/${author.slug}`}>Read more</Link>
                </span>
              }
            >
              {author.profile.biog}
            </Clamp>
          )}
          {!author.isTutor && <p>{author.profile.biog}</p>}
        </>
      )}
    </footer>
  );
};

Footer.fragments = {
  author: gql`
    fragment author on User {
      id
      roles
      slug
      isTutor
      profile {
        id
        name
        biog
        avatar {
          id
          uuid
        }
      }
    }
  `,
};

export default Footer;
