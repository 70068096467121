export const toLocal = (id: string | undefined): string[] => {
  if (!id) return [];
  return window.atob(id).split('/');
};

export const toLocalId = (id: string | undefined): string => {
  return toLocal(id)[1];
};

export const toGlobalId = (id: string, type: string) => {
  return window.btoa(`${type}/${id}`).replace(/=+$/, '');
};
