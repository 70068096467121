import React from 'react';
import { gql } from '@apollo/client';
import moment from 'moment';
import Avatar from 'components/Avatar';
import SearchResult, { Heading, Subheading, Tags, Tag } from 'components/SearchResult';

const QuestionSearchResult = ({
  result: {
    slug,
    title,
    createdAt,
    author,
    category: { school },
  },
}) => {
  return (
    <SearchResult
      to={`/${school.slug}/community/questions/${slug}`}
      preview={<Avatar user={author} size={70} />}
    >
      <Heading>{title}</Heading>
      <Subheading>
        By {author.profile.name} • {moment(createdAt).fromNow()}
      </Subheading>
      <Tags>
        <Tag>Community</Tag>
        <Tag className={school.slug}>{school.name}</Tag>
      </Tags>
    </SearchResult>
  );
};

QuestionSearchResult.fragments = {
  result: gql`
    fragment QuestionSearchResult_result on Question {
      id
      slug
      title
      createdAt
      category {
        id
        school {
          id
          slug
          name
        }
      }
      author {
        id
        ...Avatar__user
        profile {
          id
          name
        }
      }
    }
    ${Avatar.fragments.user}
  `,
};

export default QuestionSearchResult;
