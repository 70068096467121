import React, { useCallback } from 'react';
import { Link } from 'react-router-v3';
import { useQuery, gql } from '@apollo/client';
import useDialog from 'hooks/useDialog';
import { DIALOGS } from 'components/Dialogs';
import groupBy from 'lodash/collection/groupBy';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { useMediaQuery } from 'react-responsive';
import { Section } from 'components/Layout';
import { font, typeset } from 'components/Typography';
import Page from 'components/Page';
import ProductCard from 'components/ProductCard';
import ProductGrid from 'components/sections/ProductGrid';
import Header from './components/Header';
import { PrimaryButton as BaseButton } from 'components/Button';
import { useBlackFriday } from 'hooks/useBlackFriday';
import { useCurrentUser } from '@lwe/toolkit/authentication';

const StyledPage = styled(Page)`
  background-color: #252525;
  background-repeat: repeat-y;
  background-position-y: 0, -55px;
  background-size: contain;
  padding-bottom: 80px;
`;

const StyledSection = styled(({ className, ...props }) => (
  <Section containerClassName={className} {...props} />
))`
  margin-bottom: 30px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 50px;
  }
`;

const Title = typeset({
  mobile: font.medium.group(6),
  desktop: font.medium.group(9),
  as: 'h2',
});

const Subtitle = typeset({
  mobile: font.medium.group(7),
  as: 'h2',
});

const Body = typeset({
  mobile: font.medium.group(4),
  desktop: font.medium.group(7),
  as: 'p',
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 45px 0 80px 0;
  padding: 65px;
  width: 320px;
  height: 320px;
  background-color: ${({ theme }) => theme.colors.global.white};
  color: #252525;
  border-radius: 50%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 90px;
    width: 450px;
    height: 450px;
  }

  & > ${Body}:first-child {
    margin-bottom: 20px;
  }
`;

const PrimaryButton = styled(BaseButton)`
  margin-top: 20px;
`;

const List = styled.ul`
  margin: 20px 0 20px 0;
  text-align: center;
`;

const ListItem = styled.li`
  margin: 20px 0 20px 0;
  color: ${({ theme }) => theme.colors.global.white};

  &:before {
    content: '✔ ';
  }
`;

const BLACK_FRIDAY_QUERY = gql`
  query BlackFridayQuery {
    featuredProducts: products(limit: 100, tag: "black_friday_featured", onlyVisible: true) {
      id
      ...ProductCard__product
    }
  }
  ${ProductCard.fragments.product}
`;

const groupedSchools = ['gardening', 'foodanddrink', 'photography'];

const groupBySlug = (product) => {
  if (groupedSchools.includes(product.school.slug)) {
    return product.school.slug;
  }
  return 'other';
};

const BlackFridayPage = () => {
  const { openDialog } = useDialog();
  const { showBlackFriday, blackFridayHasEnded } = useBlackFriday();
  const showRegister = useCallback(() => openDialog(DIALOGS.AUTH, 'registration'), [openDialog]);
  const { currentUser } = useCurrentUser();
  const isMobile = useMediaQuery({ maxWidth: 1023 });

  const titles = {
    gardening: `Shop Gardening Offers`,
    foodanddrink: `Shop Food and Drink Offers`,
    photography: `Shop Photography Offers`,
    other: `More Black Friday Offers`,
  };

  const { data } = useQuery(BLACK_FRIDAY_QUERY, { ssr: true });
  if (!data) return <Page />;

  const { featuredProducts } = data;

  const grouped = groupBy(featuredProducts, groupBySlug);

  const productGroups = [...groupedSchools, 'other'].map((slug) => ({
    title: titles[slug],
    products: grouped[slug],
    school: slug !== 'other' && grouped[slug] && grouped[slug][0].school,
  }));

  return (
    <StyledPage>
      <Helmet
        title="Black Friday online course deals 2023"
        meta={[
          {
            name: 'description',
            content:
              'The latest Black Friday 2023 offers from Learning with Experts. Save on courses with world renowned experts in gardening, food and drink, photography, and more.',
          },
        ]}
      />
      <Header />

      {!blackFridayHasEnded && !showBlackFriday && (
        <StyledSection>
          <Container>
            <Body>
              Our Black Friday Sale starts on 1st November and runs until midnight on 30th November.
            </Body>
            {currentUser ? (
              <>
                <Body>You'll be the first to receive Black Friday updates.</Body>
              </>
            ) : (
              <>
                <Body>Don’t miss out! Sign up for Black Friday updates.</Body>
                <PrimaryButton onClick={showRegister} small={isMobile}>
                  Get updates
                </PrimaryButton>
              </>
            )}
          </Container>
        </StyledSection>
      )}

      {showBlackFriday &&
        productGroups.map(({ school, title, products }) => {
          return (
            <StyledSection key={title}>
              <ProductGrid
                id="featuredcourses"
                white={true}
                products={products}
                heading={title}
                footer={
                  school && (
                    <PrimaryButton inverse as={Link} to={`/${school.slug}`}>
                      View all {school.name}
                    </PrimaryButton>
                  )
                }
                showCardCategoryLink={!school}
                withinBackground
                large
              />
            </StyledSection>
          );
        })}

      {blackFridayHasEnded && (
        <StyledSection>
          <Title white>Has now ended&hellip;</Title>
          <Container>
            <Body>But don't worry we still have plenty of great offers throughout the year.</Body>
            {currentUser ? (
              <>
                <Body>You'll be the first to hear about our offers.</Body>
              </>
            ) : (
              <>
                <Body>Sign up to be the first to hear about them.</Body>
                <PrimaryButton onClick={showRegister} small={isMobile}>
                  Get updates
                </PrimaryButton>
              </>
            )}
          </Container>

          <Subtitle white>Reasons to sign up!</Subtitle>
          <List>
            <ListItem>Join a global learning community</ListItem>
            <ListItem>Be the first to know about new courses</ListItem>
            <ListItem>Get early access to promotions</ListItem>
            <ListItem>Get inspirational content in you inbox</ListItem>
          </List>
        </StyledSection>
      )}
    </StyledPage>
  );
};

export default BlackFridayPage;
