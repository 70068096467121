import React from 'react';
import { Link as BaseLink } from 'react-router-v3';
import styled, { css } from 'styled-components';
import { font, typeset } from 'components/Typography';
import BlackFridayTimer from 'components/promos/BlackFridayTimer';
import { useBlackFriday } from 'hooks/useBlackFriday';

const arrow = require('./icons/arrow.svg');

const BaseHeadline = typeset({
  mobile: font.bold.group(6),
  desktop: font.bold.group(6),
  as: 'p',
});

const BaseSuffix = typeset({
  mobile: font.book.group(6),
  desktop: font.book.group(6),
  as: 'span',
});

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 0;
  color: ${({ theme }) => theme.colors.global.white};
  background-color: #252525;
  height: 50px;
  width: 100%;
  z-index: 12;
  position: fixed;
`;

const Link = styled(BaseLink)`
  padding: 16px 0 16px 0;
  text-decoration: none;
  display: flex;
  flex: 1 0 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > *:nth-child(2) {
    margin-left: 10px;
    display: none;
    @media (min-width: 600px) {
      // margin-right: 30px;
      display: flex;
    }
  }
`;

const Headline = styled(BaseHeadline)`
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: -0.5px;

  & > :nth-child(1) {
    color: ${({ theme }) => theme.schools.floristry.primary};
  }
  & > :nth-child(2) {
    color: ${({ theme }) => theme.schools.foodanddrink.primary};
  }
  & > :nth-child(3) {
    color: ${({ theme }) => theme.schools.artanddesign.primary};
  }

  ${({ desktopOnly }) =>
    desktopOnly &&
    css`
      display: none;

      @media (min-width: 600px) {
        display: block;
      }
    `}
`;

const Suffix = styled(BaseSuffix)`
  color: ${({ theme }) => theme.colors.global.white};
  text-transform: uppercase;
  line-height: 14px;
  letter-spacing: -0.6px;
`;

const Image = styled.img`
  margin-left: 9px;
`;

const BlackFridaySiteWidePromo = () => {
  const { blackFridayCountdown, blackFridaySale, blackFridayComingSoon } = useBlackFriday();
  return (
    <Content>
      <Link to="/blackfriday">
        <Headline as="p" white>
          <span>Black</span> <span>Friday</span> <span>Sale</span>
        </Headline>
        {blackFridayComingSoon && <Suffix>Coming soon</Suffix>}
        {blackFridaySale && <Suffix>Now on</Suffix>}
        {blackFridayCountdown && <BlackFridayTimer expiresAt={blackFridayCountdown.endsAt} />}
        {!blackFridayCountdown && <Image alt="View Black Friday offers" src={arrow} />}
      </Link>
    </Content>
  );
};

export default BlackFridaySiteWidePromo;
