import React from 'react';
import { gql } from '@apollo/client';
import Avatar from 'components/Avatar';
import SearchResult, { Heading, Subheading, Tags, Tag } from 'components/SearchResult';

const TutorSearchResult = ({
  result,
  result: {
    slug,
    school,
    profile: { name, shortBiog },
  },
}) => {
  return (
    <SearchResult to={`/experts/${slug}`} preview={<Avatar user={result} size={70} />}>
      <Heading>{name}</Heading>
      {shortBiog && <Subheading>{shortBiog}</Subheading>}
      <Tags>
        <Tag>Expert</Tag>
        {school && <Tag className={school.slug}>{school.name}</Tag>}
      </Tags>
    </SearchResult>
  );
};

TutorSearchResult.fragments = {
  result: gql`
    fragment TutorSearchResult_result on User {
      id
      slug
      profile {
        id
        name
        shortBiog
      }
      school {
        id
        slug
        name
      }
      ...Avatar__user
    }
    ${Avatar.fragments.user}
  `,
};

export default TutorSearchResult;
