import React from 'react';
import cx from 'classnames';
import s from './style.module.css';

const Icon = ({ name }) => {
  if (!name) return null;
  return <div className={cx(s.root, name.toLowerCase())} />;
};

export default Icon;
