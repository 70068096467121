const formatCurrency = (amount, currencyCode) => {
  const options = {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
  };

  const locale = currencyCode === 'USD' ? 'en-US' : 'en-GB';
  const numberFormatter = new Intl.NumberFormat(locale, options);

  // Use a custom currency symbol for Australian dollars.
  const formattedPrice = currencyCode === 'AUD' ? numberFormatter.format(amount).replace('A$', 'AU$') : numberFormatter.format(amount);

  return formattedPrice;
};

export default formatCurrency;
