import React from 'react';
import cx from 'classnames';
import s from './style.module.css';

const PaginationDots = ({
  nextSlide,
  previousSlide,
  currentSlide,
  goToSlide,
  color,
  slideCount,
  slidesToScroll,
}) => {
  const pages = [];
  for (let i = 0; i < slideCount; i += slidesToScroll) {
    pages.push(i);
  }

  if (pages.length > 6) {
    return (
      <div className={cx(s.pager, { [s.white]: color === 'white', [s.black]: color === 'black' })}>
        <button onClick={previousSlide}>{'<'}</button>
        <span>
          {currentSlide + 1} of {pages.length}
        </span>
        <button onClick={nextSlide}>{'>'}</button>
      </div>
    );
  }

  return (
    <ul className={cx(s.dots, { [s.white]: color === 'white', [s.black]: color === 'black' })}>
      {pages.map((index) => (
        <li key={index}>
          <button
            className={cx({ [s.active]: currentSlide === index })}
            onClick={goToSlide.bind(null, index)}
          >
            <i>&bull;</i>
          </button>
        </li>
      ))}
    </ul>
  );
};

export default PaginationDots;
