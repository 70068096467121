import { useConfiguration } from 'hooks/configuration';
import env from '@lwe/toolkit/env';

import { USER_UNAUTHENTICATED, USER_AUTHENTICATED } from '@lwe/toolkit/utils';

import { useEventBusSubscription } from '@lwe/toolkit/utils';

const Helpscout = () => {
  const configuration = useConfiguration();

  const sendToHelpscout = (event, data) => {
    const { features } = configuration;

    if (features.helpscout) {
      window.Beacon(event, data);
    }
  };

  useEventBusSubscription(USER_AUTHENTICATED, ({ detail: { user } }) => {
    const avatar = user?.profile?.avatar;

    const params = {
      email: user.email,
      name: user.profile.name,
      signature: user.helpscoutSignature,
      ...(avatar && { avatar: `${env('ASSETS_ENDPOINT')}/${avatar.uuid}/600` }),
    };

    sendToHelpscout('identify', params);
  });

  useEventBusSubscription(USER_UNAUTHENTICATED, () => {
    sendToHelpscout('logout');
  });

  return null;
};

export default Helpscout;
