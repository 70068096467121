import React from 'react';
import Collapser from 'components/Collapser';
import Section from 'components/Section';
import { SectionHeading } from 'components/Elements';
import s from './style.module.css';

const About = ({
  expert: {
    profile: { biog },
  },
}) => {
  return (
    <Section className={s.root}>
      <SectionHeading centered>About</SectionHeading>
      <section>
        <Collapser className={s.biog}>{biog}</Collapser>
      </section>
    </Section>
  );
};

export default About;
