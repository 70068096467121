import React from 'react';
import LweFooter from 'components/tenants/lwe/Footer';

import { useConfiguration } from 'hooks/configuration';

const Footer = (props) => {
  const configuration = useConfiguration();
  const { slug }  = configuration;

  return slug === 'lwe' ? <LweFooter {...props} /> : null;
};

export default Footer;
