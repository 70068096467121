import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import useClickOutside from 'click-outside-hook';
import { withRouter } from 'react-router-v3';
import { useSpring, a } from 'react-spring';
import styled from 'styled-components';
import Flyout from './components/Flyout';
import PrimaryNav from './components/PrimaryNav';
import UtilityNav from './components/UtilityNav';
import { useCustomerSupportHidder, useBodyLock } from 'hooks/utils';
import { useCurrentUser } from '@lwe/toolkit/authentication';
import { useEaster } from 'hooks/useEaster';

const Container = styled.section`
  width: 100%;
  height: 90px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  position: fixed;
  top: ${(props) => (props.offsetHeader ? `50px` : `0`)};
  left: 0;

  & > nav {
    position: relative;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.header.color};
    background-color: ${({ theme }) => theme.header.backgroundColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;

    & > div {
      width: 100%;
      height: 100%;
      max-width: 1440px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: #e4e4e4;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
`;

const FlyoutContainer = styled(a.div)`
  position: absolute;
  width: 100%;
  height: 400px;
  background-color: #fafafa;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DesktopNavigation = ({
  location: { pathname },
  navigation,
  offsetHeader = false,
  ...props
}) => {
  const { currentUser, impersonator } = useCurrentUser();
  const { basket, notifications, user, showLogin, showRegister, secure, schoolSlug } = props;

  const DEFAULT_TAB = pathname === '/search' ? 'search' : null;

  const [tab, setTab] = useState(DEFAULT_TAB);

  const drop = tab === 'search' ? 25 : 100;
  const spring = useSpring({ transform: `translateY(${tab ? drop : 0}%)` });

  const open = !!tab && tab !== 'search';
  const toggleTab = (index) => setTab(tab === index ? DEFAULT_TAB : index);

  const handleClose = () => setTab(DEFAULT_TAB);

  const ref = useClickOutside(handleClose);

  useEffect(() => {
    setTab(DEFAULT_TAB);
  }, [pathname]);

  useCustomerSupportHidder(open);
  useBodyLock(open);

  return (
    <Container ref={ref} id="navi" offsetHeader={offsetHeader}>
      <nav>
        <div>
          <PrimaryNav tab={tab} toggleTab={toggleTab} secure={secure} />
          <UtilityNav
            user={currentUser}
            impersonator={impersonator}
            basket={basket}
            notifications={notifications}
            showLogin={showLogin}
            showRegister={showRegister}
            handleClose={handleClose}
            secure={secure}
            tab={tab}
            toggleTab={toggleTab}
          />
        </div>
      </nav>
      <FlyoutContainer style={spring}>
        <Flyout tab={tab} handleClose={handleClose} navigation={navigation} />
      </FlyoutContainer>
    </Container>
  );
};

DesktopNavigation.fragments = {
  navigation: gql`
    fragment DesktopNavigation_navigation on Navigation {
      id
      ...Flyout_navigation
    }
    ${Flyout.fragments.navigation}
  `,
};

export default withRouter(DesktopNavigation);
