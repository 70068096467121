import React from 'react';
import Helmet from 'react-helmet';
import { useQuery, gql } from '@apollo/client';
import Page from 'components/Page';
import Header from './components/Header';
import ExpertsRow from './components/ExpertsRow';
import index from 'utils/schools';

const EXPERTS_QUERY = gql`
  query ExpertsQuery {
    schools {
      id
      slug
      name
      experts: tutors {
        id
        ...tutor
      }
    }
  }
  ${ExpertsRow.fragments.tutor}
`;

const Experts = () => {
  const { data, loading, error } = useQuery(EXPERTS_QUERY);
  if (!data || loading) return <Page />;
  const { schools } = data;
  const orderedSchools = index
    .filter((s) => s.published)
    .map(({ slug }) => schools.find((sc) => sc.slug === slug));
  return (
    <Page>
      <Helmet title="Our experts" />
      <section>
        <Header />
        {orderedSchools.map((school) => (
          <ExpertsRow school={school} key={school.id} />
        ))}
      </section>
    </Page>
  );
};

export default Experts;
