import React from 'react';
import { Link as BaseLink } from 'react-router-v3';
import styled, { css } from 'styled-components';
import Typography, { font } from 'components/Typography';
import { gql } from '@apollo/client';
import { assetUrl } from 'components/AssetImage';
import { useChristmas } from 'hooks/useChristmas';

import xmasBG from './assets/Xmas_background.svg';

const xmasBGStyles = css`
  background-image: url(${xmasBG});
  background-position: center;
  background-repeat: repeat-x;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.schools.gardening.primary};
  padding: 25px 15px 30px 15px;

  ${({ showChristmas }) => showChristmas && xmasBGStyles}

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 40px 45px 65px 45px;
  }
`;

const Title = styled(Typography).attrs({
  mobile: font.bold.group(6),
  desktop: font.medium.group(10),
  as: 'h1',
  white: true,
})`
  margin: 0 0 8px 0;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 10px 0;
  }
`;

const Subtitle = styled(Typography).attrs({
  mobile: font.medium.group(2),
  as: 'h3',
  white: true,
})`
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 25px;
  text-align: center;
  max-width: 825px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 45px 0;
  }
`;

const CategoryList = styled.ul`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-template-rows: repeat(4, min-content);
  grid-column-gap: 40px;
  grid-row-gap: 30px;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    grid-template-columns: repeat(4, min-content);
    grid-template-rows: repeat(2, min-content);
    grid-column-gap: 60px;
    grid-row-gap: 40px;
  }
`;

const ListItem = styled(Typography).attrs({
  mobile: font.medium.group(2),
  desktop: font.medium.group(3),
  as: 'li',
})``;

const CategoryImage = styled.div`
  margin: 0 0 15px 0;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 120px;

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${backgroundImage});
    `}

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 20px 0;
    width: 150px;
    height: 150px;
    background-size: auto 150px;
  }
`;

const CategoryTitle = styled(Typography).attrs({
  as: 'h3',
  white: true,
})`
  text-align: center;
  white-space: nowrap;
`;

const Link = styled(BaseLink)`
  text-decoration: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.global.white};

  & > ${CategoryTitle} {
    border-bottom: 1px solid transparent;
  }

  &:hover {
    & > ${CategoryImage} {
      filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
      transition: filter 0.1s ease-in;
    }

    & > ${CategoryTitle} {
      border-bottom-color: ${({ theme }) => theme.colors.global.white};
      transition: border-bottom-color 0.1s ease-in;
    }
  }
`;

const HeroCategoriesPanel = ({ schools }) => {
  if (!schools) return null;
  const { showChristmas } = useChristmas();

  return (
    <Container showChristmas={showChristmas}>
      <Title>Online learning that works</Title>
      <Subtitle>
        Don’t just watch! Practice what you learn with course assignments, get personal feedback
        from expert tutors and learn together in small classroom communities.
      </Subtitle>
      <CategoryList>
        {schools.map(({ id, files, name, slug }) => {
          const backgroundImageType = files?.avatar ?? files?.grid;
          const backgroundImage = backgroundImageType
            ? assetUrl(backgroundImageType?.uuid, '600', '600')
            : '';

          return (
            <ListItem key={id}>
              <Link to={`/${slug}`} school={slug}>
                <CategoryImage backgroundImage={backgroundImage} />
                <CategoryTitle>{name} courses</CategoryTitle>
              </Link>
            </ListItem>
          );
        })}
      </CategoryList>
    </Container>
  );
};

HeroCategoriesPanel.fragments = {
  schools: gql`
    fragment HeroCategoriesPanel__schools on Navigation {
      id
      schools {
        id
        name
        slug
        files {
          avatar {
            id
            uuid
          }
          grid {
            id
            uuid
          }
        }
      }
    }
  `,
};

export default HeroCategoriesPanel;
