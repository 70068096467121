import React, { useRef, useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import env from '@lwe/toolkit/env';
import { useCreatePaymentIntent } from 'hooks/basket';
import { withRouter } from 'react-router-v3';

const appearance = {
  theme: 'stripe',
};

const StripePaymentIntentProvider = ({ total, children, withoutPaymentIntent }) => {
  const stripeRef = useRef(loadStripe(env('STRIPE_KEY')));
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const createPaymentIntent = useCreatePaymentIntent();
  const stripe = stripeRef.current;

  useEffect(() => {
    (async () => {
      if (!withoutPaymentIntent) {
        const { clientSecret } = await createPaymentIntent();
        setStripeClientSecret(clientSecret);
      }
    })();
  }, [total, withoutPaymentIntent, setStripeClientSecret]);

  const options = {
    ...(stripeClientSecret && { clientSecret: stripeClientSecret }),
    appearance,
    fonts: [{ cssSrc: 'https://fonts.learningwithexperts.com/20200414/fonts.css' }],
  };

  if (!withoutPaymentIntent && !stripeClientSecret) return null;

  return (
    <Elements stripe={stripe} options={options} key={stripeClientSecret}>
      {children}
    </Elements>
  );
};

export default withRouter(StripePaymentIntentProvider);
