import React from 'react';
import cx from 'classnames';
import s from './style.module.css';

export const Panel = ({ nav, grid, ...props }) => {
  return <div className={cx(s.panel, { [s.nav]: nav, [s.grid]: grid })} {...props} />;
};

const Contents = ({ ...props }) => {
  return <section className={s.root} {...props} />;
};

export default Contents;
