import React from 'react';
import { Link as BaseLink } from 'react-router-v3';
import styled, { css } from 'styled-components';
import Typography, { Title as BaseTitle, font } from 'components/Typography';
import { gql } from '@apollo/client';
import { assetUrl } from 'components/AssetImage';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.global.grey1};
  padding: 30px 15px 30px 15px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 50px 45px 50px 45px;
  }
`;

const Title = styled(BaseTitle)`
  max-width: 932px;
  color: white;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const CategoryList = styled.ul`
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(min-content, 1fr));
  grid-template-rows: repeat(4, 95px);
  grid-auto-flow: row;
  grid-auto-rows: 95px;
  grid-gap: 10px;
  justify-content: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    grid-template-columns: repeat(3, 305px);
    grid-template-rows: repeat(4, 107px);
    grid-auto-rows: 107px;
  }
`;

const ListItem = styled.li`
  ${font.medium.group(5)}
  position: relative;
  line-height: 0;
  background-color: ${({ theme }) => theme.colors.global.grey1};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  grid-column: span 1;
  grid-row: span 2;

  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 16%, rgba(0, 0, 0, 0.8) 100%),
        url(${backgroundImage});
    `}

  @media ${({ theme }) => theme.breakpoints.desktop} {
    ${font.medium.group(9)}

    &:nth-child(n + 5) {
      grid-row: span 1;
      ${font.medium.group(7)};
    }
  }

  & > img {
    width: 100%;
    object-fit: contain;
  }
`;

const Link = styled(BaseLink)`
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.1s ease-in;

  ${({ theme, school }) =>
    school &&
    css`
      &:hover {
        background-color: ${theme.schools[school].primary};
      }
    `}

  & > span {
    color: ${({ theme }) => theme.colors.global.white};
  }
`;

const CategoryTitle = styled(Typography).attrs({
  as: 'span',
  white: true,
})``;

const CategoriesPanel = ({ schools, header = 'What would you like to learn?' }) => {
  if (!schools) return null;

  return (
    <Container>
      <Title as="h2">{header}</Title>
      <CategoryList>
        {schools.map(({ id, files, name, slug }) => {
          const backgroundImage = files && files.grid ? assetUrl(files.grid.uuid, '600') : '';
          return (
            <ListItem key={id} backgroundImage={backgroundImage}>
              <Link to={`/${slug}`} school={slug}>
                <CategoryTitle>{name}</CategoryTitle>
              </Link>
            </ListItem>
          );
        })}
      </CategoryList>
    </Container>
  );
};

CategoriesPanel.fragments = {
  schools: gql`
    fragment CategoriesPanel__schools on Navigation {
      id
      schools {
        id
        name
        slug
        files {
          grid {
            id
            uuid
          }
        }
      }
    }
  `,
};

export default CategoriesPanel;
