import React from 'react';
import Section from 'components/Section';
import ProductGrid from 'components/sections/ProductGrid';
import cx from 'classnames';
import s from './style.module.css';

const ProductsRow = ({ school, products }) => {
  return (
    <Section className={cx(s.school, school.slug)} key={school.slug}>
      <ProductGrid
        key={school}
        products={products}
        heading={school.name}
        className={cx(s.grid, school.slug)}
        withinBackground
        white
        centeredHeading
      />
    </Section>
  );
};

export default ProductsRow;
