import React from 'react';
import { Link } from 'react-router-v3';
import { isAdmin } from 'utils/roles';
import Section from 'components/Section';
import Avatar from 'components/Avatar';
import s from './style.module.css';
import { useCurrentUser } from '@lwe/toolkit/authentication';

const Header = ({
  expert,
  expert: {
    profile: { name, quote, quoteCitation, portrait },
  },
}) => {
  const { currentUser } = useCurrentUser();
  const altText = portrait && portrait.title ? portrait.title : `${name} - ${expert.school?.name}`;

  return (
    <>
      <Section className={s.top}>
        <h1>{name}</h1>
        <h2>Expert</h2>
      </Section>
      <Section className={s.bottom}>
        <Avatar user={expert} size={176} alt={altText} />
        {currentUser && isAdmin(currentUser) && (
          <Link className={s.edit} to={`/profile/${expert.id}`}>
            Edit
          </Link>
        )}
        {quote && <q>{quote}</q>}
        {quoteCitation && <cite>{quoteCitation}</cite>}
      </Section>
    </>
  );
};

export default Header;
