export default {
  gardening: {
    name: 'Gardening',
    tagline: 'Certified online gardening courses, taught by bestselling authors',
    partners: ['rhs', 'guardian', 'apld', 'mumsnet'],
    title: 'Online gardening & garden design courses – Gardening',
    description: 'Taught by best-selling authors',
    h1: 'Gardening Courses',
    intro:
      'Our online gardening, horticulture and garden design courses are taught by expert gardeners, horticulture professionals, garden designers, and tutors including Piet Oudolf, Chris Beardshaw, Dr Noel Kingsbury, Annie Guilfoyle and Sally Nex. Join an online gardening course at any time, and learn with practical garden projects.',
    productInfo: {
      col1: {
        title: 'Your gardening course includes:',
        body: `An online classroom with up to 20 other gardening enthusiasts.

Video tutorials led by your tutor & assignments to demonstrate your new gardening skills.

Lifetime access to video tutorials, notes and your online classroom.`,
      },
      col2: {
        title: 'How it works:',
        body: `Sign up and join the online class.

Watch video tutorials led by an expert gardener.

Practice as you learn with inspiring gardening assignments.

Chat to your fellow classmates in your online classroom.`,
      },
      col3: {
        title: 'Peer or Expert option?',
        body: `Peer level – Enjoy the benefits of group learning, share your ideas, collaborate and chat directly to classmates.

Expert level – Includes personal feedback from your tutor who will mark your assignments and provide gardening advice and tips.`,
      },
    },
    faq: [
      {
        title: "What's the difference between Expert or Peer options for gardening courses?",
        description: `<p>At Peer and Expert level, you get access to the same core content and features:</p>
<ul>
  <li>Video lesson tutorials led by your expert tutor</li>
  <li>Downloadable lesson notes</li>
  <li>Option to practise what you learn with lesson assignments</li>
  <li>Group learning within an online interactive classroom of up to 20 classmates</li>
  <li>Lifetime access to videos, notes and classroom</li>
</ul>
<p>If you choose Expert level, you get all of the above plus the following:</p>
<ul>
  <li>Personal assignment feedback and gardening or horticulture advice from your tutor</li>
  <li>Chat to your tutor and ask questions through direct messages</li>
  <li>Certificate of completion</li>
</ul>`,
      },
      {
        title: 'What is an online classroom?',
        description: `<p>Our online classroom has been developed to allow you to learn from our experts in a sociable environment alongside up to 20 other gardening students.</p>
      <p>After each video tutorial led by your expert, you will be encouraged to practice what you have learned with optional gardening assignments - these can be seen by the other students in your class, who are free to share their own thoughts and questions with you.</p>
      <p>We recommend you take part in class discussion where you can discuss your garden design ideas, share gardening experiences, ask questions, post images or work, and comment on each other’s assignments. It’s a great way to learn horticulture experts as well as from other like-minded people from around the world, who share your passion for gardening.</p>
      <p>Our online gardening classrooms have been built to enhance group learning, which has been shown to improve the experience of studying and also how well you retain the knowledge.</p>`,
      },
      {
        title: 'When can I start my course and how long will it take?',
        description: `<p>You can start your course any time within 12 months after receipt of your purchase confirmation email. Once you start, you’ll have lifetime access — meaning you can revisit the lesson videos and notes whenever you want.</p>
      <p>Our courses are designed so that you can study at your own pace, some prefer to binge learn while others like to spread their studying over a longer period of time - it’s completely up to you. To give you a rough time frame, each gardening lesson video is approximately 30-40 minutes long, and we recommend that you spend around an hour on each assignment.</p>`,
      },
      {
        title: 'How often can I speak with the Expert?',
        description: `<p>If you choose the Expert option, the time you have to interact with your gardening tutor is limited to the length of the course. You will have one week per lesson to chat to your tutor and to have your gardening assignments marked, and during this period you can ask them as many questions as you would like.</p>
      <p>To find out the length of each course, go to the gardening course page and look at the course outline to see how many lessons it contains. e.g. a four lesson course assumes that you will have completed the assignments within four weeks.</p>
      <p>The time you have to interact with your tutor starts as soon as you enter the classroom. If you find that you are struggling to complete the assignments in time, just get in contact with one of the team via the chat icon at the bottom of the page and we will help you out.</p>`,
      },
    ],
    metaKeywords:
      'gardening courses online, horticulture courses, garden design, gardening experts, learning with experts, online learning, perennial planting course, piet oudolf, chris beardshaw, noel kingsbury, annie guilfoyle, sally nex',
    metaTitle: 'Gardening Courses',
    metaDescription:
      'Gardening and horticulture courses and qualifications online including garden design. Learn from home with expert tutors and practical projects.',
    packet: {
      image: require('./img/packets/gardening.png'),
      strapline: 'Certified gardening courses taught by world famous experts',
    },
    ny: {
      title: 'Create the garden you’ve always wanted to',
      image: require('components/NewYearHero/img/gardening.png'),
    },
    join: {
      image: require('./img/sign-up/gardening.jpg'),
    },
    heroes: [
      {
        type: 'background',
        image: require('./img/jamie.jpg'),
        tagline: 'MAKE THE MOST OF THE INDOORS AND OUT',
        heading: 'Create contemporary green spaces',
        featured: 127,
        maxWidth: 600,
        promo: false,
        badge: require('./img/jamie-badge.png'),
      },
      {
        type: 'background',
        image: require('./img/gardening-bg.jpg'),
        tagline: 'LEARN THE FUNDAMENTALS OF HORTICULTURE',
        heading: 'Study for an RHS Qualification',
        featured: 123,
        tutor: 'Hilary Thomas & Dr Noel Kingsbury',
        maxWidth: 600,
        promo: false,
        badge: require('./img/rhs-approved.png'),
      },
      {
        type: 'newyear',
        title: 'Create the garden you’ve always wanted to',
        image: require('components/NewYearHero/img/gardening.png'),
      },
      {
        type: 'tutor',
        title: 'Learn about Gardening - Online Courses',
        heading: 'Learn with the plant masters',
        maxWidth: 500,
        image: require('./img/gardening.png'),
        offset: 0,
        promo: '61%',
        featured: 109,
      },
    ],
    groups: [
      { tag: 'bbc-gardeners-world-magazine', title: `BBC Gardeners' World Magazine` },
      { tag: 'love-flowers', title: 'Love Flowers' },
      { tag: 'grown-your-own', title: 'Grow Your Own' },
      { tag: 'perfect-spring-garden', title: 'The Perfect Spring Garden' },
      { tag: 'designing-your-garden', title: 'Designing Your Garden' },
    ],
  },
  jewellery: {
    name: 'Jewellery',
    partners: ['goldsmiths', 'guardian'],
    title: 'Jewellery',
    description: 'Jewellery',
    h1: 'Jewellery Courses',
    intro:
      'Our jewellery courses will take you behind the scenes at the world’s leading jewellers to learn how jewellery is valued and how to appreciate historic, vintage and modern jewellery pieces. You’ll be taught by gemstone experts and learn about rubies, diamonds, emeralds, pearls and sapphires.',
    productInfo: {
      col1: {
        title: 'Your jewellery course includes:',
        body: `An online classroom with up to 20 other students.

Video tutorials led by jewellery and gem experts, with assignments to demonstrate your new skills.

Lifetime access to video tutorials, notes and your online classroom.`,
      },
      col2: {
        title: 'How it works:',
        body: `Sign up and join the online class.

Watch video tutorials led by an expert jeweller.

Practice as you learn with assignments for every lesson.

Chat with to your fellow students in your online classroom.`,
      },
      col3: {
        title: 'Peer or Expert option?',
        body: `Peer level – Enjoy the benefits of group learning, share your ideas and chat directly to classmates.

Expert level – Includes personal feedback on your assignments from your tutor with advice and tips.`,
      },
    },
    faq: [
      {
        title: "What's the difference between Expert or Peer options for jewellery courses?",
        description: `<p>At Peer and Expert level, you get access to the same core content and features:</p>
<ul>
  <li>Video lesson tutorials led by your tutor</li>
  <li>Downloadable lesson notes</li>
  <li>Option to practice what you learn with lesson assignments</li>
  <li>Group learning within an online interactive classroom of up to 20 classmates</li>
  <li>Lifetime access to videos, notes and classroom</li>
</ul>
<p>If you choose Expert level, you get all of the above plus the following:</p>
<ul>
  <li>Personal assignment feedback and advice from your tutor</li>
  <li>Direct message your questions to your tutor</li>
  <li>Certificate of completion</li>
</ul>`,
      },
      {
        title: 'What is an online classroom?',
        description: `<p>Our online classroom has been developed to allow you to learn from our experts in a sociable environment alongside up to 20 other students.</p>
      <p>After each video tutorial led by your expert, you will be encouraged to practice what you have learned with optional jewellery or gemstone assignments. When you’ve completed the assignment upload photos of your work or write up the assignment and submit. Your work can be seen by the other students in your class, who are free to share their own thoughts and questions with you.</p>
      <p>We recommend you take part in class discussion where you can discuss your jewellery ideas, share experiences, ask questions, discuss the latest equipment, post images or work, and comment on each other’s assignments. It’s a great way to learn the expert as well as from other jewellers and gem enthusiasts who share your passion from all over the world (and you never know who you might meet!)</p>
      <p>We’ve built our jewellery classrooms to enhance group learning with has been shown to greatly improve the experience of studying, and how well you retain the knowledge.</p>`,
      },
      {
        title: 'When can I start my course and how long will it take?',
        description: `<p>You can start your course any time within 12 months after receipt of your purchase confirmation email. Once you start, you’ll have lifetime access — meaning you can revisit the lesson videos and notes whenever you want.</p>
      <p>Our courses are designed so that you can study at your own pace, some prefer to binge learn while others like to spread their studying over a longer period of time - it’s completely up to you. To give you a rough time frame, each lesson video is approximately 30-40 minutes long, and we recommend that you spend around an hour on each assignment.</p>`,
      },
      {
        title: 'How often can I speak with the Expert?',
        description: `<p>If you choose the Expert option, you’ll have one week per lesson to chat to your tutor and to have your assignments marked, and during this period you can ask them as many questions as you would like.</p>
      <p>To find out the length of each course, head to the jewellery course page and look at the course outline to see how many lessons it contains. e.g. a four lesson course assumes that you will have completed the assignments within four weeks.</p>
      <p>Your tutor interaction period will start as soon as you enter the classroom. If you find that you are struggling to complete the assignments in the allocated time, just get in contact with one of the team via the chat icon at the bottom of the page and we will help you out.</p>`,
      },
    ],
    metaKeywords:
      'jewellery courses, jewellery foundation class, valuing jewellery, gemstone assessing',
    metaTitle: 'Jewellery Courses',
    metaDescription:
      'Online jewellery courses taught by Joanna Hardy. Learn jewellery appreciation from home with an expert tutor and practical projects.',
    packet: {
      image: require('./img/packets/jewellery.png'),
      strapline: 'Learn about Fine Jewellery with Joanna Hardy',
    },
    ny: {
      title: 'Become the jewellery expert you’ve always wanted to be',
      image: require('components/NewYearHero/img/jewellery.png'),
    },
    join: {
      image: require('./img/sign-up/jewellery.jpg'),
    },
    heroes: [
      {
        type: 'newyear',
        title: 'Become the jewellery expert you’ve always wanted to be',
        image: require('components/NewYearHero/img/jewellery.png'),
        hideDiscount: true,
      },
      {
        type: 'tutor',
        title: 'Learn about Jewellery - Online Courses',
        heading: 'Learn with the jewellery cognoscenti',
        maxWidth: 600,
        image: require('./img/jewellery.png'),
        offset: -50,
        promo: false,
        featured: 121,
      },
    ],
    groups: [],
  },
  antiques: {
    name: 'Antiques',
    tagline: 'Online antiques courses, taught by the top UK & US experts, broadcasters and writers',
    partners: ['guardian', 'barnebys'],
    title: 'Online antiques & collectables courses – Antiques',
    description: 'Taught by the top UK & US experts, broadcasters and writers',
    h1: 'Antiques Courses',
    intro:
      'Our online antiques courses are ideal for any level of antiques’ enthusiast looking to expand their knowledge. Whether you’re looking to build your antiques collection, upcycle your furniture, jewellery or vintage clothes; or want to value fine art, all our courses are created to develop your eye for antiques.',
    productInfo: {
      col1: {
        title: 'Your antiques course includes:',
        body: `An online classroom with up to 20 other antiques enthusiasts.

Video tutorials led by an expert in antiques & assignments to demonstrate your new skills.

Lifetime access to video tutorials, notes and your online classroom.`,
      },
      col2: {
        title: 'How it works:',
        body: `Sign up and join the online class.

Watch video tutorials led by experts in antiques.

Practice as you learn with assignments for every lesson.

Chat to your fellow antiques students in your online classroom.`,
      },
      col3: {
        title: 'Peer or Expert option?',
        body: `Peer level – Enjoy the benefits of group learning, share your ideas and chat directly to classmates.

Expert level – Includes personal feedback on your antiques assignments from your tutor with advice and tips.`,
      },
    },
    faq: [
      {
        title: "What's the difference between Expert or Peer options for antiques courses?",
        description: `<p>At Peer and Expert level, you get access to the same core content and features:</p>
<ul>
  <li>Video lesson tutorials led by your tutor</li>
  <li>Downloadable lesson notes</li>
  <li>Option to practice what you learn with lesson assignments</li>
  <li>Group learning within an online classroom of up to 20 classmates</li>
  <li>Lifetime access to videos, notes and classroom</li>
</ul>
<p>If you choose Expert level, you get all of the above plus the following:</p>
<ul>
  <li>Personal assignment feedback and advice from your tutor</li>
  <li>Direct message your questions to your tutor</li>
  <li>Certificate of completion</li>
</ul>`,
      },
      {
        title: 'What is an online classroom?',
        description: `<p>Our online classroom has been developed to allow you to learn from our experts in a sociable environment alongside up to 20 other students.</p>
      <p>After each video tutorial led by your expert, you will be encouraged to practice what you have learned with optional assignments. When you’ve completed the assignment upload your photos or write up the assignment and submit. Your work can be seen by the other students in your class, who are free to share their own thoughts and questions with you.</p>
      <p>We recommend you take part in class discussion where you can discuss your antiques  experiences, ask questions, post images or work, and comment on each other’s assignments. It’s a great way to learn from your tutor as well as other antiques enthusiasts from all over the world.</p>
      <p>We’ve built our antiques classrooms to encourage group learning, which has been shown to greatly improve the experience of studying and also how well you retain the knowledge.</p>`,
      },
      {
        title: 'When can I start my course and how long will it take?',
        description: `<p>You can start your course any time within 12 months after receipt of your purchase confirmation email. Once you start, you’ll have lifetime access — meaning you can revisit the lesson videos and notes whenever you want.</p>
      <p>Our courses are designed so that you can study at your own pace, some prefer to binge learn while others like to spread their studying over a longer period of time - it’s completely up to you. To give you a rough time frame, each lesson video is approximately 30-40 minutes long, and we recommend that you spend around an hour on each assignment.</p>`,
      },
      {
        title: 'How often can I speak with the Expert?',
        description: `<p>If you choose the Expert option, you will have one week per lesson to chat to your tutor and to have your assignments marked, and during this period you can ask them as many questions as you would like.</p>
      <p>To find out the length of each course, head to the antiques course page and look at the course outline to see how many lessons it contains. e.g. a four lesson course assumes that you will have completed the assignments within four weeks.</p>
      <p>Your tutor interaction period will start as soon as you enter the classroom. If you find that you are struggling to complete the assignments in the allocated time, just get in contact with one of the team via the chat icon at the bottom of the page and we will help you out.</p>`,
      },
    ],
    metaKeywords:
      'antique collection courses, fine art appreciation, valuing antiques class, marc allum, upcycling vintage furniture class',
    metaTitle: 'Antique Courses',
    metaDescription:
      'Antiques collecting and valuing courses, with fine art appreciation and upcycling for vintage furniture, clothes and jewellery.',
    packet: {
      image: require('./img/packets/antiques.png'),
      strapline: 'Antiques and collectables taught by the best antiques experts',
    },
    ny: {
      title: 'Become the antiques expert you’ve always wanted to be',
      image: require('components/NewYearHero/img/antiques.png'),
    },
    join: {
      image: require('./img/sign-up/antiques.jpg'),
    },
    heroes: [
      {
        type: 'newyear',
        title: 'Become the antiques expert you’ve always wanted to be',
        image: require('components/NewYearHero/img/antiques.png'),
      },
      {
        type: 'tutor',
        title: 'Learn about Antiques and Collectables - Online courses',
        heading: 'Learn with the queen of Antiques',
        maxWidth: 550,
        image: require('./img/antiques.png'),
        offset: 0,
        promo: '63%',
        featured: 86,
      },
    ],
    groups: [{ tag: 'antiques-for-interiors', title: 'Antiques for Interiors' }],
  },
  floristry: {
    name: 'Floristry',
    partners: ['guardian'],
    title: 'Online floristry courses – Floristry',
    description: 'Taught by best-selling authors',
    h1: 'Floristry Courses',
    intro:
      'From environmental floristry through to flower arranging and floral designs, this is your chance to learn a new floristry skill. Our floristry courses suit a range of experience, and are taught by pioneers such as Shane Connolly (who created the wedding flowers for The Duke and Duchess of Cambridge) and Paula Pryke.',
    productInfo: {
      col1: {
        title: 'Your floristry course includes:',
        body: `An online classroom with up to 20 other floral design students.

Video tutorials led by an expert florist & assignments to demonstrate your new flower arranging skills.

Lifetime access to video tutorials, notes and your online classroom.`,
      },
      col2: {
        title: 'How it works:',
        body: `Sign up and join the online class.

Watch video tutorials led by our experts.

Practice creating beautiful floral arrangements as you learn.

Chat to your fellow floristry students in your online classroom.`,
      },
      col3: {
        title: 'Peer or Expert option?',
        body: `Peer level – Enjoy the benefits of group learning, share your ideas, collaborate and chat directly to classmates.

Expert level – Includes personal feedback from your tutor who will mark your assignments and provide floristry advice and tips.`,
      },
    },
    faq: [
      {
        title: "What's the difference between Expert or Peer options for floristry courses?",
        description: `<p>At Peer and Expert level, you get access to the same core content and features:</p>
<ul>
  <li>Video lesson tutorials led by your tutor</li>
  <li>Downloadable lesson notes</li>
  <li>Option to practise what you learn with floristry assignments</li>
  <li>Group learning within an online interactive classroom of up to 20 classmates</li>
  <li>Lifetime access to videos, notes and classroom</li>
</ul>
<p>If you choose Expert level, you get all of the above plus the following:</p>
<ul>
  <li>Personal assignment feedback and advice from your tutor</li>
  <li>Direct message your tutor with your questions</li>
  <li>Certificate of completion</li>
</ul>`,
      },
      {
        title: 'What is an online classroom?',
        description: `<p>Our online classroom has been developed to allow you to learn from our experts in a sociable environment alongside up to 20 other floristry students.</p>
      <p>After each video tutorial, led by your expert, you will be encouraged to practice what you have learned with optional floral design assignments. When you submit a photo of your flowers or floral design or written assignment these can be seen by the other students in your class, who are free to share their own thoughts and questions with you.</p>
      <p>We recommend you take part in class discussion where you can discuss your floral design ideas, share flower arranging experiences, ask questions, post images or work, and comment on each other’s assignments. It’s a great way to learn from a floristry expert as well as from people from around the world who share your passion for floristry.</p>
      <p>Our online floristry classrooms have been built to enhance group learning, which has been proved to improve the experience of studying and also how well you retain the knowledge.</p>`,
      },
      {
        title: 'When can I start my course and how long will it take?',
        description: `<p>You can start your course any time within 12 months after receipt of your purchase confirmation email. Once you start, you’ll have lifetime access — meaning you can revisit the lesson videos and notes whenever you want.</p>
      <p>Our courses are designed so that you can study at your own pace, some prefer to binge learn while others like to spread their studying over a longer period of time - it’s completely up to you. To give you a rough time frame, each lesson video is approximately 30-40 minutes long, and we recommend that you spend around an hour on each assignment.</p>`,
      },
      {
        title: 'How often can I speak with the Expert?',
        description: `<p>If you choose the Expert option, you will have one week per lesson to chat to your tutor and to have your assignments marked, and during this period you can ask them as many questions as you would like.</p>
      <p>To find out the length of each course, head to the floristry course page and look at the course outline to see how many lessons it contains. e.g. a four lesson course assumes that you will have completed the assignments within four weeks.</p>
      <p>The time you have to interact with your tutor starts as soon as you enter the classroom. If you find that you are struggling to complete the assignments in the allocated time, just get in contact with one of the team via the chat icon at the bottom of the page and we will help you out.</p>`,
      },
    ],
    metaKeywords:
      'floristry courses, floral design online, online flower arranging courses, paula pryke, shane connolly, charlie ryrie, environmental floristry',
    metaTitle: 'Floristry Courses',
    metaDescription:
      'Floristry courses online including environmental floral design. Learn from home with expert tutors and practical projects.',
    packet: {
      image: require('./img/packets/floristry.png'),
      strapline: 'Learn about Floristry from world-renowned florists',
    },
    ny: {
      title: 'Become the florist you’ve always wanted to be',
      image: require('components/NewYearHero/img/floristry.png'),
    },
    join: {
      image: require('./img/sign-up/floristry.jpg'),
    },
    heroes: [
      {
        type: 'newyear',
        title: 'Become the florist you’ve always wanted to be',
        image: require('components/NewYearHero/img/floristry.png'),
      },
      {
        type: 'tutor',
        title: 'Learn about Floristry - Online Courses',
        heading: 'Learn with a world renowned florist',
        maxWidth: 550,
        image: require('./img/floristry.png'),
        offset: -75,
        promo: '63%',
        featured: 32,
      },
    ],
    groups: [],
  },
  photography: {
    name: 'Photography',
    tagline: 'Online photography courses, taught by bestselling authors',
    partners: ['guardian'],
    title: 'Online photography courses – Photography',
    description: 'Taught by best-selling authors',
    h1: 'Online Photography Courses',
    intro:
      'Our photography courses are taught by some of the best photographers in the world. Join the Photography Foundation taught by Michael Freeman, Wildlife Photography taught by Heather Angel or Flower and Plant Photography taught by Clive Nichols. Learn photography from home, with flexible courses which suit you.',
    productInfo: {
      col1: {
        title: 'Your photography course includes:',
        body: `An online classroom with up to 20 other student photographers.

Video tutorials led by an expert photographer & assignments to demonstrate your new skills.

Lifetime access to video tutorials, notes and your online classroom.`,
      },
      col2: {
        title: 'How it works:',
        body: `Sign up and join the online class.

Watch video tutorials led by expert photographers.

Practice as you learn with photography assignments for every lesson.

Chat to your fellow photography students in your online classroom.`,
      },
      col3: {
        title: 'Peer or Expert option?',
        body: `Peer level – Enjoy the benefits of group learning, share your ideas and chat directly to classmates.

Expert level – Includes personal feedback on your photographs from your tutor with advice and tips.`,
      },
    },
    faq: [
      {
        title: "What's the difference between Expert or Peer options for photography courses?",
        description: `<p>At Peer and Expert level, you get access to the same core content and features:</p>
<ul>
  <li>Video lesson tutorials led by your tutor - an expert photographer</li>
  <li>Downloadable lesson notes</li>
  <li>Option to practice what you learn with lesson assignments</li>
  <li>Group learning within an online interactive classroom of up to 20 classmates</li>
  <li>Lifetime access to videos, notes and classroom</li>
</ul>
<p>If you choose Expert level, you get all of the above plus the following:</p>
<ul>
  <li>Personal assignment feedback and advice from your tutor</li>
  <li>Direct message your questions to the tutor</li>
  <li>Certificate of completion</li>
</ul>`,
      },
      {
        title: 'What is an online classroom?',
        description: `<p>Our online classroom has been developed to allow you to learn from our experts in a sociable environment alongside up to 20 other students.</p>
      <p>After each video tutorial led by your tutor, you will be encouraged to practice what you have learned with optional photography assignments. When you’ve completed the assignment upload your photos or write up the assignment and submit. Your work can be seen by the other students in your class, who are free to share their own thoughts and questions with you.</p>
      <p>We recommend you take part in class discussion where you can discuss your photography ideas, share experiences, ask questions, discuss the latest equipment, post images or work, and comment on each other’s assignments. It’s a great way to learn from your tutor as well as from other photographers from around the world who share your passion.</p>
      <p>We’ve built our photography classes to make the most of group learning - which has been shown  to greatly improve the experience of studying, and also how well you retain the knowledge.</p>`,
      },
      {
        title: 'When can I start my course and how long will it take?',
        description: `<p>You can start your course any time within 12 months after receipt of your purchase confirmation email. Once you start, you’ll have lifetime access — meaning you can revisit the lesson videos and notes whenever you want.</p>
      <p>Our courses are designed so that you can study at your own pace, some prefer to binge learn while others like to spread their studying over a longer period of time - it’s completely up to you. To give you a rough time frame, each lesson video is approximately 30-40 minutes long, and we recommend that you spend around an hour on each assignment.</p>`,
      },
      {
        title: 'How often can I speak with the Expert?',
        description: `<p>If you choose the Expert option, you will have one week per lesson to chat to your tutor and to have your assignments marked, and during this period you can ask them as many questions as you would like.</p>
      <p>To find out the length of each course, head to the photography course page and look at the course outline to see how many lessons it contains. e.g. a four lesson course assumes that you will have completed the assignments within four weeks.</p>
      <p>Your tutor interaction period will start as soon as you enter the classroom. If you find that you are struggling to complete the assignments in the allocated time, just get in contact with one of the team via the chat icon at the bottom of the page and we will help you out.</p>`,
      },
    ],
    metaKeywords:
      'online photography course, learn photography online, michael freeman photographer, photography foundation course, wildlife photography, photo journalism course, architectural photography, perfect exposure lesson, macro photography',
    metaTitle: 'Online Photography Courses',
    metaDescription:
      'Online photography courses including oil portrait painting. Learn from home with expert tutors and practical projects.',
    packet: {
      image: require('./img/packets/photography.png'),
      strapline: 'From best-selling authors and award-winning photographers',
    },
    ny: {
      title: 'Become the photographer you’ve always wanted to be',
      image: require('components/NewYearHero/img/photography.png'),
    },
    join: {
      image: require('./img/sign-up/photography.jpg'),
    },
    heroes: [
      {
        type: 'newyear',
        title: 'Become the photographer you’ve always wanted to be',
        image: require('components/NewYearHero/img/photography.png'),
      },
      {
        type: 'tutor',
        title: 'Learn about Photography - Online Courses',
        heading: "Learn with Amazon's best selling photographer",
        maxWidth: 750,
        image: require('./img/photography.png'),
        offset: 35,
        promo: '60%',
        featured: 69,
      },
    ],
    groups: [
      { tag: 'beginners-photography', title: 'Beginners Photography' },
      { tag: 'wildlife-photography', title: 'Wildlife Photography' },
    ],
  },
  foodanddrink: {
    name: 'Food & Drink',
    tagline:
      'Online food and drink courses, taught by the top UK & US experts, broadcasters and writers',
    partners: ['guardian'],
    title: 'Online food & drink courses',
    description: 'Online food & drink courses',
    h1: 'Cookery Courses',
    intro:
      'Join online cooking courses from expert chefs including Michel Roux Jr, Hugh Fearnley-Whittingstall & River Cottage, Paul A Young and Atul Kucchar. Learn how to cook food from around the world, and develop your kitchen skills. You’ll be learning with a classroom of fellow chefs and foodies from around the world.',
    productInfo: {
      col1: {
        title: 'Your cookery course includes:',
        body: `An online classroom with up to 20 other cookery students.

Video tutorials led by food & drink experts with assignments to demonstrate your new skills.

Lifetime access to video tutorials, notes and your online classroom.`,
      },
      col2: {
        title: 'How it works:',
        body: `Sign up and join the online class.

Watch video tutorials led by an expert tutor or chef.

Practice as you learn with assignments for every lesson.

Chat with to your fellow students in your online classroom.`,
      },
      col3: {
        title: 'Peer or Expert option?',
        body: `Peer level – Enjoy the benefits of group learning, share your ideas and chat directly to classmates.

Expert level – Includes personal feedback on your assignments from your tutor with advice and tips.`,
      },
    },
    faq: [
      {
        title: "What's the difference between Expert or Peer options for cookery courses?",
        description: `<p>At Peer and Expert level, you get access to the same core content and features:</p>
<ul>
  <li>Video lesson tutorials led by your tutor or chef</li>
  <li>Downloadable lesson notes</li>
  <li>Option to practice what you learn with lesson assignments</li>
  <li>Group learning within an online interactive classroom of up to 20 classmates</li>
  <li>Lifetime access to videos, notes and classroom</li>
</ul>
<p>If you choose Expert level, you get all of the above plus the following:</p>
<ul>
  <li>Personal assignment feedback and advice from your tutor</li>
  <li>Direct message your cookery questions to your tutor</li>
  <li>Certificate of completion</li>
</ul>`,
      },
      {
        title: 'What is an online classroom?',
        description: `<p>Our online classroom has been developed to allow you to learn from our experts in a sociable environment alongside up to 20 other students. </p>
      <p>After each video tutorial led by your expert, you will be encouraged to practice what you have learned with optional assignments. When you’ve completed the assignment upload photos of your cooking, food, drink, or write up the assignment and submit. Your work can be seen by the other students in your class, who are free to share their own thoughts and questions with you.</p>
      <p>We recommend you take part in class discussion where you can discuss your cooking and recipe
      ideas, share experiences, ask questions, discuss the latest equipment, post images or work, and comment on each other’s assignments. It’s a great way to learn from the expert as well as from other chefs and foodies in your class who share your passion from all over the world.</p>
      <p>We’ve built our cookery classes to encourage group learning which has been shown to greatly improve the experience of studying, and how well you retain the knowledge, and we have built our classroom experience around this fact.</p>`,
      },
      {
        title: 'When can I start my course and how long will it take?',
        description: `<p>You can start your course any time within 12 months after receipt of your purchase confirmation email. Once you start, you’ll have lifetime access — meaning you can revisit the lesson videos and notes whenever you want.</p>
      <p>Our courses are designed so that you can study at your own pace, some prefer to binge learn while others like to spread their studying over a longer period of time - it’s completely up to you. To give you a rough time frame, each lesson video is approximately 30-40 minutes long, and we recommend that you spend around an hour on each assignment.</p>`,
      },
      {
        title: 'How often can I speak with the Expert?',
        description: `<p>If you choose the Expert option you will have one week per lesson to chat to your tutor and to have your assignments marked, and during this period you can ask them as many questions as you would like.</p>
      <p>To find out the length of each course, head to the cookery or food & drink course page and look at the course outline to see how many lessons it contains. e.g. a four lesson course assumes that you will have completed the assignments within four weeks.</p>
      <p>Your tutor interaction period will start as soon as you enter the classroom. If you find that you are struggling to complete the assignments in the allocated time, just get in contact with one of the team via the chat icon at the bottom of the page and we will help you out.</p>`,
      },
    ],
    metaKeywords:
      'online cookery courses, michel Roux jr, river cottage, Hugh Fearnley-Whittingstall, indian cooking, french cookery, japanese cuisine, chef lead courses, cooking at home, wine tasting course, craft beer course',
    metaTitle: 'Cookery Courses',
    metaDescription:
      'Online cookery courses including Michel Roux Jr, River Cottage and cake baking and decoration. Learn from home with expert chefs, tutors and practical projects.',
    packet: {
      image: require('./img/packets/foodanddrink.png'),
      strapline: 'Learn about cooking and brewing from the best',
    },
    ny: {
      title: 'Become the craft beer expert you’ve always wanted to be',
      image: require('components/NewYearHero/img/foodanddrink.png'),
    },
    join: {
      image: require('./img/sign-up/foodanddrink.jpg'),
    },
    heroes: [
      {
        type: 'newyear',
        title: 'Become the craft beer expert you’ve always wanted to be',
        image: require('components/NewYearHero/img/foodanddrink.png'),
      },
      {
        type: 'tutor',
        title: 'Learn about Food and Drink - Online Courses',
        heading: 'Learn with the beer sommelier',
        maxWidth: 500,
        image: require('./img/foodanddrink.png'),
        offset: 0,
        promo: '63%',
        featured: 125,
      },
    ],
    groups: [],
  },
  wellbeing: {
    name: 'Wellbeing',
    tagline: 'Tagline',
    partners: ['guardian'],
    title: 'Study wellbeing, health, exercise and the natural world.  Online wellbeing courses.',
    description:
      'Wellbeing, health, exercise and the natural world. Online courses and communities with experts including Professor Sir Muir Gray, Steve Backshall and more.',
    h1: 'Wellbeing Courses',
    intro:
      "Discover our range of wellbeing courses, designed to broaden your knowledge on a variety of subjects which heightens both mind and body. Our courses include a nature course where you can learn all about our world's wildlife and environment, through to tips about living longer and better.",
    metaTitle: 'Wellbeing Courses',
    productInfo: {
      col1: {
        title: 'Your wellbeing course includes:',
        body: `An online classroom with up to 20 other wellbeing students.

Video tutorials led by an expert in wellbeing & assignments to demonstrate your new skills.

Lifetime access to video tutorials, notes and your online classroom.`,
      },
      col2: {
        title: 'How it works:',
        body: `Sign up and join the online class.

Watch video tutorials led by experts in wellbeing.

Practice as you learn with assignments for every lesson.

Chat to your fellow wellbeing students in your online classroom.`,
      },
      col3: {
        title: 'Peer or Expert option?',
        body: `Peer level – Enjoy the benefits of group learning, share your ideas and chat directly to classmates.

Expert level – Includes personal feedback on your wellbeing assignments from your tutor with advice and tips.`,
      },
    },
    faq: [
      {
        title: "What's the difference between Expert or Peer options for wellbeing courses?",
        description: `<p>At Peer and Expert level, you get access to the same core content and features:</p>
<ul>
  <li>Video lesson tutorials led by your tutor</li>
  <li>Downloadable lesson notes</li>
  <li>Option to practice what you learn with lesson assignments</li>
  <li>Group learning within an online interactive classroom of up to 20 classmates</li>
  <li>Lifetime access to videos, notes and classroom</li>
</ul>
<p>If you choose Expert level, you get all of the above plus the following:</p>
<ul>
  <li>Personal assignment feedback and advice from your tutor</li>
  <li>Direct message your questions to your tutor</li>
  <li>Certificate of completion</li>
</ul>`,
      },
      {
        title: 'What is an online classroom?',
        description: `<p>Our online classroom has been developed to allow you to learn from our experts in a sociable environment alongside up to 20 other students.</p>
      <p>After each video tutorial led by your expert, you will be encouraged to practice what you have learned with optional assignments. When you’ve completed the assignment upload photos of your work or write up the assignment and submit. Your work can be seen by the other students in your class, who are free to share their own thoughts and questions with you.</p>
      <p>We recommend you take part in class discussion where you can discuss your wellbeing ideas, share experiences, ask questions, post images or work, and comment on each other’s assignments. It’s a great way to learn from your tutor as well as other people who share your passion for wellbeing from all over the world.</p>
      <p>We’ve built our online wellbeing classrooms to encourage group learning which has been shown to improve the experience of studying, and how well you retain the knowledge.</p>`,
      },
      {
        title: 'When can I start my course and how long will it take?',
        description: `<p>You can start your course any time within 12 months after receipt of your purchase confirmation email. Once you start, you’ll have lifetime access — meaning you can revisit the lesson videos and notes whenever you want.</p>
      <p>Our courses are designed so that you can study at your own pace, some prefer to binge learn while others like to spread their studying over a longer period of time - it’s completely up to you. To give you a rough time frame, each lesson video is approximately 30-40 minutes long, and we recommend that you spend around an hour on each assignment.</p>`,
      },
      {
        title: 'How often can I speak with the Expert?',
        description: `<p>If you choose the Expert option, you will have one week per lesson to chat to your tutor and to have your assignments marked, and during this period you can ask them as many questions as you would like.</p>
      <p>To find out the length of each course, head to the wellbeing course page and look at the course outline to see how many lessons it contains. e.g. a four lesson course assumes that you will have completed the assignments within four weeks.</p>
      <p>Your tutor interaction period will start as soon as you enter the classroom. If you find that you are struggling to complete the assignments in the allocated time, just get in contact with one of the team via the chat icon at the bottom of the page and we will help you out.</p>`,
      },
    ],
    metaKeywords:
      'wellbeing courses online, learn how to live longer, Sir Muir Gray, healthy old age, yoga classes, nature explorer class',
    metaDescription:
      'Online wellbeing courses including how to live longer, explore nature and yoga classes. Learn from home with expert tutors and practical projects.',
    packet: {},
    ny: {},
    join: {},
    heroes: [],
    groups: [],
  },
  artanddesign: {
    name: 'Art',
    tagline: 'tagline',
    partners: [],
    title: 'Online Art Courses',
    description:
      'Explore the best online art courses taught by experts including Rosso Emerald Crimson and more at Learning with Experts! Book your place now!',
    h1: 'Online Art & Interior Design Courses',
    intro:
      'Join an online art course or interior design course and learn how to paint or find your creative style with expert feedback from your tutor at every stage. All courses are online - so you can paint or design at times which work for you.',
    productInfo: {
      col1: {
        title: 'Your art course includes:',
        body: `An online classroom with up to 20 other student artists.

Video tutorials led by an expert artist, while you paint alongside and demonstrate your new skills.

Lifetime access to video tutorials, notes and your online classroom.`,
      },
      col2: {
        title: 'How it works:',
        body: `Sign up and join the online class.

Watch video tutorials led by our expert artists.

Create your own artwork alongside your tutor as you learn.

Chat to your fellow art students in your online classroom.`,
      },
      col3: {
        title: 'Peer or Expert option?',
        body: `Peer level – Enjoy the benefits of group learning, share your ideas and chat directly to classmates.

Expert level – Includes personal feedback on your artwork from your tutor with advice and tips.`,
      },
    },
    faq: [
      {
        title: "What's the difference between Expert or Peer options for art courses?",
        description: `<p>At Peer and Expert level, you get access to the same core content and features:</p>
<ul>
  <li>Video lesson tutorials led by your tutor</li>
  <li>Downloadable lesson notes</li>
  <li>Option to practise what you learn with lesson assignments</li>
  <li>Group learning within an online interactive classroom of up to 20 classmates</li>
  <li>Lifetime access to videos, notes and classroom</li>
</ul>
<p>If you choose Expert level, you get all of the above plus the following:</p>
<ul>
  <li>Personal assignment feedback and advice from your tutor</li>
  <li>Direct message your tutor with your questions</li>
  <li>Certificate of completion</li>
</ul>`,
      },
      {
        title: 'What is an online classroom?',
        description: `<p>Our online classroom has been developed to allow you to learn from our experts in a sociable environment alongside up to 20 other art students.</p>
      <p>After each video tutorial led by your expert, you will be encouraged to practice what you have learned with optional art assignments. When you’ve completed the assignment take a photo of your work or write up the assignment and submit. Your work can be seen by the other students in your class, who are free to share their own thoughts and questions with you.</p>
      <p>We recommend you take part in class discussion where you can discuss your creative, design and artistic ideas, share experiences, ask questions, post images or work, and comment on each other’s assignments. It’s a great way to learn from an expert artist and also from people from all over the world who share your passion for art and painting.</p>
      <p>Our online art classrooms have been built to enhance group learning, which has been proved to improve the experience of studying and also how well you retain the knowledge.</p>`,
      },
      {
        title: 'When can I start my course and how long will it take?',
        description: `<p>You can start your course any time within 12 months after receipt of your purchase confirmation email. Once you start, you’ll have lifetime access — meaning you can revisit the lesson videos and notes whenever you want.</p>
      <p>Our courses are designed so that you can study at your own pace, some prefer to binge learn while others like to spread their studying over a longer period of time - it’s completely up to you. To give you a rough time frame, each lesson video is approximately 30-40 minutes long, and we recommend that you spend around an hour on each assignment.</p>`,
      },
      {
        title: 'How often can I speak with the Expert?',
        description: `<p>If you choose the Expert option, you will have one week per lesson to chat to your tutor and to have your assignments marked.</p>
      <p>To find out the length of each course, head to the art course page and look at the course outline to see how many lessons it contains. e.g. a four lesson course assumes that you will have completed the assignments within four weeks.</p>
      <p>Your tutor interaction period will start as soon as you enter the classroom. If you find that you are struggling to complete your artwork in the allocated time, just get in contact with one of the team via the chat icon at the bottom of the page and we will help you out.</p>`,
      },
    ],
    metaKeywords:
      'art course online, portrait painting class online, rosso emerald crimson, oil painting class online, artist classes online',
    metaTitle: 'Online Art Courses',
    metaDescription:
      'Online art and painting courses including oil portrait painting. Learn from home with expert tutors and practical projects.',
    packet: {},
    ny: {},
    join: {},
    heroes: [],
    groups: [],
  },
};
