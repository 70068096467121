import React from 'react';
import moment from 'moment';
import TrustPilotStaticQuote from 'components/TrustPilotStaticQuote';

const quotes = {
  gardening: {
    quote:
      "Back in school with pleasure! I definitely would have no chance studying with experts like this if it wasn't for Learning with Experts.",
    author: 'Ane',
  },
  foodanddrink: {
    quote: "Its given me a huge boost, and a lot more knowledge - I'm so glad I took part.",
    author: 'Jodie',
  },
  jewellery: {
    quote:
      'The course has been absolutely wonderful […] I am seeing my jewellery and that of my parents and grandparents in a totally different light now, it is truly fascinating.',
    author: 'Hannah',
  },
  photography: {
    quote: 'Great concept, wonderful class. Learned what I wanted to, would recommend to anyone',
    author: 'Perry',
  },
  floristry: {
    quote:
      'An amazing opportunity. Lessons were easily accessed, pitched perfectly, progressive in skills and thought provoking.',
    author: 'Maureen',
  },
  antiques: {
    quote: 'A very good range of courses and very easy site to use, even on my phone.',
    author: 'Phil',
  },
  wellbeing: {
    quote: 'This is an enormous subject of course but tapping into such expertise was useful.',
    author: 'Janet',
  },
  artanddesign: {
    quote:
      'I would recommend this to someone wanting a glimpse into other artists processes and how they get to their final completed works.',
    author: 'Marni',
  },
};

const TrustPilotSummary = ({ large = true, school = null, color }) => {
  let quote = quotes.general;

  if (school) {
    quote = quotes[school];
  } else {
    const schools = Object.keys(quotes);
    const randomSchool = schools[moment().hour() % schools.length];
    quote = quotes[randomSchool];
  }

  return (
    <TrustPilotStaticQuote
      large={large}
      color={color}
      quote={quote.quote}
      author={quote.author}
    />
  );
};

export default TrustPilotSummary;
