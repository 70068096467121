import { USER_REGISTERED, USER_AUTHENTICATED, USER_UNAUTHENTICATED } from '@lwe/toolkit/utils';

import {
  PROMO_CODE_APPLIED,
  PRODUCT_IMPRESSIONS,
  PRODUCT_VIEWED,
  TRAILER_PLAY_VIDEO_CLICKED,
  PRODUCT_CLICKED,
  PROMO_CLICKED,
  PROMO_VIEWED,
  PRIVATE_MESSAGE_CREATED,
  ASSIGNMENT_SUBMITTED,
  ASSIGNMENT_MESSAGE_CREATED,
  CLASSROOM_MESSAGE_CREATED,
  MESSAGE_REPLY_CREATED,
  COMMUNITY_QUESTION_CREATED,
  COMMUNITY_ANSWER_CREATED,
  SEAT_STARTED,
  FREE_SEAT_CLAIMED,
  ADDED_TO_BASKET,
  ADD_TO_BASKET_FAILED,
  REMOVED_FROM_BASKET,
  CHECKOUT_STEP,
  SOCIAL_BUTTON_CLICKED,
  BLOG_POST_SIGNUP,
  PRODUCT_BANNER_CLICKED,
  ORDER_CREATED,
  ENTERPRISE_SALE_ENQUIRY,
} from '@root/events';
import { useEventBusSubscription } from '@lwe/toolkit/utils';
import { useCurrentUser } from '@lwe/toolkit/authentication';
import { toLocalId } from '@lwe/toolkit/utils';
import { useMemo } from 'react';

const dataLayer = typeof window !== 'undefined' && (window.dataLayer || (window.dataLayer = []));

const categoryFromSchool = (school) => {
  return school ? school.slug : 'learningwithexperts';
};

const productData = ({ title: item_name, id, school, pricing }) => ({
  item_id: String(id),
  item_name,
  price: pricing?.from?.current ?? 0,
  item_brand: '',
  item_category: categoryFromSchool(school),
});

const productVariantData = (product, variant) => {
  const { title: item_name, school, id } = product;
  const { name: variantName } = variant;

  return {
    item_id: String(id),
    item_name,
    price: variant?.price?.current ?? 0,
    item_brand: '',
    item_category: categoryFromSchool(school),
    item_variant: variantName,
  };
};

// data pushers

const pushToDataLayer = (payload) => {
  if (dataLayer) {
    if (payload.ecommerce) {
      dataLayer.push({
        ecommerce: undefined,
      });
    }
    dataLayer.push(payload);
  } else {
    console.error('No data layer present', payload);
  }
};

const stepToEventLabel = {
  0: 'Begin Checkout',
  1: 'Add Payment Info',
};

const stepToEvent = {
  0: 'begin_checkout',
  1: 'add_payment_info',
};

const promoEvent = (event, promo, target = '', eventType, event_action, user) => {
  const { id: promotion_id, name: promotion_name, heading, product } = promo;
  const creative_slot = `${heading.replace('\n', ' ')} - ${product && product.title}`;
  const location_id = [promo.position, target].filter(Boolean).join(' - ');
  return {
    event,
    ga4_event_name: event,
    event_category: 'EnhancedEcommerce',
    event_action,
    event_label: undefined,
    event_value: 0,
    event_non_interactive: true,
    ecommerce: {
      promotions: [
        {
          promotion_id,
          promotion_name,
          creative_slot,
          location_id,
        },
      ],
    },
    user,
  };
};

const toDataLayerUser = (u) =>
  u
    ? {
        user_id: u.id ? String(u.id) : undefined,
        email: u.email,
        googleAdsFormat: {
          email: u.email,
        },
      }
    : undefined;

const GA4 = () => {
  const { currentUser } = useCurrentUser();
  const user = useMemo(() => toDataLayerUser(currentUser), [currentUser]);

  useEventBusSubscription(USER_REGISTERED, ({ detail: user }) => {
    pushToDataLayer({
      event: 'sign_up',
      ga4_event_name: 'sign_up',
      event_category: 'Authentication',
      event_action: 'Completed Registration',
      event_label: undefined,
      event_value: 0,
      event_non_interactive: false,
      method: 'Email',
      user: toDataLayerUser(user),
    });
  });

  useEventBusSubscription(USER_AUTHENTICATED, ({ detail: { user } }) => {
    pushToDataLayer({
      event: 'login',
      ga4_event_name: 'login',
      event_category: 'Authentication',
      event_action: user.id ? String(toLocalId(user.id)) : undefined,
      event_label: undefined,
      event_value: 0,
      event_non_interactive: true,
      method: 'Email',
      user: toDataLayerUser(user),
    });
  });

  useEventBusSubscription(USER_UNAUTHENTICATED, () => {
    pushToDataLayer({
      event: 'log_out',
      ga4_event_name: 'log_out',
      event_category: 'Authentication',
      event_action: 'Log Out',
      event_label: undefined,
      event_value: 0,
      event_non_interactive: true,
      user: undefined,
    });
  });

  useEventBusSubscription(
    PROMO_CODE_APPLIED,
    ({ detail: code }) => {
      pushToDataLayer({
        event: 'ce_add_coupon_code',
        ga4_event_name: 'ce_add_coupon_code',
        event_category: 'Checkout',
        event_action: code ? 'Added a promo code' : 'Remove a coupon code',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PRODUCT_IMPRESSIONS,
    ({ detail }) => {
      const { products = [], list, currencyCode } = detail;
      if (products.length === 0) return;
      pushToDataLayer({
        event: 'view_item_list',
        ga4_event_name: 'view_item_list',
        event_category: 'EnhancedEcommerce',
        event_action: 'Product Impression',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: true,
        ecommerce: {
          items: products.map((product, index) => {
            return {
              ...productData(product),
              item_list_name: list,
              index,
            };
          }),
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PRODUCT_VIEWED,
    ({ detail: { product, currencyCode } }) => {
      pushToDataLayer({
        event: 'view_item',
        ga4_event_name: 'view_item',
        event_category: 'EnhancedEcommerce',
        event_action: 'Viewed Product',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: true,
        ecommerce: {
          currency: currencyCode,
          items: [
            {
              ...productData(product),
              index: 0,
            },
          ],
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    TRAILER_PLAY_VIDEO_CLICKED,
    ({ detail: { product } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_category: 'Product page trailer',
        event_action: 'Clicked play',
        event_label: product.title,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PRODUCT_CLICKED,
    ({ detail: { product, currencyCode, list, position } }) => {
      if (!product) return;
      pushToDataLayer({
        event: 'select_item',
        ga4_event_name: 'select_item',
        event_category: 'EnhancedEcommerce',
        event_action: 'Product Click',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        ecommerce: {
          currency: currencyCode,
          items: [
            {
              ...productData(product),
              index: position,
            },
          ],
          item_list_name: list,
          item_list_id: list,
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PROMO_CLICKED,
    ({ detail: promo }) => {
      const event = promoEvent(
        'select_promotion',
        promo,
        null,
        'promoClick',
        'Promo Clicked',
        user,
      );
      pushToDataLayer(event);
    },
    [user],
  );

  useEventBusSubscription(
    PROMO_VIEWED,
    ({ detail: promo }) => {
      const event = promoEvent('view_promotion', promo, null, 'promoView', 'Viewed Promo', user);
      pushToDataLayer(event);
    },
    [user],
  );

  useEventBusSubscription(
    ENTERPRISE_SALE_ENQUIRY,
    ({ detail: product }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Enterprise sale enquiry',
        category: 'Shop',
        event_category: product.school.slug,
        event_label: product.title,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PRIVATE_MESSAGE_CREATED,
    ({ detail: { message } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Created private message',
        category: 'Messages',
        event_category: message.user,
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    ASSIGNMENT_SUBMITTED,
    ({ detail: { assignment, lesson } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Submitted an assignment',
        event_category: 'Classroom',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        lesson: lesson.title,
        lessonId: lesson.id,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    ASSIGNMENT_MESSAGE_CREATED,
    ({ detail: { message } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Created an assignment message',
        event_category: 'Assignment',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        fromUserId: message.user,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    CLASSROOM_MESSAGE_CREATED,
    ({ detail: { feedItem } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Created a classroom message',
        event_category: 'Classroom',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        fromUserId: feedItem.entity.userId,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    MESSAGE_REPLY_CREATED,
    ({ detail: { message } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Created a message reply',
        event_category: 'Messages',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        fromUserId: message.user,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    COMMUNITY_QUESTION_CREATED,
    ({ detail: { question, category } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Created question',
        event_category: category.slug,
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    COMMUNITY_ANSWER_CREATED,
    ({ detail: { answer, category } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Created answer',
        event_category: category.slug,
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    SEAT_STARTED,
    ({ detail: { seat } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Started course',
        event_category: 'My Classes',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        courseId: seat.course.id,
        courseTitle: seat.course.title,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    FREE_SEAT_CLAIMED,
    ({ detail: { variant, product } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Claimed Seats',
        event_label: `${product.title} - ${variant.name}`,
        event_category: 'Free Course',
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    ADDED_TO_BASKET,
    ({
      detail: {
        product,
        variant,
        basket: { currencyCode, total, discount, promoCode },
      },
    }) => {
      pushToDataLayer({
        event: 'add_to_cart',
        ga4_event_name: 'add_to_cart',
        event_category: 'EnhancedEcommerce',
        event_action: 'Add To Basket',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        ecommerce: {
          currency: currencyCode.toUpperCase(),
          value: total,
          discount,
          coupon: promoCode?.code ?? '',
          items: [
            {
              ...productVariantData(product, variant),
              quantity: 1,
              index: 0,
            },
          ],
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    ADD_TO_BASKET_FAILED,
    () => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Add to basket failure',
        event_label: 'Product page',
        event_category: 'Checkout',
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    REMOVED_FROM_BASKET,
    ({
      detail: {
        product,
        variant,
        basket: { currencyCode },
      },
    }) => {
      pushToDataLayer({
        event: 'remove_from_cart',
        ga4_event_name: 'remove_from_cart',
        event_category: 'EnhancedEcommerce',
        event_action: 'Remove From Basket',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        ecommerce: {
          currency: currencyCode.toUpperCase(),
          value: 0,
          items: [],
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    CHECKOUT_STEP,
    ({
      detail: {
        step,
        option,
        basket: { currencyCode, items, total, discount, promoCode },
      },
    }) => {
      pushToDataLayer({
        event: stepToEvent[step],
        ga4_event_name: stepToEvent[step],
        event_category: 'EnhancedEcommerce',
        event_action: 'Checkout Step',
        event_label: stepToEventLabel[step],
        event_value: 0,
        event_non_interactive: false,
        ecommerce: {
          currency: currencyCode && currencyCode.toUpperCase(),
          value: total,
          discount,
          coupon: promoCode?.code ?? '',
          items: items.map(
            (
              {
                variant: {
                  price,
                  id,
                  name,
                  product: { title, school },
                },
              },
              index,
            ) => ({
              item_id: String(id),
              item_name: title,
              price: price?.current ?? 0,
              item_brand: '',
              item_category: categoryFromSchool(school),
              item_variant: name,
              quantity: 1,
              index,
            }),
          ),
        },
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    SOCIAL_BUTTON_CLICKED,
    ({ detail: { category, label } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Clicked share button',
        event_label: label,
        event_category: category,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    BLOG_POST_SIGNUP,
    ({ detail: { school } }) => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_category: 'Blog post',
        event_action: 'Blog post signup',
        event_label: categoryFromSchool(school),
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    PRODUCT_BANNER_CLICKED,
    () => {
      pushToDataLayer({
        event: 'GAEvent',
        ga4_event_name: 'GAEvent',
        event_action: 'Clicked Product Banner',
        event_category: 'Promo Banner',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: false,
        user,
      });
    },
    [user],
  );

  useEventBusSubscription(
    ORDER_CREATED,
    ({ detail: { order } }) => {
      const { id, items, total, promoCode, currencyCode, discount } = order;
      pushToDataLayer({
        event: 'purchase',
        ga4_event_name: 'purchase',
        event_category: 'EnhancedEcommerce',
        event_action: 'Order Complete',
        event_label: undefined,
        event_value: 0,
        event_non_interactive: true,
        ecommerce: {
          currency: currencyCode,
          transaction_id: String(id),
          value: total,
          coupon: promoCode?.code ?? '',
          discount,
          items: items.map(({ variant, title, price }, index) => ({
            item_id: variant.product.id,
            item_name: title,
            quantity: 1,
            coupon: promoCode?.code ?? '',
            price,
            item_brand: '',
            item_category: variant?.product?.school?.slug,
            item_variant: variant?.name,
            index,
          })),
        },
        user,
      });
    },
    [user],
  );

  return null;
};

export default GA4;
