import React from 'react';
import { InputContainer } from '../../Layout';
import TextInput from '../../inputs/TextInput';
import { BaseTextArea } from '../styles';

const TextArea = (props) => {
  return (
    <InputContainer {...props}>
      <BaseTextArea rows="10" cols="80" {...props} />
    </InputContainer>
  );
};

TextArea.propTypes = TextInput.propTypes;

export default TextArea;
