import React from 'react';
import { Checkbox, InputField } from 'components/Form';
import styled from 'styled-components';

const Container = styled.div`
  text-align: left;
`;

const OptInsFields = ({ optIns, name }) => {
  return (
    <Container>
      {optIns.map(({ key, title }) => {
        return (
          <InputField
            key={key}
            name={`${name}.${key}`}
            id={`${name}.${key}`}
            component={Checkbox}
            type="checkbox"
            label={title}
            defaultValue={false}
          />
        );
      })}
    </Container>
  );
};

export default OptInsFields;
