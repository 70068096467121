import React from 'react';
import { Medium } from 'components/Typography';
import s from './style.module.css';

const GiftMessage = ({ onHowItWorks }) => {
  return (
    <aside className={s.root}>
      <Medium white as="p">
        You can choose to buy as a GIFT during checkout. <a onClick={onHowItWorks}>Find out more</a>
      </Medium>
    </aside>
  );
};

export default GiftMessage;
