import React from 'react';
import AssetImage from 'components/AssetImage';
import s from './style.module.css';

const ProductPosterImage = ({ product: { files } }) => {
  const uuid = files?.poster?.uuid || files?.header?.uuid;

  return (
    <div className={s.root}>
      {uuid && (
        <AssetImage uuid={uuid} width={936} height={527} sizes="(max-width: 400px) 165px, 642px" />
      )}
    </div>
  );
};

export default ProductPosterImage;
