import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { useEventBus } from '@lwe/toolkit/utils';
import { PRODUCT_IMPRESSIONS } from '@root/events';

import ProductCard from 'components/ProductCard';
import useCurrencyCode from 'hooks/useCurrencyCode';
import cx from 'classnames';
import s from './style.module.css';

const ProductCardGrid = ({
  products,
  list,
  footer,
  showCardCategoryLink,
  large = null,
  linkPrefix = null,
  ...props
}) => {
  const currencyCode = useCurrencyCode();
  const eventBus = useEventBus();

  useEffect(() => {
    if (list && currencyCode) {
      eventBus.publish(PRODUCT_IMPRESSIONS, { products, list, currencyCode });
    }
  }, [currencyCode]);

  const showLarge = large || products.length < 4;
  return (
    <>
      <ul className={cx(s.root, { [s.large]: showLarge })}>
        {products.map((product, position) => {
          return (
            <li key={product.id}>
              <ProductCard
                product={product}
                large={showLarge}
                list={list}
                position={position}
                showCardCategoryLink={showCardCategoryLink}
                linkPrefix={linkPrefix}
                {...props}
              />
            </li>
          );
        })}
      </ul>

      {footer && <>{footer}</>}
    </>
  );
};

ProductCardGrid.fragments = {
  products: gql`
    fragment ProductCardGrid__products on Product {
      id
      ...ProductCard__product
    }
    ${ProductCard.fragments.product}
  `,
};

export default ProductCardGrid;
