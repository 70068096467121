import React from 'react';

import { useMediaQuery } from 'react-responsive';
import JsonLd from 'components/JsonLd';
import styled, { css } from 'styled-components';
import { CustomerSupportAPI } from 'utils/support';
import Section from 'components/Section';
import { Title } from 'components/Typography';
import AccordianList from 'components/AccordianList';
import { SecondaryButton } from 'components/Button';
import cx from 'classnames';
import s from './style.module.css';

const StyledSecondaryButton = styled(SecondaryButton)`
  & > svg {
    margin-right: 5px;
  }
  ${({ inverse, theme }) =>
    inverse &&
    css`
      & > svg > g {
        fill: ${theme.colors.global.white};
      }
    `}
`;

const questions = [
  {
    id: 1,
    title: 'What’s the difference between the PEER and EXPERT level gardening courses?',
    description: `<p>At Peer and Expert level, you get access to the same core content and features:</p>
    <ul>
    <li>Video lesson tutorials led by your expert tutor</li>
    <li>Downloadable lesson notes</li>
    <li>Option to practise what you learn with lesson assignments</li>
    <li>Group learning within an online interactive classroom of up to 20 classmates</li>
    <li>Lifetime access to videos, notes and classroom</li>
    </ul>
    <p>If you choose Expert level, you get all of the above plus the following:</p>
    <ul>
    <li>Personalised assignment feedback and coaching from your expert tutor</li>
    <li>Opportunity to interact with your expert tutor through a private messaging system</li>
    <li>Certificate of completion</li>
    </ul>`,
    open: true,
  },
  {
    id: 2,
    title: 'What is an online classroom?',
    description: `<p>Our online classroom has been developed to allow you to learn from our experts in a sociable environment alongside up to 20 other students. After each video tutorial led by your expert, you will be encouraged to practice what you have learned with optional assignments - these can be seen by the other students in your class, who are free to share their own thoughts and questions with you.</p>
    <p>You will be encouraged to take part in an ongoing class discussion where you can discuss ideas, post images or work, and comment on each other’s assignments. It’s a great way to learn from other like-minded people who share your passion from all over the world.</p>
    <p>This means not only do you benefit from people who are outstanding in their field, but also from a close-knit group of like-minded people whom you can learn alongside. Group learning has shown to greatly improve not just the experience of studying, but also how well you retain the knowledge, and we have built our classroom experience around this fact.</p>
    `,
  },

  {
    id: 3,
    title: 'When can I start my course and how long will it take?',
    description: `<p>You can start your course any time within 12 months after receipt of your purchase confirmation email. Once you start, you’ll have lifetime access — meaning you can revisit the lesson videos and notes whenever you want.</p>
    <p>Our courses are designed so that you can study at your own pace, some prefer to binge learn while others like to spread their studying over a longer period of time - it’s completely up to you. To give you a rough time frame, each lesson video is approximately 30-40 minutes long, and we recommend that you spend around an hour on each assignment.</p>`,
  },
  {
    id: 4,
    title: 'How often can I speak with the Expert?',
    description: `<p>If you opt for the Expert version of your course, the time you have to interact with your tutor is limited to course length. You will have one week per lesson to chat to your tutor and to have your assignments marked, and during this period you can ask them as many questions as you would like.</p>
    <p>To find out the length of each course, head to the product page and look at the Course outline to see how many lessons it contains. e.g. a four lesson course assumes that you will have completed the assignments within four weeks.</p>
    <p>Your tutor interaction period will start as soon as you enter the classroom. If you find that you are struggling to complete the assignments in the allocated time or you wish to put this function on hold, just get in contact with one of the team via the support button and we will help you out.</p>`,
  },
];

const schemaData = {
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: questions.map(({ title, description }) => {
    return {
      '@type': 'Question',
      name: title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: description.replace(/(\n\s*)/g, ''),
      },
    };
  }),
};

const FrequentlyAskedQuestionsSummary = ({
  className,
  white,
  light,
  name = '',
  contextualtitle,
  contextualQuestions,
}) => {
  const is_mobile = useMediaQuery({ maxWidth: 949 });

  const mergedQuestions = (contextualQuestions ?? questions).map((q, i) => ({
    ...q,
    ...contextualQuestions?.[i],
  }));
  const responsiveQuestions = is_mobile
    ? mergedQuestions.map((q) => ({ ...q, open: false }))
    : mergedQuestions;

  const showHelp = () =>
    window.open(
      'http://help.learningwithexperts.com',
      'Learning with Experts - Advice and answers',
    );
  const showIntercom = () => CustomerSupportAPI('showNewMessage');
  const title =
    contextualtitle ?? `Online ${name?.toLocaleLowerCase() + ' '}courses – your questions answered`;

  return (
    <Section className={cx(s.root, className)} light={light}>
      <JsonLd data={schemaData} />
      <Title white={white} as="h3">
        {title}
      </Title>
      <AccordianList white={white} entries={responsiveQuestions} showNumbers={false} />

      <div>
        <SecondaryButton inverse={white} onClick={showHelp}>
          More FAQs
        </SecondaryButton>
        <StyledSecondaryButton inverse={white} onClick={showIntercom}>
          <svg width="24" height="18" xmlns="http://www.w3.org/2000/svg">
            <g fill="#4A4A4A" fillRule="nonzero">
              <path d="M20.894 4.952h-9.865c-1.198 0-2.114.92-2.114 2.123v5.66c0 1.132.916 2.123 2.114 2.123h7.046l2.819 2.83v-2.83c1.198 0 2.114-.99 2.114-2.122V7.075c0-1.202-.916-2.123-2.114-2.123z" />
              <path d="M12.438 0H2.573C1.375 0 .46.92.46 2.123v5.66c0 1.132.915 2.123 2.113 2.123v2.83l2.819-2.83h2.114v-2.83c0-1.98 1.55-3.538 3.523-3.538h3.524V2.123C14.552.919 13.636 0 12.438 0z" />
            </g>
          </svg>
          Chat to us
        </StyledSecondaryButton>
      </div>
    </Section>
  );
};

export default FrequentlyAskedQuestionsSummary;
