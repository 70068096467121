import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import YouTubePlayer from './components/YouTubePlayer';
import VideoPlayer from 'components/LazyVideoPlayer';
import cx from 'classnames';

import s from './style.module.css';

const ProductVideoPlayer = ({ product, setVideoReady, videoReady, showVideo }) => {
  const { trailerHlsUrl, trailerYoutubeId } = product;

  const hasHlsTrailer = trailerHlsUrl !== null && trailerYoutubeId !== '';
  const hasYoutubeTrailer = trailerYoutubeId !== null && trailerYoutubeId !== '';

  const videoMetaData = {
    videoId: product.id,
    title: product.title,
    series: product.school.name,
  };

  const handleVideoReady = () => setVideoReady(true);

  return (
    <div className={cx(s.root, { [s.visible]: videoReady && showVideo })}>
      {hasHlsTrailer && (
        <VideoPlayer
          playerName="Course trailer"
          key={product.id}
          hlsURL={trailerHlsUrl}
          metaData={videoMetaData}
          onReady={handleVideoReady}
          play={showVideo}
        />
      )}

      {hasYoutubeTrailer && !hasHlsTrailer && (
        <YouTubePlayer
          trailerYoutubeId={trailerYoutubeId}
          videoReady={videoReady}
          showVideo={showVideo}
          onReady={handleVideoReady}
          play={showVideo}
        />
      )}
    </div>
  );
};

ProductVideoPlayer.fragments = {
  product: gql`
    fragment productVideoPlayer on Product {
      id
      trailerYoutubeId
      trailerHlsUrl
    }
  `,
};

ProductVideoPlayer.propTypes = {
  product: PropTypes.object.isRequired,
  setVideoReady: PropTypes.func.isRequired,
  videoReady: PropTypes.bool.isRequired,
  showVideo: PropTypes.bool.isRequired,
};

export default ProductVideoPlayer;
