import React from 'react';
import Section from 'components/Section';
import { typeset, font } from 'components/Typography';
import LazyImage from 'components/LazyImage';
import s from './style.module.css';

const images = {
  rhs: require('./images/award-rhs.png'),
  cpd: require('./images/award-cpd.png'),
  icsa: require('./images/award-icsa.png'),
  deaHealth: require('./images/award-dea-health-wellbeing.png'),
  deaHome: require('./images/award-dea-home-learning.png'),
};

const Heading = typeset({
  mobile: font.medium.group(1),
  desktop: font.medium.group(2),
});

const Partners = () => {
  const title = 'Awards & Accreditations';
  return (
    <Section className={s.root}>
      <Heading as="h3">{title}</Heading>
      <ul>
        <li key="rhs">
          <LazyImage src={images['rhs']} height={100} alt="Royal Horticultural Society - Approved Centre 2023-2024" />
        </li>
        <li key="icsa">
          <LazyImage src={images['icsa']} width={100} alt="Royal Horticultural Society" />
        </li>
        <li key="cpd">
          <LazyImage src={images['cpd']} height={100} alt="CPD Accredited (provider 50276)" />
        </li>
        <li key="deaHealth">
          <LazyImage src={images['deaHealth']} height={100} alt="Digital Education Awards 2023 Winner for Digital Health and Wellbeing Learning Product of the Year" />
        </li>
        <li key="deaHome">
          <LazyImage src={images['deaHome']} height={100} alt="Digital Education Awards 2023 Winner for Adult Home Learning Product of the Year" />
        </li>
      </ul>
    </Section>
  );
};

export default Partners;
