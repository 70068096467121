import React from 'react';
import styled from 'styled-components';
import Section from 'components/Section';
import { SectionHeading } from 'components/Elements';
import { Subtitle as BaseSubtitle, Body as BaseBody } from 'components/Typography';

const Container = styled(Section)`
  padding-bottom: 30px;
  @media (min-width: 950px) {
    padding-bottom: 40px;
  }
`;

const Subtitle = styled(BaseSubtitle)`
  margin: 14px 0 6px 0;
  @media (min-width: 950px) {
    margin: 20px 0 4px 0;
  }
`;

const Body = styled(BaseBody)`
  & > p {
    margin: 10px 0 2px 0;
  }

  & > ul {
    list-style-type: disc;
    margin: 10px 0 0 18px;
    & > li {
      margin-bottom: 5px;
    }
  }
`;

const Content = styled.div`
  padding: 0 18px;
  @media (min-width: 950px) {
    padding: 0;
  }
`;

const RhsFaq = () => {
  return (
    <Container>
      <SectionHeading>Everything you need to know</SectionHeading>
      <Content>
        <Subtitle as="h3">What is the RHS Level 2 Online Course?</Subtitle>
        <Body>
          <p>
            The Royal Horticultural Society's Level 2 Certificate in the Principles of Plant Growth and Development is an internationally recognised RHS qualification designed for those just starting out in gardening as well as experienced gardeners.
          </p>
          <p>
            To complete your RHS qualification you need to sit the RHS Level 2 exams (there are 2 exams for this course).
          </p>
        </Body>
        <Subtitle as="h3">How is the course structured?</Subtitle>
        <Body>
          <p>
            The course includes on-demand video lessons, downloadable course notes, mandatory assignments for each lesson and access to our small interactive classrooms (limited to 20 students).
          </p><p>
            To obtain the RHS Level 2 Certificate: Principles of Plant Growth and Development qualification you study the RHS courses with us, complete the mandatory assignments and then sit exams with the RHS.
          </p><p>
            Our course will leave you fully prepared for the final exam. You can interact with the world-class tutors, having them mark your assignments and provide you with your own personal feedback.
          </p><p>
            Each unit will be assessed by a separate written exam covering all learning outcomes specified in the unit. Examinations take place normally in February, June and October.
          </p>
        </Body>
        <Subtitle as="h3">Is this qualification right for me?</Subtitle>
        <Body>
          <p>
            This qualification is available to anyone over 16 years of age, from anywhere in the world. Our tutors are used to teaching people for whom English is a second language. All that is required is a keen interest in gardening.
          </p><p>
            We have students of all levels on our RHS courses - from people starting out in their horticulture careers to those who already have other professional horticulture qualifications.
          </p>
        </Body>
        <Subtitle as="h3">The RHS have designed this course to be ideal for:</Subtitle>
        <Body>
          <ul>
            <li>anyone who is working as a professional horticulturist,</li>
            <li>anyone who wants to make a career change into gardening and horticulture,</li>
            <li>anyone who wants to expand their gardening knowledge.</li>
          </ul>
        </Body>

        <Subtitle as="h3">How long does the course take?</Subtitle>
        <Body>
          <p>
            This collection is entirely flexible and fits around your schedule, so you can go at your own pace. To fully qualify you'll need to sit exams which normally take place either in February, June or October. When you start the course we'll register you for your exams.
          </p>
        </Body>

        <Subtitle as="h3">Each lesson includes:</Subtitle>
        <Body>
          <ul>
            <li>On-demand video lesson (around 30 minutes) - presented by the expert</li>
            <li>Mandatory assignments (which takes on average 3 hours to complete)</li>
            <li>Downloadable lesson notes with slides</li>
          </ul>
        </Body>

        <Subtitle as="h3">Do I need to have completed RHS Level 1?</Subtitle>
        <Body>
          <p>
            No, RHS Level 2 is run entirely separate to RHS Level 1 and you don't need to have completed RHS Level 1 (or plan on completing it) in order to study RHS Level 2.
          </p>
        </Body>

        <Subtitle as="h3">RHS Student Membership</Subtitle>
        <Body>
          <p>
            As a Learning with Experts' student, you qualify for the £10 RHS student membership (saving you £39.95 annually).
          </p>
          <p>
            The membership includes The Garden magazine, priority booking and discounted ticket rates for RHS Shows (including RHS Chelsea Flower Show and RHS Hampton Court), unlimited days out at the RHS Gardens and over 200 partner gardens across the UK and abroad. Contact us for more details.
          </p>
        </Body>

        <Subtitle as="h3">Buying for your gardening business or employees?</Subtitle>
        <Body>
          <p>
            Contact us for VAT invoices or buying multiple courses (click the speech bubble in the bottom left).
          </p>
        </Body>

        <Subtitle as="h3">More RHS qualification questions?</Subtitle>
        <Body>
          <p>
            Read more on our blog post or just click the speech bubble in the bottom right of your screen to ask us about RHS qualifications and the RHS Level 2 online course - we'd love to hear from you!
          </p>
        </Body>
      </Content>
    </Container>
  )
};

export default RhsFaq;
