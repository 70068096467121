import React, { useEffect } from 'react';
import ProductStandoutSection from 'components/sections/ProductStandoutSection';
import PageHeaderPromo from 'components/sections/PageHeaderPromo';
import { useEventBus } from '@lwe/toolkit/utils';
import { PROMO_VIEWED, PROMO_CLICKED } from '@root/events';

import { gql } from '@apollo/client';

const COMPONENTS = {
  ProductStandoutPromo: ProductStandoutSection,
  ProductHeaderPromo: PageHeaderPromo,
};

const PagePromoRenderer = ({ promos, page, position, ...props }) => {
  const promo = promos.find((p) => p.position === position);
  const eventBus = useEventBus();

  useEffect(() => {
    if (promo) {
      eventBus.publish(PROMO_VIEWED, promo);
    }
  }, [promo]);

  if (!promo) return null;

  const Component = COMPONENTS[promo.__typename];
  if (!Component) return null;

  const handleClick = () => eventBus.publish(PROMO_CLICKED, promo);

  return <Component promo={promo} onClick={handleClick} {...props} />;
};

PagePromoRenderer.fragments = {
  promo: gql`
    fragment PagePromoRenderer__promo on PagePromo {
      id
      __typename
      position
      name
      ...ProductStandoutSection__promo
      ...PageHeaderPromo__promo
    }
    ${ProductStandoutSection.fragments.promo}
    ${PageHeaderPromo.fragments.promo}
  `,
};

PagePromoRenderer.TOP = 'TOP';
PagePromoRenderer.MIDDLE = 'MIDDLE';
PagePromoRenderer.BOTTOM = 'BOTTOM';

export default PagePromoRenderer;
