import React from 'react';
import { gql } from '@apollo/client';
import { Link } from 'react-router-v3';
import ProductCard from 'components/ProductCard';
import { Title, Body, Small } from 'components/Typography';
import { PrimaryButton } from 'components/Button';
import { Content } from 'components/Layout';
import { useEventBus } from '@lwe/toolkit/utils';
import { PROMO_CLICKED } from '@root/events';

import { withRouter } from 'react-router-v3';
import cx from 'classnames';
import s from './style.module.css';

const RecommendedProduct = ({
  router,
  product,
  white,
  hideAllLink = false,
  withinBackground = false,
  heading = 'Recommended course',
  buttonLabel = 'View course',
  showHighestPrice = false,
}) => {
  const { slug, title, shortDescription, school, tutor, isBundle } = product;
  const eventBus = useEventBus();

  const handleProductClick = () => {
    eventBus.publish(PROMO_CLICKED, 'Blog Post Recommended Product', title);
    router.push(`/${school.slug}/courses/${slug}`);
  };

  const courseHeading = isBundle ? title : `${title} taught by ${tutor.profile.name}`;

  return (
    <Content className={cx(s.root, { [s.white]: white })}>
      {heading && (
        <Title as="h2" white={white}>
          {heading}
        </Title>
      )}
      <div className={s.content}>
        <ProductCard
          forceLarge
          withinBackground={withinBackground}
          product={product}
          showHighestPrice={showHighestPrice}
        />
        <div className={s.description}>
          <Title as="h3" white={white}>
            {courseHeading}
          </Title>
          <Body as="p" white={white}>
            {shortDescription}
          </Body>
          <PrimaryButton
            as={Link}
            to={`/${school.slug}/courses/${slug}`}
            inverse={white}
            border={!white}
            onClick={handleProductClick}
          >
            {buttonLabel}
          </PrimaryButton>
          {!hideAllLink && (
            <Small
              as={Link}
              to={`/${school.slug}`}
              desktop={false}
              white={white}
              className={s.allLink}
            >
              All {school.name} courses
            </Small>
          )}
        </div>
      </div>
    </Content>
  );
};

RecommendedProduct.fragments = {
  product: gql`
    fragment RecommendedProduct__product on Product {
      id
      slug
      title
      shortDescription
      isBundle
      tutor {
        id
        profile {
          id
          name
        }
      }
      school {
        id
        name
        slug
      }
      ...ProductCard__product
    }
    ${ProductCard.fragments.product}
  `,
};

export default withRouter(RecommendedProduct);
