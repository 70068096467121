import styled from 'styled-components';

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 18px 30px 18px;
  max-width: 420px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 20px 18px 50px 18px;
    width: 1005px;
    max-width: 1005px;
  }

  & > h2 {
    text-transform: lowercase;
    padding: 0 15px 0 15px;
    margin: 25px 0px 5px 0px;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  & > ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    & > li {
      margin: 7px;

      @media ${({ theme }) => theme.breakpoints.desktop} {
        margin: 14px;
      }
    }
  }
`;
