import React from 'react';
import cx from 'classnames';
import s from './style.module.css';

const Section = ({ className, wide, full, slim, gray, light, narrow, primary, ...props }) => {
  return (
    <section
      className={cx(
        s.root,
        {
          [s.slim]: slim,
          [s.primary]: primary,
          [s.narrow]: narrow,
          [s.wide]: wide,
          [s.full]: full,
          [s.gray]: gray,
          [s.light]: light,
        },
        className,
      )}
      {...props}
    />
  );
};

export default Section;
