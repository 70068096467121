import { useQuery, useMutation, gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useEventBus } from '@lwe/toolkit/utils';

import {
  ADDED_TO_BASKET,
  ADD_TO_BASKET_FAILED,
  REMOVED_FROM_BASKET,
  PROMO_CODE_APPLIED,
  ORDER_CREATED,
} from '@root/events';

import OrderSummary from 'components/OrderSummary';

const BASKET_TOKEN_QUERY = gql`
  query BasketTokenQuery {
    basketToken @client
  }
`;

const BASKET_ENTRY_GIFT_FRAGMENT = gql`
  fragment entryGift on BasketEntry {
    id
    gift
    recipientName
    recipientEmail
    recipientMessage
    deliverOn
  }
`;

const BASKET_ENTRY_FRAGMENT = gql`
  fragment basketItem on BasketEntry {
    id
    __typename
    formattedPrice
    formattedOriginalPrice
    hasSaving
    gift
    variant {
      id
      name
      price {
        current
        saving
        reducible
      }
      files {
        giftcard {
          id
          uuid
        }
      }
      product {
        id
        title
        availableOn
        school {
          id
          slug
        }
        files {
          poster {
            id
            uuid
          }
          header {
            id
            uuid
          }
        }
      }
    }
    ... on VariablePriceBasketEntry {
      variant {
        id
        files {
          giftcard {
            id
            uuid
          }
        }
      }
    }
    ...entryGift
  }
  ${BASKET_ENTRY_GIFT_FRAGMENT}
`;

const BASKET_TOTALS_FRAGMENT = gql`
  fragment basketTotals on Basket {
    id
    total
    subtotal
    discount
    balanceApplied
    balanceUsed
    formattedBalanceUsed
    formattedTotal
    formattedSubtotal
    formattedDiscount
  }
`;

const BASKET_FRAGMENT = gql`
  fragment basket on Basket {
    id
    uuid
    currencyCode
    ...basketTotals
    account {
      id
    }
    promoCode {
      id
      code
    }
    items {
      id
      ...basketItem
    }
  }
  ${BASKET_ENTRY_FRAGMENT}
  ${BASKET_TOTALS_FRAGMENT}
`;

const BASKET_QUERY = gql`
  query BasketQuery($basketToken: String!, $basketTokenExists: Boolean!) {
    basketToken @client @export(as: "basketToken")
    basket(uuid: $basketToken) @include(if: $basketTokenExists) {
      id
      ...basket
    }
  }
  ${BASKET_FRAGMENT}
`;

const ADD_TO_BASKET_MUTATION = gql`
  mutation AddToBasket($basketToken: String, $variantId: ID!, $options: BasketEntryOptionsInput!) {
    basketToken @client @export(as: "basketToken")
    addToBasket(basketUuid: $basketToken, variantId: $variantId, options: $options) {
      id
      ...basket
    }
  }
  ${BASKET_FRAGMENT}
`;

const REMOVE_FROM_BASKET = gql`
  mutation RemoveFromBasket($basketToken: String!, $basketEntryId: ID!) {
    basketToken @client @export(as: "basketToken")
    removeFromBasket(basketUuid: $basketToken, basketEntryId: $basketEntryId) {
      id
      basket {
        id
        total
        subtotal
        discount
        formattedTotal
        formattedSubtotal
        formattedDiscount
        items {
          id
        }
      }
    }
  }
`;

const UPDATE_BASKET_ENTRY_MUTATION = gql`
  mutation UpdateBasketEntry(
    $basketToken: String!
    $basketEntryId: ID!
    $gift: Boolean!
    $recipient: RecipientInput!
  ) {
    basketToken @client @export(as: "basketToken")
    updateBasketEntry(
      basketUuid: $basketToken
      basketEntryId: $basketEntryId
      gift: $gift
      recipient: $recipient
    ) {
      id
      ...entryGift
    }
  }
  ${BASKET_ENTRY_GIFT_FRAGMENT}
`;

const APPLY_PROMO_CODE_MUTATION = gql`
  mutation ApplyPromoCode($basketToken: String!, $promoCode: String) {
    basketToken @client @export(as: "basketToken")
    applyPromoCode(basketUuid: $basketToken, promoCode: $promoCode) {
      id
      total
      promoCode {
        id
        code
      }
      formattedTotal
      formattedSubtotal
      formattedDiscount
    }
  }
`;

export const persistBasketToken = (basketToken) => {
  if (basketToken) localStorage.setItem('basketToken', basketToken);
  else localStorage.removeItem('basketToken');
};

export const useBasketToken = () => {
  const { data } = useQuery(BASKET_TOKEN_QUERY, { ssr: false });
  return (data && data.basketToken) || null;
};

export const useAddToBasket = () => {
  const [addToBasket] = useMutation(ADD_TO_BASKET_MUTATION);
  const eventBus = useEventBus();
  return useCallback(
    async (variant, options) => {
      try {
        const {
          data: { addToBasket: basket },
        } = await addToBasket({
          variables: {
            variantId: variant.id,
            options,
          },
          update: (cache, { data: { addToBasket } }) => {
            cache.writeQuery({
              query: BASKET_QUERY,
              variables: {
                basketToken: addToBasket.uuid,
                basketTokenExists: true,
              },
              data: {
                basket: addToBasket,
                basketToken: addToBasket.uuid,
              },
            });
            persistBasketToken(addToBasket.uuid);
          },
        });
        const product = basket.items
          .map((item) => item.variant)
          .find((v) => v.id === variant.id)?.product;
        eventBus.publish(ADDED_TO_BASKET, { product, variant, basket });
      } catch (e) {
        eventBus.publish(ADD_TO_BASKET_FAILED, variant);
        throw e;
      }
    },
    [addToBasket],
  );
};

export const useRemoveFromBasket = () => {
  const [basket] = useBasket();
  const eventBus = useEventBus();
  const [removeFromBasket] = useMutation(REMOVE_FROM_BASKET);
  return useCallback(
    async ({ id, variant, variant: { product } }) => {
      if (basket) {
        await removeFromBasket({
          variables: {
            basketEntryId: id,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            removeFromBasket: {
              __typename: 'BasketEntry',
              id,
              basket: {
                ...basket,
                items: basket.items.filter((entry) => entry.id !== id),
              },
            },
          },
        });
        eventBus.publish(REMOVED_FROM_BASKET, { product, variant, basket });
      }
    },
    [removeFromBasket, basket],
  );
};

export const useUpdateBasketItem = () => {
  const [updateBasketEntry] = useMutation(UPDATE_BASKET_ENTRY_MUTATION);
  return useCallback(
    ({ id }, { recipient, ...values }) => {
      updateBasketEntry({
        variables: {
          basketEntryId: id,
          ...values,
          recipient,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateBasketEntry: {
            id,
            __typename: 'BasketEntry',
            ...values,
            recipientName: recipient.name,
            recipientEmail: recipient.email,
            recipientMessage: recipient.message,
            deliverOn: recipient.deliverOn,
          },
        },
      });
    },
    [updateBasketEntry],
  );
};

export const useApplyPromoCode = () => {
  const [applyPromoCode] = useMutation(APPLY_PROMO_CODE_MUTATION);
  const eventBus = useEventBus();
  return useCallback(
    async (promoCode) => {
      const response = await applyPromoCode({
        variables: {
          promoCode,
        },
      });
      eventBus.publish(PROMO_CODE_APPLIED, promoCode);
      return response;
    },
    [applyPromoCode],
  );
};

export const useBasket = () => {
  const token = useBasketToken();
  const response = useQuery(BASKET_QUERY, {
    ssr: false,
    variables: { basketTokenExists: !!token },
    skip: !token,
  });
  return useMemo(() => {
    const { data, ...rest } = response;
    return [data && data.basket, rest];
  }, [response]);
};

const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrder($basketToken: String!, $stripePaymentIntentId: String) {
    basketToken @client @export(as: "basketToken")
    createOrder(basketUuid: $basketToken, stripePaymentIntentId: $stripePaymentIntentId) {
      id
      ...OrderSummary__order
      user {
        id
        account {
          id
          balance
          formattedBalance
        }
      }
    }
  }
  ${OrderSummary.fragments.order}
`;

export const useCreateOrder = () => {
  const [createOrder, { client }] = useMutation(CREATE_ORDER_MUTATION);
  const eventBus = useEventBus();
  return useCallback(
    async (variables) => {
      const {
        data: { createOrder: order },
      } = await createOrder({
        variables,
      });
      persistBasketToken(null);
      client.writeQuery({
        query: BASKET_QUERY,
        data: {
          basket: null,
          basketToken: null,
        },
        variables: {
          basketTokenExists: false,
        },
      });
      eventBus.publish(ORDER_CREATED, { order });
      return order;
    },
    [createOrder, client],
  );
};

const CREATE_PAYMENT_INTENT_MUTATION = gql`
  mutation CreatePaymentIntent($basketToken: String!) {
    basketToken @client @export(as: "basketToken")
    createPaymentIntent(basketUuid: $basketToken) {
      clientSecret
    }
  }
`;

export const useCreatePaymentIntent = () => {
  const [createPaymentIntent] = useMutation(CREATE_PAYMENT_INTENT_MUTATION);
  return useCallback(async () => {
    const { data } = await createPaymentIntent();
    return data.createPaymentIntent;
  }, [createPaymentIntent]);
};

const SWITCH_BASKET_MUTATION = gql`
  mutation SwitchBasket($basketToken: String!) {
    switchBasket(uuid: $basketToken) {
      id
      ...basket
    }
  }
  ${BASKET_FRAGMENT}
`;

export const useSwitchBasket = () => {
  const [switchBasket] = useMutation(SWITCH_BASKET_MUTATION, {
    update: (cache, { data: { switchBasket } }) => {
      if (switchBasket) {
        cache.writeQuery({
          query: BASKET_QUERY,
          variables: {
            basketToken: switchBasket.uuid,
            basketTokenExists: true,
          },
          data: {
            basket: switchBasket,
            basketToken: switchBasket.uuid,
          },
        });
      }
    },
    onCompleted: (data) => {
      if (data.switchBasket) {
        persistBasketToken(data.switchBasket.uuid);
      }
    },
  });
  return useCallback(
    (basketToken) => {
      return switchBasket({
        variables: {
          basketToken,
        },
      });
    },
    [switchBasket],
  );
};

const UPDATE_BASKET_USE_BALANCE_MUTATION = gql`
  mutation UpdateBasketUseBalance($basketToken: String!, $useBalance: Boolean!) {
    basketToken @client @export(as: "basketToken")
    updateBasketUseBalance(basketUuid: $basketToken, useBalance: $useBalance) {
      id
      ...basketTotals
    }
  }
  ${BASKET_TOTALS_FRAGMENT}
`;

export const useUpdateBasketUseBalance = (useBalance) => {
  const [updateBasketUseBalance] = useMutation(UPDATE_BASKET_USE_BALANCE_MUTATION, {
    variables: {
      useBalance,
    },
  });
  return updateBasketUseBalance;
};

const REDEEM_GIFT_CARD_AMOUNT_MUTATION = gql`
  mutation RedeemGiftCardAmount($code: String!, $basketToken: String) {
    basketToken @client @export(as: "basketToken")
    redeemGiftCardAmount(code: $code, basketToken: $basketToken, useBalance: true) {
      basket {
        id
        ...basketTotals
      }
      redemption {
        id
        accountLedgerEntry {
          id
          account {
            id
            balance
            formattedBalance
          }
        }
      }
    }
  }
  ${BASKET_TOTALS_FRAGMENT}
`;

export const useRedeemGiftCardAmount = () => {
  const [redeemGiftCardAmount] = useMutation(REDEEM_GIFT_CARD_AMOUNT_MUTATION);
  return redeemGiftCardAmount;
};
