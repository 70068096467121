import styled, { css } from 'styled-components';
import Typography, { font } from 'components/Typography';

const arrow = require('./assets/arrow.svg');

const TabLink = styled(Typography).attrs({
  mobile: font.book.custom(22, 22),
  as: 'button',
})`
  border: 0;
  margin: 0 20px 0 0;
  padding: 0 0 0 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  @media ${({ theme }) => theme.breakpoints.hd} {
    margin-right: 35px;
  }

  &:after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 8px;
    background: url(${arrow}) no-repeat;
    margin: 0 0 3px 6px;

    transition: 0.6s;
    transform-style: preserve-3d;
  }

  ${({ active }) =>
    active &&
    css`
      &:after {
        transform: rotateX(180deg);
      }
    `}
`;

export default TabLink;
