import React from 'react';
import styled, { css } from 'styled-components';
import { Section } from 'components/Layout';
import { font, typeset } from 'components/Typography';
import BlackFridayTitle from 'components/BlackFridayTitle';
import { useBlackFriday } from 'hooks/useBlackFriday';

const BaseTitle = typeset({
  mobile: font.bold.group(9),
  desktop: font.bold.group(12),
  hd: font.bold.group(12),
  as: 'h1',
});

const BaseSubtitle = typeset({
  mobile: font.medium.group(7),
  desktop: font.medium.group(9),
  as: 'h2',
});

const StyledSection = styled(({ className, ...props }) => (
  <Section containerClassName={className} {...props} />
))`
  padding: 70px 15px 15px 15px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 110px 0 35px 0;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    padding: 100px 0 35px 0;
  }
`;

const Content = styled.div`
  padding: 0 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
    max-width: 760px;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    max-width: 1110px;
  }
`;

const Title = styled(BaseTitle)`
  margin-bottom: 10px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    line-height: 82px;
    max-width: 600px;
  }
`;

const Subtitle = styled(BaseSubtitle)`
  margin: 0 0 18px 0;
  color: #ffffff;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 30px 0;
  }
`;

const Header = () => {
  const {
    blackFridayComingSoon,
    showBlackFriday,
    blackFridayFinalWeekend,
    blackFridayEndsTomorrow,
    blackFridayEndsToday,
  } = useBlackFriday();

  return (
    <StyledSection wide>
      <Content>
        <Title white>
          {blackFridayComingSoon && <Subtitle as="p">It's nearly here...</Subtitle>}
          {showBlackFriday &&
            !blackFridayFinalWeekend &&
            !blackFridayEndsTomorrow &&
            !blackFridayEndsToday && <Subtitle as="p">Online Courses</Subtitle>}
          {blackFridayFinalWeekend && <Subtitle as="p">Ends Midnight 30th November</Subtitle>}
          {blackFridayEndsTomorrow && <Subtitle as="p">Ends Midnight Tomorrow</Subtitle>}
          {blackFridayEndsToday && <Subtitle as="p">Ends Midnight Tonight</Subtitle>}
          <BlackFridayTitle title="Black Friday" suffix="Sale" animated />
        </Title>
      </Content>
    </StyledSection>
  );
};

export default Header;
