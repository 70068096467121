import React from 'react';
import cx from 'classnames';
import s from './style.module.css';

const Step = ({ heading, name }) => {
  return (
    <li className={cx(s.root, name)}>
      <h3>{heading}</h3>
    </li>
  );
};

export default Step;
