import styled from 'styled-components';

export const FixedHeader = styled.header`
  position: fixed;
  z-index: 10;

  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;

  color: ${({ theme }) => theme.header.color};
  background-color: ${({ theme }) => theme.header.backgroundColor};

  font-weight: 300;
  font-stretch: normal;
  font-size: 15px;
  font-family: ${({ theme }) => theme.typography.fonts.primary};
  line-height: 19px;

  & > nav {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    & .logo {
      margin-left: 22px;
      max-width: 250px;
    }

    & ul {
      flex: 1;
      width: 100%;
      height: 100%;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 22px;
    }

    & li {
      margin-left: 18px;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;

      &:hover {
        & > .sub-menu {
          visibility: visible;
          opacity: 1;
        }
      }

      &.back:after {
        border-right: 1px solid ${({ theme }) => theme.header.color};
        padding-right: 18px;
        content: '';
        height: 14px;
      }

      & div {
        margin-right: 12px;
      }

      & .sub-menu {
        display: flex;
        visibility: hidden;
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
        top: 100%;
        right: 0;
        width: auto;
        height: auto;
        margin: 0px 0 0 0;
        padding: 10px;
        box-shadow: 0 0 4px rgb(0 0 0 / 20%);
        background-color: ${({ theme }) => theme.header.backgroundColor};

        opacity: 0;
        transition: opacity 200ms ease-out;

        & > li {
          & > a {
            padding: 8px;
          }
          margin: 0;
          box-sizing: border-box;
          width: 100%;
          min-width: 150px;
          cursor: pointer;

          a:hover {
            span:before {
              visibility: hidden;
            }
          }
        }

        &::before {
          content: '';
          width: 100%;
          height: 70px;
          margin-top: -70px;
        }
      }
    }

    a {
      text-decoration: none;
      position: relative;

      & span:before {
        width: 100%;
        content: '';
        transition: all 0.2s ease-in-out 0s;
        opacity: 0.85;
        left: 0;
        position: absolute;
        bottom: -5px;
        height: 1px;
        background-color: ${({ theme }) => theme.header.color};
        visibility: hidden;
        transform: scaleX(0);
      }

      &:hover {
        span:before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }

    & .notificatonBadge {
      margin-left: 7px;
    }
  }
`;
