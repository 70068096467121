import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Section from 'components/Section';
import AssetImage from 'components/AssetImage';
import BrandLogo from 'components/BrandLogo';
import cx from 'classnames';
import { Title } from 'components/Typography';
import { useEventBus } from '@lwe/toolkit/utils';
import { TRAILER_PLAY_VIDEO_CLICKED } from '@root/events';
import s from './style.module.css';
import { gql } from '@apollo/client';
import ProductVideoPlayer from './components/ProductVideoPlayer';

const CourseVideoPreview = ({ product }) => {
  const [videoReady, setVideoReady] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const eventBus = useEventBus();

  const handleClick = () => {
    setShowVideo(true);
    eventBus.publish(TRAILER_PLAY_VIDEO_CLICKED, { product });
  };

  const {
    trailerYoutubeId,
    trailerHlsUrl,
    files: { header, logo, headermobile },
    tutorQuote,
  } = product;
  const hasVideo =
    (trailerYoutubeId !== null && trailerYoutubeId !== '') ||
    (trailerHlsUrl !== null && trailerYoutubeId !== '');

  return (
    <Section full className={s.root}>
      <ProductVideoPlayer
        product={product}
        setVideoReady={setVideoReady}
        videoReady={videoReady}
        showVideo={showVideo}
      />

      <div className={cx(s.poster, { [s.showVideo]: showVideo })}>
        <div>
          {logo && <BrandLogo logo={logo} hide={showVideo} />}
          {header && header.uuid && (
            <AssetImage sizes="(min-width: 1440px) 1440px, 100%" uuid={header.uuid} width={1500} />
          )}
          {hasVideo && (
            <div className={cx({ [s.ready]: videoReady })}>
              <button onClick={handleClick}>Watch</button>
              <Title white>Watch the course trailer</Title>
            </div>
          )}
          {!hasVideo && tutorQuote && (
            <div className={cx(s.ready, s.quote)}>
              <Title as="blockquote" white>
                {tutorQuote}
              </Title>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

CourseVideoPreview.fragments = {
  product: gql`
    fragment productVideoPreview on Product {
      id
      tutorQuote
      files {
        grid {
          id
          uuid
        }
        header {
          id
          uuid
        }
        logo {
          id
          uuid
        }
        headermobile {
          id
          uuid
        }
      }
      ...productVideoPlayer
    }
    ${ProductVideoPlayer.fragments.product}
  `,
};

CourseVideoPreview.propTypes = {
  product: PropTypes.object.isRequired,
  trailerYoutubeId: PropTypes.string,
  trailerHlsUrl: PropTypes.string,
};

export default CourseVideoPreview;
