import React from 'react';
import styled, { useTheme } from 'styled-components';
import { font } from 'components/Typography';

const BaseHelp = styled.div`
  margin-top: 8px;
  ${font.book.group(1)}
  color: ${({ theme }) => theme.colors.global.grey2}
`;

const BaseError = styled.div`
  margin-top: 8px;
  ${font.book.group(1)}
  color: ${({ theme }) => theme.colors.alerts.error.color}
`;

export const Error = ({ message }) => {
  return (
    <BaseError>
      <p>{message}</p>
    </BaseError>
  );
};

export const Help = ({ help }) => {
  return (
    <BaseHelp>
      <p>{help}</p>
    </BaseHelp>
  );
};

export const StripeFieldContainer = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.forms.inputRounded};
  line-height: 1;
  display: block;
  width: 100%;
  padding: 14px 14px 14px 14px;
  margin: 8px 0 8px 0;
  border: 1px solid ${({ theme }) => theme.colors.global.grey3};
  color: ${({ theme }) => theme.colors.global.grey1};
  background-color: ${({ theme }) => theme.colors.global.forms.inputBackground};
`;

export const useStripeTheme = () => {
  const theme = useTheme();
  return {
    base: {
      color: theme.colors.global.grey1,
      fontSize: '16px',
      '::placeholder': {
        color: theme.colors.global.grey2,
      },
    },
    invalid: {
      color: theme.colors.global.alertError,
    },
  };
};
