import styled, { css } from 'styled-components';

const template = ({ font = 'primary', weight = 'medium', group = 1, fontSize, lineHeight }) => {
  return ({ theme }) => {
    return css`
      font-family: ${theme.typography.fonts[font]};
      font-size: ${fontSize || theme.typography.groups[group].fontSize}px;
      line-height: ${lineHeight || theme.typography.groups[group].lineHeight}px;
      font-weight: ${theme.typography.weights[weight]};
    `;
  };
};

class Weight {
  constructor(weight, config) {
    this.config = {
      ...config,
      weight,
    };
  }

  group(id) {
    return template({
      ...this.config,
      group: id,
    });
  }

  custom(fontSize, lineHeight) {
    return template({
      ...this.config,
      fontSize,
      lineHeight,
    });
  }
}

class Font {
  constructor(font) {
    this.config = {
      font,
    };
  }

  get medium() {
    return new Weight('medium', this.config);
  }

  get book() {
    return new Weight('book', this.config);
  }

  get bold() {
    return new Weight('bold', this.config);
  }
}

export const font = new Font('primary');

const Typography = styled.span.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['theme'].includes(prop) && defaultValidatorFn(prop),
})`
  color: ${({ theme }) => theme.colors.global.grey1};

  ${({ white, theme }) =>
    white &&
    css`
      color: ${theme.colors.global.white};
    `}

  ${({ primary, theme }) =>
    primary &&
    theme.schools.current &&
    css`
      color: ${theme.schools.current.primary};
    `}

  ${({ smooth }) =>
    smooth &&
    css`
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    `}

  ${({ mobile }) => mobile}

  ${({ theme, ...props }) => {
    return Object.entries(theme.breakpoints).map(([breakpoint, query]) => {
      const rule = props[breakpoint];
      if (!rule) return null;
      return css`
        @media ${query} {
          ${rule}
        }
      `;
    });
  }}
`;

export default Typography;

export const typeset = (defaults) => {
  const Component = styled(Typography).attrs((props) => ({ ...defaults, ...props }))``;
  return Object.assign(Component, defaults);
};

export const Subtitle = typeset({
  mobile: font.medium.group(4),
  desktop: font.medium.group(5),
});

export const Body = typeset({
  mobile: font.book.group(4),
  desktop: font.book.group(3),
});

export const Large = typeset({
  mobile: font.bold.group(6),
  desktop: font.medium.group(9),
});

export const Title = typeset({
  mobile: font.bold.group(4),
  desktop: font.medium.group(7),
});

export const Small = typeset({
  mobile: font.medium.group(2),
  desktop: font.book.group(1),
});

export const Medium = typeset({
  mobile: font.book.custom(14.5, 18),
  desktop: font.book.custom(16, 18),
});

export const Headline = typeset({
  mobile: font.medium.group(2),
});

export const Hero = typeset({
  mobile: font.medium.group(11),
});

export const XtraLarge = typeset({
  mobile: font.medium.group(10),
});
