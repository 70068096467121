import React, { useCallback } from 'react';
import { withRouter } from 'react-router-v3';
import HomeLink from 'components/HomeLink';
import MenuButton from '../MenuButton';
import BasketButton from '../../..//BasketButton';
import SearchButton from '../../..//SearchButton';
import Icon from 'components/NextIcon';
import s from './style.module.css';
import { useConfiguration } from 'hooks/configuration';

const UtilityNav = ({ router, toggleOpen, closeNav, open, user, secure }) => {
  const { features } = useConfiguration();
  const showSearch = useCallback(() => {
    closeNav();
    router.push('/search');
  }, [closeNav, router]);

  return (
    <ul className={s.root} data-cy="nav-utility-mobile">
      <li>
        <HomeLink />
      </li>
      {!secure && (
        <>
          {features.search && (
            <li>
              <SearchButton onClick={showSearch} />
            </li>
          )}
          {features.shop && (
            <li>
              <BasketButton />
            </li>
          )}
          <li>
            <MenuButton open={open} onClick={toggleOpen} notify />
          </li>
        </>
      )}
      {secure && (
        <li>
          <Icon name="small-lock" className={s.lock} />
        </li>
      )}
    </ul>
  );
};

export default withRouter(UtilityNav);
