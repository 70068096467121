import React from 'react';
import { gql } from '@apollo/client';
import cx from 'classnames';
import { Link } from 'react-router-v3';
import BaseAvatar from 'components/Avatar';
import styled from 'styled-components';
import { Body, typeset, font } from 'components/Typography';
import s from './style.module.css';

const Avatar = styled(BaseAvatar)`
  position: absolute !important;
  left: 0;
  top: 5px;
`;

const Small = typeset({
  mobile: font.book.custom(12, 16),
});

const ProductStrip = ({
  product: { slug, title, isBundle, tutor, school, nestedProducts },
  ...props
}) => {
  return (
    <Link
      to={`/${school.slug}/courses/${slug}`}
      className={cx(s.root, school.slug, { [s.collection]: isBundle })}
      {...props}
    >
      {tutor && <Avatar size={28} user={tutor} />}
      <Body as="h3">{title}</Body>
      {tutor && <Small as="p">Taught by {tutor.profile.name}</Small>}
      {isBundle && <Small as="p">Collection of {nestedProducts.length} courses</Small>}
    </Link>
  );
};

ProductStrip.fragments = {
  product: gql`
    fragment ProductStrip_product on Product {
      id
      slug
      title
      isBundle
      nestedProducts {
        id
      }
      school {
        id
        slug
      }
      tutor {
        id
        profile {
          id
          name
        }
        ...Avatar__user
      }
    }
    ${Avatar.fragments.user}
  `,
};

export default ProductStrip;
