import React from 'react';
import { Link, withRouter } from 'react-router-v3';
import moment from 'moment';

import { useMediaQuery } from 'react-responsive';
import AssetImage from 'components/AssetImage';
import { Article } from './styles';

const BlogPostPacket = ({ router, post }) => {
  const { title, publishedAt, author, school, slug, files } = post;

  const name = author?.profile?.name;
  const isMobile = useMediaQuery({ maxWidth: 1023 });

  const handleClick = (e) => {
    router.push(`/${school.slug}/blog/${slug}`);
  };
  const { header, grid } = files;
  const image = grid || header;
  return (
    <Article onClick={handleClick}>
      <header>
        <h3>
          <Link to={`/${school.slug}/blog/${slug}`}>{title}</Link>
        </h3>
        <ul>
          <li>
            {name}
            {!isMobile && ` • ${moment(publishedAt).format('MMMM Do, YYYY')}`}
          </li>
          <li />
        </ul>
      </header>
      {image && <AssetImage uuid={image.uuid} />}
    </Article>
  );
};

export default withRouter(BlogPostPacket);
