import React from 'react';
import GridSection from 'components/GridSection';
import BlogPostPacketGrid from 'components/BlogPostPacketGrid';

const BlogPostGrid = ({ heading, posts, primary, white, ...props }) => {
  if (posts.length === 0) return null;
  return (
    <GridSection heading={heading} primary={primary} white={white}>
      <BlogPostPacketGrid list={heading} posts={posts} white={white} {...props} />
    </GridSection>
  );
};

export default BlogPostGrid;
