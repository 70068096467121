import styled from 'styled-components';

export const MenuDrawerContainer = styled.div`
  font-family: ${({ theme }) => theme.typography.fonts.primary};
  font-size: 16px;
  color: #333333;

  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  .bg {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .slide-container {
    position: relative;
    left: 100%;
  }

  .notificatonBadge {
    margin-left: 7px;
  }

  nav {
    min-width: 300px;
    background: white;
    height: 100vh;
  }

  ul {
    padding: 0 30px 30px 30px;
  }

  li {
    display: flex;
    align-items: center;
    padding: 15px 25px 15px 0;
    border-bottom: 1px solid rgba(51, 51, 51, 0.12);

    a {
      text-decoration: none;
    }

    &:first-child {
      a {
        margin-left: 10px;
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .header {
    height: 50px;

    .dt-close-mobile-menu-icon {
      cursor: pointer;
      padding: 5px 5px 5px 5px;
      margin: 15px 15px 0px 0px;
      min-width: 12px;
      min-height: 12px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;

      background-color: ${({ theme }) => theme.header.backgroundColor};
    }

    .close-line-wrap {
      width: 20px;
      height: 20px;
    }

    .close-line {
      width: 20px;
      top: 50%;
      margin-top: -1px;
      display: inline-block;
      transform-origin: 50% 50%;
      height: 2px;
      position: absolute;
      content: '';
      background: #ffffff;

      &:first-child {
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &:nth-child(2) {
        display: none;
      }

      &:last-child {
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
`;
