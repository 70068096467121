import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import Helmet from 'react-helmet';
import Page from 'components/Page';
import { Link } from 'react-router-v3';
import { PrimaryButton } from 'components/Button';
import TrustPilotSummary from 'components/sections/TrustPilotSummary';
import FeaturedProducts from 'components/sections/FeaturedProducts';
import ProductGrid from 'components/sections/ProductGrid';
import FrequentlyAskedQuestionsSummary from 'components/sections/FrequentlyAskedQuestionsSummary';
import ExpertsPanel from 'components/sections/ExpertsPanel';
import LatestBlogPosts from 'components/LatestBlogPosts';
import ClassroomExplainer from 'components/sections/ClassroomExplainer';
import ProductCardGrid from 'components/ProductCardGrid';
import NineToFivePromo from 'components/promos/NineToFivePromo';
import PageInfo from './components/PageInfo';
import ProductInfo from 'components/sections/ProductInfo';

import staticContent from 'assets/staticContent';

const COURSES_QUERY = gql`
  query Courses($schoolSlug: String) {
    school(slug: $schoolSlug) {
      id
      slug
      name
      tutors(limit: 6, starred: true) {
        id
        ...ExpertsPanel__experts
      }
      products(onlyVisible: true, limit: 50) {
        id
        ...ProductCardGrid__products
        tags
      }
      featuredProducts: products(limit: 6, tag: "featured", onlyVisible: true) {
        id
        ...FeaturedProducts__products
      }
      blog {
        id
        posts(limit: 3) {
          id
          ...LatestBlogPosts__posts
        }
      }
    }
  }

  ${ExpertsPanel.fragments.experts}
  ${FeaturedProducts.fragments.products}
  ${LatestBlogPosts.fragments.posts}
  ${ProductCardGrid.fragments.products}
  ${NineToFivePromo.fragments.promotions}
`;

const featuredProductsTitles = {
  foodanddrink: "Our favourite gifts for Valentine's",
  photography: "Our favourite gifts for Valentine's",
  gardening: "Our favourite gifts for Valentine's",
  jewellery: "Our favourite gifts for Valentine's",
  antiques: "Our favourite gifts for Valentine's",
  wellbeing: 'Buy today, start anytime',
  floristry: "Our favourite gifts for Valentine's",
};

const Courses = ({ params: { schoolSlug } }) => {
  const [limit, setLimit] = useState(true);
  const handleToggleMore = () => setLimit(!limit);
  const { data } = useQuery(COURSES_QUERY, {
    variables: { schoolSlug },
    ssr: true,
  });

  if (!data) return <Page />;

  const {
    school,
    school: { tutors, products, featuredProducts, blog },
  } = data;

  const content = {
    ...staticContent[school.slug],
  };

  const featuredProductsHeading = 'Buy today, start anytime';

  return (
    <Page className={school.slug}>
      <Helmet
        title={content.metaTitle}
        meta={[
          { name: 'description', content: content.metaDescription },
          { name: 'keywords', content: content.metaKeywords },
        ]}
      />
      <PageInfo h1={content?.h1} intro={content?.intro} slug={school.slug} />

      {products.length > 1 ? (
        <ProductGrid
          id="all-courses"
          sort
          total={products.length}
          products={limit && products.length > 50 ? products.slice(0, 6) : products}
          showingMore={!limit}
          onToggleMore={products.length > 50 && handleToggleMore}
          withinBackground
          darkText
          large
        />
      ) : (
        <FeaturedProducts
          id="featured-products"
          products={featuredProducts}
          heading={featuredProductsHeading}
          darkText
        />
      )}
      <TrustPilotSummary school={school.slug} color="light" />
      <ProductInfo slug={school.slug} contextualInfo={content?.productInfo} />
      <ExpertsPanel experts={tutors} color="gray" heading={`Meet our ${school.name} experts`} />
      <LatestBlogPosts
        posts={blog.posts}
        school={school}
        heading={`Get some ${school.name} inspiration`}
      >
        <PrimaryButton inverse as={Link} to={`/${school.slug}/blog`}>
          View all posts
        </PrimaryButton>
      </LatestBlogPosts>
      <FrequentlyAskedQuestionsSummary
        light
        name={school.name}
        contextualQuestions={content?.faq}
      />
      <ClassroomExplainer school={school} />
    </Page>
  );
};

export default Courses;
