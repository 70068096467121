import React, { useEffect } from 'react';
import { Headline } from 'components/Typography';
import pluralize from 'pluralize';
import Pagination from 'components/Pagination';
import { gql } from '@apollo/client';
import { Section } from 'components/Layout';
import Product from './components/ProductSearchResult';
import BlogPost from './components/BlogPostSearchResult';
import Question from './components/QuestionSearchResult';
import Tutor from './components/TutorSearchResult';
import Page from './components/PageSearchResult';
import School from './components/SchoolSearchResult';
import { useEventBus } from '@lwe/toolkit/utils';
import { PRODUCT_IMPRESSIONS } from '@root/events';
import useCurrencyCode from 'hooks/useCurrencyCode';
import s from './style.module.css';

const RESULT_COMPONENTS = {
  Product,
  BlogPost,
  Question,
  Tutor,
  Page,
  School,
};

const Results = ({ search: { results, pagination, term } }) => {
  const { currentPage, totalPages, totalCount } = pagination;
  const currencyCode = useCurrencyCode();
  const eventBus = useEventBus();

  useEffect(() => {
    const products = results.filter((r) => r.__typename === 'Product');
    if (products.length && currencyCode) {
      eventBus.publish(PRODUCT_IMPRESSIONS, {
        products,
        list: 'Search results',
        currencyCode,
      });
    }
  }, [results, currencyCode]);

  return (
    <Section wide>
      <Headline className={s.heading} as="p">
        {totalCount} {pluralize('result', totalCount)} found
      </Headline>
      <ul className={s.results}>
        {results.map((result) => {
          const Component = RESULT_COMPONENTS[result.__typename];
          if (!Component) return null;
          return (
            <li key={`${result.__typename}-${result.id}`}>
              <Component result={result} />
            </li>
          );
        })}
      </ul>
      {totalPages > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          hrefBuilder={(selected) => {
            const page = selected === 0 ? '' : `&page=${selected + 1}`;
            return `/search?term=${term}${page}`;
          }}
          outline
        />
      )}
    </Section>
  );
};

Results.fragments = {
  results: gql`
    fragment Results_results on Search {
      results {
        ... on Product {
          id
          __typename
          ...ProductSearchResult_result
        }
        ... on BlogPost {
          id
          __typename
          ...BlogPostSearchResult_result
        }
        ... on Page {
          id
          __typename
          ...PageSearchResult_result
        }
        ... on Question {
          id
          __typename
          ...QuestionSearchResult_result
        }
        ... on User {
          id
          __typename
          ...TutorSearchResult_result
        }
        ... on School {
          id
          __typename
          ...SchoolSearchResult_result
        }
      }
    }
    ${Product.fragments.result}
    ${BlogPost.fragments.result}
    ${Page.fragments.result}
    ${Question.fragments.result}
    ${Tutor.fragments.result}
    ${School.fragments.result}
  `,
};

export default Results;
