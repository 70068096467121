import React from 'react';

const LessonIcon = () => {
  return (
    <svg viewBox="0 0 33 32" width="33" height="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.473 20.905h-.796V3.192c0-.798-.636-1.437-1.592-1.437H18.1l-.159-.638C17.781.48 17.145 0 16.349 0s-1.274.32-1.592 1.117l-.16.638H3.614c-.956 0-1.592.639-1.592 1.437v17.713h-.796c-.478 0-.796.32-.796.798v1.596c0 .478.318.798.796.798h6.05L5.363 30c-.319.798.318 1.755 1.114 1.915.796.32 1.752-.32 1.91-1.117l2.23-6.862H22.24l2.228 6.862c.478 1.436 1.752 1.117 1.91 1.117.797-.32 1.274-1.117 1.115-1.915l-1.91-5.904h6.05c.477 0 .796-.32.796-.798v-1.596c-.16-.479-.478-.798-.956-.798zm-10.348-5.479H6.797c-.955 0-1.592-.5-1.592-1.25s.637-1.25 1.592-1.25h14.328c.955 0 1.592.5 1.592 1.25 0 .625-.796 1.25-1.592 1.25zm4.776-6.383H6.797c-.955 0-1.592-.5-1.592-1.25s.637-1.25 1.592-1.25H25.9c.955 0 1.592.5 1.592 1.25 0 .625-.796 1.25-1.592 1.25z"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default LessonIcon;
