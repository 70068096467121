import React, { useState } from 'react';
import { withRouter } from 'react-router-v3';
import Contents from '../Contents';
import SearchForm from 'components/SearchForm';
import s from './style.module.css';

const Search = ({ router, location: { query } }) => {
  const handleSubmit = ({ term }) => {
    router.push(`/search?term=${term}`);
  };

  return (
    <Contents>
      <div className={s.root} data-cy="form-search">
        <SearchForm initialTerm={query.term} onSubmit={handleSubmit} />
      </div>
    </Contents>
  );
};

export default withRouter(Search);
