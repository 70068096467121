import React from 'react';
import { withRouter, Link } from 'react-router-v3';
import { useMediaQuery } from 'react-responsive';

import { typeset, font } from 'components/Typography';
import { Nav } from './styles';

export const Subtitle = typeset({
  mobile: font.book.group(3),
  desktop: font.book.group(5),
});

const NavItem = (props) => {
  return (
    <Subtitle white as="li">
      <Link activeClassName="active" {...props} />
    </Subtitle>
  );
};

const SchoolNavigation = ({ router: { location }, school, productTitle, offsetHeader }) => {
  const is_mobile = useMediaQuery({ maxWidth: 949 });

  const inCourses = location.pathname?.startsWith(`/${school.slug}/courses`);

  return (
    <Nav
      slug={school.slug}
      offsetHeader={offsetHeader}
      role="navigation"
      aria-label="Secondary menu"
    >
      <div>
        <ul className="breadcrumbs">
          {!is_mobile && (
            <>
              <NavItem to="/" onlyActiveOnIndex={!inCourses}>
                Home
              </NavItem>
              <li>/</li>
            </>
          )}
          <NavItem to={`/${school.slug}`} onlyActiveOnIndex={!inCourses}>
            {school.name} Courses
          </NavItem>
          {productTitle && !is_mobile && (
            <>
              <li>/</li>
              <li>{productTitle}</li>
            </>
          )}
        </ul>
        <ul className="submenu">
          <NavItem to={`/${school.slug}/community`}>
            {!is_mobile && `${school.name} `}Community
          </NavItem>
          <NavItem to={`/${school.slug}/blog`}>{!is_mobile && `${school.name} `}Blog</NavItem>
        </ul>
      </div>
    </Nav>
  );
};

export default withRouter(SchoolNavigation);
