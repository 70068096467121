import React from 'react';
import { Field } from 'react-final-form';
import TextInput from '../../inputs/TextInput';

const FileField = (props) => {
  return (
    <Field {...props}>
      {({ input, ...rest }) => {
        return <TextInput {...input} {...rest} />;
      }}
    </Field>
  );
};

export default FileField;
