import React from 'react';
import { gql } from '@apollo/client';
import { Body, Title } from 'components/Typography';
import { SectionHeading } from 'components/Elements';
import { Content, Section } from 'components/Layout';
import AssetImage from 'components/AssetImage';
import Avatar from 'components/Avatar';
import s from './style.module.css';

const ExpertProfile = ({ tutor }) => {
  if (!tutor) return null;

  const {
    school,
    profile: { name, biog, quote, quoteCitation, portrait },
  } = tutor;
  const altText = portrait?.title || `${name} - ${school.name}`;

  return (
    <Section color="gray" className={s.root}>
      <SectionHeading white>Meet {name}</SectionHeading>
      <Content>
        <div className={s.columns}>
          {portrait && (
            <div className={s.portrait}>
              <AssetImage
                sizes="(min-width: 1024px) 445px, 100%"
                width={445}
                uuid={portrait.uuid}
                alt={altText}
              />
            </div>
          )}
          <div className={s.biog}>
            {!portrait && <Avatar size={95} user={tutor} className={s.avatar} alt={altText} />}
            {tutor && quote && (
              <blockquote>
                <Title as="p" white>
                  {quote}
                </Title>
                {quoteCitation && (
                  <Body as="footer" white>
                    {quoteCitation}
                  </Body>
                )}
              </blockquote>
            )}
            {biog && (
              <Body
                as="div"
                white
                dangerouslySetInnerHTML={{ __html: biog.replace(/(?:\r\n|\r|\n)/g, '<br>') }}
              />
            )}
          </div>
        </div>
      </Content>
    </Section>
  );
};

ExpertProfile.fragments = {
  expert: gql`
    fragment ExpertProfile__expert on User {
      id
      ...Avatar__user
      profile {
        id
        name
        biog
        quote
        quoteCitation
        portrait {
          id
          uuid
          title
        }
      }
      school {
        id
        slug
        name
      }
    }
    ${Avatar.fragments.user}
  `,
};

export default ExpertProfile;
