export const ORDER_CREATED = 'ORDER_CREATED';
export const ADDED_TO_BASKET = 'ADDED_TO_BASKET';
export const REMOVED_FROM_BASKET = 'REMOVED_FROM_BASKET';
export const ADD_TO_BASKET_FAILED = 'ADD_TO_BASKET_FAILED';
export const PROMO_CODE_APPLIED = 'PROMO_CODE_APPLIED';
export const SEAT_STARTED = 'SEAT_STARTED';
export const CHECKOUT_STEP = 'CHECKOUT_STEP';
export const SOCIAL_BUTTON_CLICKED = 'SOCIAL_BUTTON_CLICKED';
export const BLOG_POST_SIGNUP = 'BLOG_POST_SIGNUP';
export const PRODUCT_BANNER_CLICKED = 'PRODUCT_BANNER_CLICKED';
export const PROMO_VIEWED = 'PROMO_VIEWED';
export const PROMO_CLICKED = 'PROMO_CLICKED';
export const PRODUCT_CLICKED = 'PRODUCT_CLICKED';
export const PRODUCT_IMPRESSIONS = 'PRODUCT_IMPRESSIONS';
export const PRODUCT_VIEWED = 'PRODUCT_VIEWED';
export const TRAILER_PLAY_VIDEO_CLICKED = 'TRAILER_PLAY_VIDEO_CLICKED';
export const PRIVATE_MESSAGE_CREATED = 'PRIVATE_MESSAGE_CREATED';
export const ASSIGNMENT_SUBMITTED = 'ASSIGNMENT_SUBMITTED';
export const ASSIGNMENT_MESSAGE_CREATED = 'ASSIGNMENT_MESSAGE_CREATED';
export const CLASSROOM_MESSAGE_CREATED = 'CLASSROOM_MESSAGE_CREATED';
export const MESSAGE_REPLY_CREATED = 'MESSAGE_REPLY_CREATED';
export const COMMUNITY_QUESTION_CREATED = 'COMMUNITY_QUESTION_CREATED';
export const COMMUNITY_ANSWER_CREATED = 'COMMUNITY_ANSWER_CREATED';
export const FREE_SEAT_CLAIMED = 'FREE_SEAT_CLAIMED';
export const ENTERPRISE_SALE_ENQUIRY = 'ENTERPRISE_SALE_ENQUIRY';
