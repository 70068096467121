import React from 'react';
import Partners from './components/Partners';
import styled from 'styled-components';
import { PrimaryButton } from 'components/Button';
import { Link } from 'react-router-v3';
import { CustomerSupportAPI } from 'utils/support';
import Icon from 'components/NextIcon';
import LazyImage from 'components/LazyImage';
import s from './style.module.css';

const year = new Date().getFullYear();

const ft = require('./images/FT@3x.png');
const daily = require('./images/Daily_Express@3x.png');
const vogue = require('./images/Vogue@3x.png');
const guardian = require('./images/The_Guardian@3x.png');
const tatler = require('./images/Tatler@3x.png');

const showAffiliateArticle = () => {
  CustomerSupportAPI('openArticle', '6318bc29c713d51da3edc47b');
};

const AffiliateLink = styled.a`
  cursor: pointer;
`;

const Footer = ({ school, schools, showRegister, showHowItWorks, app }) => {
  return (
    <>
      {!app && <Partners />}
      <footer className={s.root}>
        <section>
          <div className={s.featured}>
            <div>
              <span>
                As featured in<span>:</span>
              </span>
            </div>
            <div>
              <LazyImage src={ft} width={85} alt="Financial Times" />
              <LazyImage src={daily} width={128} alt="Daily Express" />
            </div>
            <div>
              <LazyImage src={vogue} width={128} alt="Vogue Business" />
            </div>
            <div>
              <LazyImage src={guardian} width={86} alt="The Guardian" />
              <LazyImage src={tatler} width={79} alt="Tatler" />
            </div>
          </div>

          <div>
            <section>
              <h4>Stay updated</h4>
              <p>
                Receive free Learning with Experts updates by email including special offers and new
                courses.
              </p>
              <PrimaryButton inverse onClick={showRegister}>
                Sign up
              </PrimaryButton>
              {school && (
                <p>
                  <small>Connect with our {school.name} communities</small>
                </p>
              )}

              <div>
                <span>Follow us</span>
                <div>
                  <a href="https://twitter.com/lwe_hub">
                    <Icon name="twitter" />
                  </a>
                </div>
                <div>
                  <a href="https://instagram.com/learningwithexperts">
                    <Icon name="instagram" />
                  </a>
                </div>
                <div>
                  <a href="https://www.facebook.com/LWEHub/">
                    <Icon name="facebook" />
                  </a>
                </div>
              </div>
            </section>
            <section>
              {school && (
                <ul>
                  <li>
                    <h4>{school.name}</h4>
                  </li>
                  <li>
                    <Link to={`/${school.slug}`}>Courses</Link>
                  </li>
                  <li>
                    <Link to={`/${school.slug}/community`}>Community</Link>
                  </li>
                  <li>
                    <Link to={`/${school.slug}/blog`}>Blog</Link>
                  </li>
                </ul>
              )}
              <ul>
                <li>
                  <h4>Categories</h4>
                </li>
                <li>
                  <a href="https://business.learningwithexperts.com/?utm_source=learningwithexperts&utm_medium=lwe_footer&utm_campaign=organic_b2b">For Business</a>
                </li>
                {schools.map((theSchool) => (
                  <li key={theSchool.slug}>
                    <Link to={`/${theSchool.slug}`}>{theSchool.name}</Link>
                  </li>
                ))}
              </ul>
              <ul>
                <li>
                  <h4>Support</h4>
                </li>
                <li>
                  <a onClick={showHowItWorks} href="#">
                    How it works
                  </a>
                </li>
                <li>
                  <a href="https://help.learningwithexperts.com">FAQs</a>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <a href="https://press.learningwithexperts.com">Press &amp; Media</a>
                </li>
                <li>
                  <Link to="/contact-us">Contact</Link>
                </li>
                <li>
                  <AffiliateLink onClick={showAffiliateArticle}>Affiliates</AffiliateLink>
                </li>
                <li className={s.jobs}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://angel.co/company/learningwithexperts"
                  >
                    Jobs
                  </a>
                </li>
              </ul>
            </section>
          </div>
        </section>

        <section>
          <ul>
            <li>© {year} Learning with experts</li>
            <li>
              <Link to="/terms-and-conditions">Terms & conditions</Link>
            </li>
            <li>
              <Link to="/privacy-policy">Privacy policy</Link>
            </li>
          </ul>
        </section>
      </footer>
    </>
  );
};

export default Footer;
