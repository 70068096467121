import React from 'react';
import styled from 'styled-components';
import { Section } from 'components/Layout';
import { SectionHeading, Link } from 'components/Elements';
import CollectionStats from 'components/CollectionStats';
import { Large, Body, Subtitle } from 'components/Typography';
import ProductCard from 'components/ProductCard';
import s from './style.module.css';
import { gql } from '@apollo/client';

const Collection = ({ collection }) => {
  const { title, slug, school, shortDescription, nestedProducts, pricing } = collection;
  const totalIndividualPrice = nestedProducts.reduce((acc, product) => {
    return acc + product.pricing.from.current;
  }, 0);
  const saving = Math.floor((1 - pricing.from.current / totalIndividualPrice) * 100);

  return (
    <div className={s.collection}>
      <ProductCard product={collection} large />
      <section>
        <Large as="h3">{title}</Large>
        <Subtitle as="p" primary>
          Master the entire subject and SAVE {saving}% when you buy this collection
        </Subtitle>
        <CollectionStats product={collection} />
        <Body as="p" dangerouslySetInnerHTML={{ __html: shortDescription }} />
        <Link to={`/${school.slug}/courses/${slug}`} button gray>
          Learn more
        </Link>
      </section>
    </div>
  );
};

const IncludedInCollections = ({ product }) => {
  if (!product || !product.collections || !product.collections.length) return null;
  const { collections } = product;
  const phrase = collections.length > 1 ? 'these collections' : 'this collection';
  return (
    <Section>
      <SectionHeading>Frequently bought as part of {phrase}</SectionHeading>
      <ul className={s.root}>
        {collections.map((collection) => {
          return (
            <li key={collection.id}>
              <Collection collection={collection} />
            </li>
          );
        })}
      </ul>
    </Section>
  );
};

IncludedInCollections.fragments = {
  product: gql`
    fragment includedInCollections on Product {
      id
      collections {
        id
        title
        slug
        shortDescription
        pricing {
          from {
            current
          }
        }
        nestedProducts {
          id
          tutor {
            id
          }
          pricing {
            from {
              current
            }
          }
        }
        ...ProductCard__product
        ...collectionStats
      }
    }
    ${ProductCard.fragments.product}
    ${CollectionStats.fragments.product}
  `,
};

export default IncludedInCollections;
