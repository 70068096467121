import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-v3';
import { useQuery, useMutation, gql } from '@apollo/client';
import { CustomerSupportAPI } from 'utils/support';
import { Section, Content } from 'components/Layout';
import { Title, Body } from 'components/Typography';
import CategoriesPanel from 'components/sections/CategoriesPanel';
import BlogPostListing from 'components/sections/BlogPostListing';
import s from './style.module.css';

const icons = {
  success: require('./assets/icon-success.svg'),
  problem: require('./assets/icon-problem.svg'),
};

const CONFIRM_UNREGISTERED_USER_MUTATION = gql`
  mutation ConfirmUnregisteredUser($token: String!) {
    confirmUnregisteredUser(token: $token) {
      id
      isConfirmed
      schools(limit: 1) {
        id
        slug
        name
        ...BlogPostListing__posts
      }
    }
  }
  ${BlogPostListing.fragments.posts}
`;

const CATEGORY_QUERY = gql`
  query CategoryQuery {
    navigation {
      id
      schools {
        id
        name
        slug
        path
      }
      ...CategoriesPanel__schools
    }
  }
  ${CategoriesPanel.fragments.schools}
`;

const UnregisteredUserConfirm = ({ params: { token } }) => {
  const [confirmed, setConfirmed] = useState(false);
  const [confirmUnregisteredUser, { data, loading }] = useMutation(
    CONFIRM_UNREGISTERED_USER_MUTATION,
    { variables: { token } },
  );
  const unregisteredUser = data && data.confirmUnregisteredUser;

  const handleClick = () => {
    CustomerSupportAPI('showNewMessage');
  };

  useEffect(() => {
    const confirm = async () => {
      try {
        await confirmUnregisteredUser();
        setConfirmed(true);
      } catch (e) {
        setConfirmed(false);
      }
    };
    confirm();
  }, []);

  const { data: categoryData, loading: categoryLoading } = useQuery(CATEGORY_QUERY, { ssr: false });

  if (loading || categoryLoading || !categoryData) return null;

  const school = unregisteredUser && unregisteredUser.schools && unregisteredUser.schools[0];
  const {
    navigation: { schools },
  } = categoryData;

  return (
    <>
      <Section gray className={s.root}>
        {confirmed ? (
          <>
            <img alt="Success" src={icons.success} />
            <Title as="h1">Thank you for subscribing!</Title>
            <Body as="p">
              Keep an eye out for the next edition of our newsletter, in the meantime check out our
              latest posts.
            </Body>
          </>
        ) : (
          <>
            <img alt="Problem" src={icons.problem} />
            <Title as="h1">There's been a problem!</Title>
            <Body as="p">
              We're sorry, but something went wrong. Please{' '}
              <Link to="#" onClick={handleClick}>
                contact us
              </Link>{' '}
              and we'll try to help.
            </Body>
          </>
        )}
      </Section>
      {confirmed && school && <BlogPostListing school={school} />}
      <CategoriesPanel schools={schools} />
    </>
  );
};

export default UnregisteredUserConfirm;
