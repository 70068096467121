import React from 'react';
import Modal from 'react-modal';
import cx from 'classnames';
import Icon from 'components/NextIcon';
import s from './style.module.css';

Modal.setAppElement('#app');

const Dialog = ({ dark, fullscreen, children, title, handleClose, footer = null, ...props }) => {
  return (
    <Modal
      className={cx(s.root, { [s.dark]: dark, [s.fullscreen]: fullscreen })}
      bodyOpenClassName={s.body}
      overlayClassName={cx(s.overlay, { [s.fulloverlay]: fullscreen })}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={!!handleClose}
      shouldCloseOnEsc={!!handleClose}
      closeTimeoutMS={200}
      {...props}
    >
      <section className={s.container}>
        {handleClose && <Icon name="close" onClick={handleClose} className={s.close} />}
        <section className={s.content}>
          <div>{children}</div>
        </section>
        {footer}
      </section>
    </Modal>
  );
};

export default Dialog;
