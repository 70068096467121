import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { Link } from 'react-router-v3';
import { Title } from 'components/Typography';
import Menu, { MenuLink } from '../Menu';
import s from './style.module.css';

const Courses = ({ navigation: { schools }, navigateHome, closeNav }) => {
  return (
    <Menu>
      <ul>
        <li>
          <MenuLink back onClick={navigateHome}>
            Back
          </MenuLink>
        </li>
        <li>
          <Title as="h3">Courses</Title>
          <ul>
            {schools.map(({ id, name, slug }) => {
              return (
                <li key={id}>
                  <Link onClick={closeNav} to={`/${slug}`}>
                    {name}
                  </Link>
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    </Menu>
  );
};

Courses.fragments = {
  navigation: gql`
    fragment Mobile__Courses__navigation on Navigation {
      id
      schools {
        id
        name
        slug
      }
    }
  `,
};

export default Courses;
