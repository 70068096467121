import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { isAdmin, isTutor } from 'utils/roles';
import Helmet from 'react-helmet';
import JsonLd from 'components/JsonLd';
import moment from 'moment';
import AssetImage from 'components/AssetImage';
import { Link } from 'components/Elements';
import Page from 'components/Page';
import Corner from 'components/atoms/Corner';
import { BlogPostEditorButton } from 'components/admin';
import BlogPostPacket from 'components/BlogPostPacket';
import ProductCard from 'components/ProductCard';
import BlogPostGrid from 'components/BlogPostGrid';
import ProductGrid from 'components/sections/ProductGrid';
import Footer from './components/Footer';
import findMetaTags from 'utils/findMetaTags';
import SocialButtons from 'components/SocialButtons';
import { useEventBus } from '@lwe/toolkit/utils';
import { SOCIAL_BUTTON_CLICKED } from '@root/events';
import RecommendedProduct from 'components/RecommendedProduct';
import SlateBody from 'components/SlateBody';
import MailingListSignUp from 'components/MailingListSignUp';
import s from './style.module.css';
import { useCurrentUser } from '@lwe/toolkit/authentication';
import { useConfiguration } from 'hooks/configuration';
import styled from 'styled-components';

const Header = styled.header`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.schools.current.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;

  & > img {
    height: 56.34vw;
    @media ${({ theme }) => theme.breakpoints.desktop} {
      height: initial;
    }
  }

  & > * {
    width: 100%;
    @media ${({ theme }) => theme.breakpoints.desktop} {
      width: 765px;
    }
  }

  & > h1 {
    padding: 20px 20px 0 20px;
    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-size: 36px;
      line-height: 44px;
      margin: 30px 0 0 0;
      padding: 0;
    }
  }

  & > p {
    padding: 0 20px 0 20px;
    @media ${({ theme }) => theme.breakpoints.desktop} {
      font-size: 16px;
      line-height: 18px;
      margin: 10px 0;
      padding: 0;
    }

    & a {
      text-decoration: none;
    }
  }

  & > ul {
    padding-left: 18px;
    margin: 0 0 20px 0;
    @media ${({ theme }) => theme.breakpoints.desktop} {
      margin: 5px 0 30px 0;
      padding: 0;
    }
  }
`;

const BLOG_LISTING_QUERY = gql`
  query SchoolBlogPostQuery($schoolSlug: String, $blogPostSlug: String!) {
    school(slug: $schoolSlug) {
      id
      name
      slug
      bestSellingProducts: products(limit: 3, starred: true, onlyVisible: true) {
        id
        ...ProductCard__product
      }
      blog {
        id
        post(slug: $blogPostSlug) {
          id
          slug
          title
          metaTitle
          metaDescription
          body
          slateBody
          publishedAt
          school {
            id
            slug
          }
          author {
            id
            ...author
          }
          files {
            header {
              id
              uuid
            }
          }
          relatedBlogPosts {
            id
            ...post
          }
          relatedProducts {
            id
            ...ProductCard__product
          }
          embeddedProducts {
            id
            ...RecommendedProduct__product
          }
        }
      }
      ...MailingListSignUp__school
    }
  }
  ${Footer.fragments.author}
  ${BlogPostPacket.fragments.post}
  ${ProductCard.fragments.product}
  ${RecommendedProduct.fragments.product}
  ${MailingListSignUp.fragments.school}
`;

const BlogPost = ({ location: { hash }, params: { schoolSlug, blogPostSlug } }) => {
  const { currentUser } = useCurrentUser();
  const { settings } = useConfiguration();
  const eventBus = useEventBus();
  const variables = { schoolSlug, blogPostSlug };
  const { data } = useQuery(BLOG_LISTING_QUERY, {
    variables,
    ssr: true,
  });
  if (!data) return <Page />;
  const { school } = data;
  if (!school.blog.post) return <Page />;
  const showEditor = isAdmin(currentUser);
  const {
    bestSellingProducts,
    blog,
    blog: { post },
    slug,
  } = school;
  if (!post) return null;
  const {
    files: { header },
    relatedBlogPosts,
    relatedProducts,
    title,
    author,
    metaDescription,
    publishedAt,
    body,
  } = post;
  const { slateBody } = post;
  const metaTags = findMetaTags({ school: school, ...post }, settings);
  const datePublished = moment(publishedAt).format('YYYY-MM-DD');

  const hasRelatedProducts = relatedProducts.length > 0;
  const products = hasRelatedProducts ? relatedProducts : bestSellingProducts;
  const productTitle = hasRelatedProducts ? 'Related courses' : 'Our best selling courses';
  const isPublished = publishedAt && moment(publishedAt).isBefore();

  const handleSocialClick = (category) => {
    eventBus.publish(SOCIAL_BUTTON_CLICKED, { category, label: 'Blog post page' });
  };

  const postUrl = `${settings.frontendUrl}/${school.slug}/blog/${post.slug}`;

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    mainEntity: {
      '@context': 'http://schema.org',
      '@type': 'Article',
      name: post.title,
      about: school.name,
      abstract: metaDescription,
      url: postUrl,
      author: {
        '@type': 'Person',
        name: author && author.profile.name,
      },
      datePublished: datePublished,
    },
  };

  return (
    <Page>
      {showEditor && (slateBody || hash === '#edit') && (
        <BlogPostEditorButton post={post} blog={blog} />
      )}
      <article className={s.root}>
        <Helmet title={`${post.metaTitle || post.title} - ${school.name}`} meta={metaTags} />
        <JsonLd data={schemaData} />
        <Header>
          {!isPublished && <Corner primary />}
          <h1>{title}</h1>
          <p>
            {author && (
              <>
                {' '}
                By{' '}
                <Link to={isTutor(author) ? `/experts/${author.slug}` : ''}>
                  {author.profile.name}
                </Link>{' '}
                •{' '}
              </>
            )}
            {publishedAt ? (
              <time dateTime={datePublished} itemProp="datePublished" content={datePublished}>
                {moment(publishedAt).format('MMMM Do, YYYY')}
              </time>
            ) : (
              <span>Unpublished</span>
            )}
          </p>
          <SocialButtons
            message={`${title} from Learning with Experts`}
            onClick={handleSocialClick}
          />
          {header && <AssetImage uuid={header.uuid} width={765} height={431} />}
        </Header>
        {slateBody ? (
          <SlateBody as="section" itemProp="text" context={post} body={slateBody} />
        ) : (
          <section itemProp="text" dangerouslySetInnerHTML={{ __html: body }} />
        )}
        <Footer author={author} />
        <MailingListSignUp school={school} />
      </article>
      <BlogPostGrid
        posts={relatedBlogPosts}
        school={school}
        heading="Related posts"
        carousel
        primary
        white
        dots="white"
      />
      <ProductGrid
        className={s.relatedProducts}
        products={products}
        school={school}
        heading={productTitle}
        color="gray"
      />
    </Page>
  );
};

export default BlogPost;
