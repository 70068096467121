import React, { useState } from 'react';
import { gql } from '@apollo/client';
import styled, { css } from 'styled-components';
import { scroller } from 'react-scroll';
import useCurrencyCode from 'hooks/useCurrencyCode';
import BaseTag from 'components/atoms/Tag';
import AddToBasketButton from 'components/AddToBasketButton';
import { font, typeset, Body } from 'components/Typography';
import { RadioContainer, BaseRadio, BaseLabel } from 'components/Form';
import KlarnaPromo from 'components/KlarnaPromo';

const Container = styled.div`
  width: 100%;
  padding: 0 0 0 0;
  color: ${({ theme }) => theme.colors.global.grey1};
  background-color: ${({ theme }) => theme.colors.global.white};
  border-radius: ${({ theme }) => theme.forms.inputRounded};
  overflow: hidden;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 100%;
    // margin-bottom: 15px;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    // max-width: 369px;
    // margin-bottom: 15px;
  }
`;

const Tag = styled(BaseTag).attrs({
  mobile: font.book.custom(9, 16),
  hd: font.book.custom(12, 16),
})`
  position: absolute;
  top: 2px;
  right: 0;

  @media ${({ theme }) => theme.breakpoints.hd} {
    top: 8px;
  }
`;

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Variant = styled.li`
  width: 100%;
  padding: 10px 15px 10px 12px;
  background-color: ${({ theme }) => theme.colors.global.grey6};
  border-radius: ${({ theme }) => `
    ${theme.forms.inputRounded}
    ${theme.forms.inputRounded}
    0
    0`};

  &:last-child {
    border-top: 1px solid ${({ theme }) => theme.colors.global.grey4};
    border-radius: ${({ theme }) => `
      0
      0
      ${theme.forms.inputRounded}
      ${theme.forms.inputRounded}`};
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      padding-bottom: 12px;
      background-color: ${({ theme }) => theme.colors.global.white};
    `}
`;

const StyledRadioButton = styled(BaseRadio)`
  && ~ ${BaseLabel} {
    padding: 5px 28px;
    @media ${({ theme }) => theme.breakpoints.desktop} {
      padding: 3px 28px;
    }
  }
  && ~ ${BaseLabel}::before, && ~ ${BaseLabel}::after {
    height: 20px;
    width: 20px;
    ${({ isOrphan }) =>
      isOrphan &&
      css`
        display: none;
      `}
  }

  &&:checked ~ ${BaseLabel}::after {
    border-width: 5px;
    box-shadow: inset 0 0 0 10px ${({ theme }) => theme.schools.current.primary};
    &:hover {
      cursor: pointer;
    }
  }
`;

const Label = styled(BaseLabel)`
  && {
    margin: 0 0 0 0;
    ${font.medium.group(5)}
    color: ${({ theme }) => theme.colors.global.grey1};

    &:hover {
      cursor: pointer;
    }

    @media ${({ theme }) => theme.breakpoints.hd} {
      ${font.medium.group(7)}
    }
  }
`;

const Heading = typeset({
  mobile: font.medium.group(5),
  as: 'h2',
  primary: true,
});

const Price = styled(Heading)`
  margin-left: 26px;
  margin-top: -5px;
  color: ${({ theme }) => theme.schools.current.primary};

  ${({ highlight }) =>
    highlight &&
    css`
      color: #c9463d;

      & > s {
        color: ${({ theme }) => theme.colors.global.grey1};
      }
    `};

  @media ${({ theme }) => theme.breakpoints.hd} {
    ${font.medium.group(7)}
  }
`;

const AddToBasketContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 4px 26px;
  opacity: 0;
  height: 0;
  transition-property: all;
  transition-duration: 0.18s;
  transition-timing-function: ease-in-out;

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      opacity: 1;
      height: 105px;

      @media ${({ theme }) => theme.breakpoints.hd} {
        height: 125px;
      }
    `}
`;

const Description = styled(Body).attrs({ desktop: font.book.group(1), hd: font.book.group(3) })`
  margin: 10px 0 0 0;

  & > a {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Button = styled(AddToBasketButton)`
  @media ${({ theme }) => theme.breakpoints.desktop} {
    ${font.medium.group(1)}
    padding: 8px 14px;
    border-radius: ${({ theme }) => theme.forms.buttonRoundedSmall};
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    ${font.medium.group(4)}
    padding: 15px 20px 15px 20px;
    border-radius: ${({ theme }) => theme.forms.buttonRounded};
  }
`;

const handleScrollToPricing = (e) => {
  e.preventDefault();
  window.document.querySelector('#pricing').scrollIntoView({
    behavior: 'smooth',
  });
};

const KlarnaContainer = styled.div`
  padding: 0 0 0 26px;
`;

const sorter = (a) => (a.tag === 'tutor_access_all' ? -1 : 1);

const VariantSelector = ({ product }) => {
  const variants = product.variants.filter((v) => v.tag !== 'tutor_access_1').sort(sorter);
  const [selectedVariantId, setSelectedVariantId] = useState(variants[0].id);
  const currencyCode = useCurrencyCode();

  const toggleVariant = (e) => {
    setSelectedVariantId(e.target.value);
  };

  const isOrphan = variants.length === 1;
  return (
    <Container>
      <List>
        {variants.map((variant) => {
          const isExpert = variant.name === 'Expert';
          const {
            price: {
              isDiscounted,
              current: numericCurrent,
              formatted: { retail, current },
            },
          } = variant;
          const isSelected = selectedVariantId === variant.id;
          const isFree = numericCurrent === 0;

          return (
            <Variant key={variant.id} isSelected={isSelected}>
              <RadioContainer>
                <StyledRadioButton
                  defaultChecked={isSelected}
                  id={variant.id}
                  value={variant.id}
                  name="variant"
                  onChange={toggleVariant}
                  isOrphan={isOrphan}
                />
                {!isOrphan && <Label htmlFor={variant.id}>{variant.name} option</Label>}
                {isOrphan && <Label htmlFor={variant.id}>Buy now, start anytime</Label>}
                {isExpert && !isOrphan && (
                  <Tag primary small>
                    Recommended
                  </Tag>
                )}
              </RadioContainer>
              {!isFree && (
                <Price highlight={isDiscounted}>
                  {isDiscounted && <s>{retail}</s>} {current}
                </Price>
              )}
              {isFree && <Price>FREE</Price>}
              <AddToBasketContainer isSelected={isSelected}>
                {isExpert && (
                  <Description as="p">
                    Includes personal feedback and tuition.{' '}
                    <a onClick={handleScrollToPricing}>More</a>
                  </Description>
                )}
                {!isExpert && (
                  <Description as="p">
                    Learn in an online classroom with no more than 20 classmates.{' '}
                    <a onClick={handleScrollToPricing}>More</a>
                  </Description>
                )}
                <Button
                  fullWidth
                  invert={false}
                  product={product}
                  variant={variant}
                  isExpert={isExpert}
                />
              </AddToBasketContainer>
              {isSelected && (
                <KlarnaContainer>
                  <KlarnaPromo amount={numericCurrent} fontSize={12} />
                </KlarnaContainer>
              )}
            </Variant>
          );
        })}
      </List>
    </Container>
  );
};

VariantSelector.fragments = {
  variants: gql`
    fragment VariantSelector__variants on Product {
      id
      variants {
        id
        tag
        name
        price {
          isDiscounted
          current
          formatted {
            retail
            current
          }
        }
      }
    }
  `,
};

export default VariantSelector;
