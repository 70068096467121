import React, { useState } from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import { PrimaryButton } from 'components/Button';
import s from './style.module.css';

const Collapser = ({ children, className }) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);
  return (
    <>
      <SmoothCollapse expanded={expanded} collapsedHeight="240px">
        <div className={className}>
          <div dangerouslySetInnerHTML={{ __html: children }} />
        </div>
      </SmoothCollapse>
      {expanded && (
        <div className={s.less}>
          <PrimaryButton onClick={toggleExpanded}>Show less</PrimaryButton>
        </div>
      )}
      {!expanded && (
        <div className={s.more}>
          <PrimaryButton onClick={toggleExpanded}>Read more</PrimaryButton>
        </div>
      )}
    </>
  );
};

export default Collapser;
