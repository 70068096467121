import React, { useRef } from 'react';
import styled from 'styled-components';
import { Elements, PaymentMethodMessagingElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import env from '@lwe/toolkit/env';
import { useTheme } from 'styled-components';
import { useConfiguration } from 'hooks/configuration';
import useCurrencyCode from 'hooks/useCurrencyCode';

const Wrapper = styled.div`
  cursor: pointer;
  margin: 10px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  & .StripeElement {
    width: 100%;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 7px 0 -3px 0;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    margin: 10px 0 0 0;
  }
`;

const supportedCountries = [
  'US',
  'CA',
  'AU',
  'NZ',
  'GB',
  'IE',
  'FR',
  'ES',
  'DE',
  'AT',
  'BE',
  'DK',
  'FI',
  'IT',
  'NL',
  'NO',
  'SE',
];

const KlarnaPromo = ({ amount, fontSize = 14, light = false }) => {
  const stripeRef = useRef(loadStripe(env('STRIPE_KEY')));
  const stripe = stripeRef.current;
  const theme = useTheme();
  const configuration = useConfiguration();
  const currencyCode = useCurrencyCode();
  const countryCode = configuration?.country?.isoCode;

  if (!supportedCountries.includes(countryCode)) return null;
  if (amount === 0) return null;

  const grey = '#4a4a4a';
  const white = '#ffffff';
  const primary = theme?.schools?.current?.primary || grey;

  const appearance = {
    variables: {
      colorText: light ? white : grey,
      colorTextSecondary: light ? white : primary,
      fontSizeBase: `${fontSize}px`,
      spacingUnit: '2px',
      fontFamily: 'Gotham, Helvetica, Arial, sans-serif',
    },
  };

  const total = Math.ceil(amount * 100);

  return (
    <Wrapper>
      <Elements
        stripe={stripe}
        options={{
          appearance,
          fonts: [{ cssSrc: 'https://fonts.learningwithexperts.com/20200414/fonts.css' }],
        }}
      >
        <PaymentMethodMessagingElement
          options={{
            amount: total,
            currency: currencyCode,
            paymentMethodTypes: ['klarna'],
            countryCode,
          }}
        />
      </Elements>
    </Wrapper>
  );
};

export default KlarnaPromo;
