import React from 'react';
import styled from 'styled-components';
import AssetImage from 'components/AssetImage';
import { Headline, Body } from 'components/Typography';
import { PrimaryButton } from 'components/Button';
import { Link } from 'react-router-v3';
import { gql } from '@apollo/client';

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 196px;
  padding: 0 20px 0 185px;

  & > a {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Details = styled.div``;

const StyledBody = styled(Body)`
  margin-top: 4px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 50%;
  margin-top: 13px;
`;

const ExpertPromo = ({
  expert: {
    slug,
    profile: { name, mediumBiog, portrait },
  },
  onClick,
  ...props
}) => {
  return (
    <Container {...props}>
      {portrait && (
        <Link onClick={onClick} to={`/experts/${slug}`}>
          <AssetImage uuid={portrait.uuid} width={168} height={192} />
        </Link>
      )}
      <Details>
        <Headline as="h3">{name}</Headline>
        <StyledBody as="p">{mediumBiog}</StyledBody>
        <StyledPrimaryButton as={Link} to={`/experts/${slug}`} onClick={onClick} small>
          View courses
        </StyledPrimaryButton>
      </Details>
    </Container>
  );
};

ExpertPromo.fragments = {
  expert: gql`
    fragment ExpertPromo_expert on User {
      id
      slug
      profile {
        id
        name
        mediumBiog
        portrait {
          id
          uuid
        }
      }
    }
  `,
};

export default ExpertPromo;
