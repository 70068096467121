import React from 'react';
import cx from 'classnames';
import s from './style.module.css';

const NavItem = ({ children, active, tab, ...props }) => {
  return (
    <li className={cx(s.root, { [s.active]: active, [s.tab]: tab })} {...props}>
      {children}
    </li>
  );
};

export default NavItem;
