import { useEffect } from 'react';
import { CustomerSupportAPI } from 'utils/support';

export const useCustomerSupportHidder = (hide) => {
  useEffect(() => {
    if (hide) CustomerSupportAPI('hide');
    const intercom = document.querySelector('.intercom-launcher-frame');
    if (intercom) intercom.style.display = hide ? 'none' : 'block';
  }, [hide]);
};

export const useBodyLock = (lock) => {
  useEffect(() => {
    document.body.style.overflow = lock ? 'hidden' : 'visible';
  }, [lock]);
};
