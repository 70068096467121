import React from 'react';
import Section from 'components/Section';
import TrustPilot from 'components/TrustPilot';
import { SectionHeading } from 'components/Elements';

import s from './style.module.css';

const WhatOurCommunityAreSaying = () => (
  <Section className={s.root}>
    <SectionHeading centered component="h2">
      Our community loves us
    </SectionHeading>
    <TrustPilot />
  </Section>
);

export default WhatOurCommunityAreSaying;
