const stringToHash = (str) => {
  var hash = 0;
  if (!str || str.length === 0) return hash;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  return hash;
};

const colors = [
  { from: '#EF14C5', to: '#FFC160', angle: 134 },
  { from: '#3023AE', to: '#C86DD7', angle: 134 },
  { from: '#B4EC51', to: '#429321', angle: -45 },
  { from: '#FAD961', to: '#F76B1C', angle: -45 },
  { from: '#6CF6DA', to: '#176DA0', angle: -45 },
];

export const nameToColor = (name) => {
  const hash = stringToHash(name);
  return colors[((hash % colors.length) + colors.length) % colors.length];
};
