import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { animated } from 'react-spring';
import Courses from '../Courses';
import Experts from '../Experts';
import Home from '../Home';
import useMenuTransition from 'hooks/useMenuTransition';
import s from './style.module.css';

const COMPONENTS = {
  courses: Courses,
  experts: Experts,
};

const Flyout = ({ navigation, closeNav, showLogin, user }) => {
  const [selected, setSelected] = useState(null);
  const navigateBack = () => setSelected(null);
  const transition = useMenuTransition(selected);

  return transition.map(({ item, key, props }) => {
    const Component = COMPONENTS[item] || Home;
    return (
      <animated.div key={key} style={props} className={s.root} data-cy="nav-mobile">
        <Component
          navigation={navigation}
          navigateHome={navigateBack}
          setSelected={setSelected}
          closeNav={closeNav}
          showLogin={showLogin}
          user={user}
        />
      </animated.div>
    );
  });
};

Flyout.fragments = {
  navigation: gql`
    fragment Mobile__Flyout__navigation on Navigation {
      id
      ...Mobile__Courses__navigation
      ...Mobile__Experts__navigation
    }
    ${Courses.fragments.navigation}
    ${Experts.fragments.navigation}
  `,
};

export default Flyout;
