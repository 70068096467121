import { useTransition } from 'react-spring';

const useMenuTransition = (selected) => {
  return useTransition(selected, null, {
    initial: { transform: `translateX(${selected ? '100%' : '-1%'})` },
    from: { transform: `translateX(${selected ? '100%' : '-100%'})` },
    enter: { transform: `translateX(0)` },
    leave: { transform: `translateX(${selected ? '-' : ''}100%)` },
  });
};

export default useMenuTransition;
