import React from 'react';
import { Title } from 'components/Typography';
import cx from 'classnames';
import s from './style.module.css';

const SectionHeading = ({
  className,
  component: Component = 'h2',
  white = false,
  centered = false,
  ...props
}) => {
  return (
    <Title
      as={Component}
      className={cx(s.root, { [s.centered]: centered }, className)}
      white={white}
      {...props}
    />
  );
};

SectionHeading.propTypes = {};

export default SectionHeading;
