import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { useTransition, a } from 'react-spring';
import Flyout from './components/Flyout';
import UtilityNav from './components/UtilityNav';
import styled from 'styled-components';
import { useCurrentUser } from '@lwe/toolkit/authentication';
import { useCustomerSupportHidder, useBodyLock } from 'hooks/utils';
import { useEaster } from 'hooks/useEaster';

const Slider = ({ open, navigation, closeNav, showLogin, user }) => {
  const { showEaster } = useEaster();
  const transition = useTransition(open, null, {
    from: { position: 'absolute', width: '100%', transform: 'translateX(-100%)' },
    enter: { transform: 'translateX(0)' },
    leave: { transform: 'translateX(-100%)' },
  });

  return transition.map(({ item, key, props }) => {
    if (!item) return null;
    return (
      <a.div key={key} style={props}>
        <Flyout
          navigation={navigation}
          closeNav={closeNav}
          showLogin={showLogin}
          showEaster={showEaster}
          user={user}
        />
      </a.div>
    );
  });
};

const Container = styled.nav`
  width: 100%;
  height: 60px;
  position: relative;
  z-index: 10;
  position: fixed;

  top: ${(props) => (props.offsetHeader ? `50px` : `0px`)};

  & a {
    text-decoration: none;
  }

  & > ul {
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.header.backgroundColor};
    position: relative;
    z-index: 2;
    overflow: hidden;
    display: flex;
    align-items: center;

    & > li {
      &:first-child {
        flex: 1;
      }
      display: flex;
    }
  }

  & > div {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: white;
    top: 0;
    left: 0;
    will-change: transform;
  }
`;

const MobileNavigation = ({ navigation, ...props }) => {
  const { currentUser, loading } = useCurrentUser();
  const { showEaster } = useEaster();

  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  const closeNav = () => setOpen(false);

  useCustomerSupportHidder(open);
  useBodyLock(open);

  const { basket, showLogin, secure, offsetHeader } = props;

  return (
    <Container offsetHeader={offsetHeader}>
      <UtilityNav
        toggleOpen={toggleOpen}
        closeNav={closeNav}
        open={open}
        basket={basket}
        user={currentUser}
        secure={secure}
      />
      <Slider
        navigation={navigation}
        open={open}
        closeNav={closeNav}
        showLogin={showLogin}
        user={currentUser}
        showEaster={showEaster}
      />
    </Container>
  );
};

MobileNavigation.fragments = {
  navigation: gql`
    fragment MobileNavigation_navigation on Navigation {
      id
      ...Mobile__Flyout__navigation
    }
    ${Flyout.fragments.navigation}
  `,
};

export default MobileNavigation;
