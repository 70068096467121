import React from 'react';
import s from './style.module.css';

const YouTubePlayer = ({ id }) => {
  return (
    <div className={s.root}>
      <iframe src={`https://www.youtube.com/embed/${id}`} frameBorder={0} allowFullScreen />
    </div>
  );
};

export default YouTubePlayer;
