import React from 'react';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import Contents, { Panel } from '../Contents';
import ProductCard from 'components/ProductCard';
import ProductStrip from 'components/ProductStrip';
import ProductPromo from 'components/ProductPromo';
import SchoolList from '../../../SchoolList';
import { Link } from 'react-router-v3';
import { Subtitle, Headline } from 'components/Typography';

const giftIcon = require('./assets/icon-present.svg');

const GiftImage = styled.img`
  height: 12px;
`;

const GiftLink = styled(Link)`
  display: block;
  margin-top: 12px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.schools.floristry.primary};
`;

const Courses = ({ handleClose, navigation: { schools, bestSellingProducts, latestProducts } }) => {
  return (
    <Contents data-cy="nav-primary-courses-desktop">
      <Panel nav>
        <Subtitle as="h2">Courses</Subtitle>
        <SchoolList
          schools={schools}
          renderItem={({ slug, name }) => {
            return (
              <Headline as={Link} to={`/${slug}`} onClick={handleClose}>
                {name}
              </Headline>
            );
          }}
        />

        <GiftLink
          to="/gifts"
          title="Buy a Learning with Experts gift card"
          onClick={handleClose}
        >
          <GiftImage src={giftIcon} alt="Present" /> Gift Cards
        </GiftLink>
      </Panel>
      <Panel>
        <Subtitle as="h2">Best selling courses</Subtitle>
        <ul>
          {bestSellingProducts.map((product) => {
            return (
              <li key={product.id}>
                <ProductStrip product={product} onClick={handleClose} />
              </li>
            );
          })}
        </ul>
      </Panel>
      <Panel>
        <Subtitle as="h2">Latest course</Subtitle>
        {latestProducts.map((product) => {
          return <ProductPromo key={product.id} product={product} onClick={handleClose} />;
        })}
      </Panel>
    </Contents>
  );
};

Courses.fragments = {
  navigation: gql`
    fragment Courses_navigation on Navigation {
      id
      bestSellingProducts(limit: 5) {
        id
        ...ProductStrip_product
      }
      latestProducts(limit: 1) {
        id
        ...ProductPromo_product
      }
    }
    ${ProductStrip.fragments.product}
    ${ProductPromo.fragments.product}
  `,
};

export default Courses;
