import React from 'react';
import styled, { css } from 'styled-components';
import { Section } from 'components/Layout';

const pattern = require('./assets/background.svg');

const ValentinesSection = styled(({ className, ...props }) => (
  <Section containerClassName={className} {...props} />
))`
  background-color: #c9463d;
  background-image: url(${pattern});
  background-repeat: no-repeat;
  background-size: 250%;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    background-size: 100%;
  }

  ${({ theme }) =>
    theme.schools.current &&
    css`
      background-color: ${theme.schools.current.primary};
    `}
`;

export default ValentinesSection;
