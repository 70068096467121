import React, { useEffect } from 'react';
import scriptLoader from 'react-async-script-loader';
import cx from 'classnames';
import s from './style.module.css';

const trustPilotBusinessUnitID = '5a72fdb77dd4b200014b394a';

const TrustPilot = ({
  isScriptLoaded,
  isScriptLoadSucceed,
  templateId = '53aa8912dec7e10d38f59f36',
  dark,
  height = '140px',
  className,
}) => {
  const ready = isScriptLoaded && isScriptLoadSucceed;

  useEffect(() => {
    if (ready && window.Trustpilot)
      window.Trustpilot.Modules.WidgetManagement.findAndApplyWidgets();
  }, [ready]);

  return (
    <section className={cx(s.root, className, { [s.dark]: dark })}>
      <div
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id={templateId}
        data-businessunit-id={trustPilotBusinessUnitID}
        data-style-height={height}
        data-style-width="100%"
        data-theme={dark ? 'dark' : 'light'}
        data-stars="4,5"
      >
        <a
          href="https://uk.trustpilot.com/review/learningwithexperts.com"
          target="_blank"
          rel="noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </section>
  );
};

export default scriptLoader('//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js')(
  TrustPilot,
);
