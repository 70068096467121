import React from 'react';
import { SchoolThemeProvider } from 'components/ThemeProvider';
import { useQuery, gql } from '@apollo/client';
import Helmet from 'react-helmet';
import Page from 'components/Page';
import TrustPilotSummary from 'components/ProductDetail/components/TrustPilotSummary';
import Cpd from 'components/ProductDetail/components/Cpd';
import ResellerProductHeader from './components/ResellerProductHeader';
import CourseVideoPreview from 'components/sections/CourseVideoPreview';
import CourseOutline from 'components/sections/CourseOutline';
import ProductPackageChooser from 'components/sections/ProductPackageChooser';
import CourseDescription from 'components/sections/CourseDescription';
import ExpertProfile from 'components/ExpertProfile';
import CollectionOutline from 'components/sections/CollectionOutline';
import IncludedInCollections from 'components/sections/IncludedInCollections';
import ClassroomExplainer from 'components/sections/ClassroomExplainer';
import WhatOurCommunityAreSaying from 'components/WhatOurCommunityAreSaying';
import { Link } from 'react-router-v3';
import styled from 'styled-components';

import Section from 'components/Section';
import PageNotFound from 'components/PageNotFound';
import { useConfiguration } from 'hooks/configuration';

const Reseller_PRODUCT_DETAIL_QUERY = gql`
  query ResellerProductDetail($schoolSlug: String!, $productSlug: String!) {
    school(slug: $schoolSlug) {
      id
      slug
      name
      product(slug: $productSlug) {
        id
        slug
        metaTitle
        metaDescription
        visible
        shortDescription
        trailerSynopsis
        publishedOn
        availableToBuy
        cpdHours
        school {
          id
          slug
          name
        }
        tutor {
          id
          ...ExpertProfile__expert
        }
        ...ResellerProductHeader__product
        ...productOutline
        ...productSelector
        ...courseDescription
        ...collectionOutline
        ...includedInCollections
        ...productVideoPreview
      }
      ...ClassroomExplainer__school
    }
  }
  ${ResellerProductHeader.fragments.product}
  ${CourseOutline.fragments.product}
  ${ProductPackageChooser.fragments.product}
  ${CourseDescription.fragments.product}
  ${ExpertProfile.fragments.expert}
  ${CollectionOutline.fragments.product}
  ${IncludedInCollections.fragments.product}
  ${CourseVideoPreview.fragments.product}
  ${ClassroomExplainer.fragments.school}
`;

const Breadcrumb = styled.div`
  display: flex;
  margin: 20px 0 0 0;

  & > a {
    width: auto;
  }
`;

const ResellerProductDetail = ({ params: { schoolSlug, productSlug } }) => {
  const variables = { schoolSlug, productSlug };
  const { data } = useQuery(Reseller_PRODUCT_DETAIL_QUERY, {
    variables,
    ssr: true,
  });

  if (!data) return <Page />;
  if (!data.school || !data.school.product) return <PageNotFound />;

  const {
    school,
    school: {
      product,
      product: { tutor },
    },
  } = data;
  if (!product) return <Page />;

  const { metaTitle = product.title, isBundle, collections } = product;

  return (
    <SchoolThemeProvider school={school}>
      <Page>
        <Helmet
          title={`Resellers - ${metaTitle || product.title}`}
          meta={[
            {
              name: 'robots',
              content: 'noindex',
            },
          ]}
        />
        <ResellerProductHeader product={product} />
        <Section>
          <Breadcrumb>
            <Link to="/resellers">Resellers</Link>&nbsp;&gt; {product.title}
          </Breadcrumb>
        </Section>
        <CourseDescription product={product} />
        <Cpd product={product} />
        {!isBundle && <CourseOutline product={product} />}
        {isBundle && <CollectionOutline product={product} />}
        <TrustPilotSummary />
        <ClassroomExplainer school={school} />
        <ExpertProfile tutor={tutor} />
        <WhatOurCommunityAreSaying />
      </Page>
    </SchoolThemeProvider>
  );
};

export default ResellerProductDetail;
