import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Image from 'components/Image';

import s from './style.module.css';

const ResponsiveImage = ({ urls, width, height, className, ...props }) => {
  const url = urls.url;
  const retinaUrl = urls.retinaUrl ?? url;
  const halfUrl = urls.halfUrl ?? url;
  const quarterUrl = urls.quarterUrl ?? halfUrl;

  return (
    <Image
      src={url}
      srcSet={`${quarterUrl} ${Math.floor(width / 4)}w, ${halfUrl} ${Math.floor(
        width / 2,
      )}w, ${url} ${width}w, ${retinaUrl} ${width * 2}w`}
      width={width}
      height={height}
      className={cx(s.root, className)}
      fade
      {...props}
    />
  );
};

ResponsiveImage.propTypes = {
  urls: PropTypes.shape({
    url: PropTypes.string.isRequired,
    retinaUrl: PropTypes.string,
    halfUrl: PropTypes.string,
    quarterUrl: PropTypes.string,
  }),
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default ResponsiveImage;
