import React, { useCallback } from 'react';
import { Link, withRouter } from 'react-router-v3';
import { useTransition, a } from 'react-spring';

import { MenuDrawerContainer } from './styles';
import { hasDashboard, isAdmin } from 'utils/roles';
import Avatar from 'components/Avatar';
import { useLegacyLogoutAndRedirect } from 'hooks/user';
import NotificationBadge from 'components/NotificationBadge';
import { toLocalId } from '@lwe/toolkit/utils';
import { useConfiguration } from 'hooks/configuration';

const MenuDrawer = ({ open, user, handleClose, handleDetach, router }) => {
  const {
    features,
    settings: { homepageRedirect },
  } = useConfiguration();
  const slideTransition = useTransition(open, null, {
    from: { position: 'absolute', transform: 'translateX(0%)' },
    enter: { transform: 'translateX(-100%)' },
    leave: { transform: 'translateX(0%)' },
    onRest: (openSlide) => {
      if (!openSlide) handleDetach();
    },
  });

  const fadeTransition = useTransition(open, null, {
    from: {
      opacity: 0,
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 100,
    },
  });

  const logout = useLegacyLogoutAndRedirect(router);

  const handleSignOut = useCallback(() => {
    logout();
    handleClose();
  }, [handleClose, logout]);

  const hasDashboardAccess = hasDashboard(user);
  const userIsAdmin = isAdmin(user);
  const notifications = user?.notifications;

  return (
    <MenuDrawerContainer>
      {fadeTransition.map(({ item, key, props }) => {
        if (!item) return null;
        return <a.div key={key} style={props} className="bg"></a.div>;
      })}
      <div className="slide-container">
        {slideTransition.map(({ item, key, props }) => {
          if (!item) return null;
          return (
            <a.div key={key} style={props}>
              <nav>
                <div className="header">
                  <div className="dt-close-mobile-menu-icon" onMouseDown={handleClose}>
                    <div className="close-line-wrap">
                      <span className="close-line"></span>
                      <span className="close-line"></span>
                      <span className="close-line"></span>
                    </div>
                  </div>
                </div>
                {user && (
                  <ul>
                    <li>
                      <Avatar user={user} />
                      <Link to={`/profile/${toLocalId(user?.id)}`} onClick={handleClose}>
                        <span>Account</span>
                      </Link>
                    </li>
                    {userIsAdmin && (
                      <li>
                        <Link to="/classrooms/admin" onClick={handleClose}>
                          <span>Administration</span>
                        </Link>
                      </li>
                    )}

                    {hasDashboardAccess && (
                      <li>
                        <Link to="/classrooms/dashboard" onClick={handleClose}>
                          <span>Dashboard</span>
                        </Link>
                      </li>
                    )}

                    <li>
                      <Link to={`/classrooms`} onClick={handleClose}>
                        <span>My courses</span>
                      </Link>
                    </li>

                    <li>
                      <Link to={`/signin`} onClick={handleSignOut}>
                        <span>Sign out</span>
                      </Link>
                    </li>
                  </ul>
                )}
              </nav>
            </a.div>
          );
        })}
      </div>
    </MenuDrawerContainer>
  );
};

export default withRouter(MenuDrawer);
