import React from 'react';
import AssetImage from 'components/AssetImage';
import s from './style.module.css';

const AssetImageBlock = ({ node }) => {
  return (
    <figure className={s.root}>
      <AssetImage uuid={node.uuid} alt={node.alt} title={node.title} width={765} />
      {node.caption && <figcaption>{node.caption}</figcaption>}
    </figure>
  );
};

export default AssetImageBlock;
