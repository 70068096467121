import React from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import { Headline, Body } from 'components/Typography';
import { Link } from 'react-router-v3';
import { PrimaryButton } from 'components/Button';
import ProductCard from 'components/ProductCard';

const Container = styled.div`
  display: flex;
`;

const Details = styled.div`
  padding-left: 20px;
  max-width: 250px;
`;

const StyledBody = styled(Body)`
  margin-top: 4px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  width: 50%;
  margin-top: 13px;
`;

const ProductPromo = ({
  product,
  product: { slug, title, shortDescription, school },
  onClick,
  ...props
}) => {
  return (
    <Container {...props}>
      <ProductCard product={product} onClick={onClick} />
      <Details>
        <Headline as="h3">{title}</Headline>
        <StyledBody as="p">{shortDescription}</StyledBody>
        <StyledPrimaryButton
          onClick={onClick}
          as={Link}
          to={`/${school.slug}/courses/${slug}`}
          small
        >
          View course
        </StyledPrimaryButton>
      </Details>
    </Container>
  );
};

ProductPromo.fragments = {
  product: gql`
    fragment ProductPromo_product on Product {
      id
      ...ProductCard__product
      title
      slug
      shortDescription
      school {
        id
        slug
      }
    }
    ${ProductCard.fragments.product}
  `,
};

export default ProductPromo;
