import React from 'react';
import { Link } from 'react-router-v3';
import styled, { css } from 'styled-components';
import { PrimaryButton as BaseButton } from 'components/Button';
import { Section as BaseSection } from 'components/Layout';
import Typography, { font } from 'components/Typography';
import { useIsMobile } from '@lwe/toolkit/theming';
import { gql } from '@apollo/client';

const easterAssets = {
  image: require('./assets/paul.jpg'),
  icon: require('./assets/icon-egg.svg'),
  background: require('./assets/outlines.svg'),
  url: '/foodanddrink/courses/how-to-make-the-perfect-easter-egg-at-home',
};

const Section = styled(({ className, ...props }) => (
  <BaseSection containerClassName={className} {...props} />
))`
  background-color: ${({ theme }) => theme.schools.foodanddrink.primary};
  overflow: hidden;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 30px 30px 30px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    justify-contents: flex-start;
    align-items: flex-start;
    width: 880px;
    min-height: 375px;
    padding: 43px 0 43px 0;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    padding: 60px 0 60px 0;
    width: 1140px;
    min-height: 525px;
  }
`;

const Icon = styled.img`
  display: none;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 15px 0;
    display: block;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    margin: 0 0 20px 0;
  }
`;

const Terms = styled(Typography).attrs({
  mobile: font.book.group(2),
  desktop: font.medium.group(5),
  white: true,
  as: 'p',
})`
  order: 4;
  margin-top: 10px;
`;

const Title = styled(Typography).attrs({
  mobile: font.bold.group(6),
  desktop: font.medium.group(9),
  hd: font.medium.group(10),
  as: 'h1',
  white: true,
})`
  max-width: 285px;
  margin: 0 0 15px 0;
  text-align: center;
  order: 2;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 15px 0;
    text-align: left;
    max-width: 360px;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    margin: 0 0 25px 0;
    max-width: 460px;
  }

  & > span {
    white-space: nowrap;
  }
`;

const ImageContainer = styled.div`
  margin: 0 0 15px 0;
  padding: 60px 42px 51px 42px;
  width: 266px;
  background-image: url(${easterAssets.background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  order: 1;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 0;
    padding: 50px 80px 50px 65px;
    position: absolute;
    top: 0;
    right: 0;
    width: 462px;
    height: 100%;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    width: 650px;
    padding-left: 90px;
    padding-right: 115px;
  }

  & img {
    max-width: 100%;
    clip-path: url(#eggPath);
  }
`;

const PrimaryButton = styled(BaseButton)`
  order: 3;
  padding: 8px 8px;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 8px 0;
    padding: 14px;
  }
`;

const Easter = () => {
  const isMobile = useIsMobile();
  return (
    <Section wide>
      <Container>
        <Icon src={easterAssets.icon} />
        <Title>
          Make your own Easter Egg with master chocolatier <span>Paul Young</span>
        </Title>
        <ImageContainer>
          <svg width="0" height="0" xmlns="http://www.w3.org/2000/svg">
            <clipPath
              id="eggPath"
              clipPathUnits="objectBoundingBox"
              transform="scale(0.003184713 0.00243309)"
            >
              <path
                d="M313.814436,235.159127 C307.871226,122.260313 238.517988,0 156.946836,0 C75.3756839,0 3.77302377,125.9704 0.0792357432,240.551695 C-1.81501453,299.20127 29.6768962,411 156.946836,411 C285.187579,411 316.774203,291.32812 313.814436,235.159127 Z"
                id="path-1"
              ></path>
            </clipPath>
          </svg>
          <img src={easterAssets.image} alt="Paul Young - master chocolatier" />
        </ImageContainer>
        <PrimaryButton small={isMobile} large={!isMobile} inverse as={Link} to={easterAssets.url}>
          GET 15% OFF
        </PrimaryButton>
        <Terms>Use code EASTER24 at checkout</Terms>
      </Container>
    </Section>
  );
};

Easter.fragments = {
  school: gql`
    fragment Easter__school on School {
      id
      slug
    }
  `,
  promotions: gql`
    fragment Easter__promotions on Promotion {
      easter: group(name: easter) {
        endsAt
      }
    }
  `,
};

export default Easter;
