import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'components/Elements';
import Icon from 'components/NextIcon';
import BasketButton from '../../../BasketButton';
import SearchButton from '../../../SearchButton';
import { PrimaryButton as BaseButton } from 'components/Button';
import AuthLinks from './components/AuthLinks';
import UserMenu from './components/UserMenu';
import Avatar from 'components/Avatar';
import { Badge } from 'components/Elements';
import NavItem from '../NavItem';
import { useRevertImpersonator } from '@lwe/toolkit/authentication';
import { useConfiguration } from 'hooks/configuration';
import s from './style.module.css';

const PrimaryButton = styled(BaseButton)`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const UtilityNav = ({ user, impersonator, showLogin, showRegister, secure, tab, toggleTab }) => {
  const [openNav, setOpenNav] = useState(false);
  const toggleNav = () => setOpenNav(!openNav);
  const { features } = useConfiguration();
  const showSearch = () => toggleTab('search');
  const revertImpersonator = useRevertImpersonator();

  if (secure) {
    return (
      <ul className={s.root}>
        <li>
          <Icon name="small-lock" className={s.lock} />
        </li>
      </ul>
    );
  }

  return (
    <ul className={s.root} data-cy="nav-utility-desktop">
      {user && (
        <li>
          <PrimaryButton as={Link} to="/classrooms" small>
            My Courses
          </PrimaryButton>
        </li>
      )}
      <li className={s.user}>
        {!user && <AuthLinks showLogin={showLogin} showRegister={showRegister} />}
        {impersonator && (
          <Avatar
            onDoubleClick={revertImpersonator}
            className={s.impersonator}
            user={impersonator}
            size={28}
          />
        )}
        {user && <Avatar user={user} size={28} onClick={toggleNav} data-cy="toggle-user-menu" />}
        {openNav && <UserMenu closeNav={toggleNav} user={user} />}
      </li>
      {features.search && (
        <NavItem active={tab === 'search'}>
          <SearchButton onClick={showSearch} />
        </NavItem>
      )}
      {features.shop && (
        <li>
          <BasketButton />
        </li>
      )}
    </ul>
  );
};

export default UtilityNav;
