import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-v3';
import Typography, { font } from 'components/Typography';
import { PrimaryButton } from 'components/Button';
import LazyImage from 'components/LazyImage';
import env from '@lwe/toolkit/env';

const newWindowIcon = require('./assets/New_window_icon.svg');
const logoImage = require('./assets/LWE_Logo_B2B.svg');
const promoImageDesktop = require('./assets/Desktop-B2BPromo-Image@2x.png');
const promoImageMobile = require('./assets/Mobile-B2BPromo-Image@2x.png');

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.global.grey1};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
  }
`;

const Content = styled.div`
  cursor: pointer;
  position: relative;
  padding: 0 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 77px;
  background-size: 310px;
  background-image: url(${promoImageMobile});

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
    width: 934px;
    background-image: url(${promoImageDesktop});
    background-position-x: right;
    background-position-y: 0px;
    background-size: auto 100%;
  }
`;

const CallToAction = styled.div`
  display: flex;
  z-index: 0;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px 15px 20px 15px;

  .logo {
    margin-bottom: 280px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 380px;
    padding: 40px 0 40px 0;
    align-items: flex-start;
    text-align: left;

    .logo {
      margin-bottom: 25px;
    }
  }
`;

const ClickArea = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
`;

const BusinessButton = styled(PrimaryButton)`
  background: #ecc35d;
  border: none;
  font-size: 12px;
  padding: 10px 15px;
  border-radius: 3px;

  &:hover {
    background: #d1ac4f;
  }

  img {
    margin-left: 7px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 15px;
    padding: 15px 20px;
    border-radius: 4px;
  }
`;

const Title = styled(Typography).attrs({
  mobile: font.medium.group(6),
  desktop: font.medium.group(9),
  as: 'h2',
  white: true,
})`
  margin: 0 0 10px 0;
  font-weight: 300;
  margin-bottom: 16px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 15px 0;
    font-weight: 500;
  }
`;

const Subtitle = styled(Typography).attrs({
  mobile: font.medium.group(2),
  as: 'h3',
  white: true,
})`
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 20px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 25px 0;
  }
`;

const B2BPromo = () => {
  const isNonProd = env('NETWORK') === 'nonprod';
  const link = isNonProd
    ? 'https://staging.business.learningwithexperts.com/'
    : 'https://business.learningwithexperts.com/';

  const handleClick = useCallback(() => {
    window.open(link);
  }, [link]);

  return (
    <Section>
      <Content onClick={handleClick}>
        <CallToAction>
          <LazyImage src={logoImage} width="200px" className="logo" />
          <Title>Are you a business or organisation?</Title>
          <Subtitle>
            Find out how Learning with Experts for Business can help you grow by bringing people
            together to learn new skills and collaborate.
          </Subtitle>
          <BusinessButton inverse as="a" href={link} target="_blank">
            Learn more
            <img src={newWindowIcon} />
          </BusinessButton>
        </CallToAction>
      </Content>
    </Section>
  );
};

export default B2BPromo;
