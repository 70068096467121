import React from 'react';
import styled from 'styled-components';

import { Section } from 'components/Layout';
import ProductCardGrid from 'components/ProductCardGrid';
import { SectionHeading } from 'components/Elements';
import Button from 'components/Button';

const Tools = styled.div`
  width: 100%;

  padding: 10px 18px;

  & > span {
    color: ${({ theme }) => theme?.header?.color};
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0px 12px 0px;
    max-width: 926px;
  }

  @media (min-width: 1248px) {
    max-width: 1250px;
  }
`;

const ProductGrid = ({
  white,
  primary,
  centeredHeading,
  gray,
  color,
  darkText,
  heading,
  tag,
  maxPerTag = 3,
  list,
  large,
  small,
  withinBackground,
  onToggleMore,
  showingMore,
  footer,
  showCardCategoryLink,
  products: all,
  showHighestPrice = false,
  sort,
  total,
  ...props
}) => {
  const products = tag
    ? all.filter((product) => product.tags && product.tags.includes(tag)).slice(0, maxPerTag)
    : all;
  if (!products || products.length === 0) return null;
  return (
    <Section primary={primary} color={color} wide {...props}>
      {heading && (
        <SectionHeading centered={centeredHeading} white={white || primary || color === 'gray'}>
          {heading}
        </SectionHeading>
      )}
      {sort && (
        <Tools>
          {/* <div></div>
          <span>{`${total} Courses`}</span> */}
        </Tools>
      )}
      <ProductCardGrid
        products={products}
        footer={footer}
        withinBackground={withinBackground || primary || gray || color === 'gray'}
        list={heading}
        large={large}
        darkText={darkText}
        showCardCategoryLink={showCardCategoryLink}
        showHighestPrice={showHighestPrice}
      />
      {onToggleMore && (
        <Button onClick={onToggleMore}>{showingMore ? 'Show less' : 'Show more'}</Button>
      )}
    </Section>
  );
};

export default ProductGrid;
