import React from 'react';
import { CustomerSupportAPI } from 'utils/support';
import styled from 'styled-components';
import { Link } from 'react-router-v3';

const errorMappings = {
  invalid_login_attempt: 'The email or password you entered is incorrect.',
  invalid_facebook_login_attempt: 'There was a problem logging in with Facebook.',
  email_taken: 'The email you entered is already in use.',
  email_invalid: 'The email you entered is invalid.',
  password_too_short: 'The password you entered must be longer than 4 characters.',
  required_keys_not_supplied: 'Please fill out all missing fields.',
  user_not_found: 'An account matching that email couldn’t be found.',
  invalid_reset_params: 'Something went wrong.',
  passwords_not_matching: 'The passwords entered don’t match.',
  reset_password_token_invalid: 'This reset link has expired.',
  report_failed: 'Something went wrong generating the report. Please try again.',
  slug_taken: 'The supplied URL has already been taken.',
  title_blank: 'Please supply a title.',
  slug_blank: 'Please supply a URL.',
  basket_could_not_be_created: 'Your booking has not gone through. Please contact us for help.',
  promo_code_not_found: (
    <>
      It looks like that code isn't working. Use code <strong>STARTNOW</strong> for 5% off.
    </>
  ),
  promo_code_invalid: (
    <>
      The promo code is no longer valid and has been removed.{' '}
      <Link to="/basket">Back to basket</Link>
    </>
  ),
  gift_voucher_not_found: 'That gift card code could not be found.',
  code_is_not_provided: 'You must enter a valid gift card code',
  gift_voucher_not_redeemable: 'That gift card code has already been used',
  basket_used: 'There was a problem, contact customer support',
  basket_not_found: 'There was a problem, contact customer support',
  basket_or_token_not_found: 'Your order can not be found',
  payment_error: (
    <span>
      There was a problem with your payment, please{' '}
      <a href="#" style={{ cursor: 'pointer', textDecoration: 'underline' }}>
        contact us
      </a>
    </span>
  ),
  partner_code_unsupported: (
    <>
      This is a voucher code. Please <Link to="/redeem">redeem it here</Link>.
    </>
  ),
  gift_card_course_unsupported: (
    <>
      This is a gift card code. Please <Link to="/gifts">redeem it here</Link>.
    </>
  ),
  gift_card_amount_unsupported: (
    <>This is a gift card code. Please redeem it on the payment page.</>
  ),
};

const paymentHelpMessage = 'There was a problem with my payment, can you help?';

const List = styled.ul`
  margin: 10px 0 10px 0;
  padding: 17px 22px 15px 22px;
  display: block;
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
  border-radius: ${({ theme }) => theme.forms.inputRounded};
  border-color: ${({ theme }) => theme.colors.alerts.error.color};
  color: ${({ theme }) => theme.colors.alerts.error.color};
  background-color: ${({ theme }) => theme.colors.alerts.error.background};
`;

const ErrorList = ({ errors, className }) => {
  if (!errors) return null;

  if (typeof errors === 'string') {
    return (
      <List data-cy="form-error">
        <li>{errors}</li>
      </List>
    );
  } else if (!Array.isArray(errors)) {
    errors = [errors];
  }

  return (
    <List className={className} data-cy="form-error">
      {errors.map((error) => (
        <li
          key={error.code || error.field}
          onClick={() =>
            error.code === 'payment_error' &&
            CustomerSupportAPI('showNewMessage', paymentHelpMessage)
          }
        >
          {errorMappings[error.code] || error.message || 'Something went wrong.'}
        </li>
      ))}
    </List>
  );
};

export default ErrorList;
