import React from 'react';
import cx from 'classnames';
import s from './style.module.css';

const ExplainerSteps = ({ children, className }) => {
  return <ul className={cx(s.root, className)}>{children}</ul>;
};

export default ExplainerSteps;
export { default as Step } from './components/Step';
