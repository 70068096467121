import React from 'react';
import { gql } from '@apollo/client';
import { Section } from 'components/Layout';
import styled, { css } from 'styled-components';
import { font, Subtitle } from 'components/Typography';
import SingleProduct from './components/SingleProduct';
import ProductCardGrid from 'components/ProductCardGrid';
import { useEaster } from 'hooks/useEaster';

const StyledSection = styled(({ className, ...props }) => (
  <Section containerClassName={className} {...props} />
)).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['primary', 'wide', 'noPadding', 'color', 'school', 'containerClassName'].includes(prop) &&
    defaultValidatorFn(prop),
})`
  background-color: ${({ theme }) => theme.colors.global.grey4};
  padding: 30px 0 30px 0;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 50px 45px 50px 45px;
  }
`;

const StyledTitle = styled(Subtitle).attrs({
  mobile: font.bold.group(4),
  desktop: font.medium.group(7),
  as: 'h2',
})`
  align-self: flex-start;
  padding: 0 13px 10px 13px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 0 0 20px 0;
  }
`;

const FeaturedProducts = ({ products, heading, darkText, footer, ...props }) => {
  if (products.length === 0) return null;

  const isSingle = products.length === 1;
  const [{ school }] = products;

  return (
    <StyledSection school={school} {...props}>
      {heading && <StyledTitle white={!darkText}>{heading}</StyledTitle>}

      {isSingle ? (
        <SingleProduct product={products[0]} darkText={darkText} />
      ) : (
        <ProductCardGrid
          products={products}
          footer={footer}
          list="homepage-featured-products"
          withinBackground
          darkText={darkText}
          large
        />
      )}
    </StyledSection>
  );
};

FeaturedProducts.fragments = {
  products: gql`
    fragment FeaturedProducts__products on Product {
      id
      ...SingleProduct__product
      ...ProductCardGrid__products
    }
    ${SingleProduct.fragments.product}
    ${ProductCardGrid.fragments.products}
  `,
};

export default FeaturedProducts;
