import styled, { css } from 'styled-components';
import Typography, { font } from 'components/Typography';

const Tag = styled(Typography).attrs(({ small }) => {
  return {
    mobile: font.book.custom(small ? 10 : 12, 16),
  };
})`
  padding: 4px 12px 3px;
  background-color: ${({ theme }) => theme.colors.global.white};
  border-radius: 12px;

  ${({ small }) =>
    small &&
    css`
      padding: 2px 8px 2px;
      border-radius: 10px;
    `}

  ${({ gradient, theme }) =>
    gradient &&
    css`
      background: linear-gradient(90deg, rgba(216, 146, 6, 1) 0%, rgba(209, 90, 128, 1) 100%);
      color: ${theme.colors.global.white};
    `}

  ${({ primary, theme }) =>
    primary &&
    theme.schools.current &&
    css`
      background-color: ${theme.schools.current.primary};
      color: ${theme.colors.global.white};
    `}
`;

export default Tag;
