import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import styled, { css } from 'styled-components';
import cx from 'classnames';
import { useEventBus } from '@lwe/toolkit/utils';
import { PRODUCT_CLICKED } from '@root/events';

import { useMediaQuery } from 'react-responsive';
import useCurrencyCode from 'hooks/useCurrencyCode';
import Avatar from 'components/Avatar';
import AssetImage from 'components/AssetImage';
import Tag from 'components/atoms/Tag';
import { Link, withRouter } from 'react-router-v3';
import { typeset, font } from 'components/Typography';
import { NOOP } from 'utils';
import s from './style.module.css';

const Heading = typeset({
  mobile: font.medium.group(2),
  smooth: true,
});

const Subheading = typeset({
  mobile: font.medium.group(2),
  smooth: true,
});

const Header = styled.header`
  ${({ showPrices }) =>
    !showPrices &&
    css`
      justify-content: center !important;
    `}
`;

const ProductCard = ({
  router,
  product,
  large,
  forceLarge,
  className,
  withinBackground,
  coloured,
  darkText,
  list,
  thumbnail,
  position,
  showCardCategoryLink,
  onClick,
  showPrices = true,
  showHighestPrice = false,
  linkPrefix = null,
}) => {
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  const currencyCode = useCurrencyCode();
  const isLarge = forceLarge ? true : isLargeScreen && large;
  const eventBus = useEventBus();

  if (!product) return null;

  const {
    school,
    tutor,
    title,
    nestedProducts,
    files: { header, grid },
    isBundle,
    isNew,
    availableToBuy,
  } = product;

  showPrices = showPrices && availableToBuy;

  const image = grid || header;
  const avatarSize = isLarge ? 76 : 36;
  const linkSuffix = `/${school.slug}/courses/${product.slug}`;
  const linkPath = linkPrefix ? `${linkPrefix}${linkSuffix}` : linkSuffix;
  const Container = isLarge ? 'article' : 'div';
  const {
    largestPercentageSaving,
    from: { current: numericCurrentFrom, formatted: formattedFrom, isDiscounted: isDiscountedFrom },
    to: { current: numericCurrentTo, formatted: formattedTo, isDiscounted: isDiscountedTo },
  } = product.pricing;

  const handleClick = () => {
    router.push(linkPath);
    eventBus.publish(PRODUCT_CLICKED, { product, currencyCode, list, position });
    onClick();
  };

  const backupAltText = isBundle
    ? `${title} - ${school.name}`
    : `${tutor?.profile?.name}: ${title} - ${school.name}`;

  const altText = image?.title || backupAltText;
  const isFree = numericCurrentFrom === 0 || numericCurrentTo === 0;
  const isDiscounted = isDiscountedFrom || isDiscountedTo;

  return (
    <>
      <Container
        onClick={handleClick}
        className={cx(s.root, className, school.slug, {
          [s.hasAvatar]: tutor || isBundle,
          [s.collection]: isBundle,
          [s.large]: isLarge,
          [s.withinBackground]: withinBackground,
          [s.darkText]: darkText,
          [s.coloured]: coloured,
          [s.thumbnail]: thumbnail,
          [s.highlight]: isDiscounted,
          [s.showCategoryLink]: showCardCategoryLink,
        })}
      >
        <Header showPrices={showPrices}>
          {tutor && <Avatar size={avatarSize} className={s.avatar} user={tutor} />}

          <Heading as="span" className={s.price} desktop={font.medium.group(isLarge ? 5 : 2)}>
            {!isFree && showPrices && (
              <>
                {!showHighestPrice && (
                  <span className={cx(s.fromPrice, { [s.collection]: isBundle })}>
                    {isDiscountedFrom ? <s>{formattedFrom.retail}</s> : 'From'}{' '}
                    <span>{formattedFrom.current}</span>
                  </span>
                )}
                <span
                  className={cx({
                    [s.toPrice]: !showHighestPrice,
                    [s.collection]: isBundle,
                  })}
                >
                  {isDiscountedTo ? <s>{formattedTo.retail}</s> : 'Up to '}{' '}
                  <span>{formattedTo.current}</span>
                </span>
              </>
            )}
            {isFree && 'FREE'}
          </Heading>

          <Heading as="h3" desktop={font.medium.group(isLarge ? 5 : 2)}>
            <Link to={linkPath}>{title}</Link>
          </Heading>

          {tutor && <Subheading as="p">{tutor?.profile?.name}</Subheading>}

          {isBundle && (
            <Subheading as="p">
              {isLarge && <span>Collection of </span>}
              {nestedProducts.length} courses
            </Subheading>
          )}
        </Header>
        {image && (
          <AssetImage
            width={isLarge ? 294 : 168}
            height={isLarge ? 187 : 101}
            uuid={image.uuid}
            alt={altText}
          />
        )}
        {largestPercentageSaving && showPrices && (
          <Tag small={!isLarge} gradient className={s.tag}>
            Save {largestPercentageSaving}
          </Tag>
        )}
        {!largestPercentageSaving && !isFree && isNew && (
          <Tag small={!isLarge} className={s.tag}>
            New
          </Tag>
        )}
        {!largestPercentageSaving && isFree && (
          <Tag small={!isLarge} className={s.tag}>
            Free
          </Tag>
        )}
      </Container>
      {showCardCategoryLink && (
        <Link className={s.categorylink} to={`/${school.slug}`}>
          View all {school.name}
        </Link>
      )}
    </>
  );
};

const ProductCardWithRouter = withRouter(ProductCard);

ProductCardWithRouter.propTypes = {
  product: PropTypes.object.isRequired,
  /** Display card in large format. */
  large: PropTypes.bool,
  /** Card will be displayed on a background colour. */
  withinBackground: PropTypes.bool,
};

ProductCardWithRouter.defaultProps = {
  large: false,
  withinBackground: false,
  onClick: NOOP,
};

ProductCardWithRouter.fragments = {
  product: gql`
    fragment ProductCard__product on Product {
      id
      title
      slug
      isBundle
      isNew
      availableToBuy
      school {
        id
        slug
        name
      }
      nestedProducts {
        id
      }
      pricing {
        from {
          isDiscounted
          current
          formatted {
            current
            retail
          }
        }
        to {
          isDiscounted
          current
          formatted {
            current
            retail
          }
        }
        largestPercentageSaving
      }
      files {
        header {
          id
          uuid
          title
        }
        grid {
          id
          uuid
          title
        }
      }
      tutor {
        id
        profile {
          id
          name
        }
        ...Avatar__user
      }
    }
    ${Avatar.fragments.user}
  `,
};

export default ProductCardWithRouter;
