import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import moment from 'moment';

const BLACK_FRIDAY_PROMOTIONS_QUERY = gql`
  query BlackFridayPromotionsQuery {
    promotions {
      blackFridayComingSoon: group(name: blackFridayComingSoon) {
        endsAt
      }
      blackFridaySale: group(name: blackFridaySale) {
        endsAt
      }
      blackFridayFinalWeekend: group(name: blackFridayFinalWeekend) {
        endsAt
      }
      blackFridayEndsToday: group(name: blackFridayEndsToday) {
        endsAt
      }
      blackFridayEndsTomorrow: group(name: blackFridayEndsTomorrow) {
        endsAt
      }
      blackFridayCountdown: group(name: blackFridayCountdown) {
        endsAt
      }
    }
  }
`;

export const useBlackFriday = () => {
  const { data } = useQuery(BLACK_FRIDAY_PROMOTIONS_QUERY, { ssr: true });

  const {
    promotions: {
      blackFridayComingSoon,
      blackFridaySale,
      blackFridayFinalWeekend,
      blackFridayEndsTomorrow,
      blackFridayEndsToday,
      blackFridayCountdown,
    },
  } = data ?? { promotions: {} };

  return useMemo(() => {
    const showBlackFriday = blackFridaySale || blackFridayCountdown;
    const showBlackFridaySiteWideHeader = showBlackFriday || blackFridayComingSoon;

    // TODO: Is there a smarter way of figure out that the promotion has ended that persists?
    const blackFridayHasEnded = moment('2023-12-01').isSameOrBefore(moment());

    return {
      blackFridayComingSoon,
      blackFridaySale,
      blackFridayFinalWeekend,
      blackFridayCountdown,
      showBlackFriday,
      showBlackFridaySiteWideHeader,
      blackFridayEndsTomorrow,
      blackFridayEndsToday,
      blackFridayHasEnded,
    };
  }, [
    blackFridayComingSoon,
    blackFridaySale,
    blackFridayFinalWeekend,
    blackFridayEndsTomorrow,
    blackFridayEndsToday,
    blackFridayCountdown,
  ]);
};
