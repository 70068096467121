import findSchoolName from '../utils/findSchoolName';
import env from '@lwe/toolkit/env';

const generateTag = (name, value) => ({ property: name, content: value });

const findMetaTags = (
  { type, files, school, slug, title, metaDescription, visible },
  { frontendUrl },
) => {
  const metaTags = [];

  const isProduct = ['course', 'bundle'].includes(type);

  const imageUUID = files && (files.grid ? files.grid.uuid : files.header && files.header.uuid);
  const imageURL = imageUUID && `${env('ASSETS_ENDPOINT')}/${imageUUID}/500`;

  const slugPrefix = isProduct ? 'courses' : 'blog';

  const url = `${frontendUrl}/${school.slug}/${slugPrefix}/${slug}`;

  // The visibility check is explicitly checking for false, to avoid false positives.
  if (visible === false) metaTags.push(generateTag('robots', 'noindex'));

  metaTags.push(generateTag('twitter:card', 'summary_large_image'));
  metaTags.push(generateTag('twitter:site', '@lwe_hub'));
  metaTags.push(generateTag('twitter:title', `${title}`));

  const contentType = isProduct ? 'product' : 'article';
  metaTags.push(generateTag('og:title', `${title}`));
  metaTags.push(generateTag('og:type', `${contentType}`));
  metaTags.push(generateTag('og:url', `${url}`));
  metaTags.push(generateTag('og:site_name', `${findSchoolName(school)}`));

  if (imageURL) {
    metaTags.push(generateTag('og:image', `${imageURL}`));
    metaTags.push(generateTag('twitter:image', `${imageURL}`));
  }

  if (metaDescription) {
    metaTags.push(generateTag('twitter:description', `${metaDescription}`));
    metaTags.push(generateTag('og:description', `${metaDescription}`));
    metaTags.push({ name: 'description', content: metaDescription });
  }

  return metaTags;
};

export default findMetaTags;
