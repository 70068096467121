import React from 'react';
import { Link } from 'react-router-v3';
import { gql } from '@apollo/client';
import { Section, Content } from 'components/Layout';
import { Title } from 'components/Typography';
import { PrimaryButton } from 'components/Button';
import BlogPostPacket from 'components/BlogPostPacket';
import cx from 'classnames';
import s from './style.module.css';

const BlogPostListing = ({ school, school: { name, blog, slug } }) => {
  return (
    <Section className={cx(s.root, slug)}>
      <Content>
        <Title white as="h2">
          Latest {name} posts
        </Title>
        <ul className={s.posts}>
          {blog.posts.map((post, index) => (
            <li key={`post-${index}`}>
              <BlogPostPacket school={school} post={post} />
            </li>
          ))}
        </ul>
      </Content>
      <PrimaryButton inverse as={Link} to={`/${slug}/blog`}>
        View all posts
      </PrimaryButton>
    </Section>
  );
};

BlogPostListing.fragments = {
  posts: gql`
    fragment BlogPostListing__posts on School {
      id
      blog {
        id
        posts(limit: 3) {
          id
          ...post
        }
      }
    }
    ${BlogPostPacket.fragments.post}
  `,
};

export default BlogPostListing;
