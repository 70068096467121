import React from 'react';
import { gql } from '@apollo/client';
import { Title } from 'components/Typography';
import { PrimaryButton } from 'components/Button';
import { Link } from 'react-router-v3';
import ExpertPanel from './components/ExpertPanel';
import ExpertProfile from 'components/ExpertProfile';

import { Section } from './styles';

const ExpertsPanel = ({ experts, heading = 'Meet our experts', color = 'light' }) => {
  if (!experts || experts.length === 0) return null;

  const isSingle = experts.length === 1;

  if (isSingle) {
    return <ExpertProfile tutor={experts[0]} />;
  }

  return (
    <Section color={color}>
      <div className="header">
        <Title as="h2">{heading}</Title>
      </div>
      <ul>
        {experts.map((expert) => {
          return (
            <li key={expert.id}>
              <ExpertPanel expert={expert} />
            </li>
          );
        })}
      </ul>
      <PrimaryButton as={Link} to="/experts" inverse>
        View all experts
      </PrimaryButton>
    </Section>
  );
};

ExpertsPanel.fragments = {
  experts: gql`
    fragment ExpertsPanel__experts on User {
      id
      ...ExpertPanel__expert
      ...ExpertProfile__expert
    }
    ${ExpertPanel.fragments.expert}
    ${ExpertProfile.fragments.expert}
  `,
};

export default ExpertsPanel;
