import React from 'react';
import { gql } from '@apollo/client';
import { Link } from 'react-router-v3';
import BaseAvatar from 'components/Avatar';
import styled from 'styled-components';
import { Body, typeset, font } from 'components/Typography';
import s from './style.module.css';

const Avatar = styled(BaseAvatar)`
  position: absolute !important;
  left: 0;
  top: 5px;
`;

const Small = typeset({
  mobile: font.book.custom(12, 16),
});

const ExpertStrip = ({ expert, expert: { slug, profile }, ...props }) => {
  return (
    <Link to={`/experts/${slug}`} className={s.root} {...props}>
      <Avatar size={28} user={expert} />
      <Body as="h3">{profile.name}</Body>
      <Small as="p">{profile.shortBiog}</Small>
    </Link>
  );
};

ExpertStrip.fragments = {
  expert: gql`
    fragment ExpertStrip_expert on User {
      id
      slug
      profile {
        id
        name
        shortBiog
      }
      ...Avatar__user
    }
    ${Avatar.fragments.user}
  `,
};

export default ExpertStrip;
