import React from 'react';
import { gql } from '@apollo/client';
import AssetImage from 'components/AssetImage';
import RemoveButton from 'components/RemoveButton';
import moment from 'moment';
import styled from 'styled-components';
import s from './style.module.css';

const OrderItemSummary = ({
  item: {
    title: itemTitle,
    gift,
    promoCode,
    giftCard,
    variant,
    variant: { name },
    formattedPrice,
    formattedOriginalPrice,
    hasSaving,
  },
}) => {
  const {
    product: {
      availableOn,
      title,
      school,
      files: { header, poster },
    },
    files: { giftcard: giftCardImage },
  } = variant;
  const slug = school ? school.slug : 'global';
  const image = poster || header || giftCardImage;

  const variantDescription =
    variant && ['Peer', 'Expert'].includes(variant.name) ? variant.name : null;

  return (
    <li>
      <h4 className={slug}>{itemTitle || title}</h4>
      {image && <AssetImage uuid={image.uuid} width={60} height={60} />}
      <ul>
        {variantDescription && <li className="package">{variantDescription}</li>}
        {availableOn && moment().isBefore(availableOn) && (
          <li className="launch">{moment(availableOn).format('Do MMM YYYY')}</li>
        )}
        {gift && <li className="gift">Yes</li>}
        {hasSaving && (
          <li className="price">
            <s>{formattedOriginalPrice}</s>
          </li>
        )}
        <li className={hasSaving ? null : 'price'}>{formattedPrice}</li>
      </ul>
    </li>
  );
};

const OrderSummary = ({ handleRemoveUseBalance, order }) => {
  const {
    items,
    promoCode,
    formattedTotal,
    formattedSubtotal,
    formattedDiscount,
    balance,
    balanceUsed,
    formattedBalanceUsed,
  } = order;
  return (
    <section className={s.root}>
      <h3>Order summary</h3>
      <ol>
        {items.map((item, idx) => {
          return <OrderItemSummary key={item.id} item={item} />;
        })}
      </ol>
      <ul>
        {formattedSubtotal && formattedSubtotal !== formattedTotal && (
          <li className="subtotal">{formattedSubtotal}</li>
        )}
        {balanceUsed > 0 && (
          <li className="balance">
            -{formattedBalanceUsed}
            {handleRemoveUseBalance && <RemoveButton onClick={handleRemoveUseBalance} />}
          </li>
        )}
        {promoCode && <li className="promocode">{promoCode.code}</li>}
        <li className="total">{formattedTotal}</li>
        {formattedDiscount && <li className="saving">{formattedDiscount}</li>}
      </ul>
    </section>
  );
};

OrderSummary.fragments = {
  order: gql`
    fragment OrderSummary__order on Order {
      id
      total
      subtotal
      discount
      totalGbp
      currencyCode
      formattedTotal
      formattedSubtotal
      formattedDiscount
      formattedBalanceUsed
      balanceUsed
      promoCode {
        id
        code
      }
      items {
        id
        title
        gift
        giftCard {
          id
          code
        }
        seats {
          id
        }
        currencyCode
        price
        formattedPrice
        formattedOriginalPrice
        hasSaving
        variant {
          id
          name
          files {
            giftcard {
              id
              uuid
            }
          }
          product {
            id
            availableOn
            title
            school {
              id
              slug
            }
            files {
              poster {
                id
                uuid
              }
              header {
                id
                uuid
              }
            }
          }
        }
      }
    }
  `,
};

export default OrderSummary;
