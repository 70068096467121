import React from 'react';
import { gql } from '@apollo/client';
import YouTubePlayer from 'components/YouTubePlayer';
import VideoPlayer from 'components/LazyVideoPlayer';

const ProductVideoPlayer = ({ image, product: { trailerYoutubeId, trailerHlsUrl, files }, ...props }) => {
  const poster = image ?
                 image :
                 files.poster ? files.poster : files.header;

  if (trailerHlsUrl) {
    return <VideoPlayer hlsURL={trailerHlsUrl} poster={poster} {...props} />;
  }

  return <YouTubePlayer id={trailerYoutubeId} {...props} />;
};

ProductVideoPlayer.fragments = {
  product: gql`
    fragment ProductVideoPlayer__product on Product {
      id
      trailerYoutubeId
      trailerHlsUrl
      files {
        poster {
          id
          uuid
        }
        header {
          id
          uuid
        }
      }
    }
  `,
};

export default ProductVideoPlayer;
