import React from 'react';
import cx from 'classnames';
import s from './style.module.css';
import styled, { css } from 'styled-components';
import { SchoolThemeProvider } from '../ThemeProvider';

const StyledSection = styled.section`
  ${({ theme, $primary }) => {
    return (
      $primary &&
      css`
        background-color: ${theme.colors.primary};
      `
    );
  }}
`;

export const Section = ({
  primary,
  wide,
  noPadding,
  color,
  school,
  containerClassName,
  className,
  ...props
}) => {
  return (
    <StyledSection
      $primary={!!school}
      className={cx(
        s.section,
        {
          [s.gray]: color === 'gray',
          [s.light]: color === 'light',
          [s.primary]: primary,
          [s.wide]: wide,
          [s.noPadding]: noPadding,
        },
        containerClassName,
      )}
    >
      <div className={cx(s.inner, className)} {...props} />
    </StyledSection>
  );
};

Section.defaultProps = {
  primary: false,
};

export const Content = ({ tag: Tag, className, ...props }) => {
  return <Tag className={cx(s.content, className)} {...props} />;
};

Content.defaultProps = {
  tag: 'div',
};
