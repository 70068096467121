/* global FB */
import React, { useEffect, useState } from 'react';
import env from '@lwe/toolkit/env';
import { FacebookButton } from 'components/Button';
import ErrorList from 'components/ErrorList';
import { toErrorList } from 'components/Form';
import { useAuthenticateWithFacebook } from '@lwe/toolkit/authentication';

const FacebookAuthButton = ({ onSuccess, label = 'Sign up with Facebook', school, inCheckout }) => {
  const authenticateWithFacebook = useAuthenticateWithFacebook();
  const [error, setError] = useState(null);

  useEffect(() => {
    // Load FB JS SDK
    /*eslint-disable */
    window.fbAsyncInit = function () {
      FB.init({
        appId: env('FACEBOOK_APP_ID'),
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v4.0',
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    /*eslint-enable */
  }, []);

  const loginWithFacebook = () =>
    new Promise((resolve, reject) =>
      FB.login(
        (response) => (response.status === 'connected' ? resolve(response) : reject(response)),
        { scope: 'email' },
      ),
    );

  const facebookAuthHandler = async () => {
    setError(null);
    try {
      const {
        authResponse: { signedRequest },
      } = await loginWithFacebook();
      await authenticateWithFacebook(signedRequest, inCheckout ? 'CHECKOUT' : 'SHOP', school);
      onSuccess();
    } catch (e) {
      setError(toErrorList(e));
    }
  };

  return (
    <>
      <FacebookButton fullWidth onClick={facebookAuthHandler}>
        {label}
      </FacebookButton>
      {error && <ErrorList errors={error} />}
    </>
  );
};

export default FacebookAuthButton;
