import styled from 'styled-components';

export const PageContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  min-height: 100vh;
  width: 100%;

  padding-top: ${(props) => (props.offsetHeader ? `110px` : `60px`)};

  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding-top: ${(props) => (props.offsetHeader ? `140px` : `90px`)};
  }

  & > main {
    flex: 1;
  }

  /* not sure yet if this is needed
  div.app-content {
    position: relative;
    height: auto;
    min-height: 100%;
    margin-bottom: -$footer-height;
    &:after {
      content: '';
      display: block;
      height: $footer-height;
    }
    & > main {
      height: 100%;
    }
  }
  */
`;
