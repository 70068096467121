import React, { useCallback } from 'react';
import { Link, withRouter } from 'react-router-v3';
import styled, { css } from 'styled-components';
import Typography, { font } from 'components/Typography';
import { PrimaryButton } from 'components/Button';
import { useChristmas } from 'hooks/useChristmas';
import { useEaster } from 'hooks/useEaster';

const presentIcon = require('./assets/icon-present.svg');
const xmasBg = require('assets/svg/xmas/Xmas_Illustration_1.svg');

const giftCards = {
  present: require('./assets/bg-giftcard-gbp.png'),
  easter: require('./assets/easter-bg-giftcard-gbp.png'),
  xmas: require('assets/svg/xmas/bg-giftcard-xmas.svg'),
};

const xmasStyles = css`
  background-image: url(${xmasBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  background-color: ${({ theme }) => theme.schools.antiques.primary};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.schools.jewellery.primary};
  background: linear-gradient(
    122deg,
    ${({ theme }) => theme.schools.floristry.primary} 17%,
    ${({ theme }) => theme.schools.jewellery.primary} 74%
  );

  ${({ showChristmas }) => showChristmas && xmasStyles}

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
  }
`;

const Content = styled.div`
  cursor: ${({ showChristmas }) => (showChristmas ? 'default' : 'pointer')};
  position: relative;
  padding: 0 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-size: 0 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
    background-position: right -15px top;
    background-repeat: no-repeat;
    background-size: contain;
    width: 934px;
  }
`;

const ClickArea = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
`;

const CallToAction = styled.div`
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 15px 20px 15px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 270px;
    padding: 70px 0 70px 0;
    align-items: flex-start;
  }
`;

const CardImage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: none;
  }
`;

const Icon = styled.img`
  display: none;
  width: 24px;
  margin: 0 0 10px 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: block;
    width: 32px;
    margin: 0 0 15px 0;
  }
`;

const Title = styled(Typography).attrs({
  mobile: font.medium.group(6),
  desktop: font.medium.group(9),
  as: 'h2',
  white: true,
})`
  margin: 0 0 10px 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 15px 0;
  }
`;

const Subtitle = styled(Typography).attrs({
  mobile: font.medium.group(2),
  as: 'h3',
  white: true,
})`
  margin: 0 0 10px 0;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 20px 0;
    text-align: start;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;

  & > *:last-child {
    margin-left: 12px;
  }
`;

const GiftCardHomepagePromo = ({ router }) => {
  const { showChristmas } = useChristmas();
  const { showEaster } = useEaster();

  const cardType = showEaster ? 'easter' : showChristmas ? 'xmas' : 'present';
  const cardImage = giftCards[cardType];

  const handleClick = useCallback(() => {
    router.push('/gifts');
  }, [router]);

  return (
    <Section showChristmas={showChristmas}>
      <Content
        showChristmas={showChristmas}
        style={{ backgroundImage: `url(${cardImage})` }}
        onMouseDown={showChristmas ? undefined : handleClick}
      >
        <CallToAction>
          <Icon alt="Gift box" src={presentIcon} />
          <Title>Give the gift of knowledge</Title>
          <Subtitle>
            Treat your family, friends and colleagues to the gift of knowledge from world leading
            experts.
          </Subtitle>
          <CardImage>
            <img
              alt="Learning with experts gift card"
              src={cardImage}
              width="294px"
              height="195px"
            />
          </CardImage>
          {showChristmas ? (
            <ButtonsContainer>
              <PrimaryButton inverse as={Link} to="/christmas">
                Gift guide
              </PrimaryButton>
              <PrimaryButton inverse as={Link} to="/gifts">
                Gift cards
              </PrimaryButton>
            </ButtonsContainer>
          ) : (
            <PrimaryButton inverse as={Link} to="/gifts">
              View gift cards
            </PrimaryButton>
          )}
        </CallToAction>
      </Content>
    </Section>
  );
};

export default withRouter(GiftCardHomepagePromo);
