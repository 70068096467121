import React, { useCallback } from 'react';
import { Section, Content as BaseContent } from 'components/Layout';
import styled from 'styled-components';
import { typeset, font } from 'components/Typography';
import { PrimaryButton } from 'components/Button';
import { Share } from 'grommet-icons';
import BaseTag from 'components/atoms/Tag';

const BaseHeader = typeset({
  mobile: font.medium.group(8),
  desktop: font.medium.group(6),
});

const BaseBody = typeset({
  mobile: font.medium.group(4),
  desktop: font.medium.group(2),
});

const Header = styled(BaseHeader)`
  margin-top: 15px;
  font-weight: bold;
`;

const Body = styled(BaseBody)`
  margin-top: 10px;
`;

const Tag = styled(BaseTag)`
  background-color: #f3cb45;
  color: #1c1c1c;
`;

const Content = styled(BaseContent)`
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
  }
  width: 100%;
  background-color: #acc4e3;
  margin: 50px 0;
  padding: 0 !important;
`;

const Left = styled.div`
  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 65%;
  }
  background: url(${require('./assets/bg.png')}) no-repeat;
  background-size: cover;
  aspect-ratio: 910 / 510;
`;

const Right = styled.div`
  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 35%;
  }
  padding: 25px 30px;
  align-items: flex-start;

  ${Header}, ${Body} {
    color: #1c1c1c;
  }
`;

const Button = styled(PrimaryButton)`
  margin-top: 20px;
  &,
  &:hover {
    background-color: #ca3636;
    border: 1px solid #ca3636;
    color: white;
  }
`;

const NoBullshitPromo = () => {
  const navigate = useCallback(() => {
    window.open(
      'https://elearning.aargroup.co.uk?utm_source=learningwithexperts&utm_campaign=homepage_promo',
      '_blank',
    );
  }, []);

  return (
    <Section>
      <Content>
        <Left />
        <Right>
          <Tag primary>New & Exclusive</Tag>
          <Header as="h2">No Bullsh*t Leadership</Header>
          <Body as="p">
            Join bestselling author Chris Hirst and leadership coach Vonnie Alexander for this
            online course designed to accelerate the development of emerging leaders. If you're
            looking to make change happen, then No Bullsh*t Leadership is for you.
          </Body>
          <Button inverse onClick={navigate}>
            View course &nbsp;
            <Share size="small" color="white" />
          </Button>
        </Right>
      </Content>
    </Section>
  );
};

export default NoBullshitPromo;
