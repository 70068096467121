import React from 'react';
import { gql } from '@apollo/client';
import { Link } from 'react-router-v3';
import { Section } from 'components/Layout';
import styled, { css } from 'styled-components';
import ProductCard from 'components/ProductCard';
import { font, Subtitle } from 'components/Typography';
import ProductCardGrid from 'components/ProductCardGrid';
import { PrimaryButton } from 'components/Button';
import { useBlackFriday } from 'hooks/useBlackFriday';
import { useChristmas } from 'hooks/useChristmas';
import { useEaster } from 'hooks/useEaster';
import { BlackFriday } from './components/Heading';

const backgroundEaster = require('./assets/background-easter.svg');

const StyledSection = styled(({ className, ...props }) => (
  <Section containerClassName={className} {...props} />
)).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['primary', 'wide', 'noPadding', 'color', 'containerClassName'].includes(prop) &&
    defaultValidatorFn(prop),
})`
  background-color: ${({ theme, isBlackFriday, showChristmas }) =>
    showChristmas ? `#4A4A4A` : isBlackFriday ? `#252525` : theme.colors.global.grey4};
  padding: 30px 0 30px 0;
  width: 100%;

  ${({ showEaster }) =>
    showEaster &&
    css`
      background-color: ${({ theme }) => theme.colors.global.grey1};
    `}

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 50px 45px 50px 45px;

    ${({ showEaster }) =>
      showEaster &&
      css`
        background-repeat: no-repeat;
        background-position: center;
        background-size: 110%;
        background-color: ${({ theme }) => theme.colors.global.grey1};
        background-image: url(${backgroundEaster});
      `}
  }
`;

const StyledTitle = styled(Subtitle).attrs({
  mobile: font.bold.group(4),
  desktop: font.medium.group(7),
  as: 'h2',
})`
  align-self: flex-start;
  padding: 0 13px 10px 13px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 0 0 20px 0;
  }
`;

const HomepageFeaturedProducts = ({ products, ...props }) => {
  const { showBlackFriday } = useBlackFriday();
  const { showChristmas } = useChristmas();
  const { showEaster } = useEaster();
  if (products.length === 0) return null;

  const darkText = !showChristmas && !showBlackFriday && !showEaster;
  const footer = showBlackFriday && !showChristmas && (
    <PrimaryButton inverse as={Link} to="/blackfriday">
      View more offers
    </PrimaryButton>
  );
  return (
    <StyledSection
      isBlackFriday={showBlackFriday}
      showChristmas={showChristmas}
      showEaster={showEaster}
      {...props}
    >
      <StyledTitle white={!darkText}>
        {showChristmas ? (
          `Buy Today, Start Anytime`
        ) : (
          <>
            {!showBlackFriday && !showEaster && `Popular courses, right now!`}
            {showEaster && `Save 15% on all courses with code EASTER24`}
            {showBlackFriday && <BlackFriday />}
          </>
        )}
      </StyledTitle>
      <ProductCardGrid
        products={products}
        list="homepage-featured-products"
        withinBackground
        darkText={darkText}
        footer={footer}
        large
      />
    </StyledSection>
  );
};

HomepageFeaturedProducts.fragments = {
  products: gql`
    fragment HomepageFeaturedProducts__products on Query {
      featuredProducts: products(limit: 12, tag: "homepage_featured", onlyVisible: true) {
        id
        ...ProductCard__product
      }
    }
    ${ProductCard.fragments.product}
  `,
};

export default HomepageFeaturedProducts;
