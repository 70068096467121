import React from 'react';
import { Link } from 'react-router-v3';
import { Headline, Body } from 'components/Typography';
import s from './style.module.css';

const LinkList = ({ heading, ...props }) => {
  return (
    <div className={s.root}>
      <Headline as="h3">{heading}</Headline>
      <ul {...props} />
    </div>
  );
};

export const SearchLink = ({ children }) => {
  return (
    <li className={s.search}>
      <Body as={Link} to={{ pathname: '/search', query: { term: children } }}>
        {children}
      </Body>
    </li>
  );
};

export const ExternalLink = (props) => {
  return (
    <li>
      <Body as="a" target="_blank" {...props} />
    </li>
  );
};

export const InternalLink = (props) => {
  return (
    <li>
      <Body as={Link} {...props} />
    </li>
  );
};

export default LinkList;
