import React, { useState } from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import BrandLogo from 'components/BrandLogo';
import { Section } from 'components/Layout';
import ProductVideoPlayer from 'components/ProductVideoPlayer';
import ProductPosterImage from 'components/ProductPosterImage';
import TrustPilotQuote from 'components/TrustPilotQuote';
import CollectionStats from 'components/CollectionStats';
import { font, typeset, Body } from 'components/Typography';

const StyledSection = styled(({ className, ...props }) => (
  <Section containerClassName={className} {...props} />
))`
  background-color: ${({ theme }) => theme.schools.current.primary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 0 0 0 0;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row-reverse;
    align-items: stretch;
  }
`;

const StyledBody = styled(Body).attrs({ desktop: font.book.group(3), hd: font.book.group(5) })`
  margin: 6px 0 5px 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 4px 0;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    margin: 2px 0 10px 0;
  }
`;

const BaseTitle = typeset({
  mobile: font.bold.group(6),
  desktop: font.medium.group(7),
  hd: font.medium.group(9),
  as: 'h1',
});

const Title = styled(BaseTitle)`
  margin: 0 0 18px 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 15px 0;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 12px 0;
  padding: 0 18px 7px 18px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 35%;
    margin: 12px 0;
    padding: 0 25px;
    flex: 0 1 auto;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    padding: 0 39px;
  }
`;

const StyledVideo = styled.div`
  position: relative;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    align-self: center;
    width: 75%;
  }
`;

const ResellerProductHeader = ({ product }) => {
  const [hide, setHide] = useState(false);

  if (!product) return null;

  const handlePreviewClick = () => setHide(true);

  const {
    title,
    tutor,
    trailerHlsUrl,
    nestedProducts,
    shortDescription,
    quote,
    files: { logo },
  } = product;

  const distinctById = (curr, idx, arr) => arr.map((t) => t.id).indexOf(curr.id) === idx;
  const uniqueTutors = tutor ? [tutor] : nestedProducts.map((p) => p.tutor).filter(distinctById);
  const hasMultipleTutors = uniqueTutors.length > 1;
  return (
    <StyledSection wide>
      <Content key={product.id}>
        <Description>
          <StyledBody as="p" white book>
            {!hasMultipleTutors && <>{uniqueTutors[0].profile.name}</>}
            {hasMultipleTutors && <CollectionStats product={product} />}
          </StyledBody>
          <Title white>{title}</Title>
          <StyledBody as="p" white book>
            {shortDescription}
          </StyledBody>
        </Description>
        <StyledVideo>
          {trailerHlsUrl && (
            <ProductVideoPlayer product={product} onClick={handlePreviewClick} showWatchPreview />
          )}
          {!trailerHlsUrl && <ProductPosterImage product={product} />}
          {quote && <TrustPilotQuote quote={quote} hide={hide} />}
          {logo && <BrandLogo logo={logo} hide={hide} />}
        </StyledVideo>
      </Content>
    </StyledSection>
  );
};

ResellerProductHeader.fragments = {
  product: gql`
    fragment ResellerProductHeader__product on Product {
      id
      title
      isBundle
      trailerHlsUrl
      availableOn
      publishedOn
      availableToBuy
      trailerSynopsis
      shortDescription
      quote {
        id
        ...TrustPilotQuote__quote
      }
      ...ProductVideoPlayer__product
      ...collectionStats
      tutor {
        id
        profile {
          id
          name
        }
      }
    }
    ${CollectionStats.fragments.product}
    ${TrustPilotQuote.fragments.quote}
    ${ProductVideoPlayer.fragments.product}
  `,
};

export default ResellerProductHeader;
