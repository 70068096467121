import React, { ReactNode } from 'react';
import { Box, BoxExtendedProps, Text } from 'grommet';

export type BadgeSizeType = 'large' | 'medium' | 'small' | 'xsmall';
type PadType = { horizontal: string; vertical: string };
type IconSizeType = { width: string; height: string };

const padFactory = (size: BadgeSizeType, isNumber: boolean): PadType => {
  switch (size) {
    case 'large':
      return isNumber
        ? { horizontal: '18px', vertical: '4px' }
        : { horizontal: '14px', vertical: '9.5px' };
    case 'medium':
      return isNumber
        ? { horizontal: '14px', vertical: '3px' }
        : { horizontal: '12px', vertical: '4.5px' };
    case 'small':
      return isNumber
        ? { horizontal: '10px', vertical: '1.5px' }
        : { horizontal: '7px', vertical: '3.5px' };
    default:
      return isNumber
        ? { horizontal: '6px', vertical: '1px' }
        : { horizontal: '6px', vertical: '1px' };
  }
};

const textSizeFactory = (size: BadgeSizeType, isNumber: boolean): string => {
  switch (size) {
    case 'large':
      return isNumber ? '22px' : '12px';
    case 'medium':
      return isNumber ? '15px' : '12px';
    case 'small':
      return isNumber ? '12px' : '9px';
    default:
      return '9px';
  }
};

const iconSizeFactory = (size: BadgeSizeType): IconSizeType => {
  switch (size) {
    case 'large':
      return { width: '20px', height: '18px' };
    case 'medium':
      return { width: '16px', height: '14px' };
    case 'small':
      return { width: '14px', height: '12px' };
    default:
      return { width: '14px', height: '12px' };
  }
};

const gapFactory = (size: BadgeSizeType): string => {
  switch (size) {
    case 'large':
    case 'medium':
      return 'xsmall';
    default:
      return 'xxsmall';
  }
};

type TProps = BoxExtendedProps & {
  label?: string | number;
  icon?: ReactNode;
  size?: BadgeSizeType;
  reverse?: boolean;
  uppercase?: boolean;
};

const Badge = ({
  label,
  icon,
  size = 'medium',
  background = 'status-ok',
  color,
  reverse = false,
  uppercase = true,
  ...rest
}: TProps): JSX.Element | null => {
  const isNumber = typeof label === 'number';
  const processedLabel = label ? (uppercase ? String(label).toUpperCase() : label) : undefined;

  if (!isNumber && size === 'xsmall')
    console.warn("Badge: Text badge is not allowed to be size 'xsmall'");

  return !isNumber && size === 'xsmall' ? null : (
    <Box
      background={background}
      round="large"
      pad={padFactory(size, isNumber)}
      justify="center"
      {...rest}
    >
      <Box
        direction={reverse ? 'row-reverse' : 'row'}
        align="center"
        gap={Boolean(icon) && Boolean(label) ? gapFactory(size) : undefined}
      >
        {icon && (
          <Box align="center" justify="center" {...iconSizeFactory(size)}>
            {icon}
          </Box>
        )}
        <Text size={textSizeFactory(size, isNumber)} weight={500} color={color}>
          {processedLabel}
        </Text>
      </Box>
    </Box>
  );
};

export default Badge;
