import React from 'react';
import PropTypes from 'prop-types';
import { Help, Error } from 'components/Form/Helpers';
import { BaseLabel } from '../inputs/styles';
import { font } from 'components/Typography';
import styled, { css } from 'styled-components';

export const InputGroup = styled.div`
  margin: 2em 0 2em 0;

  ${({ tight, theme }) =>
    tight &&
    css`
      & > * {
        margin: 1em 0 1em 0;
      }
    `}

  ${({ noMargin, theme }) =>
    noMargin &&
    css`
      margin: 0 0 0 0;
    `}
  
  ${({ noWrap, theme }) =>
    noWrap &&
    css`
      & {
        flex-wrap: nowrap;
      }
    `}
  
  ${({ row, theme }) =>
    row &&
    css`
      display: block;

      @media ${theme.breakpoints.desktop} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      && > * {
        flex: 1 0 auto;
        margin: 2em 0 2em 0;

        @media ${theme.breakpoints.desktop} {
          margin: 0 1em 0 1em;
        }

        &:first-child {
          @media ${theme.breakpoints.desktop} {
            margin-left: 0;
          }
        }

        &:last-child {
          @media ${theme.breakpoints.desktop} {
            margin-right: 0;
          }
        }
      }
    `}
  
  ${({ collapsed, theme }) =>
    collapsed &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      && > * {
        flex: 0 0 auto;
        margin: 0 1em 0 0em;
      }
    `}
  
  ${({ column, theme }) =>
    column &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
  
  ${({ alignTop, theme }) =>
    alignTop &&
    css`
      display: flex;
      align-items: flex-start;
    `}
  
  ${({ justifyStart, theme }) =>
    justifyStart &&
    css`
    justify-content: flex-start;
    
    && > *:first-child {
      margin-left: 0;
  `}
  
  ${({ justifyEnd, theme }) =>
    justifyEnd &&
    css`
    justify-content: flex-end;
    
    && > *:last-child {
      margin-right: 0;
  `}
`;

InputGroup.propTypes = {
  row: PropTypes.bool,
  collapsed: PropTypes.bool,
  tight: PropTypes.bool,
  centered: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export const WithIcon = styled.div`
  margin: 0 0 0 0;
  position: relative;

  & > span {
    position: absolute;
    top: 0;
  }

  ${({ left, theme }) =>
    left &&
    css`
      & > input {
        padding-left: 3em;
      }

      & > span {
        left: 0;
      }
    `}

  ${({ right, theme }) =>
    right &&
    css`
      & > input {
        padding-right: 3em;
      }

      & > span {
        right: 0;
      }
    `}
`;

WithIcon.propTypes = {
  left: PropTypes.bool,
  right: PropTypes.bool,
};

const BaseContainer = styled.div`
  ${({ noMargin }) =>
    !noMargin &&
    css`
      margin: 2em 0 2em 0;
    `}
`;

export const InputContainer = ({
  id,
  label,
  largeLabel,
  error,
  message,
  help,
  children,
  noMargin,
}) => {
  return (
    <BaseContainer noMargin={noMargin}>
      {label && (
        <BaseLabel largeLabel={largeLabel} htmlFor={id}>
          {label}
        </BaseLabel>
      )}
      {children}
      {help && !error && <Help help={help} />}
      {error && <Error message={message} />}
    </BaseContainer>
  );
};

InputContainer.propTypes = {
  error: PropTypes.bool,
  message: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
};

export const RadioContainer = styled.div`
  margin: 0 0 0 0;
  position: relative;

  & > ${BaseLabel} {
    color: ${({ theme }) => theme.colors.global.grey2};
    ${font.book.group(3)}
    display: inline-block;
    padding: 0.25em 0.5em 0.25em 2em;

    /* Base styles for use on both pseudo elements. */
    &::before,
    &::after {
      border: 1px solid;
      height: 1.125em;
      left: 0.125em;
      position: absolute;
      top: 0.35em;
      width: 1.125em;
      border-radius: 100%;
      content: ' ';
      transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    }

    &::before {
      border-color: transparent;
      box-shadow: 0 0 0 0 transparent;
    }

    &::after {
      border-color: ${({ theme }) => theme.colors.global.grey2};
    }
  }
`;

/* TODO: Should take a "row" prop for horizontal styling */
export const CheckboxContainer = styled.div`
  margin: 0 0 0 0;
  padding-left: 2em;
  position: relative;

  & > ${BaseLabel} {
    color: ${({ theme }) => theme.colors.global.grey2};
    ${font.book.group(3)}
    display: inline-block;
    padding: 0.25em 0.5em 0.25em 1em;
    vertical-align: top;

    /* Base styles for use on both pseudo elements. */
    &::before,
    &::after {
      content: ' ';
      position: absolute;
      transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out,
        transform 0.1s ease-in-out;
    }

    /* Styles for the custom box boundary. */
    &::before {
      border-color: ${({ theme }) => theme.colors.global.grey2};
      border-radius: 2px;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.global.grey2};
      height: 1.125em;
      left: 0.125em;
      top: 0.325em;
      width: 1.125em;
    }

    /* This recreates the "check" mark. */
    &::after {
      border: 0;
      border-bottom: 2px solid ${({ theme }) => theme.colors.global.white};
      border-right: 2px solid ${({ theme }) => theme.colors.global.white};
      height: 0.825em;
      left: 0.425em;
      top: 0.325em;
      transform-origin: center center;
      transform: rotate(45deg) scale(0);
      width: 0.5em;
    }
  }
`;
