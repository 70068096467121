import React, { useState } from 'react';
import SchoolList from '../../../SchoolList';
import Contents, { Panel } from '../Contents';
import { Link } from 'react-router-v3';
import ExpertStrip from 'components/ExpertStrip';
import { Subtitle, Headline } from 'components/Typography';
import { gql } from '@apollo/client';
import cx from 'classnames';
import s from './style.module.css';
import ExpertPromo from 'components/ExpertPromo';

const Experts = ({ handleClose, navigation: { schools, latestExperts } }) => {
  const [selected, setSelected] = useState(null);
  const selectedSchool = schools.find((s) => s.id === selected);

  return (
    <Contents data-cy="nav-primary-experts-desktop">
      <Panel>
        <Subtitle as="h2">Experts</Subtitle>
        <SchoolList
          schools={schools}
          renderItem={({ id, slug, name }) => {
            const active = id === selected;
            const handleClick = (e) => {
              e.preventDefault();
              setSelected(active ? null : id);
            };
            return (
              <Headline
                onClick={handleClick}
                as={Link}
                to={`/${slug}/experts`}
                className={cx(s.link, { [s.active]: active })}
              >
                {name}
              </Headline>
            );
          }}
        />
        <p>
          {selected && (
            <Headline as={Link} onClick={handleClose} to={`/experts`}>
              View all
            </Headline>
          )}
        </p>
      </Panel>
      {!selected && (
        <Panel>
          <Subtitle as="h2">Newest experts</Subtitle>
          <div className={s.promos}>
            {latestExperts.map((expert) => {
              return <ExpertPromo key={expert.id} expert={expert} onClick={handleClose} />;
            })}
          </div>
        </Panel>
      )}
      {selected && (
        <Panel grid>
          <ul>
            {selectedSchool.latestExperts.map((expert) => {
              return (
                <li key={expert.id}>
                  <ExpertStrip expert={expert} onClick={handleClose} />
                </li>
              );
            })}
          </ul>
        </Panel>
      )}
    </Contents>
  );
};

Experts.fragments = {
  navigation: gql`
    fragment Experts_navigation on Navigation {
      id
      ...SchoolList_schools
      schools {
        id
        latestExperts: tutors(limit: 20) {
          id
          ...ExpertStrip_expert
        }
      }
      latestExperts(limit: 2) {
        id
        ...ExpertPromo_expert
      }
    }
    ${SchoolList.fragments.schools}
    ${ExpertStrip.fragments.expert}
    ${ExpertPromo.fragments.expert}
  `,
};

export default Experts;
