import React from 'react';
import { gql } from '@apollo/client';
import { Section } from 'components/Layout';
import ProductCard from 'components/ProductCard';
import ProductGrid from 'components/sections/ProductGrid';
import s from './style.module.css';

const CollectionOutline = ({ product }) => {
  if (!product) return null;
  const { nestedProducts } = product;
  return (
    <Section color="light" className={s.root}>
      <ProductGrid
        products={nestedProducts}
        heading="Courses within this collection"
        list={`Courses within ${product.title}`}
        color="light"
      />
    </Section>
  );
};

CollectionOutline.fragments = {
  product: gql`
    fragment collectionOutline on Product {
      id
      nestedProducts {
        id
        ...ProductCard__product
      }
    }
    ${ProductCard.fragments.product}
  `,
};

export default CollectionOutline;
