import React from 'react';
import Nuka from 'nuka-carousel';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
  padding: 30px 0;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 60px 0;
    max-width: 950px;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    max-width: 1280px;
  }
`;

const Row = styled.div`
  display: flex;

  & > * {
    margin-right: 25px;
    margin-left: 25px;
  }
`;

const IconLeft = ({ previousSlide }) => (
  <div onMouseDown={previousSlide}>
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="chevron-left"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      width="15px"
    >
      <path
        fill="#4A4A4A"
        d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
      ></path>
    </svg>
  </div>
);

const IconRight = ({ nextSlide }) => (
  <div onMouseDown={nextSlide}>
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="chevron-right"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      width="15px"
    >
      <path
        fill="#4A4A4A"
        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
      ></path>
    </svg>
  </div>
);

const InfoItems = ({ children }) => {
  const is_mobile = useMediaQuery({ maxWidth: 949 });

  return (
    <Container>
      {is_mobile ? (
        <Nuka
          wrapAround
          autoplay
          renderCenterLeftControls={IconLeft}
          renderCenterRightControls={IconRight}
          renderBottomCenterControls={null}
        >
          {children}
        </Nuka>
      ) : (
        <Row>{children}</Row>
      )}
    </Container>
  );
};

export default InfoItems;
