import React, { useState } from 'react';
import cx from 'classnames';
import usePrevious from 'hooks/usePrevious';
import useMeasure from 'hooks/useMeasure';
import { useSpring, a } from 'react-spring';
import s from './style.module.css';

const Accordian = ({ header, open: initialOpen, children, white, ...props }) => {
  const [open, setOpen] = useState(initialOpen);
  const toggle = () => setOpen(!open);
  const previous = usePrevious(open);
  const [bind, { height: viewHeight }] = useMeasure();

  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0, transform: 'translate3d(0,-20px,0)' },
    to: {
      height: open ? viewHeight : 0,
      opacity: open ? 1 : 0,
      transform: `translate3d(0,${open ? 0 : -20}px,0)`,
    },
  });

  return (
    <section className={cx(s.root, { [s.open]: open, [s.white]: white })} {...props}>
      <div className={s.header} onClick={toggle}>
        {header}
      </div>
      <a.div
        className={s.wrapper}
        style={{ opacity, height: open && previous === open ? 'auto' : height }}
      >
        <a.div style={{ transform, overflow: 'hidden' }} {...bind}>
          {children}
        </a.div>
      </a.div>
    </section>
  );
};

export default Accordian;
