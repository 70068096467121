import React from 'react';
import { gql } from '@apollo/client';
import Section from 'components/Section';
import AccordianList from 'components/AccordianList';
import { SectionHeading, OverviewDescription } from 'components/Elements';
import s from './style.module.css';
const RhsModules = ({ product }) => {
  if (!product) return null;
  const { nestedProducts } = product;

  const entries = nestedProducts.map((product) => {
    const { id, title, slateDescription } = product;

    return {
      id,
      title: title.replace('RHS Level 2: ', ''),
      slateDescription: JSON.parse(slateDescription),
    };
  });

  return (
    <Section className={s.root}>
      <SectionHeading>Module Overview</SectionHeading>
      <AccordianList entries={entries} showNumbers={false} />
    </Section>
  );
};

RhsModules.fragments = {
  product: gql`
  fragment rhsModules on Product {
    id
    nestedProducts {
      id
      title
      description
      slateDescription

      tutor {
        id
        profile {
          id
          name
        }
      }
    }
  }
  `,
};

export default RhsModules;