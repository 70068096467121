import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import styled, { useTheme, keyframes } from 'styled-components';
import 'react-circular-progressbar/dist/styles.css';
import s from './style.module.css';

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const svg = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`;

const circle = keyframes`
  0%,
  25% {
    stroke-dashoffset: 280;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 280;
    transform: rotate(360deg);
  }
`;

const Svg = styled.svg`
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-name: ${svg};
  width: 50px;
  height: 50px;

  & > circle {
    animation-duration: 1.4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: ${circle};
    animation-fill-mode: both;
    display: block;
    fill: transparent;
    stroke-linecap: round;
    stroke-dasharray: 283;
    stroke-dashoffset: 280;
    stroke-width: 8px;
    transform-origin: 50% 50%;
  }
`;

const Spinner = ({ color }) => {
  return (
    <Svg viewBox="0 0 100 100">
      <circle cx="50" cy="50" r="46" stroke={color} />
    </Svg>
  );
};

const Progress = ({ progress, color, loading }) => {
  const theme = useTheme();
  if (loading) {
    return (
      <Container>
        <Spinner color={theme.colors.primary} />
      </Container>
    );
  }

  return (
    <Container>
      <CircularProgressbar
        className={s.progress}
        value={progress}
        text={null}
        styles={buildStyles({
          pathColor: theme.colors.primary,
        })}
      />
    </Container>
  );
};

export default Progress;
