import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'components/Elements';
import { PrimaryButton } from 'components/Button';
import OrderSummary from 'components/OrderSummary';
import styled from 'styled-components';
import Header from 'components/Basket/components/Header';
import BaseSection from 'components/Section';

const Section = styled(BaseSection)`
  min-height: 40vh;
`;

const ORDER_RECEIPT_QUERY = gql`
  query OrderReceipt($orderId: ID!) {
    order(id: $orderId) {
      id
      ...OrderSummary__order
    }
  }
  ${OrderSummary.fragments.order}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
    margin-top: 30px;
  }

  & p {
    line-height: 1.3;
  }

  & > div {
    max-width: 450px;
    flex: 1;
    padding: 0 20px;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      padding: 0;
    }

    & > h2 {
      margin: 20px 0;
      font-size: 26px;
      letter-spacing: -1px;
      color: #4a4a4a;
    }
  }
`;

const OrderReceipt = ({ params: { orderId } }) => {
  const { data } = useQuery(ORDER_RECEIPT_QUERY, { variables: { orderId }, ssr: false });

  if (!data) return null;
  const { order } = data;
  if (!order) return null;

  const seats = order.items.flatMap((item) => item.seats);
  const hasSeats = seats.length > 0;

  return (
    <>
      <Header>
        <h2>Order receipt</h2>
      </Header>
      <Section slim>
        <Container>
          <div>
            <h2>Thank you for your order</h2>
            <p>An email confirmation for order number {order.id} has been sent to you.</p>
            {hasSeats && (
              <>
                <h2>What happens next?</h2>
                <p>
                  Whenever you are ready to start your course all you have to do is sign in and
                  click ‘My courses’
                </p>
              </>
            )}
            <br />
            <PrimaryButton as={Link} to="/classrooms">
              Go to My courses
            </PrimaryButton>
          </div>
          <OrderSummary order={order} />
        </Container>
      </Section>
    </>
  );
};

export default OrderReceipt;
