import React from 'react';
import styled from 'styled-components';

import Typography, { font } from 'components/Typography';

const Section = styled.section`
  padding: 25px 18px 0px 18px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: ${({ theme, slug }) => theme?.schools?.[slug]?.primary};
    opacity: 0.2;

    max-width: 1245px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 55px 18px 0px 18px;
  }
`;

const Heading = styled(Typography).attrs({
  mobile: font.medium.group(5),
  desktop: font.medium.group(7),
  as: 'h1',
})`
  color: ${({ theme, slug }) => theme?.schools?.[slug]?.primary};
  text-align: center;
  max-width: 770px;
  margin: 0 auto 15px auto;
`;

const Intro = styled(Typography).attrs({
  mobile: font.medium.group(2),
  desktop: font.medium.group(3),
  as: 'p',
})`
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  max-width: 785px;
  margin: 0 auto 22px auto;

  color: ${({ theme }) => theme?.colors?.global?.grey1};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 auto 52px auto;
  }
`;

const PageInfo = ({ h1, intro, slug }) => {
  return (
    <Section slug={slug}>
      <Heading slug={slug}>{h1}</Heading>
      <Intro>{intro}</Intro>
    </Section>
  );
};

export default PageInfo;
