import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import cx from 'classnames';
import formatCurrency from 'utils/formatCurrency';
import moment from 'moment';
import pluralize from 'pluralize';
import AddToBasketButton from 'components/AddToBasketButton';
import Tag from 'components/atoms/Tag';
import Avatar from 'components/Avatar';
import { typeset, font, Body, Small, Title as Title, Headline } from 'components/Typography';
import { gql } from '@apollo/client';
import KlarnaPromo from 'components/KlarnaPromo';
import useCurrencyCode from 'hooks/useCurrencyCode';

import s from './style.module.css';

const Header = styled.div`
  flex: 0 0 auto;
`;

const List = styled.ul`
  flex: 1 0 auto;
  margin: 20px 0 20px 0;
`;

const Footer = styled.div`
  flex: 0 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  & > em,
  & > span {
    & > s {
      color: ${({ theme }) => theme.schools.current.tint1};
    }
  }

  & > small {
    margin-top: 5px;
    font-weight: 100;
    &:empty {
      height: 5px;
    }
  }
`;

const Heading = typeset({
  mobile: font.medium.group(7),
  desktop: font.medium.custom(26, 30),
});

const OptionTitle = typeset({
  mobile: Title.desktop,
});

const Pricing = ({ product, variant, currencyCode, active, embargoed, isExpert }) => {
  const { isBundle, nestedProducts, availableOn } = product;
  const hasNestedProductsAvailableToBuy = nestedProducts.some(p => p.availableToBuy);

  const {
    price: {
      isDiscounted,
      current: numericCurrent,
      formatted: { retail, current },
    },
  } = variant;

  const individualTotal = nestedProducts.reduce((acc, { variants }) => {
    return acc + variants.find((v) => variant.tag === v.tag)?.price?.current;
  }, 0);

  const isFree = numericCurrent === 0;
  const isSaving = individualTotal > numericCurrent;

  return (
    <Footer>
      {
        <Heading primary={active} white={!active}>
          {!isFree && (
            <>
              {isDiscounted && <s>{retail}</s>} {current}
            </>
          )}
          {isFree && 'FREE'}
        </Heading>
      }
      {isBundle && hasNestedProductsAvailableToBuy && isSaving && (
        <Headline white={!active} as="div">
          {formatCurrency(individualTotal, currencyCode.toUpperCase())} if courses bought separately
        </Headline>
      )}
      <AddToBasketButton
        className={s.addToBasket}
        invert={!active}
        product={product}
        variant={variant}
        isExpert={isExpert}
      />
      <Small as="small" white={!active}>
        {embargoed && `Start anytime from ${moment(availableOn).format('MMMM Do YYYY')}`}
      </Small>
      <KlarnaPromo amount={numericCurrent} light={!active} fontSize={15} />
    </Footer>
  );
};

const distinctById = (curr, idx, arr) => arr.map((t) => t.id).indexOf(curr.id) === idx;
const toSentence = (arr) => {
  const list = arr.slice();
  const last = list.pop();
  if (!list.length) return last;
  return `${list.join(', ')} & ${last}`;
};

const ProductPackage = ({ product, variant, displayedOnItsOwn }) => {
  const [less, setLess] = useState(true);
  const currencyCode = useCurrencyCode();
  const { isBundle, lessons, tutor, markedBy, nestedProducts, cpdHours } = product;

  if (!product || !variant || !currencyCode) return null;

  const currency = currencyCode.toLowerCase();
  const isExpert = variant.tag === 'tutor_access_all';
  const active = isExpert;
  const { availableOn } = product;

  const { id, name, tag, certificateAvailable } = variant;
  const Item = ({ icon, ...props }) => {
    return (
      <Small
        as="li"
        white={!active}
        desktop={false}
        className={cx(s.item, {
          [s.white]: !active,
          [s.calendar]: icon === 'calendar',
          [s.clock]: icon === 'clock',
          [s.pencil]: icon === 'pencil',
          [s.rosette]: icon === 'rosette',
        })}
        {...props}
      />
    );
  };

  const tutors = tutor ? [tutor] : nestedProducts.map((p) => p.tutor);
  const uniqueTutors = tutors.filter(distinctById);
  const tutorOrMarkedByNames = tutor
    ? [markedBy || tutor.profile.name]
    : [...new Set(nestedProducts.map((p) => p.markedBy || p.tutor.profile.name))];
  const tutorNames = uniqueTutors.map((t) => t.profile.name);
  const embargoed = availableOn && moment().isBefore(availableOn);
  const hasMultipleTutors = tutorNames.length > 1;
  const hasManyTutors = tutorNames.length > 2;
  const customMarkedBy = markedBy || 'Expert Tutors';
  const lessonCount = isBundle
    ? nestedProducts.reduce((a, b) => a + b.lessons.length, 0)
    : lessons.length;
  const handleMore = () => setLess(!less);

  return (
    <div id={`variant-${id}`} className={cx(s.root, { [s.active]: active })}>
      <Header>
        <OptionTitle as="h3" white={!active} className={s.title}>
          {displayedOnItsOwn ? (
            `Included in this course`
          ) : (
            <>
              {' '}
              <span>The</span> {name} option
            </>
          )}
        </OptionTitle>
        {isExpert && (
          <>
            {!displayedOnItsOwn && (
              <Tag primary className={s.tag}>
                Recommended
              </Tag>
            )}
            <div className={cx(s.header, { [s.many]: hasManyTutors })}>
              <div className={cx(s.avatars, { [s.many]: uniqueTutors.length > 2 })}>
                {uniqueTutors.map((tutor) => (
                  <Avatar key={tutor.id} user={tutor} size={hasManyTutors ? 42 : 70} />
                ))}
              </div>
              <Body as="p" white={!active}>
                Develop your learning further with{' '}
                {!hasMultipleTutors && <>marked assignments and</>} personal tuition from{' '}
                {hasManyTutors ? customMarkedBy : toSentence(tutorOrMarkedByNames)}
              </Body>
            </div>
          </>
        )}
        {!isExpert && (
          <Body as="p" white={!active}>
            Discover the benefits of group learning in an online interactive classroom of no more
            than 20 people. Get the most from shared knowledge and community study
          </Body>
        )}
      </Header>

      <List className={cx(s.list, { [s.open]: !less })}>
        <Item icon="calendar">
          Start course{isBundle && 's'}{' '}
          {embargoed
            ? `anytime from ${moment(availableOn).format('MMMM Do YYYY')}`
            : 'whenever you like'}
        </Item>
        {isExpert && (
          <Item icon="clock">
            {isBundle
              ? 'Personalised'
              : `${lessonCount} ${pluralize(
                  'weeks',
                  lessonCount,
                )} tutor access for personalised`}{' '}
            assignment feedback & coaching
          </Item>
        )}
        {!isExpert && <Item icon="clock">Practise what you learn with your peers</Item>}
        {isExpert && lessonCount && (
          <Item icon="pencil">
            {lessonCount}{' '}
            {hasManyTutors
              ? 'marked assignments'
              : `${pluralize('assignments', lessonCount)} marked by ${
                  hasManyTutors ? 'Expert Tutors' : toSentence(tutorOrMarkedByNames)
                }`}
          </Item>
        )}
        {isExpert && certificateAvailable && (
          <Item icon="rosette">Certificate of completion{cpdHours && ` and CPD hours`}</Item>
        )}
        <Item>Online classroom with up to 20 classmates</Item>
        {lessons && (
          <Item>
            {lessonCount} {pluralize('lessons', lessonCount)} with expert{' '}
            {pluralize('videos', lessonCount)} & notes
          </Item>
        )}
        {!isExpert && lessons && (
          <Item>
            {lessonCount} course {pluralize('assignments', lessonCount)}
          </Item>
        )}
        <Item>Group chat & direct message {isExpert && `with tutor & `} classmates</Item>
        <Item>Lifetime access to videos, notes & classroom</Item>
      </List>

      <Small as="a" onClick={handleMore} white={!active} className={cx({ [s.show]: less })}>
        Learn more
      </Small>

      <Pricing
        currencyCode={currency}
        product={product}
        variant={variant}
        active={active}
        embargoed={embargoed}
        isExpert={isExpert}
      />
    </div>
  );
};

ProductPackage.defaultProps = {
  minimised: false,
};

ProductPackage.fragments = {
  product: gql`
    fragment packageProduct on Product {
      id
      tutor {
        id
        profile {
          id
          firstName
          name
        }
      }
      markedBy
      isBundle
      availableOn
      cpdHours
      nestedProducts {
        id
        markedBy
        tutor {
          id
          profile {
            id
            firstName
            name
          }
        }
        lessons {
          id
        }
        variants {
          id
          tag
          price {
            current
          }
        }
      }
    }
  `,
  variant: gql`
    fragment packageVariant on Variant {
      id
      tag
      availableToBuy
      certificateAvailable
      name
      price {
        isDiscounted
        current
        formatted {
          retail
          current
        }
      }
    }
  `,
};

export default ProductPackage;
