import React from 'react';
import styled from 'styled-components';

import InfoItems from './components/InfoItems';

const Section = styled.section`
  padding: 0px 18px 0px 18px;
  background: ${({ theme, bgColor }) => theme?.colors?.global?.lightBackground};
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    max-width: 800px;
    background: ${({ theme, slug }) => theme?.schools?.[slug]?.primary};
    opacity: 0.4;
  }
`;

const InfoItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: ${({ theme }) => theme?.colors?.global?.grey1};
    font-family: Gotham;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-bottom: 13px;
    max-width: 290px;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      max-width: 400px;
    }
  }
  p {
    white-space: pre-wrap;
    color: ${({ theme }) => theme?.colors?.global?.grey1};
    font-family: Gotham;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    max-width: 290px;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      max-width: 400px;
    }
  }
`;

const info = {
  col1: {
    title: 'What you’ll get',
    body: `Learn in an online interactive classrooms with up to 20 other classmates.

    Video tutorials led by our experts & regular assignments for practicing.
    
    Downloadable notes and get lifetime access to video tutorials, notes and online classroom.`,
  },
  col2: {
    title: 'How it works',
    body: `Join the online classroom.

    Watch video tutorials led by our experts.
    
    Practice what you learn with inspiring assignments.
    
    Download notes and get lifetime access to videos, assignments and classroom.`,
  },
  col3: {
    title: 'What are the options',
    body: `Peer level – Enjoy the benefits of group learning, share ideas, collaborate and chat direclty to classmates.

    Expert level – Includes personalised coaching from your expert tutor who will mark your assignments and provide feedback and tips.`,
  },
};

const ProductInfo = ({ slug, contextualInfo }) => {
  const mergedInfo = {
    col1: { ...info.col1, ...contextualInfo?.col1 },
    col2: { ...info.col2, ...contextualInfo?.col2 },
    col3: { ...info.col3, ...contextualInfo?.col3 },
  };

  return (
    <Section color="light" slug={slug}>
      <InfoItems>
        <InfoItem>
          <h3>{mergedInfo.col1.title}</h3>
          <p>{mergedInfo.col1.body}</p>
        </InfoItem>
        <InfoItem>
          <h3>{mergedInfo.col2.title}</h3>
          <p>{mergedInfo.col2.body}</p>
        </InfoItem>
        <InfoItem>
          <h3>{mergedInfo.col3.title}</h3>
          <p>{mergedInfo.col3.body}</p>
        </InfoItem>
      </InfoItems>
    </Section>
  );
};

export default ProductInfo;
