import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Link as BaseLink, withRouter } from 'react-router-v3';
import { useTheme } from 'styled-components';
import { toLocalId } from '@lwe/toolkit/utils';

import { FixedHeader } from './styles';
import { isAdmin, hasDashboard } from 'utils/roles';
import Avatar from 'components/Avatar';
import { useLegacyLogoutAndRedirect } from 'hooks/user';
import { useConfiguration } from 'hooks/configuration';
import { LazyImage } from '@lwe/toolkit/ui-library';
import { genAssetImgSrc } from '@lwe/toolkit/utils';

const Link = styled(BaseLink)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Image = styled(LazyImage)`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const DesktopHeader = ({ user, router }) => {
  const {
    features,
    settings: { homepageRedirect },
  } = useConfiguration();
  const logout = useLegacyLogoutAndRedirect(router);
  const theme = useTheme();

  const handleSignOut = useCallback(() => {
    logout();
  }, [logout]);

  const userIsAdmin = isAdmin(user);
  const hasDashboardAccess = hasDashboard(user);

  return (
    <FixedHeader>
      <nav>
        <div className="logo">
          <Link to={homepageRedirect ?? '/'}>
            <Image height='48px' {...genAssetImgSrc(theme?.logo?.desktop?.uuid, 400)} fade={true} />
          </Link>
        </div>
        {user && (
          <ul>
            <li>
              <Link to={`/classrooms`}>
                <span>MY COURSES</span>
              </Link>
            </li>

            <li>
              <Avatar user={user} />
              <ul className="sub-menu">
                {userIsAdmin && (
                  <li>
                    <Link to="/classrooms/admin">Administration</Link>
                  </li>
                )}
                {hasDashboardAccess && (
                  <li>
                    <Link to="/classrooms/dashboard">
                      <span>Dashboard</span>
                    </Link>
                  </li>
                )}
                <li>
                  <Link to={`/profile/${toLocalId(user?.id)}`}>Manage Account</Link>
                </li>
                <li>
                  <Link to={`/signin`} onMouseDown={handleSignOut}>
                    Sign out
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        )}
      </nav>
    </FixedHeader>
  );
};

export default withRouter(DesktopHeader);
