import React from 'react';
import { gql } from '@apollo/client';
import cx from 'classnames';
import s from './style.module.css';

const TrustPilotQuote = ({ hide, className, quote: { body, citation, rating } }) => {
  const ratingImage = rating ? `rating-${rating.toString().replace('.', '')}` : null;

  return (
    <figure className={cx(s.root, className, { [s.hide]: hide })}>
      <blockquote>
        <p>{body}</p>
        <footer>
          <cite>{citation}</cite>
        </footer>
      </blockquote>
      {ratingImage && (
        <div
          className={cx(s.rating, ratingImage)}
          aria-label={`"Rated ${rating} out of 5 by Trustpilot."`}
        >
          <div className={s.logo}></div>
        </div>
      )}
    </figure>
  );
};

TrustPilotQuote.fragments = {
  quote: gql`
    fragment TrustPilotQuote__quote on PagePromoQuote {
      id
      body
      citation
      rating
    }
  `,
};

export default TrustPilotQuote;
