import React from 'react';
import cx from 'classnames';
import s from './style.module.css';

const PageHeading = ({ className, component: Component = 'h2', centered = false, ...props }) => {
  return <Component className={cx(s.root, className)} {...props} />;
};

PageHeading.propTypes = {};

export default PageHeading;
