import React from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'components/Form';
import { CheckboxContainer } from 'components/Form/Layout';
import { BaseLabel, BaseCheckbox } from '../styles';

const Checkbox = ({ id, name, label, error, disabled, input, ...rest }) => {
  return (
    <CheckboxContainer error={error}>
      <BaseCheckbox id={id} name={name} disabled={disabled} {...input} {...rest} />
      <BaseLabel htmlFor={name}>{label}</BaseLabel>
    </CheckboxContainer>
  );
};

Checkbox.propTypes = RadioButton.propTypes;

export default Checkbox;
