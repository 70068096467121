import React from 'react';
import Carousel from 'components/Carousel';
import MediaQuery from 'react-responsive';
import cx from 'classnames';
import s from './style.module.css';

const PacketGrid = ({ items = [], bordered, children, carousel, ...props }) => {
  return (
    <MediaQuery query="(min-width: 1024px)">
      {(desktop) => {
        const isCarousel = carousel && !desktop && items.length > 1;
        const List = isCarousel ? Carousel : 'ul';
        const Item = isCarousel ? 'section' : 'li';

        return (
          <List
            className={cx(s.root, {
              [s.bordered]: bordered,
              [s.carousel]: isCarousel,
              [s.list]: !isCarousel,
            })}
            {...props}
          >
            {items.map((item, i) => (
              <Item key={item.id}>{children(item, i)}</Item>
            ))}
          </List>
        );
      }}
    </MediaQuery>
  );
};

export default PacketGrid;
