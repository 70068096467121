import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Helmet from 'react-helmet';
import JsonLd from 'components/JsonLd';
import Page from 'components/Page';
import TutorSpotlight from 'components/TutorSpotlight';
import HomepageFeaturedProducts from 'components/sections/HomepageFeaturedProducts';
import FrequentlyAskedQuestionsSummary from 'components/sections/FrequentlyAskedQuestionsSummary';
import CategoriesPanel from 'components/sections/CategoriesPanel';
import TrustPilotSummary from 'components/sections/TrustPilotSummary';
import HeroCategoriesPanel from 'components/sections/HeroCategoriesPanel';
import PagePromoRenderer from 'components/PagePromoRenderer';
import GiftCardHomepagePromo from 'components/promos/GiftCardHomepagePromo';
import B2BPromo from 'components/promos/B2BPromo';
import ProductInfo from 'components/sections/ProductInfo';
import ValentinesDay from 'components/promos/ValentinesDay';

import BlackFridayHomepagePromo from 'components/promos/BlackFridayHomepagePromo';
import Easter from 'components/promos/Easter';

import ClassroomExplainer from 'components/sections/ClassroomExplainer';
import { useConfiguration } from 'hooks/configuration';
import { useBlackFriday } from 'hooks/useBlackFriday';
import { useChristmas } from 'hooks/useChristmas';
import { useEaster } from 'hooks/useEaster';
import { useSpringSale } from 'hooks/useSpringSale';
import SpringSaleHomepagePromo from 'components/promos/SpringSaleHomepagePromo';
import NoBullshitPromo from '../sections/NoBullshitPromo';

const HOME_QUERY = gql`
  query HomeQuery {
    navigation {
      id
      schools {
        id
        name
        slug
        path
        tutors(limit: 1, starred: true) {
          id
          ...tutor
        }
      }
      ...CategoriesPanel__schools
      promos {
        id
        ...PagePromoRenderer__promo
      }
    }
    ...HomepageFeaturedProducts__products
    promotions {
      ...ValentinesDay__promotions
    }
  }
  ${PagePromoRenderer.fragments.promo}
  ${TutorSpotlight.fragments.tutor}
  ${HomepageFeaturedProducts.fragments.products}
  ${CategoriesPanel.fragments.schools}
  ${ValentinesDay.fragments.promotions}
`;

const Home = () => {
  const { data } = useQuery(HOME_QUERY, { ssr: true });
  const { settings } = useConfiguration();
  const { showBlackFriday } = useBlackFriday();
  const { showChristmas } = useChristmas();
  const { showEaster } = useEaster();
  const { showSpringSale } = useSpringSale();

  if (!data) return <Page />;

  const schemaData = {
    '@context': 'https://schema.org/',
    '@type': 'WebSite',
    name: 'Learning with Experts',
    url: `${settings.frontendUrl}`,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${settings.frontendUrl}/search?term={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  };

  const {
    featuredProducts,
    navigation: { schools, promos },
    promotions: { valentines },
  } = data;

  const hasHeaderPromo = promos.some(promo => promo.position === 'TOP');

  const showAarPromo = true;
  const showPromotion = showBlackFriday || showChristmas || showEaster || showSpringSale;
  const isValentines = !!valentines;
  const takeover = isValentines || showPromotion || showSpringSale;

  return (
    <Page id="home">
      <Helmet
        title="Online Courses Taught by Experts"
        meta={[
          {
            name: 'description',
            content:
              'Learning with Experts offer a range of courses across gardening, photography and more, delivered online so you can learn when you like. Visit us.',
          },
        ]}
      />
      <JsonLd data={schemaData} />
      {showSpringSale && <SpringSaleHomepagePromo />}
      {isValentines && <ValentinesDay />}
      {hasHeaderPromo && (
        <PagePromoRenderer position={PagePromoRenderer.TOP} promos={promos} name="Homepage" />
      )}

      {showBlackFriday && <BlackFridayHomepagePromo />}
      {!hasHeaderPromo && <HeroCategoriesPanel schools={schools} />}

      {showEaster && <Easter />}
      {showChristmas && <GiftCardHomepagePromo />}
      {!showChristmas && <TrustPilotSummary />}
      {hasHeaderPromo && <CategoriesPanel schools={schools} />}
      <HomepageFeaturedProducts products={featuredProducts} id="homepage-featured-products" />

      {showAarPromo ? (
        <NoBullshitPromo />
        ):(
        <PagePromoRenderer position={PagePromoRenderer.MIDDLE} promos={promos} name="Homepage" />
      )}

      {!showChristmas && <GiftCardHomepagePromo />}
      {!showPromotion && <ClassroomExplainer />}
      {showPromotion && <ProductInfo />}
      <B2BPromo />
      <FrequentlyAskedQuestionsSummary />
    </Page>
  );
};

export default Home;
