import React from 'react';
import cx from 'classnames';
import s from './style.module.css';

const RemoveButton = ({ className, ...props }) => {
  return (
    <button className={cx(s.root, className)} {...props}>
      x
    </button>
  );
};

export default RemoveButton;
