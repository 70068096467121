import React from 'react';
import { Link } from 'react-router-v3';
import { useBasket } from 'hooks/basket';
import s from './style.module.css';

const BasketButton = () => {
  const [basket] = useBasket();
  const count = basket?.items?.length;
  const displayCount = count !== 0 && count && (count > 9 ? '9+' : count);

  return (
    <Link to="/basket" className={s.root}>
      {displayCount}
    </Link>
  );
};

export default BasketButton;
