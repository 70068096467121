import React from 'react';
import Section from 'components/Section';
import s from './style.module.css';

const Header = () => {
  return (
    <Section className={s.top}>
      <h1>Our experts</h1>
      <h2>Meet our hand-picked, world class experts</h2>
    </Section>
  );
};

export default Header;
