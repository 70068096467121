import React from 'react';
import cx from 'classnames';
import Section from 'components/Section';
import { SectionHeading } from 'components/Elements';
import { Button } from 'components/Elements';
import s from './style.module.css';

const GridSection = ({
  heading,
  white = false,
  id = null,
  className,
  children,
  onToggleMore,
  showingMore,
  ...props
}) => {
  return (
    <Section className={cx(s.root, className)} {...props} id={id}>
      {heading && (
        <SectionHeading centered white={white}>
          {heading}
        </SectionHeading>
      )}
      {children}
      {onToggleMore && (
        <Button onClick={onToggleMore}>{showingMore ? 'Show less' : 'Show more'}</Button>
      )}
    </Section>
  );
};

export default GridSection;
