import React, { useState } from 'react';
import styled from 'styled-components';
import { gql } from '@apollo/client';
import JsonLd from 'components/JsonLd';
import BrandLogo from 'components/BrandLogo';
import { assetUrl } from 'components/AssetImage';
import { Section } from 'components/Layout';
import VariantSelector from './components/VariantSelector';
import ProductVideoPlayer from 'components/ProductVideoPlayer';
import ProductPosterImage from 'components/ProductPosterImage';
import TrustPilotQuote from 'components/TrustPilotQuote';
import CollectionStats from 'components/CollectionStats';
import { font, typeset, Body } from 'components/Typography';

const StyledSection = styled(({ className, ...props }) => (
  <Section containerClassName={className} {...props} />
))`
  background-color: ${({ theme }) => theme.schools.current.primary};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 0 0 0 0;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row-reverse;
    align-items: stretch;
  }
`;

const StyledBody = styled(Body).attrs({ desktop: font.book.group(3), hd: font.book.group(5) })`
  margin: 6px 0 5px 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 4px 0;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    margin: 2px 0 10px 0;
  }
`;

const BaseTitle = typeset({
  mobile: font.bold.group(6),
  desktop: font.medium.group(7),
  hd: font.medium.group(9),
  as: 'h1',
});

const Title = styled(BaseTitle)`
  margin: 0 0 18px 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 5px 0;
    flex-grow: 1;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 12px 0;
  padding: 0 18px 7px 18px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 35%;
    margin: 12px 0;
    padding: 0 25px;
    flex: 0 1 auto;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    padding: 0 39px;
  }
`;

const StyledVideo = styled.div`
  position: relative;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    align-self: center;
    width: 75%;
  }
`;

const ProductHeader = ({ product }) => {
  const [hide, setHide] = useState(false);

  if (!product) return null;

  const handlePreviewClick = () => setHide(true);

  const {
    title,
    tutor,
    markedBy,
    trailerHlsUrl,
    availableOn,
    nestedProducts,
    quote,
    publishedOn,
    trailerSynopsis,
    files,
    files: { logo },
    availableToBuy,
  } = product;

  const distinctById = (curr, idx, arr) => arr.map((t) => t.id).indexOf(curr.id) === idx;
  const uniqueTutors = tutor ? [tutor] : nestedProducts.map((p) => p.tutor).filter(distinctById);
  const hasMultipleTutors = uniqueTutors.length > 1;
  const hasManyTutors = uniqueTutors.length > 2;
  const tutorOrMarkedByNames = tutor
    ? [markedBy || tutor.profile.name]
    : [...new Set(nestedProducts.map((p) => p.markedBy || p.tutor.profile.name))];

  const poster = files.poster ? files.poster : files.header;
  const thumbnailUrl = poster && assetUrl(poster.uuid, 924, 527, 2);

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: title,
    description: trailerSynopsis,
    uploadDate: publishedOn,
    thumbnailUrl: thumbnailUrl,
    contentUrl: trailerHlsUrl,
  };

  return (
    <StyledSection wide>
      <Content key={product.id}>
        <Description>
          <StyledBody as="p" white book>
            {!hasMultipleTutors && <>{uniqueTutors[0].profile.name}</>}
            {hasMultipleTutors && <CollectionStats product={product} />}
          </StyledBody>
          <Title white>{title}</Title>
          {availableToBuy && (
            <>
              <VariantSelector
                product={product}
                hasMultipleTutors={hasMultipleTutors}
                hasManyTutors={hasManyTutors}
                tutorOrMarkedByNames={tutorOrMarkedByNames}
              />
            </>
          )}
        </Description>
        <StyledVideo>
          {trailerSynopsis && <JsonLd data={schemaData} />}
          {trailerHlsUrl && (
            <ProductVideoPlayer product={product} onClick={handlePreviewClick} showWatchPreview />
          )}
          {!trailerHlsUrl && <ProductPosterImage product={product} />}
          {quote && <TrustPilotQuote quote={quote} hide={hide} />}
          {logo && <BrandLogo logo={logo} hide={hide} />}
        </StyledVideo>
      </Content>
    </StyledSection>
  );
};

ProductHeader.fragments = {
  product: gql`
    fragment ProductHeader__product on Product {
      id
      title
      isBundle
      trailerHlsUrl
      availableOn
      publishedOn
      availableToBuy
      trailerSynopsis
      quote {
        id
        ...TrustPilotQuote__quote
      }
      ...VariantSelector__variants
      ...ProductVideoPlayer__product
      ...collectionStats
      tutor {
        id
        profile {
          id
          name
        }
      }
    }
    ${CollectionStats.fragments.product}
    ${TrustPilotQuote.fragments.quote}
    ${VariantSelector.fragments.variants}
    ${ProductVideoPlayer.fragments.product}
  `,
};

export default ProductHeader;
