import React from 'react';
import PropTypes from 'prop-types';
import { InputContainer, WithIcon } from '../../Layout';
import { BaseInput } from '../styles';

const TextInput = React.forwardRef((props, ref) => {
  return (
    <InputContainer {...props}>
      <BaseInput ref={ref} {...props} />
    </InputContainer>
  );
});

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  message: PropTypes.string,
  help: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextInput;

export const TextInputWithLeftIcon = ({ icon, ...props }) => {
  return (
    <InputContainer {...props}>
      <WithIcon left>
        <span>{icon}</span>
        <BaseInput {...props} />
      </WithIcon>
    </InputContainer>
  );
};

TextInputWithLeftIcon.propTypes = {
  ...TextInput.propTypes,
  icon: PropTypes.element,
};

export const TextInputWithRightIcon = ({ icon, ...props }) => {
  return (
    <InputContainer {...props}>
      <WithIcon right>
        <span>{icon}</span>
        <BaseInput {...props} />
      </WithIcon>
    </InputContainer>
  );
};

TextInputWithRightIcon.propTypes = TextInputWithLeftIcon.propTypes;
