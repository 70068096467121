import React, { useEffect, useState } from 'react';
import useClientOnly from 'hooks/useClientOnly';
import styled, { css } from 'styled-components';
import { font, typeset } from 'components/Typography';

const BaseItem = typeset({
  mobile: font.book.group(6),
  desktop: font.book.group(6),
  as: 'span',
});

const List = styled.ol`
  color: ${({ theme }) => theme.colors.global.white};
  background-color: #252525;
  align-items: center;
  display: none;
  @media (min-width: 500px) {
    display: flex;
  }
`;

const Item = styled(BaseItem)`
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.global.white};
  text-transform: capitalize;
  line-height: 12px;
  &:not(:first-child):before {
    font-weight: bold;
    content: ':';
    margin: 0 8px;
  }
`;

const Value = styled.span`
  color: ${({ theme }) => theme.colors.global.white};
  margin: 0 0 0 0;
`;

const Unit = styled.span`
  margin: 0 0 0 3px;
`;

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const format = (value, pad) => ((pad ? '00' : '') + Math.max(value, 0)).slice(-2);
const pluralize = (word, value) => `${word}${value !== 1 ? 's' : ''}`;

const BlackFridayTimer = ({ expiresAt = '11-23-2020 12:00', inline }) => {
  const renderTimer = useClientOnly();
  const [tick, setTick] = useState(0);
  const difference = new Date(expiresAt) - new Date();
  const finished = difference < 0;

  useEffect(() => {
    const timer = setTimeout(() => setTick(tick + 1), MINUTE);
    if (finished) clearTimeout(timer);
    return () => clearTimeout(timer);
  }, [tick]);

  const days = Math.floor(difference / DAY);
  const hours = Math.floor((difference / HOUR) % 24);
  const minutes = Math.floor((difference / SECOND) % 60);

  if (!renderTimer || finished) return null;

  return (
    <List inline={inline}>
      <Item>
        <Value>{format(days)}</Value>
        <Unit>{pluralize('day', days)}</Unit>
      </Item>
      <Item>
        <Value>{format(hours)}</Value>
        <Unit>{pluralize('hour', hours)}</Unit>
      </Item>
      <Item>
        <Value>{format(minutes)}</Value>
        <Unit>{pluralize('minute', minutes)}</Unit>
      </Item>
    </List>
  );
};

export default BlackFridayTimer;
