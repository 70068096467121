import React from 'react';
import PacketGrid from 'components/PacketGrid';
import BlogPostPacket from 'components/BlogPostPacket';

const BlogPostPacketGrid = ({ posts, school, list, ...props }) => {
  return (
    <PacketGrid items={posts} {...props}>
      {(post) => <BlogPostPacket post={post} school={school} />}
    </PacketGrid>
  );
};

export default BlogPostPacketGrid;
