import React, { useEffect, useCallback, useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import cx from 'classnames';
import Footer from './components/Footer';
import Header from 'components/Header';
import { DIALOGS } from 'components/Dialogs';
import Dialogs from 'components/Dialogs';
import useDialog from 'hooks/useDialog';
import { useCurrentUser } from '@lwe/toolkit/authentication';
import usePrevious from 'hooks/usePrevious';
import { useBlackFriday } from 'hooks/useBlackFriday';
import { useSpringSale } from 'hooks/useSpringSale';
import { useSummerSale } from 'hooks/useSummerSale';
import { PageContainer } from './styles';
import loadable from '@loadable/component';
import env from '@lwe/toolkit/env';
import BlackFridaySiteWidePromo from 'components/promos/BlackFridaySiteWidePromo';
import ValentinesSiteWidePromo from 'components/promos/ValentinesSiteWidePromo';
import SpringSaleSiteWidePromo from 'components/promos/SpringSaleSiteWidePromo';
import SummerSaleSiteWidePromo from 'components/promos/SummerSaleSiteWidePromo';

import Shell from 'components/Shell';

const TimeTravel = loadable(() => import('./components/TimeTravel'));

const SCHOOLS_QUERY = gql`
  query SchoolQuerys($schoolSlug: String) {
    schools(onlyPublished: true) {
      id
      slug
      name
    }
    school(slug: $schoolSlug) {
      id
      slug
      name
    }
    promotions {
      valentines: group(name: valentines) {
        endsAt
      }
    }
  }
`;

const nonProduction = env('ENVIRONMENT') !== 'production';

const App = ({ params: { schoolSlug }, authToken, ...props }) => {
  const { currentUser } = useCurrentUser();
  const { data, startPolling, stopPolling, refetch } = useQuery(SCHOOLS_QUERY, {
    variables: { schoolSlug },
    ssr: true,
  });
  const { openDialog: dialogOpener } = useDialog();
  const { showBlackFridaySiteWideHeader } = useBlackFriday();
  const { showSpringSale } = useSpringSale();
  const { showSummerSale } = useSummerSale();
  const { location, children, routes, router } = props;
  const previousUser = usePrevious(currentUser);

  const app = useMemo(() => routes.some((route) => route.app), [routes]);
  const secure = useMemo(() => routes.some((route) => route.secure), [routes]);

  useEffect(() => {
    startPolling(1000 * 60 * 5); // check feature flags every 5 minutes
    return () => stopPolling();
  }, []);

  useEffect(() => {
    if (currentUser || previousUser) {
      requestAnimationFrame(refetch);
    }
  }, [currentUser]);

  const showLogin = useCallback(() => dialogOpener(DIALOGS.AUTH, 'login'), [dialogOpener]);
  const showRegister = useCallback(
    () => dialogOpener(DIALOGS.AUTH, 'registration'),
    [dialogOpener],
  );
  const showHowItWorks = useCallback(() => dialogOpener(DIALOGS.HOWITWORKS), [dialogOpener]);

  if (!data) return null;

  const {
    schools,
    school,
    promotions: { valentines },
  } = data;
  const showValentinesSiteWideHeader = !!valentines;
  const showSiteWide = showBlackFridaySiteWideHeader || showValentinesSiteWideHeader || showSpringSale || showSummerSale;

  if (!schools) return null;

  const visitJoinPage = (schoolSlug) => {
    props.router.push(`/${schoolSlug}/join`);
  };

  return (
    <Shell location={location}>
      {showBlackFridaySiteWideHeader && <BlackFridaySiteWidePromo />}
      {showValentinesSiteWideHeader && <ValentinesSiteWidePromo />}
      {showSpringSale && <SpringSaleSiteWidePromo />}
      {showSummerSale && <SummerSaleSiteWidePromo />}
      <Dialogs schools={schools} location={location} school={school} schoolSlug={schoolSlug} />
      {nonProduction && <TimeTravel />}
      <Header
        user={currentUser}
        showLogin={showLogin}
        showRegister={showRegister}
        school={school}
        secure={secure}
        offsetHeader={showSiteWide}
      />
      <PageContainer className={cx(school && school.slug)} offsetHeader={showSiteWide}>
        {children}
        <Footer
          school={school}
          schools={schools}
          showRegister={showRegister}
          showHowItWorks={showHowItWorks}
          visitJoinPage={visitJoinPage}
          app={app}
        />
      </PageContainer>
    </Shell>
  );
};

export default App;
