import React from 'react';

const ExpertIcon = () => {
  return (
    <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <circle fill="#FFF" cx="16" cy="16" r="15" />
        <path d="M16 0C7.162 0 0 7.162 0 16c0 8.838 7.162 16 16 16 8.838 0 16-7.162 16-16 0-8.838-7.162-16-16-16zm5.95 24.376L16 21.257l-5.95 3.119 1.155-6.643-4.795-4.621 6.643-.982L16 6.122l2.947 6.006 6.643.984-4.795 4.62 1.155 6.644z" />
      </g>
    </svg>
  );
};

export default ExpertIcon;
