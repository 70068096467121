import React from 'react';
import { gql } from '@apollo/client';
import AssetImage from 'components/AssetImage';
import Corner from 'components/atoms/Corner';
import { Link, withRouter } from 'react-router-v3';
import moment from 'moment';
import cx from 'classnames';
import s from './style.module.css';

const BlogPostPacket = ({ post, school, large = false, maxWidth = true, router }) => {
  const handleClick = () => router.push(`/${school.slug}/blog/${post.slug}`);
  const isPublished = post.publishedAt && moment(post.publishedAt).isBefore();
  return (
    <article
      className={cx(s.root, { [s.large]: large, [s.maxWidth]: maxWidth })}
      onClick={handleClick}
    >
      {!isPublished && <Corner primary />}
      {post.files && post.files.header && (
        <AssetImage
          uuid={post.files.header.uuid}
          width={large ? 608 : 295}
          height={large ? 386 : 181}
        />
      )}
      <h2>
        <Link to={`/${school.slug}/blog/${post.slug}`}>{post.title}</Link>
      </h2>
      {large && <p>{post.synopsis}</p>}
      <span>
        {post.author && post.author.profile && <>By {post.author.profile.name} • </>}
        {post.publishedAt && <>{moment(post.publishedAt).format('MMMM Do, YYYY')}</>}
      </span>
    </article>
  );
};

BlogPostPacket.fragments = {
  post: gql`
    fragment post on BlogPost {
      id
      slug
      title
      synopsis
      publishedAt
      author {
        id
        profile {
          id
          name
        }
      }
      files {
        header {
          id
          uuid
        }
      }
    }
  `,
};

export default withRouter(BlogPostPacket);
