import React from 'react';
import AssetImage from 'components/AssetImage';
import styled, { css } from 'styled-components';

const Figure = styled.figure`
  position: absolute;
  z-index: 1;
  right: 40px;
  bottom: 30px;
  width: 250px;
  margin: 0;
  padding: 0;
  display: none;
  transition: opacity 0.5s ease-in;
  pointer-events: none;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: block;
  }

  ${({ hide }) =>
    hide &&
    css`
      opacity: 0;
    `}
`;

const BrandLogo = ({ logo, hide }) => {
  return (
    <Figure hide={hide}>
      <AssetImage uuid={logo.uuid} width={250} />
    </Figure>
  );
};

export default BrandLogo;
