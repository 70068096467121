import React from 'react';
import { gql } from '@apollo/client';
import s from './style.module.css';

const SchoolList = ({ renderItem, schools }) => {
  return (
    <ul className={s.root}>
      {schools.map((school) => {
        return <li key={school.id}>{renderItem(school)}</li>;
      })}
    </ul>
  );
};

SchoolList.fragments = {
  schools: gql`
    fragment SchoolList_schools on Navigation {
      id
      schools {
        id
        slug
        name
      }
    }
  `,
};

export default SchoolList;
