import React from 'react';
import SearchResult, { Heading, Subheading, Description, Tags, Tag } from 'components/SearchResult';
import ProductCard from 'components/ProductCard';
import ProductThumbnail from 'components/ProductThumbnail';
import { gql } from '@apollo/client';

const ProductSearchResult = ({
  result,
  result: { id, title, slug, school, tutor, shortDescription },
}) => {
  return (
    <SearchResult
      to={`/${school.slug}/courses/${slug}`}
      preview={<ProductThumbnail product={result} />}
    >
      <Heading>{title}</Heading>
      {tutor && <Subheading>Taught by {tutor.profile.name}</Subheading>}
      {shortDescription && <Description>{shortDescription}</Description>}
      <Tags>
        <Tag>Course</Tag>
        <Tag className={school.slug}>{school.name}</Tag>
      </Tags>
    </SearchResult>
  );
};

ProductSearchResult.fragments = {
  result: gql`
    fragment ProductSearchResult_result on Product {
      id
      title
      slug
      shortDescription
      tutor {
        id
        profile {
          id
          name
        }
      }
      school {
        id
        slug
        name
      }
      ...ProductCard__product
    }
    ${ProductCard.fragments.product}
  `,
};

export default ProductSearchResult;
