import styled, { css } from 'styled-components';
import { font } from 'components/Typography';

export const BaseLabel = styled.label`
  ${font.book.group(1)}
  color: ${({ theme }) => theme.colors.global.grey2};
  display: block;
  margin: 0 0 8px 0;

  ${({ largeLabel, theme }) =>
    largeLabel &&
    css`
      ${font.bold.group(4)}
      margin: 0 0 15px 0;
      color: ${theme.colors.global.grey1};

      @media ${theme.breakpoints.desktop} {
        ${font.medium.group(7)}
        margin: 0 0 10px 0;
      }
    `}
`;

export const BaseInput = styled.input.attrs(({ type }) => ({
  type: type || 'text',
}))`
  ${font.book.group(4)}
  border-radius: ${({ theme }) => theme.forms.inputRounded};
  line-height: 1;
  display: block;
  width: 100%;
  padding: 14px 14px 14px 14px;
  margin: 0 0 0 0;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.global.grey3};
  color: ${({ theme }) => theme.colors.global.grey1};
  background-color: ${({ theme }) => theme.colors.global.forms.inputBackground};
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: linear;

  &::placeholder {
    color: ${({ theme }) => theme.colors.global.grey2};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.global.forms.inputFocusBorder};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.colors.global.grey5};
      border-color: ${theme.colors.global.grey5};
      cursor: not-allowed;
    `}

  ${({ error, theme }) =>
    error &&
    css`
      border-color: ${theme.colors.alerts.error.color};
      background-color: ${theme.colors.alerts.error.background};
    `}
`;

export const BaseTextArea = styled.textarea`
  ${font.book.group(4)}
  border-radius: ${({ theme }) => theme.forms.inputRounded};
  padding: 12px 14px 12px 14px;
  margin: 8px 0 8px 0;
  border: 1px solid ${({ theme }) => theme.colors.global.grey3};
  color: ${({ theme }) => theme.colors.global.grey1};
  background-color: ${({ theme }) => theme.colors.global.forms.inputBackground};
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: linear;

  &::placeholder {
    color: ${({ theme }) => theme.colors.global.grey2};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.global.forms.inputFocusBorder};
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.colors.global.grey5};
      border-color: ${theme.colors.global.grey5};
      cursor: not-allowed;
    `}

  ${({ error, theme }) =>
    error &&
    css`
      border-color: ${theme.colors.alerts.error.color};
      background-color: ${theme.colors.alerts.error.background};
    `}
`;

export const BaseRadio = styled.input.attrs({ type: 'radio' })`
  appearance: none; /* Terribly supported, but maybe one day */
  background: none;
  opacity: 0.00001;
  z-index: 2;
  border: 1px solid;
  height: 1.125em;
  left: 0.125em;
  position: absolute;
  top: 0.825em;
  width: 1.125em;
  border-radius: 100%;
  content: ' ';
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

  &:checked ~ ${BaseLabel}::before {
    border-color: transparent;
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.global.grey1};
  }

  &.error ~ ${BaseLabel}::before, &:checked.error ~ ${BaseLabel}::before {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.alerts.error.color};
  }

  &:focus ~ ${BaseLabel}::before {
    border-color: transparent;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.global.forms.inputFocusBorder};
  }

  &:checked ~ ${BaseLabel}::after {
    /* Checked: indicator surround colour */
    border-color: ${({ theme }) => theme.colors.global.white};
    border-width: 4px;
    /* Checked: indicator colour */
    box-shadow: inset 0 0 0 5px ${({ theme }) => theme.colors.global.grey1};
  }

  &[disabled] ~ ${BaseLabel} {
    color: ${({ theme }) => theme.colors.global.grey3};
    cursor: not-allowed;
  }
`;

export const BaseCheckbox = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  margin-left: -2em;
  margin-top: 0.75em;
  opacity: 0.00001;
  position: relative;
  vertical-align: top;
  z-index: 2;
  height: 1.125em;
  left: 0.125em;
  width: 1.125em;

  &:checked ~ ${BaseLabel}::before {
    border-color: transparent;
    background-color: ${({ theme }) => theme.colors.global.grey2};
  }

  &.error ~ ${BaseLabel}::before, &:checked.error ~ ${BaseLabel}::before {
    color: ${({ theme }) => theme.colors.alerts.error.color};
    background-color: ${({ theme }) => theme.colors.alerts.error.background};
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.alerts.error.color};
  }

  &:focus ~ ${BaseLabel}::before {
    border-color: transparent;
    box-shadow: 0 0 0 3px #228bec;
    outline-offset: 2px;
    outline: 2px solid transparent;
  }

  &:checked ~ ${BaseLabel}::after {
    transform: rotate(45deg) scale(1);
  }

  &.error ~ ${BaseLabel}::after, &:checked.error ~ ${BaseLabel}::after {
    color: ${({ theme }) => theme.colors.alerts.error.color};
  }

  &[disabled] ~ ${BaseLabel} {
    color: ${({ theme }) => theme.colors.global.grey3};
    cursor: not-allowed;
  }
`;
