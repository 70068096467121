import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import PropTypes from 'prop-types';

import s from './style.module.css';

const YouTubePlayer = ({ trailerYoutubeId, onReady, play }) => {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (player && play) {
      player.playVideo();
    }
  }, [player, play]);

  if (!trailerYoutubeId) return null;

  const playerReady = (event) => {
    onReady();
    setPlayer(event.target);
  };

  const opts = {
    playerVars: {
      showinfo: 0,
      modestbranding: 1,
      rel: 0,
      playsinline: 1,
    },
  };

  return (
    <YouTube className={s.root} videoId={trailerYoutubeId} opts={opts} onReady={playerReady} />
  );
};

YouTubePlayer.propTypes = {
  trailerYoutubeId: PropTypes.string,
  onReady: PropTypes.func.isRequired,
};

export default YouTubePlayer;
