import React from 'react';
import { Link as BaseLink } from 'react-router-v3';
import styled, { css } from 'styled-components';
import { font, typeset } from 'components/Typography';
import { useSpringSale } from 'hooks/useSpringSale';

const icon = require('./assets/heart.svg');

const BaseHeadline = typeset({
  mobile: font.medium.group(4),
  desktop: font.medium.group(5),
  as: 'p',
});

const Icon = styled.img`
  width: 26px;
  margin-right: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 0;
  background: ${({ theme }) => theme.schools.antiques.primary};
  color: ${({ theme }) => theme.colors.global.white};
  height: 50px;
  width: 100%;
  z-index: 12;
  position: fixed;
`;

const Link = styled(BaseLink)`
  padding: 16px 0 16px 0;
  text-decoration: none;
  display: flex;
  flex: 1 0 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Headline = styled(BaseHeadline)`
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: -0.5px;
`;

const SpringSaleSiteWidePromo = () => {
  const { saleUpToPercentage } = useSpringSale();
  return (
    <Content>
      <Link to="/gardening">
        <Icon alt="" src={icon} />
        <Headline as="p" white>
          Gardening Spring Sale - save {saleUpToPercentage}%
        </Headline>
      </Link>
    </Content>
  );
};

export default SpringSaleSiteWidePromo;
