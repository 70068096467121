import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-v3';
import styled, { css } from 'styled-components';
import { useBlackFriday } from 'hooks/useBlackFriday';
import { Section } from 'components/Layout';
import { font, typeset } from 'components/Typography';
import { PrimaryButton } from 'components/Button';
import BlackFridayTitle from 'components/BlackFridayTitle';

const BaseTitle = typeset({
  mobile: font.bold.group(10),
  desktop: font.bold.group(12),
  hd: font.bold.group(12),
  as: 'h1',
});

const BaseSubtitle = typeset({
  mobile: font.medium.group(7),
  desktop: font.medium.group(9),
  as: 'h2',
});

const StyledSection = styled(({ className, ...props }) => (
  <Section containerClassName={className} {...props} />
))`
  background-color: #252525;
  padding: 60px 15px 60px 15px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 130px 0 130px 0;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    padding: 157px 0;
  }
`;

const Content = styled.div`
  padding: 0 0 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
    max-width: ${({ vertical }) => (vertical ? '1024px' : '900px')};
  }
`;

const Header = styled(BaseTitle)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: 10px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    align-items: flex-start;
    flex: 0 0 55%;
    align-items: center;
    text-align: ${({ vertical }) => (vertical ? 'center' : 'left')};
    line-height: ${({ vertical }) => (vertical ? '83px' : '78px')};
    margin: 0;
  }
`;

const CallToAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: ${({ vertical }) => (vertical ? 'auto' : '235px')};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    align-items: ${({ vertical }) => (vertical ? 'center' : 'flex-start')};
    text-align: ${({ vertical }) => (vertical ? 'center' : 'left')};
  }
`;

const Subtitle = styled(BaseSubtitle)`
  margin: 0px 0 20px 0;
  line-height: 30px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    line-height: 40px;
    margin-top: ${({ vertical }) => (vertical ? '10px' : '0px')};
    margin-bottom: ${({ vertical }) => (vertical ? '35px' : '28px')};
  }
`;

const BlackFridayHomepagePromo = () => {
  const {
    showBlackFriday,
    blackFridayFinalWeekend,
    blackFridayEndsTomorrow,
    blackFridayEndsToday,
  } = useBlackFriday();
  const vertical = Boolean(blackFridayFinalWeekend) || Boolean(blackFridayEndsToday);

  return (
    <StyledSection wide>
      <Content vertical={vertical}>
        <Header vertical={vertical}>
          {!blackFridayFinalWeekend && !blackFridayEndsToday && showBlackFriday && (
            <BlackFridayTitle title="Black Friday" suffix="Sale" animated />
          )}
          {blackFridayFinalWeekend && <BlackFridayTitle title="Final Weekend" animated />}
          {blackFridayEndsToday && <BlackFridayTitle title="Final Day" animated />}
        </Header>
        <CallToAction vertical={vertical}>
          {!blackFridayFinalWeekend &&
            !blackFridayEndsTomorrow &&
            !blackFridayEndsToday &&
            showBlackFriday && (
              <Subtitle as="p" white vertical={vertical}>
                Online courses on sale now!
              </Subtitle>
            )}
          {blackFridayFinalWeekend && (
            <Subtitle as="p" white vertical={vertical}>
              Black Friday Sale Ends Midnight 30th November
            </Subtitle>
          )}
          {blackFridayEndsTomorrow && (
            <Subtitle as="p" white vertical={vertical}>
              Ends Midnight Tomorrow
            </Subtitle>
          )}
          {blackFridayEndsToday && (
            <Subtitle as="p" white vertical={vertical}>
              Black Friday Sales Ends Midnight Tonight
            </Subtitle>
          )}
          <PrimaryButton large inverse as={Link} to="/blackfriday">
            Shop Offers
          </PrimaryButton>
        </CallToAction>
      </Content>
    </StyledSection>
  );
};

export default BlackFridayHomepagePromo;
