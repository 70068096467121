import React from 'react';
import Section from 'components/Section';
import { SectionHeading } from 'components/Elements';
import s from './style.module.css';

const LatestQuestionAnswer = ({ questionAnswer, expert }) =>
  questionAnswer && (
    <Section className={s.root}>
      <SectionHeading centered>Latest community Q&A</SectionHeading>
      <dl>
        <dt>
          <div dangerouslySetInnerHTML={{ __html: questionAnswer.questionBody }} />
          <span>Posted by {questionAnswer.questionAuthor}</span>
        </dt>
        <dd>
          <div dangerouslySetInnerHTML={{ __html: questionAnswer.answerBody }} />
          <span>Posted by {questionAnswer.answerAuthor}</span>
        </dd>
      </dl>
    </Section>
  );

export default LatestQuestionAnswer;
