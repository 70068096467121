import { gql } from '@apollo/client';

export const user = gql`
  fragment Avatar__user on User {
    id
    online @client
    profile {
      id
      name
      avatar {
        id
        uuid
      }
    }
  }
`;
