import React from 'react';
import styled from 'styled-components';
import Form, { EmailField, InputGroup, toFormErrors } from 'components/Form';
import { Content as BaseContent } from 'components/Layout';
import { PrimaryButton } from 'components/Button';
import ErrorList from 'components/ErrorList';
import { font, Title } from 'components/Typography';
import { useResetPasswordRequest } from '@lwe/toolkit/authentication';

const Link = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

const SmallLink = styled(Link)`
  ${font.book.group(2)}
`;

const Content = styled(BaseContent)`
  margin: 20px auto 20px auto;
`;

const Submitted = styled.div`
  ${font.book.group(4)}
  @media (max-width: 600px) {
    ${font.book.group(3)};
  }

  background-color: ${({ theme }) => theme.colors.global.grey6};
  margin: 15px 0 30px 0;
  padding: 15px;
  border-radius: 3px;

  & > p {
    margin: 10px 0;
  }
`;

const ForgottenPasswordForm = ({ onSuccess, onRequestLogin }) => {
  const resetPasswordRequest = useResetPasswordRequest();
  const [submitted, setSubmitted] = React.useState(false);

  const onSubmit = async ({ email }) => {
    if (!email) return toFormErrors('Please enter your email address');
    try {
      const { protocol, pathname, hostname, port: p } = window.location;
      const port = ['443', '80'].includes(p) ? '' : `:${p}`;

      const domain = `${protocol}//${hostname}${port}`;
      const params = new URLSearchParams();
      params.set('returnTo', `${domain}${pathname}`);

      const rt = `${domain}/auth/reset?${params.toString()}`;

      await resetPasswordRequest(email, rt);
      setSubmitted(true);
    } catch (e) {
      return toFormErrors(e);
    }
  };

  const onResetPasswordReset = () => {
    setSubmitted(false);
  };

  const onSignIn = () => {
    onSuccess();
  };

  return (
    <Content>
      {!submitted && (
        <>
          <Title as="h1">Forgotten password?</Title>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitError }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {submitError && <ErrorList errors={submitError} />}
                  <EmailField id="email" name="email" label="Email address" />
                  <InputGroup tight>
                    <PrimaryButton fullWidth>Request a new password</PrimaryButton>
                  </InputGroup>
                  <InputGroup collapsed>
                    {onRequestLogin && (
                      <SmallLink onClick={onRequestLogin}>Already have a password?</SmallLink>
                    )}
                  </InputGroup>
                </form>
              );
            }}
          />
        </>
      )}
      {submitted && (
        <>
          <Title as="h1">Password reset requested</Title>
          <Submitted>
            <p>
              We've sent you a reset password email. (If you haven't received it after a few minutes
              then check your spam folder).
            </p>
            <p>
              If you have your password, you can <Link onClick={onSignIn}>sign in</Link> now.
              Otherwise, you can <Link onClick={onResetPasswordReset}>request a new password</Link>.
            </p>
          </Submitted>
        </>
      )}
    </Content>
  );
};

export default ForgottenPasswordForm;
