import React from 'react';
import { gql } from '@apollo/client';
import Spinner from 'react-tiny-spin';
import loadable from '@loadable/component';
import s from './style.module.css';

const LazyVideoPlayer = loadable(
  () => import(/* webpackChunkName: "player" */ 'components/VideoPlayer'),
  {
    fallback: (
      <div className={s.root}>
        <Spinner
          config={{
            width: 1,
            length: 4,
            radius: 4,
            color: 'rgb(255,255,255)',
          }}
        />
      </div>
    ),
  },
);

LazyVideoPlayer.fragments = {
  video: gql`
    fragment VideoPlayer__video on Video {
      id
      streamUrl
      storyboardUrl
    }
  `,
};

export default LazyVideoPlayer;
