import React from 'react';
import { gql } from '@apollo/client';
import Carousel from 'components/Carousel';
import MediaQuery from 'components/Responsive';
import Avatar from 'components/Avatar';
import { Link } from 'react-router-v3';
import s from './style.module.css';
import chunk from 'lodash/array/chunk';

const ExpertList = ({ tutors, hideSchool = false }) => {
  return (
    <MediaQuery query="(min-width: 1024px)">
      {(matches) => {
        const Tag = matches ? 'div' : 'section';
        const count = matches ? 5 : 1;
        return (
          <Tag>
            <Carousel className={s.root} list inside dots="white" cellAlign="center" wrapAround>
              {chunk(tutors, count).map((strip, idx) => (
                <section className={s.strip} key={`strip-${idx}`}>
                  {strip.map((user) => {
                    const { profile: { avatar, name } = {}, path, school } = user;
                    return (
                      <Link to={path} key={path}>
                        <figure>
                          <Avatar user={user} size={138} />
                        </figure>
                        <h4>
                          {name}
                          {!hideSchool && school && <span>{school.name}</span>}
                        </h4>
                      </Link>
                    );
                  })}
                </section>
              ))}
            </Carousel>
          </Tag>
        );
      }}
    </MediaQuery>
  );
};

ExpertList.fragments = {
  tutor: gql`
    fragment tutor on User {
      id
      path
      profile {
        id
        name
        avatar {
          id
          uuid
        }
      }
      school {
        id
        slug
        name
      }
    }
  `,
};

export default ExpertList;
