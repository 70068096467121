import styled from 'styled-components';

import { Section as BaseSection } from 'components/Layout';

export const Section = styled(BaseSection)`
  padding: 30px 15px 30px 15px !important;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 50px 15px 50px 15px !important;
  }

  & > .header {
    width: 100%;
    max-width: 345px;

    h2 {
      color: white;
      text-transform: lowercase;

      &::first-letter {
        text-transform: capitalize;
      }
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      max-width: 915px;
      width: 915px;
      margin-bottom: 10px;
    }
  }

  & > ul {
    margin: 20px 0 10px 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      width: 930px;
      flex-direction: row;
      justify-content: center;
    }

    & > li {
      position: relative;

      display: block;
      width: 345px;
      height: 190px;
      margin: 0 0 20px 0;

      background: ${({ color }) => color};

      @media ${({ theme }) => theme.breakpoints.desktop} {
        width: 290px;
        height: 160px;
        margin: 0 10px 20px 10px;
      }
    }
  }

  &.single ul {
    justify-content: center;
  }

  & > a {
    width: initial;
  }
`;
