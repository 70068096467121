import React from 'react';
import cx from 'classnames';
import s from './style.module.css';

const Benefits = ({ className, benefits, ...props }) => {
  return (
    <ul className={cx(s.root, className)} {...props}>
      {benefits.filter(Boolean).map((benefit) => (
        <li key={benefit}>
          <p>{benefit}</p>
        </li>
      ))}
    </ul>
  );
};

export default Benefits;
