import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { font } from 'components/Typography';

const facebookIcon = require('./assets/facebook-white.svg');
const submittingWhite = require('./assets/submitting-white.svg');
const submittingBlack = require('./assets/submitting-black.svg');

// TODO
// - icon variant

const Button = styled.button.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['small', 'large', 'inverse'].includes(prop) && defaultValidatorFn(prop),
})`
  ${font.medium.group(4)}
  line-height: 1;
  padding: 15px 20px 15px 20px;
  outline: none;
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.global.grey1};
  border: 1px solid ${({ theme }) => theme.colors.global.grey1};
  color: ${({ theme }) => theme.colors.global.white};
  border-radius: ${({ theme }) => theme.forms.buttonRounded};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-property: color, border-color, background-color, box-shadow, background-image;
  transition-duration: 0.1s;
  transition-timing-function: linear;

  ${({ small, theme }) =>
    small &&
    css`
      ${font.medium.group(1)}
      padding: 3px 14px 3px 14px;
      border-radius: ${theme.forms.buttonRoundedSmall};
    `}

  ${({ large, theme }) =>
    large &&
    css`
      ${font.medium.group(7)}
      padding: 19px 25px 19px 25px;
      border-radius: ${theme.forms.buttonRoundedLarge};
    `}
  
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.global.grey2};
    border-color: ${({ theme }) => theme.colors.global.grey2};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.global.grey1};
    border-color: ${({ theme }) => theme.colors.global.grey1};
    box-shadow: 0 0 4px ${({ theme }) => theme.colors.global.grey2};
  }

  ${({ inverse, theme }) =>
    inverse &&
    css`
      background-color: ${theme.colors.global.white};
      border: 1px solid ${theme.colors.global.white};
      color: ${theme.colors.global.grey1};

      &:hover,
      &:focus {
        background-color: ${theme.colors.global.grey4};
        border-color: ${theme.colors.global.grey4};
      }

      &:active {
        background-color: ${theme.colors.global.white};
        border-color: ${theme.colors.global.white};
        box-shadow: 0 0 4px ${theme.colors.global.white};
      }
    `}

  ${({ submitting }) =>
    submitting &&
    css`
      background-repeat: no-repeat;
      background-size: 65%;
      background-position: center center;
      color: transparent;
      pointer-events: none;
      background-image: url(${submittingWhite});

      & > img {
        visibility: hidden;
      }

      ${({ inverse }) =>
        inverse &&
        css`
          background-image: url(${submittingBlack});
        `}

      ${({ fullWidth }) =>
        fullWidth &&
        css`
          background-size: 12%;
        `}
    `}
  
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  
  ${({ secondary, theme }) =>
    secondary &&
    css`
      background-color: transparent;
      border: 1px solid ${theme.colors.global.grey1};
      color: ${theme.colors.global.grey1};

      &:hover,
      &:focus {
        background-color: ${theme.colors.global.grey4};
        border-color: ${theme.colors.global.grey1};
        color: ${theme.colors.global.grey1};
      }

      &:active {
        background-color: ${theme.colors.global.white};
        box-shadow: 0 0 4px ${theme.colors.global.grey2};
      }

      ${({ inverse, theme }) =>
        inverse &&
        css`
          border: 1px solid ${theme.colors.global.white};
          color: ${theme.colors.global.white};

          &:hover,
          &:focus {
            background-color: ${theme.colors.global.secondaryButtonInverse};
            border-color: ${theme.colors.global.white};
            color: ${theme.colors.global.white};
          }

          &:active {
            background-color: transparent;
            box-shadow: 0 0 4px ${theme.colors.global.white};
          }
        `}

      ${({ submitting }) =>
        submitting &&
        css`
          background-repeat: no-repeat;
          background-size: 65%;
          background-position: center center;
          color: transparent;
          pointer-events: none;
          background-image: url(${submittingBlack});

          & > img {
            visibility: hidden;
          }

          ${({ inverse }) =>
            inverse &&
            css`
              background-image: url(${submittingWhite});
            `}

          ${({ fullWidth }) =>
            fullWidth &&
            css`
              background-size: 12%;
            `}
        `}
    `}

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.colors.global.grey4};
      border: 1px solid ${theme.colors.global.grey4};
      color: ${theme.colors.global.grey2};
      cursor: not-allowed;

      &:hover,
      &:focus {
        background-color: ${theme.colors.global.grey4};
        border-color: ${theme.colors.global.grey4};
        color: ${theme.colors.global.grey2};
      }
    `}
`;

Button.propTypes = {
  small: PropTypes.bool,
  large: PropTypes.bool,
  inverse: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  submitting: PropTypes.bool,
};

export const FacebookButton = styled(Button).attrs({ type: 'button' })`
  background-color: #3b5998;
  border-color: #3b5998;
  color: ${({ theme }) => theme.colors.global.white};
  padding-top: 12px;
  padding-bottom: 12px;

  &:before {
    content: '';
    background-image: url(${facebookIcon});
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }

  &:hover {
    background-color: #547dd6;
    border-color: #547dd6;
  }
`;

export default Button;
export { Button as PrimaryButton };
export const SecondaryButton = styled(Button).attrs({ secondary: true })``;
