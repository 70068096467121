import React, { useState } from 'react';
import { Link } from 'react-router-v3';
import { gql } from '@apollo/client';
import { Section as BaseSection, Content as BaseContent } from 'components/Layout';
import ProductVideoPlayer from 'components/ProductVideoPlayer';
import ProductPosterImage from 'components/ProductPosterImage';
import TrustPilotQuote from 'components/TrustPilotQuote';
import { SchoolThemeProvider } from 'components/ThemeProvider';
import BrandLogo from 'components/BrandLogo';
import Tag from 'components/atoms/Tag';
import { PrimaryButton } from 'components/Button';
import s from './style.module.css';
import cx from 'classnames';
import Icon from './components/Icon';
import { typeset, font } from 'components/Typography';
import styled, { css } from 'styled-components';

const Section = styled(BaseSection)`
  ${({ $background }) =>
    !$background &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
    `}
  ${({ $background, $inverted }) =>
    $background &&
    $inverted &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
    `}
`;

const Content = styled(BaseContent)`
  background-color: ${({ theme }) => theme.colors.primary};
`;

const PointerLink = styled(Link)`
  cursor: pointer;
`;

export const Header = typeset({
  mobile: font.medium.group(8),
  desktop: font.medium.group(9),
});

export const Body = typeset({
  mobile: font.medium.group(4),
  desktop: font.medium.group(2),
});

const ProductStandoutSection = ({ promo, onClick }) => {
  const {
    heading,
    subheading,
    icon,
    product,
    quote,
    flipped,
    constrained,
    invert,
    tag,
    image,
    usePosterImage,
  } = promo;
  const [hide, setHide] = useState(false);
  const logo = product?.files?.logo;
  const handlePreviewClick = () => setHide(true);

  return (
    <SchoolThemeProvider school={product.school}>
      <Section
        wide
        containerClassName={cx(s.container, { [s.background]: constrained, [s.inverted]: invert })}
        className={cx(s.root, product.school.slug)}
        school={!constrained && product.school.slug}
        color={constrained && 'light'}
        $background={constrained}
        $inverted={invert}
      >
        <Content
          className={cx(s.content, {
            [s.flip]: flipped,
            [s.constrained]: constrained,
            [s.hasIcon]: icon,
          })}
        >
          <div className={s.description}>
            <Icon name={icon} />
            {tag && (
              <Tag primary={invert} className={s.tag}>
                {tag}
              </Tag>
            )}
            <Header as="h2" white={!invert} hd={font.medium.group(constrained ? 9 : 10)}>
              {heading}
            </Header>
            <Body as="p" white={!invert} hd={font.medium.group(constrained ? 2 : 5)}>
              {subheading}
            </Body>
            {product.slug && (
              <PrimaryButton
                as={Link}
                to={`/${product.school.slug}/courses/${product.slug}`}
                inverse={!invert}
                onClick={onClick}
              >
                View course
              </PrimaryButton>
            )}
          </div>
          <div className={s.videocitation}>
            {!usePosterImage && (
              <ProductVideoPlayer product={product} image={image} onClick={handlePreviewClick} showWatchPreview />
            )}
            {usePosterImage && (
              <PointerLink to={`/${product.school.slug}/courses/${product.slug}`}>
                <ProductPosterImage product={product} />
              </PointerLink>
            )}
            {quote && <TrustPilotQuote className={s.quote} quote={quote} hide={hide} />}
            {logo && <BrandLogo className={s.logo} logo={logo} hide={hide} />}
          </div>
        </Content>
      </Section>
    </SchoolThemeProvider>
  );
};

ProductStandoutSection.fragments = {
  promo: gql`
    fragment ProductStandoutSection__promo on PagePromo {
      id
      heading
      subheading
      flipped
      constrained
      tag
      usePosterImage
      image {
        id
        uuid
      }
      product {
        id
        slug
        school {
          id
          slug
          name
        }
        files {
          logo {
            id
            uuid
          }
        }
        ...ProductVideoPlayer__product
      }
      ... on ProductStandoutPromo {
        icon
        quote {
          id
          ...TrustPilotQuote__quote
        }
      }
    }
    ${TrustPilotQuote.fragments.quote}
    ${ProductVideoPlayer.fragments.product}
  `,
};

export default ProductStandoutSection;
