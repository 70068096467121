import React from 'react';
import { ThemeProvider } from 'styled-components';
import Helmet from 'react-helmet';
import { useConfiguration } from 'hooks/configuration';
import GlobalStyle from './styles';

import { usePartnerTheme } from '@lwe/toolkit/utils';

export const theme = {
  schools: {
    gardening: {
      primary: '#878D06',
      tint1: '#C3C682',
      tint2: '#E7E8CD',
    },
    antiques: {
      primary: '#C9463D',
      tint1: '#E4A29E',
      tint2: '#F4DAD8',
    },
    foodanddrink: {
      primary: '#D89206',
      tint1: '#EBC882',
      tint2: '#F7E9CD',
    },
    jewellery: {
      primary: '#563476',
      tint1: '#AA99BA',
      tint2: '#DDD6E3',
    },
    photography: {
      primary: '#00697b',
      tint1: '#99C3CA',
      tint2: '#CCE1E4',
    },
    floristry: {
      primary: '#D15A80',
      tint1: '#E8ACBF',
      tint2: '#F5DEE5',
    },
    wellbeing: {
      primary: '#0080B8',
      tint1: '#88C7E2',
      tint2: '#CBE4EF',
    },
    artanddesign: {
      primary: '#00A585',
      tint1: '#99DBCE',
      tint2: '#CCEDE6',
    },
    ['the-university-of-buckingham']: {
      primary: '#100b72',
      tint1: '#100b72',
      tint2: '#100b72',
    },
    goldsmiths: {
      primary: '#4d4d4c',
      tint1: '#4d4d4c',
      tint2: '#4d4d4c',
    },
    ['optimal-ageing']: {
      primary: '#002147',
      tint1: '#002147',
      tint2: '#002147',
    },
  },
  colors: {
    primary: '#4A4A4A',
    tint1: 'blue',
    tint2: 'green',
    global: {
      black: '#000000',
      grey1: '#4A4A4A',
      grey2: '#808080',
      grey3: '#B6B6B6',
      grey4: '#E4E4E4',
      grey5: '#ECECEC',
      grey6: '#F8F8F8',
      white: '#FFFFFF',
      quoteText: '#FFFFFF',
      darkBackground: '#4A4A4A',
      lightBackground: '#f8f8f8',
      secondaryButtonInverse: 'rgba(255, 255, 255, .12)',
      forms: {
        inputBorder: '#B6B6B6',
        inputBackground: '#FFFFFF',
        inputFocusBorder: '#538DFF',
        inputDisabledBackground: '#E4E4E4',
        inputDisabledBorder: '#E4E4E4',
      },
    },
    alerts: {
      info: { color: '#35419E', background: '#daddf7' },
      success: { color: '#00A042', background: '#d9f1e3' },
      warning: { color: '#D39F00', background: '#f9f1d9' },
      error: { color: '#CB3A3A', background: '#f7e2e2' },
    },
  },
  typography: {
    weights: {
      book: '400',
      medium: '500',
      bold: '700',
    },
    groups: {
      1: { fontSize: 12, lineHeight: 18 },
      2: { fontSize: 14, lineHeight: 20 },
      3: { fontSize: 15, lineHeight: 22 },
      4: { fontSize: 16, lineHeight: 22 },
      5: { fontSize: 18, lineHeight: 24 },
      6: { fontSize: 20, lineHeight: 24 },
      7: { fontSize: 24, lineHeight: 32 },
      8: { fontSize: 28, lineHeight: 32 },
      9: { fontSize: 36, lineHeight: 42 },
      10: { fontSize: 46, lineHeight: 56 },
      11: { fontSize: 64, lineHeight: 74 },
      12: { fontSize: 96, lineHeight: 102 },
    },
  },
  forms: {
    inputRounded: '4px',
    buttonRoundedSmall: '3px',
    buttonRounded: '4px',
    buttonRoundedLarge: '5px',
  },
  breakpoints: {
    mobile: '(min-width: 950px)',
    desktop: '(min-width: 1024px)',
    hd: '(min-width: 1280px)',
  },
  header: {
    color: '#4a4a4a',
    backgroundColor: '#FFFFFF',
  },
  footer: {
    color: '#bcbcbc',
    backgroundColor: '#4a4a4a',
  },
  logo: {
    mobile: {
      image: require('./assets/lwe-logo-mobile.svg'),
      width: 36,
      height: 36,
    },
    desktop: {
      image: require('./assets/lwe-logo.svg'),
      width: 283,
      height: 35,
    },
  },
};

const themeWithCurrentSchool = (school) => (theme) => {
  if (!school) {
    return theme;
  }

  return {
    ...theme,
    schools: {
      ...theme.schools,
      current: theme.schools[school.slug],
      currentSlug: school.slug,
    },
    colors: {
      ...theme.colors,
      ...theme.schools[school.slug],
    },
  };
};

export const SchoolThemeProvider = ({ school, ...props }) => {
  const theme = themeWithCurrentSchool(school);
  return <ThemeProvider theme={theme} {...props} />;
};

const Provider = ({ children }) => {
  // NOTE: This has to stay for some reason as it fails elsewhere.
  const configuration = useConfiguration();
  const partnerTheme = usePartnerTheme();

  if (!configuration) return null;
  if (!partnerTheme) return null;

  const classroomHeader = partnerTheme?.global?.colors?.['classroom-header'];

  // NOTE: This is a big hacky, but it's to avoid using the gradient in the LWE header
  const headerBackground = classroomHeader?.includes('linear-gradient')
    ? '#FFFFFF'
    : classroomHeader;

  const mergedTheme = {
    ...theme,
    header: {
      ...theme.header,
      backgroundColor: headerBackground,
    },
    colors: {
      ...theme.colors,
      primary: partnerTheme?.global?.colors?.brand,
    },
    typography: {
      ...theme.typography,
      stylesheet: partnerTheme?.global?.font?.links,
      fonts: {
        primary: partnerTheme?.global?.font?.family,
      },
    },
    logo: {
      mobile: {
        ...theme?.logo?.mobile,
        uuid: partnerTheme?.global?.logo?.web,
      },
      desktop: {
        ...theme?.logo?.desktop,
        uuid: partnerTheme?.global?.logo?.web,
      },
    },
  };

  return (
    <ThemeProvider theme={mergedTheme}>
      <GlobalStyle />
      <Helmet>
        {mergedTheme?.typography?.stylesheet?.map((item, index) => (
          <link key={index} {...item} />
        ))}
      </Helmet>
      {children}
    </ThemeProvider>
  );
};

export default Provider;
