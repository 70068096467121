import React from 'react';
import Accordian from 'components/atoms/Accordian';
import SlateBody from 'components/SlateBody';
import { Body, Subtitle } from 'components/Typography';
import cx from 'classnames';
import s from './style.module.css';

const Header = ({ showNumbers, number, title, white, aside = null }) => (
  <div className={cx(s.header, { [s.showNumbers]: showNumbers })}>
    <Subtitle white={white} as="h3" data-number={number}>
      {title}
    </Subtitle>
    {aside}
  </div>
);

const Copy = (props) => {
  return <Body as="div" className={s.body} smooth {...props} />;
};

const AccordianList = ({ entries = [], showNumbers = true, white }) => {
  return (
    <ul className={s.root}>
      {entries.map((entry, idx) => {
        const { id, open, description, slateDescription } = entry;
        const isSlate = Array.isArray(slateDescription) && slateDescription.length > 0;
        return (
          <li key={id}>
            <Accordian
              white={white}
              open={open}
              header={
                <Header white={white} number={idx + 1} showNumbers={showNumbers} {...entry} />
              }
            >
              {isSlate ? (
                <SlateBody className={s.body} body={slateDescription} />
              ) : (
                <Copy white={white} dangerouslySetInnerHTML={{ __html: description }} />
              )}
            </Accordian>
          </li>
        );
      })}
    </ul>
  );
};

export default AccordianList;
