import React from 'react';
import Section from 'components/Section';
import { Title, Body, Subtitle, Small } from 'components/Typography';
import AccordianList from 'components/AccordianList';
import s from './style.module.css';
import { SectionHeading } from 'components/Elements';
import { gql } from '@apollo/client';

const Stats = () => {
  return (
    <div className={s.stats}>
      <Small>30mins Video</Small>
      <Small>1 hr Assignment</Small>
    </div>
  );
};

const CourseOutline = ({ product, showStats }) => {
  if (!product || !product.lessons) return null;

  const lessons = product.lessons.map((lesson, i) => {
    return {
      ...lesson,
      open: i === 0,
    };
  });

  return (
    <Section className={s.root}>
      <SectionHeading>Course outline</SectionHeading>
      <AccordianList entries={lessons} />
    </Section>
  );
};

CourseOutline.propTypes = {};

CourseOutline.fragments = {
  product: gql`
    fragment productOutline on Product {
      id
      lessons {
        id
        title
        description
        slateDescription
        assignmentTitle
        assignmentDescription
        slateAssignmentDescription
      }
    }
  `,
};

export default CourseOutline;
