import React from 'react';
import { Link as BaseLink } from 'react-router-v3';
import styled from 'styled-components';
import { Subtitle as BaseSubtitle } from 'components/Typography';

const Subtitle = styled(BaseSubtitle)`
  font-weight: 300;
  & > span {
    display: none;
    @media ${({ theme }) => theme.breakpoints.desktop} {
      display: inline;
    }
  }
  & > strong {
    font-weight: 500;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 0;
  color: ${({ theme }) => theme.colors.global.white};
  background-color: #c9463d;
  height: 50px;
  width: 100%;
  z-index: 12;
  position: fixed;
`;

const Link = styled(BaseLink)`
  padding: 16px 0 16px 0;
  text-decoration: none;
  display: flex;
  flex: 1 0 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > *:nth-child(2) {
    margin-left: 10px;
    display: none;
    @media (min-width: 600px) {
      // margin-right: 30px;
      display: flex;
    }
  }
`;

const ValentinesSiteWidePromo = () => {
  return (
    <Content>
      <Link to="/valentines">
        <Subtitle white>
          Give the love of learning <span>on Valentine's Day</span> and <strong>get 15% OFF</strong>
        </Subtitle>
      </Link>
    </Content>
  );
};

export default ValentinesSiteWidePromo;
