import React from 'react';
import { Link } from 'components/Elements';
import useClickOutside from 'click-outside-hook';
import { isAdmin, isTutor, isModerator, isObserver } from 'utils/roles';
import { persistBasketToken } from 'hooks/basket';
import s from './style.module.css';
import { useConfiguration } from 'hooks/configuration';
import { useLegacyLogoutAndRedirect } from 'hooks/user';
import { toLocalId } from '@lwe/toolkit/utils';
import { withRouter } from 'react-router-v3';
import { useHistory } from 'react-router';

const UserMenu = ({ router, user, closeNav }) => {
  const { features } = useConfiguration();
  const logout = useLegacyLogoutAndRedirect(router);
  const ref = useClickOutside(closeNav);
  const history = useHistory();

  const handleLogOut = () => {
    closeNav();
    logout();
    persistBasketToken(undefined);
  };

  const handleNavigation = (e) => {
    history.push(e.target.getAttribute('href'));
    closeNav();
  };

  const hasDashboardAccess =
    isTutor(user) || isAdmin(user) || isModerator(user) || isObserver(user);

  return (
    <ul className={s.root} ref={ref} data-cy="menu-user">
      {user.profile && <li className={s.username}>{user.profile.name}</li>}
      {hasDashboardAccess && (
        <>
          <li>
            <Link onClick={handleNavigation} to="/classrooms/dashboard">
              Tutor Dashboard
            </Link>
          </li>
          {isAdmin(user) && (
            <li>
              <Link onClick={handleNavigation} to="/classrooms/admin">
                Administration
              </Link>
            </li>
          )}
        </>
      )}
      <li>
        <Link onClick={handleNavigation} to="/classrooms">
          My Courses
        </Link>
      </li>
      <li>
        <Link onClick={handleNavigation} to={`/profile/${toLocalId(user.id)}`}>
          Manage Account
        </Link>
      </li>
      {features.giftcards && (
        <li>
          <Link onClick={handleNavigation} to="/gifts">
            Redeem a gift
          </Link>
        </li>
      )}
      <li>
        <Link onClick={handleLogOut} data-cy="sign-out">
          Sign Out
        </Link>
      </li>
    </ul>
  );
};

export default withRouter(UserMenu);
