import React from 'react';
import { Content } from 'components/Layout';

const PasswordResetSent = ({ showForgotten }) => {
  return (
    <Content>
      <h2>Check your email</h2>
      <p>
        We've sent you an email to confirm your email address, once you get the email click the link
        to set your new password.
      </p>
      <p>
        Not got the email? <a onClick={showForgotten}>Request another</a>.
      </p>
    </Content>
  );
};

export default PasswordResetSent;
