export const CustomerSupportAPI = (action, ...args) => {
  if (window.Beacon) {
    switch (action) {
      case 'openArticle': {
        window.Beacon('article', args[0], { type: 'modal' });
        break;
      }
      case 'showNewMessage': {
        window.Beacon('navigate', '/ask/chat/');
        window.Beacon('prefill', { text: args[0] });
        window.Beacon('open');
        break;
      }
      case 'showNewMessageWithSubject': {
        window.Beacon('navigate', '/ask/chat/');
        window.Beacon('prefill', { subject: args[0], text: args[1] });
        window.Beacon('open');
        break;
      }
      case 'hide': {
        window.Beacon('close');
        break;
      }
    }
  }
};
