import React from 'react';
import TrustPilot from 'components/TrustPilot';
import s from './style.module.css';

const TrustPilotSummary = () => {
  return (
    <section className={s.root}>
      <TrustPilot height={20} templateId="5419b6ffb0d04a076446a9af" />
    </section>
  );
};

export default TrustPilotSummary;
