import React from 'react';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import { Link } from 'react-router-v3';
import { Title, Subtitle, Body, font } from 'components/Typography';
import { Content } from 'components/Layout';
import { PrimaryButton } from 'components/Button';
import ProductCard from 'components/ProductCard';

const StyledContent = styled(Content)`
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin-bottom: 50px;
    flex-direction: row;
  }

  & > article {
    align-self: center;
    margin: 0 15px 0 15px;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      align-self: flex-start;
      margin: 0 0 0 0;
    }
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 18px;
  width: 100%;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 0;
    margin-left: 26px;
  }

  & > a {
    margin-top: 18px;
    order: -1;
    align-self: center;

    &:first-of-type {
      margin-top: 18px;
    }

    &:last-of-type {
      margin-bottom: 25px;
    }

    @media ${({ theme }) => theme.breakpoints.desktop} {
      margin: 20px 0 0 0;
      order: 0;
      align-self: flex-start;

      &:first-of-type {
        margin-top: 30px;
      }
    }
  }
`;

const StyledTitle = styled(Title).attrs({
  mobile: font.bold.group(4),
  desktop: font.medium.group(9),
  as: 'h3',
})`
  margin-bottom: 5px;
`;

const StyledSubtitle = styled(Subtitle).attrs({
  as: 'h4',
})`
  margin-bottom: 5px;
`;

const StyledBody = styled(Body).attrs({
  mobile: font.book.group(4),
  desktop: font.book.group(2),
  as: 'p',
})`
  width: 100%;
`;

const SingleProduct = ({ product, darkText }) => {
  const { slug, title, shortDescription, school, tutor } = product;

  return (
    <StyledContent>
      <ProductCard forceLarge product={product} />
      <Description>
        <StyledTitle white={!darkText}>{title}</StyledTitle>
        {tutor && <StyledSubtitle white={!darkText}>Taught by {tutor.profile.name}</StyledSubtitle>}
        <StyledBody white={!darkText}>{shortDescription}</StyledBody>
        <PrimaryButton as={Link} to={`/${school.slug}/courses/${slug}`} inverse={!darkText}>
          View Course
        </PrimaryButton>
      </Description>
    </StyledContent>
  );
};

SingleProduct.fragments = {
  product: gql`
    fragment SingleProduct__product on Product {
      id
      slug
      title
      shortDescription
      isBundle
      tutor {
        id
        profile {
          id
          name
        }
      }
      school {
        id
        name
        slug
      }
      ...ProductCard__product
    }
    ${ProductCard.fragments.product}
  `,
};

export default SingleProduct;
