import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import { useEventBus } from '@lwe/toolkit/utils';
import { BLOG_POST_SIGNUP } from '@root/events';

import { Body } from 'components/Typography';
import { Section } from 'components/Layout';
import { SectionHeading } from 'components/Elements';
import { PrimaryButton } from 'components/Button';
import ErrorList from 'components/ErrorList';
import Form, { EmailField, toFormErrors } from 'components/Form';
import { InputGroup } from 'components/Form/Layout';
import s from './style.module.css';
import cx from 'classnames';

const posters = {
  antiques: require('./assets/poster-antiques.png'),
  floristry: require('./assets/poster-floristry.png'),
  foodanddrink: require('./assets/poster-foodanddrink.png'),
  gardening: require('./assets/poster-gardening.png'),
  jewellery: require('./assets/poster-jewellery.png'),
  photography: require('./assets/poster-photography.png'),
  wellbeing: require('./assets/poster-wellbeing.png'),
  artanddesign: require('./assets/poster-artanddesign.png'),
};

const CREATE_UNREGISTERED_USER_MUTATION = gql`
  mutation CreateUnregisteredUser($email: String!, $signupType: SignupType!, $schoolSlug: String) {
    createUnregisteredUser(email: $email, signupType: $signupType, schoolSlug: $schoolSlug) {
      id
    }
  }
`;

const MailingListSignUp = ({ school }) => {
  const [subscribed, setSubscribed] = useState(false);
  const [createUnregisteredUser] = useMutation(CREATE_UNREGISTERED_USER_MUTATION);
  const eventBus = useEventBus();

  const onSubmit = async ({ email }) => {
    try {
      const variables = { email, schoolSlug: school.slug, signupType: 'BLOG' };
      await createUnregisteredUser({ variables });
      setSubscribed(true);
      eventBus.publish(BLOG_POST_SIGNUP, { school });
    } catch (e) {
      return toFormErrors(e);
    }
  };

  const poster = posters[school.slug];

  return (
    <Section className={cx(s.root)}>
      <div className={s.listform}>
        <SectionHeading as="h2">
          {subscribed ? 'Thanks for subscribing!' : 'Stay updated'}
        </SectionHeading>
        <Body as="p">
          {subscribed
            ? `Before you can start enjoying our emails you will need to confirm your subscription. Click the link in the email we've sent you.`
            : `Receive free updates by email including special offers and new courses.`}
        </Body>
        {!subscribed && (
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitError, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <InputGroup row collapsed noMargin alignTop>
                    <EmailField
                      required
                      name="email"
                      placeholder="Enter email address"
                      help="You can unsubscribe at any time"
                    />
                    <PrimaryButton type="submit" submitting={submitting}>
                      Subscribe
                    </PrimaryButton>
                  </InputGroup>
                  {submitError && <ErrorList errors={submitError} />}
                </form>
              );
            }}
          />
        )}
      </div>
      <div className={cx(s.poster, school.slug)}>
        <img src={poster} alt={school.name} />
      </div>
    </Section>
  );
};

MailingListSignUp.fragments = {
  school: gql`
    fragment MailingListSignUp__school on School {
      id
      slug
      name
    }
  `,
};

export default MailingListSignUp;
