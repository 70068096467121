import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { gql } from '@apollo/client';
import ProductPackage from 'components/ProductPackage';

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
    align-items: stretch;

    ${({ displayedOnItsOwn, theme }) =>
      !displayedOnItsOwn &&
      css`
        &:before {
          content: 'or';
          color: white;
          @mixin gotham-medium;
          font-size: 24px;
          display: block;
          width: 70px;
          text-align: center;
          order: 1;
          align-self: center;
          @media (min-width: 1280px) {
            width: 87px;
          }
        }
      `}
  }

  & > li {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      width: auto;
    }

    &:first-child {
      order: 0;
      margin-bottom: 20px;
      @media ${({ theme }) => theme.breakpoints.desktop} {
        margin-bottom: 0;
      }
    }
    &:last-child {
      order: 2;
    }
  }
`;

const sorter = (a) => (a.tag === 'tutor_access_all' ? -1 : 1);

const Selector = ({ product }) => {
  if (!product) return null;
  const variants = product.variants.filter((v) => v.tag !== 'tutor_access_1').sort(sorter);
  const displayedOnItsOwn = variants.length === 1;
  return (
    <List displayedOnItsOwn={displayedOnItsOwn}>
      {variants.map((variant, idx) => {
        return (
          <li key={variant.id}>
            <ProductPackage
              product={product}
              variant={variant}
              displayedOnItsOwn={displayedOnItsOwn}
            />
          </li>
        );
      })}
    </List>
  );
};

Selector.fragments = {
  product: gql`
    fragment productSelector on Product {
      id
      variants {
        id
        tag
        ...packageVariant
      }
      ...packageProduct
    }
    ${ProductPackage.fragments.product}
    ${ProductPackage.fragments.variant}
  `,
};

export default Selector;
