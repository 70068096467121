import React from 'react';
import styled, { css } from 'styled-components';
import { Link as BaseLink } from 'react-router-v3';
import { useConfiguration } from 'hooks/configuration';

const Link = styled(BaseLink)`
  display: inline-block;
  color: transparent;
  overflow: hidden;
  user-select: none;
  margin-left: 15px;

  ${({ theme }) => css`
    background: url(${({ theme }) => theme.logo.mobile.image}) no-repeat left center;
    background-size: ${theme.logo.mobile.width}px ${theme.logo.mobile.height}px;
    width: ${theme.logo.mobile.width}px;
    height: ${theme.logo.mobile.height}px;
  `}

  @media (min-width: 1000px) {
    ${({ theme }) => css`
      background: url(${({ theme }) => theme.logo.desktop.image}) no-repeat;
      background-size: ${theme.logo.desktop.width}px ${theme.logo.desktop.height}px;
      width: ${theme.logo.desktop.width}px;
      height: ${theme.logo.desktop.height}px;
    `}
    margin-left: 30px;
    margin-right: 45px;
  }


  @media (min-width: 950px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

const HomeLink = (props) => {
  const {
    settings: { title, parentUrl },
  } = useConfiguration();
  return (
    <Link to="/" {...props}>
      {title}
    </Link>
  );
};

export default HomeLink;
