import React, { useState } from 'react';
import { CardNumberElement } from 'react-stripe-elements';
import { InputContainer } from '../../Layout';
import { StripeFieldContainer, useStripeTheme } from '../../Helpers';
import styled from 'styled-components';
import cx from 'classnames';
import 'paymentfont/css/paymentfont.css';

const Brand = styled.span`
  color: ${({ theme }) => theme.colors.global.grey3};
  position: absolute;
  right: 9px;
  bottom: 8px;
  font-size: 23px;
`;

const cardBrandToPfClass = {
  visa: 'pf-visa',
  mastercard: 'pf-mastercard',
  amex: 'pf-american-express',
  discover: 'pf-discover',
  diners: 'pf-diners',
  jcb: 'pf-jcb',
  unknown: null,
};

const CardNumberInput = (props) => {
  const { id, name } = props;
  const theme = useStripeTheme();
  const [brand, setBrand] = useState(false);

  const handleChange = ({ brand }) => setBrand(brand);

  return (
    <InputContainer {...props}>
      <StripeFieldContainer>
        <CardNumberElement name={name} id={id} style={theme} onChange={handleChange} />
        {brand && <Brand className={cx('pf', cardBrandToPfClass[brand])}></Brand>}
      </StripeFieldContainer>
    </InputContainer>
  );
};

export default CardNumberInput;
