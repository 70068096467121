import React, { useContext } from 'react';
import Section from 'components/Section';
import styled from 'styled-components';
import { ThemeContext, Button } from 'grommet';
import { Badge } from '@lwe/toolkit/ui-library';
import { SectionHeading } from 'components/Elements';
import { CustomerSupportAPI } from 'utils/support';
import { useEventBus } from '@lwe/toolkit/utils';
import { ENTERPRISE_SALE_ENQUIRY } from '@root/events';
import { darken } from 'polished';

const Container = styled(Section)`
  background-color: ${(props) => props.colour};
  text-align: center;
  padding: 8px 0 24px 0;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 16px 0 48px 0;
  }
`;

const Heading = styled(SectionHeading)`
  color: white;
  display: flex;
  justify-content: center;
  && {
    margin-bottom: 4px;
  }
`;

const Text = styled.p`
  color: white;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  padding: 6px 10px;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 0 0 4px 0;
    margin: 0;
    font-size: 15px;
    line-height: 22px;
  }
`;

const EnterpriseSales = ({ product }) => {
  const theme = useContext(ThemeContext);
  const eventBus = useEventBus();

  const handleAskQuestion = () => {
    eventBus.publish(ENTERPRISE_SALE_ENQUIRY, product);
    CustomerSupportAPI('showNewMessageWithSubject', `${product.title} for business or group`);
  };

  const primary = theme?.schools?.[product.school.slug]?.primary;
  const darkPrimary = darken(0.075, primary);

  return (
    <Container colour={darkPrimary}>
      <div>
        <Heading>
          <Badge
            background={primary}
            color="white"
            label="New"
            size="medium"
            alignSelf="center"
            margin={{ right: 'xxsmall' }}
            uppercase={false}
          />
          Buy this course for a business or group
        </Heading>

        <Text>
          You can now buy this course through your business or organisation for individuals and
          groups up to 20
        </Text>

        <ThemeContext.Extend
          value={
            {
               button: {
                secondary: {
                  color: '#4A4A4A',
                  background: {
                    color: 'white',
                  }
                }
              }
            }
          }
        >
        <Button
          secondary
          size="small"
          label="Contact us"
          margin="xxsmall"
          onClick={handleAskQuestion}
          width="150px"
        />
        </ThemeContext.Extend>

      </div>
    </Container>
  );
};

export default EnterpriseSales;
