import React from 'react';
import { useQuery, gql } from '@apollo/client';
import Spinner from 'react-tiny-spin';
import { Section } from 'components/Layout';
import Results from './components/Results';
import NoResults from './components/NoResults';
import NoData from './components/NoData';
import Form from './components/Form';
import Page from 'components/Page';
import Helmet from 'react-helmet';
import queryString from 'query-string';
import s from './style.module.css';
import { useConfiguration } from 'hooks/configuration';

const SEARCH_QUERY = gql`
  query SearchQuery($term: String!, $page: Int) {
    search(term: $term, page: $page) {
      term
      pagination {
        totalCount
        totalPages
        currentPage
        nextPage
        prevPage
      }
      ...Results_results
    }
  }
  ${Results.fragments.results}
`;

const Search = ({
  router,
  location: {
    pathname,
    query: { term, page = 1 },
  },
}) => {
  page = parseInt(page, 10);
  const { settings } = useConfiguration();
  const { data, loading } = useQuery(SEARCH_QUERY, {
    variables: {
      term,
      page,
    },
    ssr: true,
    skip: !term || term === '',
  });

  const search = data && data.search;
  const Component = search ? (search.results.length ? Results : NoResults) : NoData;
  const showSpinner = loading && page === 1;
  const query = queryString.stringify({ term, page });

  return (
    <Page className={s.root}>
      <Helmet>
        <link rel="canonical" href={`${settings.frontendUrl}${pathname}?${query}`} />
      </Helmet>
      <Section wide>
        <Form router={router} term={term} />
      </Section>
      {showSpinner ? <Spinner /> : <Component search={search} />}
    </Page>
  );
};

export default Search;
