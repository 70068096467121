import React from 'react';
import cx from 'classnames';
import { gql } from '@apollo/client';
import AssetImage from 'components/AssetImage';
import { nameToColor } from 'utils/color';
import { user as Avatar__user } from './fragments';
import s from './style.module.css';

const Avatar = ({ className, size = 30, hideOnline, user, alt, ...props }) => {
  let avatarUrl = null;
  let avatarUUID = null;

  if (user && user.files && user.files.avatar) {
    // From the REST API (this should really be a square image, so for now it's deliberately larger than it needs to be)
    avatarUrl = `${user.files.avatar[0].urls.original}/600`;
  } else if (user && user.profile && user.profile.avatar) {
    // From GraphQL
    avatarUUID = user.profile.avatar.uuid;
  }
  const name =
    (user && user.profile && user.profile.name) || (user && `${user.firstName} ${user.lastName}`);
  const initials =
    name &&
    name
      .split(' ')
      .slice(0, 2)
      .map((n) => n[0])
      .join('');
  const { from, to, angle } = nameToColor(name);
  const fixedSize = {
    width: `${size}px`,
    height: `${size}px`,
    backgroundImage: `linear-gradient(${angle}deg, ${from} 0%, ${to} 100%)`,
    fontSize: size / 2.5,
    lineHeight: `${size / 2.5}px`,
  };
  return (
    <div
      style={fixedSize}
      className={cx(
        s.root,
        { [s.online]: user && user.online && !hideOnline && size < 70 },
        className,
      )}
      {...props}
    >
      {avatarUUID && (
        <AssetImage className={s.round} uuid={avatarUUID} width={size} height={size} alt={alt} />
      )}
      {avatarUrl && <div style={{ backgroundImage: `url(${avatarUrl})` }} className={s.image} />}
      {!avatarUUID && !avatarUrl && initials}
    </div>
  );
};

Avatar.fragments = {
  user: Avatar__user,
};

export default Avatar;
