import { useEffect } from 'react';
import { isSSR } from 'utils';
import useScript from 'react-script-hook';
import { ORDER_CREATED } from '@root/events';
import { useEventBusSubscription } from '@lwe/toolkit/utils';
import { toLocalId } from '@lwe/toolkit/utils';

const TAP_GLOBAL = '__tap';

if (!isSSR) {
  window.TapfiliateObject = TAP_GLOBAL;
  window[TAP_GLOBAL] =
    window[TAP_GLOBAL] ||
    function () {
      (window[TAP_GLOBAL].q = window[TAP_GLOBAL].q || []).push(arguments);
    };
}

const tap = (...args) => window[TAP_GLOBAL](...args);

const Tapfiliate = ({ id }) => {
  useEffect(() => {
    tap('create', id, { integration: 'javascript' });
    tap('detect');
  }, [id]);

  useScript({
    src: '//script.tapfiliate.com/tapfiliate.js',
    checkForExisting: true,
  });

  useEventBusSubscription(ORDER_CREATED, ({ detail: { order } }) => {
    const niftTransaction =
      order.currencyCode === 'USD' && order.promo && order.promoCode.code === 'NIFT25';
    const commissionTypeIdentifier = niftTransaction ? 'niftbounty' : '';
    tap(
      'conversion',
      order.id,
      order.totalGbp,
      { customer_id: toLocalId(order.user.id) },
      commissionTypeIdentifier,
    );
  });

  return null;
};

export default Tapfiliate;
