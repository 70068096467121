import React from 'react';
import { gql } from '@apollo/client';
import { useTransition, a } from 'react-spring';
import Courses from '../Courses';
import Experts from '../Experts';
import Search from '../Search';
import s from './style.module.css';

const COMPONENTS = {
  courses: Courses,
  experts: Experts,
  search: Search,
};

const Flyout = ({ tab, handleClose, navigation }) => {
  const transition = useTransition(tab, null, {
    from: { position: 'absolute', width: '100%', opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const transitions = transition.map(({ item, key, props }) => {
    const Component = COMPONENTS[item];
    if (!Component) return null;
    return (
      <a.div key={key} style={props}>
        <Component id={`${item}-nav`} handleClose={handleClose} navigation={navigation} />
      </a.div>
    );
  });

  return <div className={s.root}>{transitions}</div>;
};

Flyout.fragments = {
  navigation: gql`
    fragment Flyout_navigation on Navigation {
      id
      ...Courses_navigation
      ...Experts_navigation
    }
    ${Courses.fragments.navigation}
    ${Experts.fragments.navigation}
  `,
};

export default Flyout;
