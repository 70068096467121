import React from 'react';
import { Link } from 'react-router-v3';
import Section from 'components/Section';
import { SectionHeading } from 'components/Elements';
import ExpertList from 'components/ExpertList';
import s from './style.module.css';

const TutorSpotlight = ({ tutors, hideSchool = false, schoolName = '' }) =>
  tutors &&
  tutors.length > 0 && (
    <Section className={s.root} wide>
      <SectionHeading centered>
        <Link to="/experts">Our hand-picked, world class {schoolName.toLowerCase()} experts</Link>
      </SectionHeading>
      <ExpertList tutors={tutors} hideSchool={hideSchool} />
      <div className={s.more}>
        <Link to="/experts">Meet all of our experts</Link>
      </div>
    </Section>
  );

TutorSpotlight.fragments = ExpertList.fragments;

export default TutorSpotlight;
