import React from 'react';
import cx from 'classnames';
import Avatar from 'components/Avatar';
import { Headline } from 'components/Typography';
import { Link } from 'react-router-v3';
import s from './style.module.css';

const Product = ({ className, product: { slug, tutor, title, school }, ...props }) => {
  return (
    <Link to={`/${school.slug}/courses/${slug}`} className={cx(s.root, className)} {...props}>
      {tutor && <Avatar size={28} user={tutor} />}
      <Headline>{title}</Headline>
      {tutor && <Headline>Taught by {tutor.profile.name}</Headline>}
    </Link>
  );
};

export default Product;
