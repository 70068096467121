import React from 'react';
import styled from 'styled-components';
import { Section } from 'components/Layout';
import { SectionHeading } from 'components/Elements';
import { Medium } from 'components/Typography';
import Selector from './components/Selector';
import { DIALOGS } from 'components/Dialogs';
import GiftMessage from 'components/atoms/GiftMessage';
import EmbargoMessage from 'components/atoms/EmbargoMessage';
import KlarnaPromo from 'components/KlarnaPromo';
import useDialog from 'hooks/useDialog';

const StyledSection = styled(Section)`
  width: 100%;
  margin: 0 0 25px 0;
  position: relative;
`;

const OffsetAnchorTarget = styled.div`
  position: absolute;
  top: -160px;
`;

const ProductPackageChooser = ({ product }) => {
  const { openDialog } = useDialog();
  const handleHowItWorks = () => openDialog(DIALOGS.HOWGIFTINGWORKS);
  const hasMultipleVariants = product.variants.length > 1;
  const isFree = product.variants.every((v) => v.price.current === 0);
  const singleVariantMessage = isFree
    ? 'Start whenever you like for free'
    : 'Buy now, start anytime';
  return (
    <StyledSection primary>
      <OffsetAnchorTarget id="pricing" />
      <SectionHeading white>
        {hasMultipleVariants ? 'Choose how you want to learn' : singleVariantMessage}
      </SectionHeading>
      <Selector product={product} />
      {!isFree && <GiftMessage onHowItWorks={handleHowItWorks} />}
    </StyledSection>
  );
};

ProductPackageChooser.defaultProps = {
  openDialog: () => {},
};

ProductPackageChooser.fragments = {
  product: Selector.fragments.product,
};

export default ProductPackageChooser;
