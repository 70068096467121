import React from 'react';

const AssignmentIcon = () => {
  return (
    <svg viewBox="0 0 32 32" width="32" height="32" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.88.5c-.515 0-1.008.203-1.372.567L4.032 17.535c-.234.241-.4.538-.485.863L.639 29.054a1.937 1.937 0 002.38 2.378l10.66-2.906c.325-.084.623-.251.863-.485L31.02 11.573c.361-.37.56-.869.552-1.386A9.694 9.694 0 0021.88.5zM7.975 26.06A3.876 3.876 0 006 24.084l1.134-4.151a5.814 5.814 0 015.001 4.994L7.976 26.06z"
        fillRule="nonzero"
      />
    </svg>
  );
};

export default AssignmentIcon;
