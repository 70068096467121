import React from 'react';
import TrustPilotStaticQuote from 'components/TrustPilotStaticQuote';

const quotes = [
  {
    quote: "Rigorous and intelligent courses, superb customer care and support. I took the RHS level 2 course, enjoyed it immensely and remain grateful for the excellent content and lecturers, as well as the support guidance given to me.",
    author: 'Martine',
  },
  {
    quote: "I completed the RHS Level 2 certificate; the lessons and resources were detailed, engaging and well-thought out.",
    author: 'Joanna',
  },
  {
    quote: "Jack responded to any queries and concerns very promptly throughout the duration of the RHS course in a polite and helpful manner.",
    author: 'Joanna',
  },
  {
    quote: "I've just completed the RHS Level 2 Course and have really enjoyed it. The tutors are quick to reply and give excellent feedback to develop a wider understanding.",
    author: 'Kate',
  },
  {
    quote: "The fantastic RHS Level 2 gardening course greatly increased my knowledge and helped me pass my exams. The content is extensive, easy to follow and the questions reflect those in the exams.",
    author: 'Anna',
  },
  {
    quote: "Really enjoying this course- the material is covered in an interesting way with videos and regular assessments to check learning and prompt responses from expert tutors.",
    author: 'Annette',
  }
];

const RhsTrustPilotSummary = ({ large = true, color }) => {
  const quote = quotes[Math.random() * quotes.length | 0];

  return (
    <TrustPilotStaticQuote
      large={large}
      color={color}
      quote={quote.quote}
      author={quote.author}
    />
  );
};

export default RhsTrustPilotSummary;
