import React from 'react';
import PropTypes from 'prop-types';
import { SectionHeading } from 'components/Elements';
import { Content, Section } from 'components/Layout';
import { Body as BaseBody } from 'components/Typography';
import SlateBody from 'components/SlateBody';
import { gql } from '@apollo/client';
import styled from 'styled-components';
import s from './style.module.css';

const Body = styled(BaseBody).attrs({
  as: Content,
  smooth: true,
})`
  & > p {
    margin: 1em 0;
    &:first-child {
      margin: 0;
    }
  }

  & > ul,
  & > ol {
    margin: 12px 0 1.2em 18px;

    & > li {
      margin-bottom: 9px;
    }
  }

  & > ul {
    list-style-type: disc;
  }
  & > ol {
    list-style-type: decimal;
  }
`;

const CourseDescription = ({ product }) => {
  if (!product) return null;
  const { slateDescription, description, requirements } = product;
  return (
    <Section className={s.root}>
      <SectionHeading>Course Description</SectionHeading>
      {slateDescription ? (
        <SlateBody as={Body} body={slateDescription} />
      ) : (
        <Body dangerouslySetInnerHTML={{ __html: description }} />
      )}
      {requirements && (
        <>
          <SectionHeading>Requirements</SectionHeading>
          <Body dangerouslySetInnerHTML={{ __html: requirements }} />
        </>
      )}
    </Section>
  );
};

CourseDescription.fragments = {
  product: gql`
    fragment courseDescription on Product {
      id
      description
      slateDescription
      requirements
    }
  `,
};

CourseDescription.propTypes = {
  product: PropTypes.object.isRequired,
};

export default CourseDescription;
