import React from 'react';
import Dialog from 'components/Dialog';
import { CustomerSupportAPI } from 'utils/support';
import { PrimaryButton, SecondaryButton } from 'components/Button';
import { InputGroup } from 'components/Form';
import ExplainerSteps, { Step } from './components/ExplainerSteps';
import s from './style.module.css';

const handleAskQuestion = () => CustomerSupportAPI('showNewMessage');

const HowGiftingWorksDialog = ({ handleClose, ...props }) => {
  return (
    <Dialog title="How gifting works" handleClose={handleClose} {...props}>
      <section className={s.root}>
        <h1>It’s easy to give our courses as gifts</h1>
        <section>
          <section></section>
          <section>
            <ExplainerSteps>
              <Step name="basket" heading="Add any of our courses to your basket" />
              <Step name="check" heading="Check the gift option and personalise the gift card" />
              <Step name="card" heading="Complete your order" />
              <Step name="letter" heading="Receive your gift card by email" />
              <Step name="gift" heading="Print or send the gift card by email" />
            </ExplainerSteps>
            <InputGroup row>
              <SecondaryButton onClick={handleClose}>Close</SecondaryButton>
              <PrimaryButton onClick={handleAskQuestion}>Any questions?</PrimaryButton>
            </InputGroup>
          </section>
        </section>
      </section>
    </Dialog>
  );
};

export default HowGiftingWorksDialog;
