import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  vertical-align: middle;
  position: relative;
  top: ${({ offset }) => -offset || 0}px;
  @media (max-width: 950px) {
    width: ${({ mobileWidth }) => mobileWidth || 100}px;
  }
`;

export const BlackFriday = () => {
  return <>This Week's Extra Special Black Friday Offers</>;
};
