import React from 'react';
import { Section } from 'components/Layout';
import { Title, Body, Subtitle } from 'components/Typography';
import { Link } from 'react-router-v3';
import { useQuery, gql } from '@apollo/client';
import ProductCard from 'components/ProductCard';
import ProductCardGrid from 'components/ProductCardGrid';
import s from './style.module.css';

const NO_RESULTS_QUERY = gql`
  query NoResults {
    popularCourses: products(limit: 4, starred: true, onlyVisible: true) {
      id
      ...ProductCard__product
    }
    navigation {
      id
      schools {
        id
        name
        slug
      }
    }
  }
  ${ProductCard.fragments.product}
`;

const Subsection = ({ heading, children }) => {
  return (
    <Section wide className={s.subsection}>
      {heading && <Subtitle as="h3">{heading}</Subtitle>}
      {children}
    </Section>
  );
};

const Subsections = ({ data: { popularCourses, navigation } }) => {
  return (
    <>
      <Subsection heading="Popular courses">
        <ProductCardGrid products={popularCourses} coloured />
      </Subsection>
      <Subsection heading="Browse by category">
        <ul className={s.list}>
          {navigation.schools.map(({ id, name, slug }) => {
            return (
              <li key={id}>
                <Body to={`/${slug}`} as={Link}>
                  {name}
                </Body>
              </li>
            );
          })}
        </ul>
      </Subsection>
      <Subsection heading="Useful links">
        <ul className={s.list}>
          <li>
            <Body as="a" href="http://help.learningwithexperts.com/">
              Frequently asked questions
            </Body>
          </li>
          <li>
            <Body as={Link} to="/contact">
              Contact us
            </Body>
          </li>
          <li>
            <Body as={Link} to="/about">
              About us
            </Body>
          </li>
          <li>
            <Body as={Link} to="/gifts">
              Redeem a gift
            </Body>
          </li>
        </ul>
      </Subsection>
    </>
  );
};

const NoResults = ({ search: { term } }) => {
  const { data } = useQuery(NO_RESULTS_QUERY);
  return (
    <>
      <Section wide className={s.root}>
        <Title as="h2">No matching results for “{term}”</Title>
        <Body as="p">Check the spelling or try a more general term</Body>
      </Section>
      {data && <Subsections data={data} />}
    </>
  );
};

export default NoResults;
