import React from 'react';
import Page from 'components/Page';
import StripePaymentIntentProvider from 'components/Form/providers/StripePaymentIntentProvider';
import s from './style.module.css';

const Order = ({ children }) => {
  return (
    <Page>
      <StripePaymentIntentProvider withoutPaymentIntent>{children}</StripePaymentIntentProvider>
      {/* <section className={s.root}>
        <header />
        <StripePaymentIntentProvider withoutPaymentIntent>{children}</StripePaymentIntentProvider>
      </section> */}
    </Page>
  );
};

export default Order;
