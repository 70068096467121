import React from 'react';
import { Link } from 'react-router-v3';
import Section from 'components/Section';
import { SectionHeading } from 'components/Elements';
import ExpertList from 'components/ExpertList';
import cx from 'classnames';
import s from './style.module.css';

const ExpertsRow = ({ school: { slug, name, experts } }) => {
  return (
    <Section className={cx(s.school, slug)}>
      <SectionHeading centered white>
        <Link to={`/${slug}`}>{name}</Link>
      </SectionHeading>
      <ExpertList tutors={experts} hideSchool />
    </Section>
  );
};

ExpertsRow.fragments = ExpertList.fragments;

export default ExpertsRow;
