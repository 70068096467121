import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-v3';
import { Large } from 'components/Typography';
import s from './style.module.css';

const Menu = ({ className, ...props }) => {
  return <div className={cx(s.root, className)} {...props} />;
};

export const MenuLink = ({ back, ...props }) => {
  return <Large className={cx(s.link, { [s.back]: back })} {...props} />;
};

export const SmallMenuLink = ({ back, ...props }) => {
  return <Link className={cx(s.link, s.small, { [s.back]: back })} {...props} />;
};

export default Menu;
