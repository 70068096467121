import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-v3';
import Dialog from 'components/Dialog';
import { PrimaryButton } from 'components/Button';
import FeaturesList from 'components/FeaturesList';

const Section = styled.div`
  margin: 30px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 20px;
  width: 50%;
`;

const features = [
  { icon: 'notepad', text: "You can start whenever you're ready and complete at your own pace" },
  { icon: 'chat', text: 'You can share assignments and discuss them with your fellow classmates' },
  { icon: 'lock', text: 'Lessons take place in a friendly, safe and secure online classroom' },
  { icon: 'profile', text: 'Your expert tutor is available to provide personalised feedback' },
  { icon: 'player', text: 'Each lesson starts with an inspirational video from your expert tutor' },
  { icon: 'clock', text: 'You’ll have lifetime access to all course content and your classroom' },
  {
    icon: 'document',
    text: 'After each lesson, you practice what you’ve learned with an assignment',
  },
  { icon: 'globe', text: 'Continue chatting with your classmates for as long as you want' },
];

const HowItWorksDialog = ({ handleClose, ...props }) => {
  return (
    <Dialog title="How it works" handleClose={handleClose} {...props}>
      <Section>
        <FeaturesList features={features} />
        <StyledPrimaryButton as="a" href="http://help.learningwithexperts.com/">
          Frequently asked questions
        </StyledPrimaryButton>
      </Section>
    </Dialog>
  );
};

export default HowItWorksDialog;
