import React from 'react';
import { gql } from '@apollo/client';

import AssetImage from 'components/AssetImage';
import { Title, Body } from 'components/Typography';
import { ExpertLink } from './styles';

const ExpertPanel = ({
  expert: {
    path,
    profile: { portrait, avatar, name, shortBiog },
    school,
  },
}) => {
  return (
    <ExpertLink to={path} slug={school.slug}>
      {portrait && (
        <figure>
          <AssetImage uuid={portrait.uuid} width={600} height={600} />
        </figure>
      )}
      <Title white as="h3">
        {name}
      </Title>
      <Body white as="p" className={school.slug}>
        {shortBiog}
      </Body>
    </ExpertLink>
  );
};

ExpertPanel.fragments = {
  expert: gql`
    fragment ExpertPanel__expert on User {
      id
      slug
      path
      profile {
        id
        name
        shortBiog
        biog
        quote
        quoteCitation
        portrait {
          id
          uuid
        }
        avatar {
          id
          uuid
        }
      }
      school {
        id
        slug
        name
      }
    }
  `,
};

export default ExpertPanel;
