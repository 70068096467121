import React from 'react';
import SearchForm from 'components/SearchForm';
import s from './style.module.css';

const Form = ({ router, term }) => {
  const handleSubmit = ({ term }) => router.push(`/search?term=${term}`);
  return (
    <div className={s.root}>
      <SearchForm initialTerm={term} onSubmit={handleSubmit} hiddenForDesktop />
    </div>
  );
};

export default Form;
