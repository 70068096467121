import React from 'react';
import { Link } from 'react-router-v3';
import styled, { css } from 'styled-components';
import { scroller } from 'react-scroll';
import Typography, { font } from 'components/Typography';
import { PrimaryButton } from 'components/Button';
import { useSpringSale } from 'hooks/useSpringSale';

const icon = require('./assets/heart.svg');

const Section = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${({ theme }) => theme.schools.gardening.primary};
  padding: 30px 15px 30px 15px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 80px 0 80px 0;
  }
`;

const Container = styled.div`
  padding: 0 0 0 0;
  width: 100%;
  max-width: 760px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
    max-width: 800px;
  }
  @media ${({ theme }) => theme.breakpoints.hd} {
    max-width: 1100px;
  }
`;

const Offer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    align-items: flex-start;
    flex: 0 0 50%;
  }
`;

const Icon = styled.img`
  width: 32px;
  margin-bottom: 10px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 48px;
    margin-bottom: 25px;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    width: 64px;
    margin-bottom: 35px;
  }
`;

const Heading = styled(Typography).attrs({
  mobile: font.medium.group(8),
  desktop: font.medium.group(10),
  hd: font.medium.group(11),
  as: 'h2',
  white: true,
})`
  margin: 0 0 25px 0;
  padding: 0 0 0 0;
  width: 250px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    width: 450px;
    text-align: left;
    margin: 0 0 45px 0;
  }

  @media ${({ theme }) => theme.breakpoints.hd} {
    width: 630px;
  }

  & > span {
    display: block;
    color: ${({ theme }) => theme.schools.gardening.tint1};
  }
`;

const Roundel = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  width: 220px;
  height: 220px;
  background-color: ${({ theme }) => theme.colors.global.white};

  @media ${({ theme }) => theme.breakpoints.desktop} {
    display: flex;
    width: 300px;
    height: 300px;
  }
  @media ${({ theme }) => theme.breakpoints.hd} {
    width: 350px;
    height: 350px;
  }

  & > .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 30px 30px 30px 30px;
  }
`;

const SubHeading = styled(Typography).attrs({
  mobile: font.bold.group(8),
  desktop: font.bold.group(9),
  hd: font.bold.group(10),
  as: 'p',
})`
  color: ${({ theme }) => theme.schools.antiques.primary};
  margin-bottom: 5px;
`;

const Body = styled(Typography).attrs({
  mobile: font.medium.group(6),
  desktop: font.medium.group(7),
  hd: font.medium.group(8),
  as: 'p',
})`
`;

const handleClick = () =>
  scroller.scrollTo('homepage-featured-products', {
    duration: 800,
    delay: 0,
    smooth: 'easeInOutQuart',
    offset: -160,
    containerId: 'scroll-body',
  });


const SpringSaleHomepagePromo = () => {
  const { saleUpToPercentage } = useSpringSale();

  return (
    <Section>
      <Container>
        <Offer>
          <Icon alt="" src={icon} />
          <Heading>
            Get {saleUpToPercentage}% OFF gardening courses
          </Heading>
          <PrimaryButton inverse as={Link} onClick={handleClick}>
            Explore Courses
          </PrimaryButton>
        </Offer>
        <Roundel>
          <div className="inner">
            <SubHeading>
              SPRING<br />
              SALE
            </SubHeading>

            <Body>For a limited<br />
            time only</Body>
          </div>
        </Roundel>
      </Container>
    </Section>
  );
};

export default SpringSaleHomepagePromo;
