import React from 'react';

import s from './style.module.css';

const socialNetworks = ['facebook', 'twitter', 'pinterest', 'linkedin'];

const handleSocialClick = (network, props) => () => {
  let networkUrl = null;
  const encodedPageUrl = window.encodeURIComponent(window.location.href);
  const sharingMessage = props.message ? window.encodeURI(props.message) : '';

  switch (network) {
    case 'facebook':
      networkUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedPageUrl}`;
      break;
    case 'twitter':
      networkUrl = `https://twitter.com/intent/tweet?text=${sharingMessage}&url=${encodedPageUrl}`;
      break;
    case 'pinterest':
      networkUrl = `http://www.pinterest.com/pin/create/button/?description=${sharingMessage}&url=${encodedPageUrl}`;
      break;
    case 'linkedin':
      networkUrl = `http://www.linkedin.com/shareArticle?mini=true&url=${encodedPageUrl}&title=${window.document.title}&summary=${sharingMessage}`;
      break;
    default:
      break;
  }

  if (networkUrl) {
    window.open(networkUrl, 'Share', 'height=600,width=800');
  }

  if (props.onClick) {
    props.onClick(network);
  }
};

const SocialButtons = (props) => (
  <ul className={s.root}>
    {socialNetworks.map((network) => (
      <li className={network} key={network}>
        <a className={network} onClick={handleSocialClick(network, props)}>
          Link to {network}
        </a>
      </li>
    ))}
  </ul>
);

export default SocialButtons;
