import React from 'react';
import { gql } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { SectionHeading } from 'components/Elements';
import { Section } from 'components/Layout';

import BlogPostPacket from './components/BlogPostPacket';

import { InnerContainer } from './styles';

const LatestBlogPosts = ({ posts, heading, school, children, centered }) => {
  const isMobile = useMediaQuery({ maxWidth: 1023 });

  const postToShow = isMobile ? posts?.slice(0, 2) : posts;

  if (!posts || posts.length === 0) {
    return null;
  }

  return (
    <Section className={cx({ centered: centered })} school={school} color={!school && 'gray'}>
      <InnerContainer>
        <SectionHeading white>{heading}</SectionHeading>
        <ul>
          {postToShow.map((post) => (
            <li key={post.id}>
              <BlogPostPacket post={post} />
            </li>
          ))}
        </ul>
        {children}
      </InnerContainer>
    </Section>
  );
};

LatestBlogPosts.fragments = {
  posts: gql`
    fragment LatestBlogPosts__posts on BlogPost {
      id
      title
      slug
      publishedAt
      school {
        id
        slug
      }
      author {
        id
        profile {
          id
          name
        }
      }
      files {
        header {
          id
          uuid
        }
        grid {
          id
          uuid
        }
      }
    }
  `,
};

export default LatestBlogPosts;
