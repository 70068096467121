import React from 'react';
import Icon from 'components/NextIcon';
import s from './style.module.css';

const FeaturesList = ({ features }) => {
  return (
    <ul className={s.root}>
      {features &&
        features.map(({ icon, title, text }) => (
          <li key={icon}>
            <Icon name={icon} />
            <p>
              {title && <strong>{title}: </strong>}
              {text}
            </p>
          </li>
        ))}
    </ul>
  );
};

export default FeaturesList;
