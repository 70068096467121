import React, { useState } from 'react';
import { Subtitle, Small } from 'components/Typography';
import LazyImage from 'components/LazyImage';
import s from './style.module.css';
import cx from 'classnames';

const Featurette = ({ featurette: { title, summary, icon: Icon, image }, onClick, selected }) => {
  const [open, setOpen] = useState(selected);
  const expanded = onClick ? selected : open;

  const clickHandler = onClick || (() => setOpen(!open));

  return (
    <li onClick={clickHandler}>
      <div className={cx(s.root, { [s.selected]: expanded })}>
        <div className={s.icon}>
          <Icon />
        </div>

        <div className={s.description}>
          <Subtitle as="h2" book>
            {title}
          </Subtitle>
          <Small as="p" book>
            {summary}
          </Small>
        </div>
      </div>
      <div className={cx(s.screenshot, { [s.selected]: expanded })}>
        <LazyImage src={image} alt={summary} />
      </div>
    </li>
  );
};

export default Featurette;
