import React from 'react';
import { Link } from 'components/Elements';
import Dialog from 'components/Dialog';
import { DIALOGS } from 'components/Dialogs';
import s from './style.module.css';
import { useLegacyLogoutAndRedirect } from 'hooks/user';
import { toLocalId } from '@lwe/toolkit/utils';
import { withRouter } from 'react-router-v3';

const SubNav = ({ school, closeDialog }) => {
  return (
    <ul className={s.subnav}>
      <li>
        <Link to={`/${school.slug}`} onClick={closeDialog}>
          Courses
        </Link>
      </li>
      <li>
        <Link to={`/${school.slug}/gifts`} onClick={closeDialog}>
          Gifts
        </Link>
      </li>
      <li>
        <Link to={`/${school.slug}/community`} onClick={closeDialog}>
          Community
        </Link>
      </li>
      <li>
        <Link to={`/${school.slug}/blog`} onClick={closeDialog}>
          Blog
        </Link>
      </li>
    </ul>
  );
};

const NavDialog = ({ router, user, openDialog, school, closeDialog, schools = [], ...props }) => {
  const logout = useLegacyLogoutAndRedirect(router);

  return (
    <Dialog title="Menu" {...props}>
      <section className={s.root}>
        {user && (
          <ul>
            <li>
              <Link to={`/profile/${toLocalId(user.id)}`} onClick={closeDialog}>
                Profile
              </Link>
            </li>
            <li>
              <Link to="/classrooms" onClick={closeDialog}>
                My courses
              </Link>
            </li>
            <li>
              <Link to="/notifications" onClick={closeDialog}>
                Notifications
              </Link>
            </li>
            <li>
              <Link to="/messages" onClick={closeDialog}>
                Messages
              </Link>
            </li>
            <li>
              <a onClick={logout}>Sign out</a>
            </li>
          </ul>
        )}
        <ul>
          {schools.map((sc) => (
            <li key={sc.slug}>
              <Link to={`/${sc.slug}`} onClick={closeDialog}>
                {sc.name}
              </Link>
              {school === sc && <SubNav school={sc} closeDialog={closeDialog} />}
            </li>
          ))}
        </ul>
        <ul>
          <li>
            <a href="/gifts">Gifts</a>
          </li>
          <li>
            <a href="/experts">Our experts</a>
          </li>
          <li>
            <a onClick={() => openDialog(DIALOGS.HOWITWORKS)}>How it works</a>
          </li>
          <li>
            <a href="http://help.learningwithexperts.com/">FAQs</a>
          </li>
          <li>
            <Link onClick={closeDialog} to="/about">
              About us
            </Link>
          </li>
          <li>
            <Link onClick={closeDialog} to="/contact-us">
              Contact us
            </Link>
          </li>
          <li>
            <Link onClick={closeDialog} to="terms-and-conditions">
              T&Cs
            </Link>
          </li>
          <li>
            <Link onClick={closeDialog} to="privacy-policy">
              Privacy
            </Link>
          </li>
        </ul>
      </section>
    </Dialog>
  );
};

export default withRouter(NavDialog);
