import React from 'react';
import styled from 'styled-components';
import { CustomerSupportAPI } from 'utils/support';
import { hoursToSentence } from '@lwe/toolkit/utils';

import Section from 'components/Section';
import { Subtitle, Body as BaseBody, font } from 'components/Typography';

const StyledSection = styled(Section)`
  padding: 0 14px;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 0;
  }
`;

const Panel = styled.div`
  margin: 0 7px 35px 7px;
  background-color: #f8f8f8;
  padding: 24px 25px;
  cursor: pointer;

  text-align: center;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    text-align: left;
    margin: 0 0 49px 0;
    padding: 22px 28px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    flex-direction: row;
    align-items: left;
    margin: 0;
  }
`;

const Title = styled(Subtitle)`
  margin: 3px 0 6px 0;
`;

const Body = styled(BaseBody)`
  font-weight: 500;
`;

const Logo = styled.img`
  width: 260px;
  flex-shrink: 0;
  margin: 20px 0 0 0;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 0 0 0 20px;
  }
`;

const logo = require('./assets/cpd-logo.png');

const openMoreInformation = () => {
  CustomerSupportAPI('openArticle', '63807bba4745e14168a8d1e8');
};

const Cpd = ({ product }) => {
  if (!product) return null;

  const { cpdHours } = product;

  if (!cpdHours || cpdHours === 0) return null;

  const duration = hoursToSentence(cpdHours);

  return (
    <StyledSection>
      <Panel onClick={openMoreInformation}>
        <Container>
          <div>
            <Title as="h3">CPD Accreditation</Title>
            <Body mobile={font.book.group(3)}>
              Course activity has been accredited by the CPD Standards Office (CPDSO). The course
              equates to {duration} of CPD learning.
            </Body>
          </div>
          <Logo src={logo} alt="The CPD Standards Office. CPD Provider: 50276." />
        </Container>
      </Panel>
    </StyledSection>
  );
};

export default Cpd;
