import { useCallback } from 'react';
import { useMutation, gql } from '@apollo/client';
import { NOOP } from 'utils';
import fileUploadToFormData from 'utils/fileUploadToFormData';

const CREATE_FILE_UPLOADER_MUTATION = gql`
  mutation CreateFileUpload(
    $public: Boolean
    $tag: String
    $fileName: String!
    $mimeType: String
    $fileSize: Int
  ) {
    createFileUpload(
      public: $public
      tag: $tag
      fileName: $fileName
      mimeType: $mimeType
      fileSize: $fileSize
    ) {
      file {
        id
        uuid
        title
        mimeType
        fileName
        thumbnail: resize(dims: "150")
        preview
        download
        progress @client
        videoThumbnail
        stream
      }
      acl
      key
      policy
      algorithm
      credential
      date
      signature
      contentType
      uploadUrl
      securityToken
    }
  }
`;

const PROGRESS_FRAGMENT = gql`
  fragment progress on File {
    id
    progress
    thumbnail: resize(dims: "150")
    preview
  }
`;

export const useCreateFileUpload = () => {
  const [createFileUpload, { client }] = useMutation(CREATE_FILE_UPLOADER_MUTATION);

  const updateFile = useCallback(
    (f, data) => {
      client.writeFragment({
        id: client.cache.identify(f),
        fragment: PROGRESS_FRAGMENT,
        data: {
          ...data,
          id: f.id,
        },
      });
    },
    [client],
  );

  return useCallback(
    async (
      file,
      {
        isPublic = false,
        tag = 'attachment',
        update = NOOP,
        onComplete = NOOP,
        onProgress = NOOP,
      } = {},
    ) => {
      const mimeType = file.name.indexOf('.mkv') > -1 ? 'video/x-matroska' : file.type;
      const cantThumbnail = ['image/heic'].includes(mimeType);
      const thumbnail = cantThumbnail ? null : URL.createObjectURL(file);
      const preview = thumbnail;

      const {
        data: {
          createFileUpload: { file: f, ...fileUpload },
        },
      } = await createFileUpload({
        variables: {
          tag,
          public: isPublic,
          fileName: file.name,
          mimeType,
          fileSize: file.size,
        },
      });

      const formData = fileUploadToFormData(fileUpload, file, mimeType);
      const request = new XMLHttpRequest();

      request.upload.addEventListener('progress', (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          updateFile(f, { progress, thumbnail, preview });
          onProgress(progress, {
            ...f,
            progress,
            thumbnail,
            preview,
          });
        }
      });

      request.addEventListener('load', () => {
        setTimeout(
          () => updateFile(f, { progress: null, thumbnail: f.thumbnail, preview: f.preview }),
          1000,
        );
        onComplete(f);
      });

      request.open('POST', fileUpload.uploadUrl);
      request.send(formData);

      updateFile(f, {
        progress: 0,
        thumbnail,
        preview,
      });

      onProgress(0, {
        ...f,
        progress: 0,
        thumbnail,
        preview,
      });

      return {
        ...f,
        thumbnail,
        progress: 0,
        preview,
      };
    },
    [createFileUpload],
  );
};
