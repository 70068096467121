import React from 'react';
import Image from 'components/Image';
import AssetImage from 'components/AssetImage';
import cx from 'classnames';
import s from './style.module.css';

const FadeInImageBackground = ({ image, src, onLoad, className, children }) => {
  return (
    <div className={cx(s.root, className)}>
      {src && <Image src={src} onLoad={onLoad} fade />}
      {image && !src && (
        <AssetImage
          uuid={image.uuid}
          onLoad={onLoad}
          sizes="(min-width: 1440px) 1440px, 100%"
          width={1500}
          fade
        />
      )}
      <div>{children}</div>
    </div>
  );
};

FadeInImageBackground.defaultProps = {
  onLoad: () => {},
};

export default FadeInImageBackground;
