import { useQuery, gql } from '@apollo/client';
import { useCallback, useMemo } from 'react';

const DIALOG_QUERY = gql`
  query DialogQuery {
    dialog @client {
      current
      authType
      isFree
    }
  }
`;

const useDialog = () => {
  const { data, client } = useQuery(DIALOG_QUERY, { ssr: false });
  const updateDialog = useCallback(
    ({ current, authType, isFree = false }) => {
      client.writeQuery({
        query: DIALOG_QUERY,
        data: {
          dialog: {
            __typename: 'DialogState',
            current,
            authType,
            isFree,
          },
        },
      });
    },
    [client],
  );

  return useMemo(() => {
    const openDialog = (current, authType = null, isFree) =>
      updateDialog({ current, authType, isFree });
    const closeDialog = () => updateDialog({ current: null, authType: null });
    return { openDialog, closeDialog, dialog: data && data.dialog };
  }, [data, updateDialog]);
};

export default useDialog;
