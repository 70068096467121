import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';

const EASTER_PROMOTIONS_QUERY = gql`
  query EasterPromotionsQuery {
    promotions {
      easter: group(name: easter) {
        endsAt
      }
    }
  }
`;

export const useEaster = () => {
  const { data } = useQuery(EASTER_PROMOTIONS_QUERY, { ssr: true });
  const {
    promotions: { easter },
  } = data ?? { promotions: {} };

  return useMemo(() => {
    return {
      showEaster: easter,
    };
  }, [easter]);
};
