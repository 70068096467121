import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './style.module.css';

const Icon = ({ name, className, ...props }) => {
  return <span className={cx(s.icon, name, className)} {...props} />;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
};

export const icon = s.root;
export default Icon;
