import React from 'react';
import s from './style.module.css';

const Spinner = () => {
  return (
    <div className={s.root}>
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
