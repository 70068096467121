import React, { useState } from 'react';
import cx from 'classnames';
import FadeInImageBackground from 'components/FadeInImageBackground';
import s from './style.module.css';

const VignettedSectionBackgroundImage = ({ className, children, ...props }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <FadeInImageBackground
      className={cx(s.root, className)}
      onLoad={() => setImageLoaded(true)}
      {...props}
    >
      <div className={cx(s.content, { [s.visible]: imageLoaded })}>{children}</div>
    </FadeInImageBackground>
  );
};

export default VignettedSectionBackgroundImage;
