import styled from 'styled-components';

export const Article = styled.article`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  cursor: pointer;
  width: 168px;
  height: 225px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 18px 0;
    width: 295px;
    height: 362px;
  }

  & > img {
    width: 100%;
    max-width: 100%;
    height: 91px;
    object-fit: cover;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      height: 161px;
    }
  }

  & > header {
    order: 2;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    flex: 1;
    color: #4a4a4a;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      padding: 15px;
    }

    & > h3 {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: 'Gotham', Helvetica, Arial, sans-serif;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;

      & > a {
        text-decoration: none;
      }

      @media ${({ theme }) => theme.breakpoints.desktop} {
        font-family: Gotham;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 32px;
      }
    }

    & > ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: 'Gotham', Helvetica, Arial, sans-serif;
      font-weight: 400;
      -moz-osx-font-smoothing: grayscale;
      -moz-osx-font-smoothing: grayscale;
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
