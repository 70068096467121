import React from 'react';
import { gql } from '@apollo/client';

const taughtBy = (nestedProducts) => {
  const tutorCount = nestedProducts && [...new Set(nestedProducts.map((p) => p.tutor.id))].length;
  if (tutorCount === 1) return `1 Expert Tutor`;
  return `${tutorCount} Expert Tutors`;
};

const CollectionStats = ({ product }) => {
  if (!product) return null;
  const nestedProducts = product.nestedProducts;
  return (
    <span>
      {nestedProducts.length} Courses • {taughtBy(nestedProducts)}
    </span>
  );
};

CollectionStats.fragments = {
  product: gql`
    fragment collectionStats on Product {
      id
      nestedProducts {
        id
        tutor {
          id
        }
      }
    }
  `,
};

export default CollectionStats;
