import React, { useState, useRef } from 'react';
import { withRouter } from 'react-router-v3';
import s from './style.module.css';

const SearchForm = ({ initialTerm, onSubmit, router }) => {
  const inputRef = useRef();
  const [term, setTerm] = useState(initialTerm);

  const handleClear = () => {
    setTerm(null);
    inputRef.current.focus();
    router.push('/search');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ term: inputRef.current.value });
  };

  const handleChange = (e) => setTerm(e.target.value);

  return (
    <form className={s.root} onSubmit={handleSubmit}>
      <input
        placeholder="Find an expert, a course or a post"
        autoFocus
        value={term || ''}
        onChange={handleChange}
        ref={inputRef}
      />
      <button type="submit">Search</button>
      {term && (
        <button className={s.clear} onClick={handleClear} type="button">
          X
        </button>
      )}
    </form>
  );
};

export default withRouter(SearchForm);
