function hasRole(userEntity, roleName) {
  if (userEntity && userEntity.roles) {
    return userEntity.roles.indexOf(roleName) > -1;
  }

  return false;
}

export function isAdmin(userEntity) {
  return hasRole(userEntity, 'admin');
}

export function isTutor(userEntity) {
  return hasRole(userEntity, 'tutor');
}

export function isModerator(userEntity) {
  return hasRole(userEntity, 'moderator');
}

export function isObserver(userEntity) {
  return hasRole(userEntity, 'observer');
}

export function isBlogPostAuthor(userEntity) {
  return hasRole(userEntity, 'blog_post_author');
}

export function hasDashboard(userEntity) {
  return [isTutor, isAdmin, isModerator].some((f) => f(userEntity));
}
