import React from 'react';
import cx from 'classnames';
import Section from 'components/Section';
import s from './style.module.css';

const Header = ({ className, ...props }) => {
  return <Section className={cx(s.root, className)} {...props} />;
};

export default Header;
