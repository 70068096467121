import React, { useState, useEffect } from 'react';
import loadable from '@loadable/component';

export const Button = loadable(() =>
  import(/* webpackChunkName: "admin" */ '@material-ui/core/Button'),
);

const BlogPostEditor = loadable(() => import(/* webpackChunkName: "admin" */ './BlogPostEditor'));

const ProductEditor = loadable(() => import(/* webpackChunkName: "admin" */ './ProductEditor'));

const EditorButton = (Component) => (props) => {
  const [render, setRender] = useState(false);
  useEffect(() => setRender(true), []);
  if (!render) return null;

  return <Component {...props} />;
};

export const BlogPostEditorButton = EditorButton(BlogPostEditor);
export const ProductEditorButton = EditorButton(ProductEditor);
