const fileUploadToFormData = (fileUpload, file, mimeType) => {
  const params = {
    acl: fileUpload.acl,
    key: fileUpload.key,
    policy: fileUpload.policy,
    success_action_status: '200',
    'x-amz-algorithm': fileUpload.algorithm,
    'x-amz-credential': fileUpload.credential,
    'x-amz-date': fileUpload.date,
    'x-amz-signature': fileUpload.signature,
    'x-amz-security-token': fileUpload.securityToken,
    'Content-Type': mimeType,
    file: file,
  };
  const data = new FormData();
  Object.keys(params).forEach((key) => data.append(key, params[key]));
  return data;
};

export default fileUploadToFormData;
