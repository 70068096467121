import styled from 'styled-components';

export const Nav = styled.nav`
  width: 100%;
  height: 40px;
  background-color: ${({ theme, slug }) => theme?.schools?.[slug]?.primary};
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  z-index: 5;
  top: ${({ offsetHeader }) => (offsetHeader ? 110 : 60)}px;
  box-shadow: 0 0 16px 0 rgb(0 0 0 / 12%);

  @media ${({ theme }) => theme.breakpoints.mobile} {
    top: ${({ offsetHeader }) => (offsetHeader ? 140 : 90)}px;
    height: 60px;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    padding: 0 15px;

    @media (min-width: 950px) {
      padding: 0 25px;
    }

    & > ul {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      align-items: center;

      & > li {
        margin-right: 5px;
        white-space: nowrap;

        @media (min-width: 375px) {
          margin-right: 22px;
        }

        &:last-child {
          margin-right: 0;
        }

        @media (min-width: 950px) {
          margin-right: 26px;
        }

        & a {
          text-decoration: none;
        }

        & > a {
          display: block;

          &:hover {
            text-decoration: underline;
          }

          @media (min-width: 950px) {
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 22px;
          }
        }
      }

      &:last-child > li {
        margin-right: 0;

        margin-left: 5px;
        white-space: nowrap;

        @media (min-width: 375px) {
          margin-left: 22px;
        }

        &:first-child {
          margin-left: 0;
        }

        @media (min-width: 950px) {
          margin-left: 26px;
        }
      }

      &.breadcrumbs > li {
        margin-right: 12px;
      }

      &.submenu {
        justify-content: flex-end;
      }
    }
  }
`;
