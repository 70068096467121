import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-v3';
import Spinner from './components/Spinner';
import cx from 'classnames';
import s from './style.module.css';

const Link = ({
  to,
  button = false,
  wide = false,
  invert = false,
  white = false,
  gray,
  primary = false,
  small,
  className,
  ...props
}) => {
  const classes = cx(
    s.link,
    {
      [s.button]: button,
      [s.wide]: wide,
      [s.primary]: primary,
      [s.invert]: invert,
      [s.white]: white,
      [s.gray]: gray,
      [s.small]: small,
    },
    className,
  );
  if (to && to?.startsWith('http')) {
    return <a className={classes} href={to} {...props} />;
  }

  return <RouterLink className={classes} to={to} {...props} />;
};

Link.propTypes = {
  to: PropTypes.string,
  button: PropTypes.bool,
  wide: PropTypes.bool,
  primary: PropTypes.bool,
  className: PropTypes.string,
};

const Button = ({
  wide = false,
  primary = false,
  bordered = false,
  invert = false,
  white = false,
  loading = false,
  gray,
  small,
  className,
  children,
  ...props
}) => {
  return (
    <button
      className={cx(
        s.button,
        {
          [s.wide]: wide,
          [s.primary]: primary,
          [s.invert]: invert,
          [s.bordered]: bordered,
          [s.white]: white,
          [s.gray]: gray,
          [s.small]: small,
        },
        className,
      )}
      {...props}
    >
      {loading ? <Spinner /> : children}
    </button>
  );
};

Button.propTypes = {
  wide: PropTypes.bool,
  primary: PropTypes.bool,
  className: PropTypes.string,
};

const mapping = {
  headline: 'h1',
  title: 'h2', // 26px Gotham Medium
  subheading: 'h3',
  body2: 'aside',
  body: 'p',
  item: 'li',
};

const Typography = ({ type, className, primary = false, ...props }) => {
  const Component = mapping[type] || 'span';
  return <Component className={cx(type, className)} {...props} />;
};

Typography.propTypes = {
  type: PropTypes.string,
  className: PropTypes.any,
  children: PropTypes.any.isRequired,
  primary: PropTypes.bool,
};

export { Link, Button };
export { default as Badge } from './components/Badge';
export { default as Select } from './components/Select';
export { default as SectionHeading } from './components/SectionHeading';
export { default as PageHeading } from './components/PageHeading';
export { default as Quote } from './components/Quote';
export { default as Benefits } from './components/Benefits';
export { default as OverviewDescription } from './components/OverviewDescription';
export { default as LessonDescription } from './components/LessonDescription';
export default Typography;
