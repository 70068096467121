import React, { useState } from 'react';
import { gql } from '@apollo/client';
import { Link } from 'react-router-v3';
import { Title } from 'components/Typography';
import { useSwipeable } from 'react-swipeable';
import { animated } from 'react-spring';
import Menu, { MenuLink, SmallMenuLink } from '../Menu';
import useMenuTransition from 'hooks/useMenuTransition';
import ExpertStrip from 'components/ExpertStrip';
import s from './style.module.css';

const List = ({ schools, navigateHome, setSelected, closeNav }) => {
  const handlers = useSwipeable({ onSwipedLeft: navigateHome, onSwipedUp: closeNav });
  return (
    <Menu {...handlers}>
      <ul>
        <li>
          <MenuLink back onClick={navigateHome}>
            Back
          </MenuLink>
        </li>
        <li>
          <Title as="h3">Experts</Title>
          <ul>
            {schools.map(({ id, name, slug }) => {
              const handleClick = (e) => {
                e.preventDefault();
                setSelected(id);
              };
              return (
                <li key={id}>
                  <SmallMenuLink onClick={handleClick} to={`/${slug}`}>
                    {name}
                  </SmallMenuLink>
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    </Menu>
  );
};

const Detail = ({ navigateBack, school, closeNav }) => {
  const handlers = useSwipeable({ onSwipedLeft: navigateBack, onSwipedUp: closeNav });
  return (
    <Menu {...handlers}>
      <ul>
        <li>
          <MenuLink back onClick={navigateBack}>
            Experts
          </MenuLink>
        </li>
        <li>
          <Title as="h3">{school.name}</Title>
          <ul>
            {school.mobileLatestExperts.map((expert) => {
              return (
                <li key={expert.id}>
                  <div>
                    <ExpertStrip expert={expert} onClick={closeNav} />
                  </div>
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    </Menu>
  );
};

const Experts = ({ navigation: { schools }, navigateHome, closeNav }) => {
  const [selected, setSelected] = useState(null);
  const navigateBack = () => setSelected(null);
  const transition = useMenuTransition(selected);
  return transition.map(({ item, key, props }) => {
    const Component = item ? Detail : List;
    const selectedSchool = schools.find((s) => s.id === item);
    return (
      <animated.div key={key} style={props} className={s.root}>
        <Component
          schools={schools}
          school={selectedSchool}
          setSelected={setSelected}
          navigateBack={navigateBack}
          navigateHome={navigateHome}
          closeNav={closeNav}
        />
      </animated.div>
    );
  });
};

Experts.fragments = {
  navigation: gql`
    fragment Mobile__Experts__navigation on Navigation {
      id
      schools {
        id
        name
        slug
        mobileLatestExperts: tutors(limit: 6) {
          id
          ...ExpertStrip_expert
        }
      }
    }
    ${ExpertStrip.fragments.expert}
  `,
};

export default Experts;
