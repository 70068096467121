import React, { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import styled from 'styled-components';
import { useConfiguration } from 'hooks/configuration';
import { FixedHeader, LogoLink } from './styles';
import MenuDrawer from './MenuDrawer';
import { LazyImage } from '@lwe/toolkit/ui-library';
import { genAssetImgSrc } from '@lwe/toolkit/utils';

const Image = styled(LazyImage)`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

const MobileHeader = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [attached, setAttached] = useState(false);
  const theme = useTheme();
  const configuration = useConfiguration();

  const handleOpen = useCallback(() => {
    setOpen(true);
    setAttached(true);
  }, [setOpen, setAttached]);
  const handleClose = useCallback(() => setOpen(false), [setOpen]);
  const handleDetach = useCallback(() => setAttached(false), [setAttached]);

  return (
    <FixedHeader>
      <LogoLink to={configuration?.settings?.homepageRedirect ?? '/'}>
        <Image height='32px' {...genAssetImgSrc(theme?.logo?.desktop?.uuid, 400)} fade={true} />
      </LogoLink>

      {user && (
        <>
          <div className="mobile-navigation" onMouseDown={handleOpen}>
            <a href="#" className="dt-mobile-menu-icon" aria-label="Mobile menu icon">
              <div className="lines-button">
                <span className="menu-line"></span>
                <span className="menu-line"></span>
                <span className="menu-line"></span>
              </div>
            </a>
          </div>
          {attached && (
            <MenuDrawer
              user={user}
              handleClose={handleClose}
              handleDetach={handleDetach}
              open={open}
            />
          )}
        </>
      )}
    </FixedHeader>
  );
};

export default MobileHeader;
