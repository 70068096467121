import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-v3';
import { Subtitle, Headline, Body, Small } from 'components/Typography';
import BaseTag from 'components/atoms/Tag';
import s from './style.module.css';

export const Heading = (props) => {
  return <Subtitle className={s.heading} as="h3" {...props} />;
};

export const Subheading = (props) => {
  return <Headline className={s.subheading} as="p" {...props} />;
};

export const Tag = ({ className, children }) => {
  return <BaseTag className={cx(s.tag, className)}>{children}</BaseTag>;
};

export const Tags = ({ className, children }) => {
  return <p className={cx(s.tags, className)}>{children}</p>;
};

export const Description = ({ className, children }) => {
  return <Body className={cx(s.description, className)}>{children}</Body>;
};

const SearchResult = ({ to, className, type, preview, children, ...props }) => {
  return (
    <Link to={to} className={cx(s.root, className)} {...props}>
      <div>{preview}</div>
      <div>{children}</div>
    </Link>
  );
};

export default SearchResult;
