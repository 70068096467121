import React, { useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import { Section as BaseSection, Content } from 'components/Layout';
import ExpertIcon from './components/Featurette/components/ExpertIcon';
import LessonIcon from './components/Featurette/components/LessonIcon';
import AssignmentIcon from './components/Featurette/components/AssignmentIcon';
import ChatIcon from './components/Featurette/components/ChatIcon';
import Featurette from './components/Featurette';
import { SectionHeading } from 'components/Elements';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

const featurettes = [
  {
    title: 'Start anytime',
    summary: 'Watch video tutorials led by expert tutors',
    icon: LessonIcon,
    images: {
      antiques: require('./components/Featurette/images/antiques-1.png'),
      floristry: require('./components/Featurette/images/floristry-1.png'),
      foodanddrink: require('./components/Featurette/images/foodanddrink-1.png'),
      gardening: require('./components/Featurette/images/gardening-1.png'),
      jewellery: require('./components/Featurette/images/jewellery-1.png'),
      photography: require('./components/Featurette/images/photography-1.png'),
      wellbeing: require('./components/Featurette/images/wellbeing-1.png'),
    },
  },
  {
    title: 'Test yourself',
    summary: 'Practice what you learn with inspiring assignments',
    icon: AssignmentIcon,
    images: {
      antiques: require('./components/Featurette/images/antiques-2.png'),
      floristry: require('./components/Featurette/images/floristry-2.png'),
      foodanddrink: require('./components/Featurette/images/foodanddrink-2.png'),
      gardening: require('./components/Featurette/images/gardening-2.png'),
      jewellery: require('./components/Featurette/images/jewellery-2.png'),
      photography: require('./components/Featurette/images/photography-2.png'),
      wellbeing: require('./components/Featurette/images/wellbeing-2.png'),
    },
  },
  {
    title: 'Personal tuition',
    summary: 'Get assignment feedback from expert tutors',
    icon: ExpertIcon,
    images: {
      antiques: require('./components/Featurette/images/antiques-3.png'),
      floristry: require('./components/Featurette/images/floristry-3.png'),
      foodanddrink: require('./components/Featurette/images/foodanddrink-3.png'),
      gardening: require('./components/Featurette/images/gardening-3.png'),
      jewellery: require('./components/Featurette/images/jewellery-3.png'),
      photography: require('./components/Featurette/images/photography-3.png'),
      wellbeing: require('./components/Featurette/images/wellbeing-3.png'),
    },
  },
  {
    title: 'Share ideas',
    summary: 'Collaborate and chat directly to classmates',
    icon: ChatIcon,
    images: {
      antiques: require('./components/Featurette/images/antiques-4.png'),
      floristry: require('./components/Featurette/images/floristry-4.png'),
      foodanddrink: require('./components/Featurette/images/foodanddrink-4.png'),
      gardening: require('./components/Featurette/images/gardening-4.png'),
      jewellery: require('./components/Featurette/images/jewellery-4.png'),
      photography: require('./components/Featurette/images/photography-4.png'),
      wellbeing: require('./components/Featurette/images/wellbeing-4.png'),
    },
  },
];

const Section = styled(BaseSection)`
  margin: 15px 0 30px 0;
  align-items: flex-start;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    margin: 30px 0 60px 0;
  }

  & > h2 {
    padding: 0 18px;
    margin: 20px 0 25px 0;

    @media ${({ theme }) => theme.breakpoints.desktop} {
      margin-bottom: 35px;
      padding: 0;
    }
  }

  & > ul {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;

    & > li {
      margin: 5px 0 7px 0;

      @media ${({ theme }) => theme.breakpoints.desktop} {
        width: 300px;
      }

      &:hover {
        cursor: pointer;
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const ClassroomExplainer = ({ school, color=null }) => {
  const slug = school ? school.slug : 'foodanddrink';

  if (slug === 'artanddesign') return null;

  const [selected, setSelected] = useState(0);
  const [hover, setHover] = useState(false);
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' });

  useEffect(() => {
    if (!hover && isDesktop) {
      const timer = setTimeout(
        () => setSelected(selected === featurettes.length - 1 ? 0 : selected + 1),
        5000,
      );
      return () => clearTimeout(timer);
    }
  }, [selected, hover, isDesktop]);

  const handleMouseEnter = isDesktop ? () => setHover(true) : null;
  const handleMouseLeave = isDesktop ? () => setHover(false) : null;
  const heading = school
    ? `${school.name} classroom - how it works`
    : 'Why choose Learning with Experts?';
  return (
    <Section color={color}>
      <SectionHeading>{heading}</SectionHeading>

      <ul onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {featurettes.map((f, index) => {
          const featurette = {
            ...f,
            image: f.images[slug],
          };

          const clickHandler = isDesktop ? () => setSelected(index) : null;
          return (
            <Featurette
              featurette={featurette}
              selected={index === selected}
              key={index}
              onClick={clickHandler}
            />
          );
        })}
      </ul>
    </Section>
  );
};

ClassroomExplainer.fragments = {
  school: gql`
    fragment ClassroomExplainer__school on School {
      id
      slug
    }
  `,
};

export default ClassroomExplainer;
