import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { PrimaryButton } from 'components/Button';
import { withRouter } from 'react-router-v3';
import { useAddToBasket } from 'hooks/basket';

const AddToBasketButton = ({
  product,
  variant,
  variant: { availableToBuy } = {},
  router,
  ...props
}) => {
  const { className, isExpert, small, large, fullWidth, invert = true, giftOption } = props;

  const [gift, setGift] = useState(false);
  const addToBasket = useAddToBasket();

  const handleAddToBasket = useCallback(async () => {
    await addToBasket(variant, { gift });
    router.push({ pathname: '/basket', state: { basketAdded: true } });
  }, [router, product, variant, gift, addToBasket]);

  const isFree = variant.price.current == 0;
  const handleGiftChange = useCallback((e) => setGift(e.target.checked), [setGift]);

  if (!availableToBuy) {
    const { availableOn } = product;
    const availableSoon = availableOn && moment().isBefore(availableOn);
    const unavailableToBuyMessage = availableSoon ? `Coming soon` : `No longer available`;
    return (
      <div>
        <PrimaryButton
          disabled
          inverse={invert}
          primary={!isExpert}
          small={small}
          large={large}
          fullWidth={fullWidth}
          className={className}
        >
          {unavailableToBuyMessage}
        </PrimaryButton>
      </div>
    );
  }

  return (
    <div>
      <PrimaryButton
        onClick={handleAddToBasket}
        inverse={invert}
        primary={!isExpert}
        small={small}
        large={large}
        fullWidth={fullWidth}
        className={className}
      >
        Add to basket
      </PrimaryButton>
      {giftOption && (
        <label>
          <input defaultValue={gift} onChange={handleGiftChange} type="checkbox" />
          This is a gift
        </label>
      )}
    </div>
  );
};

export default withRouter(AddToBasketButton);
