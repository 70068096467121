import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  ${reset}

  #app {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  body {
    font-family: ${({ theme }) => theme.typography.fonts.primary};
  }

  strong, b {
    font-weight: 500;
  }

  em, i {
    font-style: italic;
  }

  h1, h2, h3 {
    font-family: ${({ theme }) =>
      theme.typography.fonts.secondary ?? theme.typography.fonts.primary};
  }

  /* TODO: legacy styles, remove when we can */
  div.container {
    padding: 20px;
  }

  span.notifier {
    position: absolute;
    top: -5px;
    right: -10px;
    padding: 3px 6px;
    color: white;
    font-size: 10px;
    font-weight: bold;
    background-color: rgb(201, 42, 69);
    border-radius: 10px;
    line-height: initial;
    z-index: 1;
  }

  div.field {
    margin-bottom: 10px;
    box-sizing: border-box;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    span.field-label {
      font-size: 12px;
    }
    & input,
    & textarea {
      background-color: rgba(0, 0, 0, 0.05);
      padding: 10px;
      font-size: 14px;
      resize: none;
    }
  }
`;
