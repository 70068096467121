import React, { useCallback } from 'react';
import Menu, { MenuLink } from '../Menu';
import { isAdmin, isModerator, isTutor, isObserver } from 'utils/roles';
import { Link } from 'react-router-v3';
import styled, { css } from 'styled-components';
import { font } from 'components/Typography';
import { Button } from 'components/Elements';
import s from './style.module.css';
import { persistBasketToken } from 'hooks/basket';
import { useLegacyLogoutAndRedirect } from 'hooks/user';
import { useConfiguration } from 'hooks/configuration';
import { toLocalId } from '@lwe/toolkit/utils';
import { useEaster } from 'hooks/useEaster';

const present = require(`./assets/icon-present.svg`);
const easter = require(`./assets/icon-easter.svg`);
const newWindow = require(`./assets/icon-newwindow.svg`);

const GiftLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${font.bold.group(6)}
  padding: 18px 0 18px 0;
  text-decoration: none;
  color: ${({ theme }) => theme.schools.floristry.primary};
`;

const GiftImage = styled.img`
  margin-right: 6px;

  ${({ showEaster }) =>
    showEaster &&
    css`
      height: 26px;
      margin-right: 10px;
    `}
`;

const BusinessLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${font.bold.group(6)}
  padding: 18px 0 18px 0;
  text-decoration: none;
`;

const Icon = styled.img`
  height: 16px;
  vertical-align: middle;
  margin-left: 4px;
`;

const CountBadge = ({ count }) => {
  if (!count) return null;
  const displayValue = count > 9 ? '9+' : count;
  return <span className={s.badge}>{displayValue}</span>;
};

const Home = ({ router, user, setSelected, closeNav, showLogin }) => {
  const { features } = useConfiguration();
  const logout = useLegacyLogoutAndRedirect(router);
  const handleLogout = useCallback(() => {
    logout();
    closeNav();
    persistBasketToken(undefined);
  }, [logout, closeNav, persistBasketToken]);
  const { showEaster } = useEaster();
  const icon = showEaster ? easter : present;

  const hasDashboardAccess =
    isTutor(user) || isAdmin(user) || isModerator(user) || isObserver(user);

  return (
    <Menu>
      {!user && (
        <Button onClick={showLogin} className={s.button} gray>
          Sign in / Join us
        </Button>
      )}
      {user && (
        <ul data-cy="nav-user-mobile">
          <li>
            <MenuLink as={Link} to="/classrooms" onClick={closeNav}>
              My Courses
            </MenuLink>
          </li>
          <li>
            <ul>
              {hasDashboardAccess && (
                <>
                  <li>
                    <Link to="/classrooms/dashboard" onClick={closeNav}>
                      Tutor Dashboard
                    </Link>
                  </li>
                  {isAdmin(user) && (
                    <li>
                      <Link to="/classrooms/admin" onClick={closeNav}>
                        Administration
                      </Link>
                    </li>
                  )}
                </>
              )}

              <li>
                <Link to="/messages" onClick={closeNav}>
                  Messages
                </Link>
              </li>

              <li>
                <Link to={`/profile/${toLocalId(user.id)}`} onClick={closeNav}>
                  Manage Account
                </Link>
              </li>

              {isTutor(user) && (
                <li>
                  <Link to="/classrooms/dashboard" onClick={closeNav}>
                    Students
                  </Link>
                </li>
              )}
              <li>
                <a onClick={handleLogout}>Sign Out</a>
              </li>
            </ul>
          </li>
        </ul>
      )}
      <ul data-cy="nav-primary-mobile">
        {features.schools && (
          <li>
            <MenuLink onClick={() => setSelected('courses')}>Courses</MenuLink>
          </li>
        )}
        {features.experts && (
          <li>
            <MenuLink onClick={() => setSelected('experts')}>Experts</MenuLink>
          </li>
        )}
        <li>
          <BusinessLink
            target="_blank"
            href="https://business.learningwithexperts.com"
            title="Online learning for business">
            Business
            <Icon src={newWindow} alt="Opens in a new window" />
          </BusinessLink>
        </li>
        {features.giftcards && (
          <li>
            <GiftLink to="/gifts" onClick={closeNav}>
              <GiftImage src={icon} showEaster={showEaster} alt="" />
              Gifts
            </GiftLink>
          </li>
        )}
        <li>
          <ul>
            <li>
              <a
                href="http://help.learningwithexperts.com/en/"
                onClick={closeNav}
                rel="noreferrer"
                target="_blank"
              >
                FAQs
              </a>
            </li>
            <li>
              <Link to="/about" onClick={closeNav}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/contact-us" onClick={closeNav}>
                Contact Us
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </Menu>
  );
};

export default Home;
