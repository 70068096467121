import React from 'react';
import moment from 'moment';
import Avatar from 'components/Avatar';
import SearchResult, { Heading, Subheading, Tags, Tag } from 'components/SearchResult';
import { gql } from '@apollo/client';

const BlogPostSearchResult = ({ result: { id, title, slug, school, author, publishedAt } }) => {
  if (!author) return null;
  return (
    <SearchResult to={`/${school.slug}/blog/${slug}`} preview={<Avatar size={70} user={author} />}>
      <Heading>{title}</Heading>
      <Subheading>
        By {author.profile.name} • {moment(publishedAt).format('LL')}
      </Subheading>
      <Tags>
        <Tag>Blog</Tag>
        <Tag className={school.slug}>{school.name}</Tag>
      </Tags>
    </SearchResult>
  );
};

BlogPostSearchResult.fragments = {
  result: gql`
    fragment BlogPostSearchResult_result on BlogPost {
      id
      title
      slug
      publishedAt
      school {
        id
        slug
        name
      }
      author {
        id
        ...Avatar__user
      }
    }
    ${Avatar.fragments.user}
  `,
};

export default BlogPostSearchResult;
